import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { connect, ConnectedProps } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { imageUpload } from 'store/actions';
import { getHighlightedSection, scrollHandle } from '../utils';
import Tooltip from '@mui/material/Tooltip';

const AIWriteItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    imageURL,
    imageUploadReq,
    loading,
    imageloading,
    AIActivityDetail,
    nativeLang,
    targetLang,
  } = props;

  const urlHashRef = React.useRef<any>({});

  const [openTitle1, setOpenTitle1] = useState(false);
  const [openAnswer1, setOpenAnswer1] = useState(false);

  const [activeKey, setActiveKey] = useState('');
  const [aiWriteITImage1, setAIWriteITImage1] = useState('');
  const [aiWriteITImageLoader1, setAIWriteITImageLoader1] = useState(false);
  const [aiWriteITImageValidation1, setAIWriteITImageValidation1] =
    useState(false);

  useEffect(() => {
    if (AIActivityDetail != '' && AIActivityDetail != undefined) {
      let Question1_Value =
        AIActivityDetail?.Write_IT?.Questions[0]?.Question?.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_Answer_Value =
        AIActivityDetail?.Write_IT?.Questions[0]?.Answer?.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question1_ID = AIActivityDetail?.Write_IT?.Questions[0]?.Question_ID;
      urlHashRef.current.one = Question1_ID;

      setValue('AI_WriteIt_Question1_QuestionID', Question1_ID);
      setValue('AI_WriteIt_Question1_Title', Question1_Value?.Title);
      setValue('AI_WriteIt_Question1_Answer', Question1_Answer_Value?.Answer);
      setAIWriteITImage1(Question1_Value?.Image);
      setValue('AI_WriteIt_Question1_Image', Question1_Value?.Image);

      setValue(
        'AI_WriteIt_Question1_Roman_Title',
        Question1_Value?.Title_Roman_Language
      );
      setValue(
        'AI_WriteIt_Question1_Roman_Answer',
        Question1_Answer_Value?.Answer_Roman_Language
      );
      // setValue("AI_WriteIt_Question1_Roman_Type", Question1_Value?.Type_Roman_Language)
      // setValue("AI_WriteIt_Question1_Roman_Description", Question1_Value?.Description_Roman_Language)

      setValue(
        'AI_WriteIt_Question1_Audio_Title',
        Question1_Value?.Audio_Title
      );
      setValue(
        'AI_WriteIt_Question1_Audio_Answer',
        Question1_Answer_Value?.Audio_Answer
      );
    }
  }, [AIActivityDetail]);

  useEffect(() => {
    if (urlHashRef.current.one) {
      const data = getHighlightedSection(urlHashRef.current.one);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
  }, [urlHashRef.current.one]);

  useEffect(() => {
    if (imageURL != '') {
      if (activeKey === 'AI_WriteIt_Question1_Image') {
        setAIWriteITImage1(imageURL);
        setValue('AI_WriteIt_Question1_Image', imageURL);
        setActiveKey('');
      }
    }
  }, [imageURL]);

  useEffect(() => {
    if (imageloading) {
      if (activeKey === 'AI_WriteIt_Question1_Image') {
        setAIWriteITImageLoader1(true);
      }
    }
    if (!imageloading) {
      setAIWriteITImageLoader1(false);
    }
  }, [imageloading]);

  useEffect(() => {
    setAIWriteITImage1(aiWriteITImage1);
    setValue('AI_WriteIt_Question1_Image', aiWriteITImage1);
    setAIWriteITImageValidation1(false);
  }, [aiWriteITImage1]);

  const handleChnageImage = (event: any) => {
    if (event.target.files[0].size <= 2097152) {
      setActiveKey(event.target.name);
      imageUploadReq({ file: event.target.files[0] });
    } else {
      if (event.target.name == 'AI_WriteIt_Question1_Image') {
        setAIWriteITImageValidation1(true);
      }
    }
  };

  const hash = urlHashRef.current;

  return (
    <React.Fragment>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                Grammar Sentence
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.one)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Complete Sentence <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{ disablePortal: true }}
                    onClose={() => setOpenTitle1(false)}
                    open={openTitle1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.WriteItTitle
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Title'
                      {...register('AI_WriteIt_Question1_Title')}
                      onFocus={() => setOpenTitle1(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.AI_WriteIt_Question1_Title && (
                    <p style={{ color: 'red' }}>
                      {errors.AI_WriteIt_Question1_Title.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Answer. Must match Complete Sentence{' '}
                    <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenAnswer1(false)}
                    open={openAnswer1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.AI_WriteIt_Question1_Answer
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Answer'
                      {...register('AI_WriteIt_Question1_Answer')}
                      onFocus={() => setOpenAnswer1(true)}
                    ></input>
                  </Tooltip>
                  {errors.AI_WriteIt_Question1_Answer && (
                    <p style={{ color: 'red' }}>
                      {errors.AI_WriteIt_Question1_Answer.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              {/* {sectionImage && */}
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Upload Image <span className='astrick'>*</span>
                  </label>
                  <div className='question-image'>
                    <LoadingOverlay
                      active={aiWriteITImageLoader1}
                      spinner
                      className='spinner-animation'
                    >
                      <img src={aiWriteITImage1} />
                    </LoadingOverlay>
                  </div>
                  <div className='main-cercle-div'>
                    <input
                      type='file'
                      className='form-control'
                      name='AI_WriteIt_Question1_Image'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => handleChnageImage(e)}
                    />
                    {aiWriteITImageValidation1 ? (
                      <p style={{ color: 'red' }}>
                        Less then 2MB | Only Image File Allowed - .jpg.png
                      </p>
                    ) : aiWriteITImage1 == '' ? (
                      errors.AI_WriteIt_Question1_Image && (
                        <p style={{ color: 'red' }}>
                          {errors.AI_WriteIt_Question1_Image.message}
                        </p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              {/* } */}
            </div>
          </div>
          <br />
        </Collapsible>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    imageURL: state.lesson.imageURL,
    imageloading: state.lesson.imageloading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  imageUploadReq: (param: any) => dispatch(imageUpload(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AIWriteItActivity);
