import React from 'react';
import { useAsyncDebounce } from 'react-table';

import { useTableInstance } from './index';

export const TableGlobalSearch: React.FC = () => {
  const tableInstance = useTableInstance();

  const { setGlobalFilter, state } = tableInstance;

  const handleChange = useAsyncDebounce(
    (e) => setGlobalFilter(e.target.value),
    600
  );

  return (
    <input
      type='search'
      className='form-control'
      placeholder='Search'
      onChange={handleChange}
      defaultValue={state.globalFilter}
    />
  );
};
