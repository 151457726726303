import React from 'react';

import useLoadingState from './useLoadingState';

export default function useBackendApiCaller(caller: any, deps: any[] = []) {
  const [data, setData] = React.useState<any>({});

  const { loading, startLoading, stopLoading } = useLoadingState();

  const fetch = React.useCallback(async () => {
    startLoading();
    const data = await caller();
    setData(data);
    stopLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return [loading, data, fetch];
}
