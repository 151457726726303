import {
    AI_ACTIVITY_IMAGE_DETAILS_FAILURE,
    AI_ACTIVITY_IMAGE_DETAILS_GET,
    AI_ACTIVITY_IMAGE_DETAILS_GET_SUCCESS,
    AI_ACTIVITY_IMAGE_DETAILS_REMOVE,
    AI_ACTIVITY_IMAGE_DETAILS_REMOVE_SUCCESS,
} from "./actionTypes";

export const aiActivityImageGet = (payload: any) => ({
    type: AI_ACTIVITY_IMAGE_DETAILS_GET,
    payload
});

export const aiActivityImageGetSuccess = (payload: any) => ({
    type: AI_ACTIVITY_IMAGE_DETAILS_GET_SUCCESS,
    payload,
});


export const aiActivityImageRemove = (payload: any) => ({
    type: AI_ACTIVITY_IMAGE_DETAILS_REMOVE,
    payload,
});

export const aiActivityImageRemoveSuccess = (payload: any) => ({
    type: AI_ACTIVITY_IMAGE_DETAILS_REMOVE_SUCCESS,
    payload,
});

export const aiActivityImageFailure = (payload: any) => ({
    type: AI_ACTIVITY_IMAGE_DETAILS_FAILURE,
    payload,
});
