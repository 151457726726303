import React, { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import createNotification from '../../../helpers/alert';
import Swal from 'sweetalert2';
import { BiPlus } from 'react-icons/bi';
import {
  ClearSuccesError,
  lessonIDExistorNot,
  lessonLanguageGet,
  lessonSave,
  levelGet,
} from '../../../store/actions';
import VocabularySection from '../section/vocabulary-section';
import ConversationSection from '../section/conversation-section';
import GrammarSection from '../section/grammar-section';
import QuizSection from '../section/quiz-section';
import CancelConfirmLessonModel from './LessonCancelConfirmModal';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import Loader from 'app/components/Loader';
import { Spinner } from 'react-bootstrap';
import AddSection from '../add-section/add-section';
import AIActivitySection from '../section/ai-activity-section';

const LessonAddPage: FC = (props: any) => {
  const {
    lessonsaveReq,
    success,
    error,
    lessonLanguageGetReq,
    lessonLanguageList,
    ClearSuccesErrorReq,
    loading,
    levellist,
    onGetAllLevel,
    onCheckLessonIDExistOrNot,
    lessonIDError,
    lessonIDErrorLoading,
  } = props;

  const navigate = useNavigate();
  const [selectAge, setSelectAge] = useState('');
  const [selectLevel, setSelectLevel] = useState('');
  const [selectAgeValue, setSelectAgeValue] = useState('');
  const [selectLevelValue, setSelectLevelValue] = useState('');

  const [selectLevelError, setSelectLevelError] = useState(false);
  const [selectAgeError, setSelectAgeError] = useState(false);

  const [allLanguage, setAllLanguage] = useState([]);

  const [vocabularyComponents, setVocabularyComponents] = useState<any>(false);
  const [quizComponents, setQuizComponents] = useState<any>(false);
  const [grammarComponents, setGrammarComponents] = useState<any>(false);
  const [conversationComponents, setConversationComponents] =
    useState<any>(false);
  const [aiActivityComponents, setAIActivityComponents] = useState<any>(false);
  const [cancelConfirmModel, setCancelConfirmModel] = useState(false);
  const [levelData, setLevelData] = useState([]);
  const [levelItem, setLevelItem] = useState(null);
  const [levelValue, seLevelValue] = useState(null);
  const [checkPremium, setCheckPremium] = useState(false);
  const [existIDError, setExistIDError] = useState(false);

  const [nativeLang, setNativeLang] = useState('en');
  const [targetLang, setTargetLang] = useState('en');

  const options: any = [
    { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
    { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
    { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
  ];

  useEffect(() => {
    onGetAllLevel();
  }, []);

  useEffect(() => {
    if (levellist?.length > 0) {
      let newRecord: any = [];
      levellist.forEach((element: any) => {
        let newData = {
          label: element.Level_Name,
          value: element._id,
        };
        newRecord.push(newData);
      });
      const uniqueData: any = [...new Set(newRecord)];
      setLevelData(uniqueData);
    }
  }, [levellist]);

  useEffect(() => {
    if (levelItem) {
      setLevelItem(levelItem);
    }
  }, [levelItem]);

  useEffect(() => {
    if (success) {
      ClearSuccesErrorReq();
      navigate('/lesson');
      Swal.fire('lesson add successfully', 'save', 'success');
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      createNotification('warning', 'something went wrong!');
    }
  }, [error]);

  useEffect(() => {
    if (lessonIDError) {
      setExistIDError(true);
    } else {
      setExistIDError(false);
    }
  }, [lessonIDError]);

  const regMatch =
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

  const validationSchema: any = yup.object().shape({
    LessonName: yup.string().required('Please enter lesson name'),
    Lesson_Age: yup.string().required('Please enter lesson name'),
    Lesson_Level: yup.string().required('Please enter lesson name'),

    LessonID: yup.string().required('Please enter lesson id'),

    //vocabulary Section

    // VocabularyVideoUrl: yup.string().required("Please enter video url").matches(regMatch, "Website should be a valid URL"),
    VocabularyVideoUrl:
      vocabularyComponents && yup.string().required('Please enter video url'),
    Vocabulary_Upload_VideoUrl:
      vocabularyComponents && yup.string().required('Something want wrong'),

    //Vocabulary Tap_It_Activity Questions1
    Vocabulary_TapIt_Question1_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_TapIt_Question1_Image:
      vocabularyComponents && yup.string().required('Please select image'),
    Vocabulary_TapIt_Question1_Option1:
      vocabularyComponents && yup.string().required('Please enter option1'),
    Vocabulary_TapIt_Question1_Option2:
      vocabularyComponents && yup.string().required('Please enter option2'),
    Vocabulary_TapIt_Question1_Option3:
      vocabularyComponents && yup.string().required('Please enter option3'),
    Vocabulary_TapIt_Question1_Option4:
      vocabularyComponents && yup.string().required('Please enter option4'),

    //Vocabulary Tap_It_Activity Questions2
    Vocabulary_TapIt_Question2_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_TapIt_Question2_Image:
      vocabularyComponents && yup.string().required('Please select image'),
    Vocabulary_TapIt_Question2_Option1:
      vocabularyComponents && yup.string().required('Please enter option1'),
    Vocabulary_TapIt_Question2_Option2:
      vocabularyComponents && yup.string().required('Please enter option2'),
    Vocabulary_TapIt_Question2_Option3:
      vocabularyComponents && yup.string().required('Please enter option3'),
    Vocabulary_TapIt_Question2_Option4:
      vocabularyComponents && yup.string().required('Please enter option4'),

    //Vocabulary Tap_It_Activity Questions3
    Vocabulary_TapIt_Question3_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_TapIt_Question3_Image:
      vocabularyComponents && yup.string().required('Please select image'),
    Vocabulary_TapIt_Question3_Option1:
      vocabularyComponents && yup.string().required('Please enter option1'),
    Vocabulary_TapIt_Question3_Option2:
      vocabularyComponents && yup.string().required('Please enter option2'),
    Vocabulary_TapIt_Question3_Option3:
      vocabularyComponents && yup.string().required('Please enter option3'),
    Vocabulary_TapIt_Question3_Option4:
      vocabularyComponents && yup.string().required('Please enter option4'),

    //Vocabulary Say_It_Activity Questions1
    Vocabulary_SayIt_Question1_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_SayIt_Question1_Answer:
      vocabularyComponents && yup.string().required('Please enter answer'),
    Vocabulary_SayIt_Question1_Image:
      vocabularyComponents && yup.string().required('Please select image'),

    //Vocabulary Say_It_Activity Questions2
    Vocabulary_SayIt_Question2_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_SayIt_Question2_Answer:
      vocabularyComponents && yup.string().required('Please enter answer'),
    Vocabulary_SayIt_Question2_Image:
      vocabularyComponents && yup.string().required('Please select image'),

    // //Do not remove this comment code
    //Vocabulary Say_It_Activity Questions3
    // Vocabulary_SayIt_Question3_Title: vocabularyComponents && yup.string().required("Please enter title"),
    // Vocabulary_SayIt_Question3_Answer: vocabularyComponents && yup.string().required("Please enter answer"),
    // Vocabulary_SayIt_Question3_Image: vocabularyComponents && yup.string().required("Please select image"),

    // Vocabulary Write_It_Activity Questions1
    Vocabulary_WriteIt_Question1_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_WriteIt_Question1_Answer:
      vocabularyComponents && yup.string().required('Please enter answer'),
    Vocabulary_WriteIt_Question1_Image:
      vocabularyComponents && yup.string().required('Please select image'),

    // Vocabulary Write_It_Activity Questions2
    Vocabulary_WriteIt_Question2_Title:
      vocabularyComponents && yup.string().required('Please enter title'),
    Vocabulary_WriteIt_Question2_Answer:
      vocabularyComponents && yup.string().required('Please enter answer'),
    Vocabulary_WriteIt_Question2_Image:
      vocabularyComponents && yup.string().required('Please select image'),

    // Vocabulary Listen_It_Activity Questions1
    Vocabulary_ListenIt_Question1_AudioText:
      vocabularyComponents && yup.string().required('Please enter audio text'),
    Vocabulary_ListenIt_Question1_Option1:
      vocabularyComponents && yup.string().required('Please enter option1'),
    Vocabulary_ListenIt_Question1_Option2:
      vocabularyComponents && yup.string().required('Please enter option2'),
    Vocabulary_ListenIt_Question1_Option3:
      vocabularyComponents && yup.string().required('Please enter option3'),
    Vocabulary_ListenIt_Question1_Option4:
      vocabularyComponents && yup.string().required('Please enter option4'),

    // Vocabulary Listen_It_Activity Questions2
    Vocabulary_ListenIt_Question2_AudioText:
      vocabularyComponents && yup.string().required('Please enter audio text'),
    Vocabulary_ListenIt_Question2_Option1:
      vocabularyComponents && yup.string().required('Please enter option1'),
    Vocabulary_ListenIt_Question2_Option2:
      vocabularyComponents && yup.string().required('Please enter option2'),
    Vocabulary_ListenIt_Question2_Option3:
      vocabularyComponents && yup.string().required('Please enter option3'),
    Vocabulary_ListenIt_Question2_Option4:
      vocabularyComponents && yup.string().required('Please enter option4'),

    // // Vocabulary Listen_It_Activity Questions3
    // Vocabulary_ListenIt_Question3_AudioText: vocabularyComponents && yup.string().required("Please enter audio text"),
    // Vocabulary_ListenIt_Question3_Option1: vocabularyComponents && yup.string().required("Please enter option1"),
    // Vocabulary_ListenIt_Question3_Option2: vocabularyComponents && yup.string().required("Please enter option2"),
    // Vocabulary_ListenIt_Question3_Option3: vocabularyComponents && yup.string().required("Please enter option3"),
    // Vocabulary_ListenIt_Question3_Option4: vocabularyComponents && yup.string().required("Please enter option4"),

    // // Vocabulary Listen_It_Activity Questions4
    // Vocabulary_ListenIt_Question4_AudioText: vocabularyComponents && yup.string().required("Please enter audio text"),
    // Vocabulary_ListenIt_Question4_Option1: vocabularyComponents && yup.string().required("Please enter option1"),
    // Vocabulary_ListenIt_Question4_Option2: vocabularyComponents && yup.string().required("Please enter option2"),
    // Vocabulary_ListenIt_Question4_Option3: vocabularyComponents && yup.string().required("Please enter option3"),
    // Vocabulary_ListenIt_Question4_Option4: vocabularyComponents && yup.string().required("Please enter option4"),

    // Conversation Section
    ConversationVideoUrl:
      conversationComponents &&
      yup
        .string()
        .required('Please enter video url')
        .matches(regMatch, 'Website should be a valid URL'),
    Conversation_Upload_VideoUrl:
      conversationComponents && yup.string().required('Something want wrong'),

    //Conversations Tap_It_Activity Questions1
    Conversation_TapIt_Question1_Title:
      conversationComponents && yup.string().required('Please enter title'),
    Conversation_TapIt_Question1_Image:
      conversationComponents && yup.string().required('Please select image'),
    Conversation_TapIt_Question1_Option1:
      conversationComponents && yup.string().required('Please enter option1'),
    Conversation_TapIt_Question1_Option2:
      conversationComponents && yup.string().required('Please enter option2'),
    Conversation_TapIt_Question1_Option3:
      conversationComponents && yup.string().required('Please enter option3'),
    Conversation_TapIt_Question1_Option4:
      conversationComponents && yup.string().required('Please enter option4'),

    //Conversations Tap_It_Activity Questions2
    Conversation_TapIt_Question2_Title:
      conversationComponents && yup.string().required('Please enter title'),
    Conversation_TapIt_Question2_Image:
      conversationComponents && yup.string().required('Please select image'),
    Conversation_TapIt_Question2_Option1:
      conversationComponents && yup.string().required('Please enter option1'),
    Conversation_TapIt_Question2_Option2:
      conversationComponents && yup.string().required('Please enter option2'),
    Conversation_TapIt_Question2_Option3:
      conversationComponents && yup.string().required('Please enter option3'),
    Conversation_TapIt_Question2_Option4:
      conversationComponents && yup.string().required('Please enter option4'),

    //Conversations Tap_It_Activity Questions3
    Conversation_TapIt_Question3_Title:
      conversationComponents && yup.string().required('Please enter title'),
    Conversation_TapIt_Question3_Image:
      conversationComponents && yup.string().required('Please select image'),
    Conversation_TapIt_Question3_Option1:
      conversationComponents && yup.string().required('Please enter option1'),
    Conversation_TapIt_Question3_Option2:
      conversationComponents && yup.string().required('Please enter option2'),
    Conversation_TapIt_Question3_Option3:
      conversationComponents && yup.string().required('Please enter option3'),
    Conversation_TapIt_Question3_Option4:
      conversationComponents && yup.string().required('Please enter option4'),

    // //Conversations Say_It_Activity Questions1
    Conversation_SayIt_Question1_Title:
      conversationComponents && yup.string().required('Please enter title'),
    Conversation_SayIt_Question1_Title1:
      conversationComponents && yup.string().required('Please enter title1'),
    Conversation_SayIt_Question1_Answer:
      conversationComponents && yup.string().required('Please enter answer'),
    // Conversation_SayIt_Question1_Image: yup.string().required("Please select image"),

    //Conversations Say_It_Activity Questions2
    Conversation_SayIt_Question2_Title:
      conversationComponents && yup.string().required('Please enter title'),
    Conversation_SayIt_Question2_Title1:
      conversationComponents && yup.string().required('Please enter title1'),
    Conversation_SayIt_Question2_Answer:
      conversationComponents && yup.string().required('Please enter answer'),
    // Conversation_SayIt_Question2_Image: yup.string().required("Please select image"),

    //Conversations Say_It_Activity Questions3

    //Ask Chintan bhai if remove this code
    //Do not remove this comment code

    // Conversation_SayIt_Question3_Title: conversationComponents && yup.string().required("Please enter title"),
    // Conversation_SayIt_Question3_Title1: conversationComponents && yup.string().required("Please enter title1"),
    // Conversation_SayIt_Question3_Answer: conversationComponents && yup.string().required("Please enter answer"),
    // Conversation_SayIt_Question3_Image: yup.string().required("Please select image"),

    //Conversations Write_It_Activity
    Conversation_WriteIt_Question1_Title:
      conversationComponents && yup.string().required('Please enter title'),
    Conversation_WriteIt_Question1_Answer:
      conversationComponents && yup.string().required('Please enter answer'),
    Conversation_WriteIt_Question1_Image:
      conversationComponents && yup.string().required('Please select image'),

    // Grammar Section
    GrammarVideoUrl:
      grammarComponents &&
      yup
        .string()
        .required('Please enter video url')
        .matches(regMatch, 'Website should be a valid URL'),
    Grammar_Upload_VideoUrl:
      grammarComponents && yup.string().required('Something want wrong'),

    //Grammar Tap_It_Activity Questions1
    Grammar_TapIt_Question1_Title:
      grammarComponents && yup.string().required('Please enter title'),
    Grammar_TapIt_Question1_Option1:
      grammarComponents && yup.string().required('Please enter option1'),
    Grammar_TapIt_Question1_Option2:
      grammarComponents && yup.string().required('Please enter option2'),
    Grammar_TapIt_Question1_Option3:
      grammarComponents && yup.string().required('Please enter option3'),
    Grammar_TapIt_Question1_Option4:
      grammarComponents && yup.string().required('Please enter option4'),

    //Grammar Tap_It_Activity Questions2
    Grammar_TapIt_Question2_Title:
      grammarComponents && yup.string().required('Please enter title'),
    Grammar_TapIt_Question2_Option1:
      grammarComponents && yup.string().required('Please enter option1'),
    Grammar_TapIt_Question2_Option2:
      grammarComponents && yup.string().required('Please enter option2'),
    Grammar_TapIt_Question2_Option3:
      grammarComponents && yup.string().required('Please enter option3'),
    Grammar_TapIt_Question2_Option4:
      grammarComponents && yup.string().required('Please enter option4'),

    //Grammar Tap_It_Activity Questions3
    Grammar_TapIt_Question3_Title:
      grammarComponents && yup.string().required('Please enter title'),
    Grammar_TapIt_Question3_Option1:
      grammarComponents && yup.string().required('Please enter option1'),
    Grammar_TapIt_Question3_Option2:
      grammarComponents && yup.string().required('Please enter option2'),
    Grammar_TapIt_Question3_Option3:
      grammarComponents && yup.string().required('Please enter option3'),
    Grammar_TapIt_Question3_Option4:
      grammarComponents && yup.string().required('Please enter option4'),

    //Grammar Tap_It_Activity Questions4
    Grammar_TapIt_Question4_Title:
      grammarComponents && yup.string().required('Please enter title'),
    Grammar_TapIt_Question4_Option1:
      grammarComponents && yup.string().required('Please enter option1'),
    Grammar_TapIt_Question4_Option2:
      grammarComponents && yup.string().required('Please enter option2'),
    Grammar_TapIt_Question4_Option3:
      grammarComponents && yup.string().required('Please enter option3'),
    Grammar_TapIt_Question4_Option4:
      grammarComponents && yup.string().required('Please enter option4'),

    //Grammar Write_It_Activity
    Grammar_WriteIt_Question1_Title:
      grammarComponents && yup.string().required('Please enter title'),
    Grammar_WriteIt_Question1_Answer:
      grammarComponents && yup.string().required('Please enter answer'),
    Grammar_WriteIt_Question1_Image:
      grammarComponents && yup.string().required('Please select image'),

    //Grammar Read_It_Activity
    Grammar_ReadIt_Title:
      grammarComponents && yup.string().required('Please enter title'),
    Grammar_ReadIt_Description:
      grammarComponents && yup.string().required('Please enter description'),

    // //Quiz Section
    // Quiz_Title: quizComponents && yup.string().required("Please enter title"),
    // Quiz_Description: quizComponents && yup.string().required("Please enter description"),

    //Quiz Tap_It_Activity Questions1
    Quiz_TapIt_Question1_Title:
      quizComponents && yup.string().required('Please enter title'),
    Quiz_TapIt_Question1_Image:
      quizComponents && yup.string().required('Please select image'),
    Quiz_TapIt_Question1_Option1:
      quizComponents && yup.string().required('Please enter option1'),
    Quiz_TapIt_Question1_Option2:
      quizComponents && yup.string().required('Please enter option2'),
    Quiz_TapIt_Question1_Option3:
      quizComponents && yup.string().required('Please enter option3'),
    Quiz_TapIt_Question1_Option4:
      quizComponents && yup.string().required('Please enter option4'),

    //Quiz Tap_It_Activity Questions2
    Quiz_TapIt_Question2_Title:
      quizComponents && yup.string().required('Please enter title'),
    Quiz_TapIt_Question2_Image:
      quizComponents && yup.string().required('Please select image'),
    Quiz_TapIt_Question2_Option1:
      quizComponents && yup.string().required('Please enter option1'),
    Quiz_TapIt_Question2_Option2:
      quizComponents && yup.string().required('Please enter option2'),
    Quiz_TapIt_Question2_Option3:
      quizComponents && yup.string().required('Please enter option3'),
    Quiz_TapIt_Question2_Option4:
      quizComponents && yup.string().required('Please enter option4'),

    //Quiz Tap_It_Activity Questions3
    Quiz_TapIt_Question3_Title:
      quizComponents && yup.string().required('Please enter title'),
    Quiz_TapIt_Question3_Image:
      quizComponents && yup.string().required('Please select image'),
    Quiz_TapIt_Question3_Option1:
      quizComponents && yup.string().required('Please enter option1'),
    Quiz_TapIt_Question3_Option2:
      quizComponents && yup.string().required('Please enter option2'),
    Quiz_TapIt_Question3_Option3:
      quizComponents && yup.string().required('Please enter option3'),
    Quiz_TapIt_Question3_Option4:
      quizComponents && yup.string().required('Please enter option4'),

    //Quiz Write_It_Activity Questions1
    Quiz_WriteIt_Question1_Title:
      quizComponents && yup.string().required('Please enter title'),
    Quiz_WriteIt_Question1_Answer:
      quizComponents && yup.string().required('Please enter answer'),
    Quiz_WriteIt_Question1_Image:
      quizComponents && yup.string().required('Please select image'),

    // Quiz Listen_It_Activity Questions1
    Quiz_ListenIt_Question1_AudioText:
      quizComponents && yup.string().required('Please enter audio text'),
    Quiz_ListenIt_Question1_Option1:
      quizComponents && yup.string().required('Please enter option1'),
    Quiz_ListenIt_Question1_Option2:
      quizComponents && yup.string().required('Please enter option2'),
    Quiz_ListenIt_Question1_Option3:
      quizComponents && yup.string().required('Please enter option3'),
    Quiz_ListenIt_Question1_Option4:
      quizComponents && yup.string().required('Please enter option4'),

    //AI Activity
    AI_Background_Image_Slide1:
      aiActivityComponents && yup.string().required('Please select image'),
    AI_Background_Image_Slide2:
      aiActivityComponents && yup.string().required('Please select image'),

    //AI WriteIT Activity
    AI_WriteIt_Question1_Image:
      aiActivityComponents && yup.string().required('Please select image'),
    AI_WriteIt_Question1_Title:
      aiActivityComponents && yup.string().required('Please enter title'),
    AI_WriteIt_Question1_Answer:
      aiActivityComponents && yup.string().required('Please enter answer'),

    // AI_Pick_Word_Question1_Option1: aiActivityComponents && yup.array().of(yup.string()).min(1, "option1 are required"),
    // AI_Pick_Word_Question1_Option2: aiActivityComponents && yup.array().of(yup.string()).min(1, "option2 are required"),

    // AI_Pick_Word_Question1_Option2: aiActivityComponents && yup.string().transform(value => {
    //   if (Array.isArray(value)) {
    //     return value[0];
    //   }
    //   return value;
    // }).required("Please enter Option"),
    AI_Pick_Word_Question1_Question_Sentence1:
      aiActivityComponents &&
      yup
        .string()
        .required('Please enter sentence')
        .test(
          'custom-validation',
          'Required at list one blank',
          function (value: any) {
            let count = 0;
            value
              .trim()
              .split(' ')
              .map((word: any, index: number) => {
                if (word.includes('_')) {
                  count++;
                }
              });
            if (count < 1) {
              return false;
            } else {
              return true;
            }
          }
        ),
    AI_Pick_Word_Question1_Question_Sentence2:
      aiActivityComponents &&
      yup
        .string()
        .required('Please enter sentence')
        .test(
          'custom-validation',
          'Required at list one blank',
          function (value: any) {
            let count = 0;
            value
              .trim()
              .split(' ')
              .map((word: any, index: number) => {
                if (word.includes('_')) {
                  count++;
                }
              });
            if (count < 1) {
              return false;
            } else {
              return true;
            }
          }
        ),

    // images: aiActivityComponents && yup.array().of(
    //   yup
    //     .mixed()
    //     .test('fileSize', 'Image size must be less than 2MB', function (value) {
    //       const file = value[0];
    //       return file && file.size <= 2 * 1024 * 1024; // 2MB limit
    //     })
    //     .test('fileType', 'Only image files are allowed', function (value) {
    //       const file = value[0];
    //       return file && file.type.includes('image');
    //     })
    // ),
    Option1_Images:
      aiActivityComponents &&
      yup.array().of(yup.string().required('Please select image')),
    Option1_Options:
      aiActivityComponents &&
      yup.array().of(yup.string().required('Please enter option')),
    Option1_Fake_Options:
      aiActivityComponents &&
      yup.array().of(yup.string().required('Please enter fake option')),

    Option2_Images:
      aiActivityComponents &&
      yup.array().of(yup.string().required('Please select image')),
    Option2_Options:
      aiActivityComponents &&
      yup.array().of(yup.string().required('Please enter option')),
    Option2_Fake_Options:
      aiActivityComponents &&
      yup.array().of(yup.string().required('Please enter fake option')),
  });

  let option1: any = [];

  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onInvalid = async (event: any) => {
    if (
      !vocabularyComponents &&
      !conversationComponents &&
      !quizComponents &&
      !grammarComponents &&
      !aiActivityComponents
    ) {
      toast.dismiss();
      return toast.error('Please select one activity');
    }
    const Vocabulary_Tap_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event.Vocabulary_TapIt_Question1_Option1,
      Option2: event.Vocabulary_TapIt_Question1_Option2,
      Option3: event.Vocabulary_TapIt_Question1_Option3,
      Option4: event.Vocabulary_TapIt_Question1_Option4,
    };
    const Vocabulary_Tap_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_TapIt_Question1_Answer,
      Image: event.Vocabulary_TapIt_Question1_Image
        ? event.Vocabulary_TapIt_Question1_Image
        : '',
      Title: event.Vocabulary_TapIt_Question1_Title,
      Type: event.Vocabulary_TapIt_Question1_Type,
      Description: event.Vocabulary_TapIt_Question1_Description
        ? event.Vocabulary_TapIt_Question1_Description
        : '',
    };
    const Vocabulary_Tap_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Option1: event.Vocabulary_TapIt_Question2_Option1,
      Option2: event.Vocabulary_TapIt_Question2_Option2,
      Option3: event.Vocabulary_TapIt_Question2_Option3,
      Option4: event.Vocabulary_TapIt_Question2_Option4,
    };
    const Vocabulary_Tap_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_TapIt_Question2_Answer,
      Image: event.Vocabulary_TapIt_Question2_Image
        ? event.Vocabulary_TapIt_Question2_Image
        : '',
      Title: event.Vocabulary_TapIt_Question2_Title,
      Type: event.Vocabulary_TapIt_Question2_Type,
      Description: event.Vocabulary_TapIt_Question2_Description
        ? event.Vocabulary_TapIt_Question2_Description
        : '',
    };
    const Vocabulary_Tap_IT_Answer_Data3 = {
      Language_Code: nativeLang,
      Option1: event.Vocabulary_TapIt_Question3_Option1,
      Option2: event.Vocabulary_TapIt_Question3_Option2,
      Option3: event.Vocabulary_TapIt_Question3_Option3,
      Option4: event.Vocabulary_TapIt_Question3_Option4,
    };

    const Vocabulary_Tap_IT_Questions_Data3 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_TapIt_Question3_Answer,
      Image: event.Vocabulary_TapIt_Question3_Image
        ? event.Vocabulary_TapIt_Question3_Image
        : '',
      Title: event.Vocabulary_TapIt_Question3_Title,
      Type: event.Vocabulary_TapIt_Question3_Type,
      Description: event.Vocabulary_TapIt_Question3_Description
        ? event.Vocabulary_TapIt_Question3_Description
        : '',
    };
    const Vocabulary_Tap_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Tap_IT_Answer_Data1],
        },
        Question: {
          Language: [Vocabulary_Tap_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Tap_IT_Answer_Data2],
        },
        Question: {
          Language: [Vocabulary_Tap_IT_Questions_Data2],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Tap_IT_Answer_Data3],
        },
        Question: {
          Language: [Vocabulary_Tap_IT_Questions_Data3],
        },
      },
    ];

    const Vocabulary_Say_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_SayIt_Question1_Answer,
    };

    const Vocabulary_Say_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_SayIt_Question2_Answer,
    };
    // //Do not remove this comment code
    // const Vocabulary_Say_IT_Answer_Data3 = {
    //   Language_Code: nativeLang,
    //   Answer: event.Vocabulary_SayIt_Question3_Answer,
    // }

    const Vocabulary_Say_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.Vocabulary_SayIt_Question1_Image
        ? event.Vocabulary_SayIt_Question1_Image
        : '',
      Title: event.Vocabulary_SayIt_Question1_Title,
      Type: event.Vocabulary_SayIt_Question1_Type,
      Description: event.Vocabulary_SayIt_Question1_Description
        ? event.Vocabulary_SayIt_Question1_Description
        : '',
    };

    const Vocabulary_Say_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Image: event.Vocabulary_SayIt_Question2_Image
        ? event.Vocabulary_SayIt_Question2_Image
        : '',
      Title: event.Vocabulary_SayIt_Question2_Title,
      Type: event.Vocabulary_SayIt_Question2_Type,
      Description: event.Vocabulary_SayIt_Question2_Description
        ? event.Vocabulary_SayIt_Question2_Description
        : '',
    };

    // //Do not remove this comment code
    // const Vocabulary_Say_IT_Questions_Data3 = {
    //   Language_Code: nativeLang,
    //   Image: event.Vocabulary_SayIt_Question3_Image ? event.Vocabulary_SayIt_Question3_Image : "",
    //   Title: event.Vocabulary_SayIt_Question3_Title,
    //   Type: event.Vocabulary_SayIt_Question3_Type,
    //   Description: event.Vocabulary_SayIt_Question3_Description ? event.Vocabulary_SayIt_Question3_Description : "",
    // }

    const Vocabulary_Say_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Say_IT_Answer_Data1],
        },
        Question: {
          Language: [Vocabulary_Say_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Say_IT_Answer_Data2],
        },
        Question: {
          Language: [Vocabulary_Say_IT_Questions_Data2],
        },
      },
      // {
      //   Question_ID: Date.now().toString(36) + Math.random().toString(36).substr(2),
      //   Answer: {
      //     Language: [Vocabulary_Say_IT_Answer_Data3]
      //   },
      //   Question: {
      //     Language: [Vocabulary_Say_IT_Questions_Data3]
      //   }
      // }
    ];

    const Vocabulary_Write_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_WriteIt_Question1_Answer,
    };

    const Vocabulary_Write_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Vocabulary_WriteIt_Question2_Answer,
    };

    const Vocabulary_Write_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.Vocabulary_WriteIt_Question1_Image
        ? event.Vocabulary_WriteIt_Question1_Image
        : '',
      Title: event.Vocabulary_WriteIt_Question1_Title,
      Type: event.Vocabulary_WriteIt_Question1_Type,
      Description: event.Vocabulary_WriteIt_Question1_Description
        ? event.Vocabulary_WriteIt_Question1_Description
        : '',
    };
    const Vocabulary_Write_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Image: event.Vocabulary_WriteIt_Question2_Image
        ? event.Vocabulary_WriteIt_Question2_Image
        : '',
      Title: event.Vocabulary_WriteIt_Question2_Title,
      Type: event.Vocabulary_WriteIt_Question2_Type,
      Description: event.Vocabulary_WriteIt_Question2_Description
        ? event.Vocabulary_WriteIt_Question2_Description
        : '',
    };
    const Vocabulary_Write_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Write_IT_Answer_Data1],
        },
        Question: {
          Language: [Vocabulary_Write_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Write_IT_Answer_Data2],
        },
        Question: {
          Language: [Vocabulary_Write_IT_Questions_Data2],
        },
      },
    ];

    const Vocabulary_Listen_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event.Vocabulary_ListenIt_Question1_Option1,
      Option2: event.Vocabulary_ListenIt_Question1_Option2,
      Option3: event.Vocabulary_ListenIt_Question1_Option3,
      Option4: event.Vocabulary_ListenIt_Question1_Option4,
    };

    const Vocabulary_Listen_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      AudioText: event.Vocabulary_ListenIt_Question1_AudioText,
      Answer: event.Vocabulary_ListenIt_Question1_Answer,
    };

    const Vocabulary_Listen_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Option1: event.Vocabulary_ListenIt_Question2_Option1,
      Option2: event.Vocabulary_ListenIt_Question2_Option2,
      Option3: event.Vocabulary_ListenIt_Question2_Option3,
      Option4: event.Vocabulary_ListenIt_Question2_Option4,
    };

    const Vocabulary_Listen_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      AudioText: event.Vocabulary_ListenIt_Question2_AudioText,
      Answer: event.Vocabulary_ListenIt_Question2_Answer,
    };

    // // Do not remove this comment code

    // const Vocabulary_Listen_IT_Answer_Data3 = {
    //   Language_Code: nativeLang,
    //   Option1: event.Vocabulary_ListenIt_Question3_Option1,
    //   Option2: event.Vocabulary_ListenIt_Question3_Option2,
    //   Option3: event.Vocabulary_ListenIt_Question3_Option3,
    //   Option4: event.Vocabulary_ListenIt_Question3_Option4
    // }

    // const Vocabulary_Listen_IT_Questions_Data3 = {
    //   Language_Code: nativeLang,
    //   AudioText: event.Vocabulary_ListenIt_Question3_AudioText,
    //   Answer: event.Vocabulary_ListenIt_Question3_Answer,
    // }

    // const Vocabulary_Listen_IT_Answer_Data4 = {
    //   Language_Code: nativeLang,
    //   Option1: event.Vocabulary_ListenIt_Question4_Option1,
    //   Option2: event.Vocabulary_ListenIt_Question4_Option2,
    //   Option3: event.Vocabulary_ListenIt_Question4_Option3,
    //   Option4: event.Vocabulary_ListenIt_Question4_Option4
    // }

    // const Vocabulary_Listen_IT_Questions_Data4 = {
    //   Language_Code: nativeLang,
    //   AudioText: event.Vocabulary_ListenIt_Question4_AudioText,
    //   Answer: event.Vocabulary_ListenIt_Question4_Answer,
    // }

    const Vocabulary_Listen_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Listen_IT_Answer_Data1],
        },
        Question: {
          Language: [Vocabulary_Listen_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Vocabulary_Listen_IT_Answer_Data2],
        },
        Question: {
          Language: [Vocabulary_Listen_IT_Questions_Data2],
        },
      },
      // {
      //   Question_ID: Date.now().toString(36) + Math.random().toString(36).substr(2),
      //   Answer: {
      //     Language: [Vocabulary_Listen_IT_Answer_Data3]
      //   },
      //   Question: {
      //     Language: [Vocabulary_Listen_IT_Questions_Data3]
      //   }
      // },
      // {
      //   Question_ID: Date.now().toString(36) + Math.random().toString(36).substr(2),
      //   Answer: {
      //     Language: [Vocabulary_Listen_IT_Answer_Data4]
      //   },
      //   Question: {
      //     Language: [Vocabulary_Listen_IT_Questions_Data4]
      //   }
      // }
    ];

    const Conversation_Tap_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event.Conversation_TapIt_Question1_Option1,
      Option2: event.Conversation_TapIt_Question1_Option2,
      Option3: event.Conversation_TapIt_Question1_Option3,
      Option4: event.Conversation_TapIt_Question1_Option4,
    };
    const Conversation_Tap_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Conversation_TapIt_Question1_Answer,
      Image: event.Conversation_TapIt_Question1_Image
        ? event.Conversation_TapIt_Question1_Image
        : '',
      Title: event.Conversation_TapIt_Question1_Title,
      Type: event.Conversation_TapIt_Question1_Type,
      Description: event.Conversation_TapIt_Question1_Description
        ? event.Conversation_TapIt_Question1_Description
        : '',
    };
    const Conversation_Tap_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Option1: event.Conversation_TapIt_Question2_Option1,
      Option2: event.Conversation_TapIt_Question2_Option2,
      Option3: event.Conversation_TapIt_Question2_Option3,
      Option4: event.Conversation_TapIt_Question2_Option4,
    };
    const Conversation_Tap_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Conversation_TapIt_Question2_Answer,
      Image: event.Conversation_TapIt_Question2_Image
        ? event.Conversation_TapIt_Question2_Image
        : '',
      Title: event.Conversation_TapIt_Question2_Title,
      Type: event.Conversation_TapIt_Question2_Type,
      Description: event.Conversation_TapIt_Question2_Description
        ? event.Conversation_TapIt_Question2_Description
        : '',
    };
    const Conversation_Tap_IT_Answer_Data3 = {
      Language_Code: nativeLang,
      Option1: event.Conversation_TapIt_Question3_Option1,
      Option2: event.Conversation_TapIt_Question3_Option2,
      Option3: event.Conversation_TapIt_Question3_Option3,
      Option4: event.Conversation_TapIt_Question3_Option4,
    };

    const Conversation_Tap_IT_Questions_Data3 = {
      Language_Code: nativeLang,
      Answer: event.Conversation_TapIt_Question3_Answer,
      Image: event.Conversation_TapIt_Question3_Image
        ? event.Conversation_TapIt_Question3_Image
        : '',
      Title: event.Conversation_TapIt_Question3_Title,
      Type: event.Conversation_TapIt_Question3_Type,
      Description: event.Conversation_TapIt_Question3_Description
        ? event.Conversation_TapIt_Question3_Description
        : '',
    };
    const Conversation_Tap_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Conversation_Tap_IT_Answer_Data1],
        },
        Question: {
          Language: [Conversation_Tap_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Conversation_Tap_IT_Answer_Data2],
        },
        Question: {
          Language: [Conversation_Tap_IT_Questions_Data2],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Conversation_Tap_IT_Answer_Data3],
        },
        Question: {
          Language: [Conversation_Tap_IT_Questions_Data3],
        },
      },
    ];

    const Conversation_Say_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Conversation_SayIt_Question1_Answer,
    };

    const Conversation_Say_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Conversation_SayIt_Question2_Answer,
    };

    //Ask Chintan bhai if remove this code
    //Do not remove this comment code

    // const Conversation_Say_IT_Answer_Data3 = {
    //   Language_Code: nativeLang,
    //   Answer: event.Conversation_SayIt_Question3_Answer,
    // }

    const Conversation_Say_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.Conversation_SayIt_Question1_Image
        ? event.Conversation_SayIt_Question1_Image
        : '',
      Title: event.Conversation_SayIt_Question1_Title,
      Title1: event.Conversation_SayIt_Question1_Title1,
      Type: event.Conversation_SayIt_Question1_Type,
      Description: event.Conversation_SayIt_Question1_Description
        ? event.Conversation_SayIt_Question1_Description
        : '',
    };

    const Conversation_Say_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Image: event.Conversation_SayIt_Question2_Image
        ? event.Conversation_SayIt_Question2_Image
        : '',
      Title: event.Conversation_SayIt_Question2_Title,
      Title1: event.Conversation_SayIt_Question2_Title1,
      Type: event.Conversation_SayIt_Question2_Type,
      Description: event.Conversation_SayIt_Question2_Description
        ? event.Conversation_SayIt_Question2_Description
        : '',
    };

    //Ask Chintan bhai if remove this code
    //Do not remove this comment code

    // const Conversation_Say_IT_Questions_Data3 = {
    //   Language_Code: nativeLang,
    //   Image: event.Conversation_SayIt_Question3_Image ? event.Conversation_SayIt_Question3_Image : "",
    //   Title: event.Conversation_SayIt_Question3_Title,
    //   Title1: event.Conversation_SayIt_Question3_Title1,
    //   Type: event.Conversation_SayIt_Question3_Type,
    //   Description: event.Conversation_SayIt_Question3_Description ? event.Conversation_SayIt_Question3_Description : "",
    // }

    const Conversation_Say_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Conversation_Say_IT_Answer_Data1],
        },
        Question: {
          Language: [Conversation_Say_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Conversation_Say_IT_Answer_Data2],
        },
        Question: {
          Language: [Conversation_Say_IT_Questions_Data2],
        },
      },
      //Ask Chintan bhai if remove this code
      //Do not remove this comment code

      // {
      //   Answer: {
      //     Language: [Conversation_Say_IT_Answer_Data3]
      //   },
      //   Question: {
      //     Language: [Conversation_Say_IT_Questions_Data3]
      //   }
      // }
    ];

    const Conversation_Write_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Conversation_WriteIt_Question1_Answer,
    };

    const Conversation_Write_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.Conversation_WriteIt_Question1_Image
        ? event.Conversation_WriteIt_Question1_Image
        : '',
      Title: event.Conversation_WriteIt_Question1_Title,
      Type: event.Conversation_WriteIt_Question1_Type,
      Description: event.Conversation_WriteIt_Question1_Description
        ? event.Conversation_WriteIt_Question1_Description
        : '',
    };

    const Conversation_Write_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Conversation_Write_IT_Answer_Data1],
        },
        Question: {
          Language: [Conversation_Write_IT_Questions_Data1],
        },
      },
    ];

    const Grammar_Tap_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event.Grammar_TapIt_Question1_Option1,
      Option2: event.Grammar_TapIt_Question1_Option2,
      Option3: event.Grammar_TapIt_Question1_Option3,
      Option4: event.Grammar_TapIt_Question1_Option4,
    };
    const Grammar_Tap_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Grammar_TapIt_Question1_Answer,
      Image: event.Grammar_TapIt_Question1_Image
        ? event.Grammar_TapIt_Question1_Image
        : '',
      Title: event.Grammar_TapIt_Question1_Title,
      Type: event.Grammar_TapIt_Question1_Type,
      Description: event.Grammar_TapIt_Question1_Description
        ? event.Grammar_TapIt_Question1_Description
        : '',
    };
    const Grammar_Tap_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Option1: event.Grammar_TapIt_Question2_Option1,
      Option2: event.Grammar_TapIt_Question2_Option2,
      Option3: event.Grammar_TapIt_Question2_Option3,
      Option4: event.Grammar_TapIt_Question2_Option4,
    };
    const Grammar_Tap_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Grammar_TapIt_Question2_Answer,
      Image: event.Grammar_TapIt_Question2_Image
        ? event.Grammar_TapIt_Question2_Image
        : '',
      Title: event.Grammar_TapIt_Question2_Title,
      Type: event.Grammar_TapIt_Question2_Type,
      Description: event.Grammar_TapIt_Question2_Description
        ? event.Grammar_TapIt_Question2_Description
        : '',
    };
    const Grammar_Tap_IT_Answer_Data3 = {
      Language_Code: nativeLang,
      Option1: event.Grammar_TapIt_Question3_Option1,
      Option2: event.Grammar_TapIt_Question3_Option2,
      Option3: event.Grammar_TapIt_Question3_Option3,
      Option4: event.Grammar_TapIt_Question3_Option4,
    };

    const Grammar_Tap_IT_Questions_Data3 = {
      Language_Code: nativeLang,
      Answer: event.Grammar_TapIt_Question3_Answer,
      Image: event.Grammar_TapIt_Question3_Image
        ? event.Grammar_TapIt_Question3_Image
        : '',
      Title: event.Grammar_TapIt_Question3_Title,
      Type: event.Grammar_TapIt_Question3_Type,
      Description: event.Grammar_TapIt_Question3_Description
        ? event.Grammar_TapIt_Question3_Description
        : '',
    };
    const Grammar_Tap_IT_Answer_Data4 = {
      Language_Code: nativeLang,
      Option1: event.Grammar_TapIt_Question4_Option1,
      Option2: event.Grammar_TapIt_Question4_Option2,
      Option3: event.Grammar_TapIt_Question4_Option3,
      Option4: event.Grammar_TapIt_Question4_Option4,
    };

    const Grammar_Tap_IT_Questions_Data4 = {
      Language_Code: nativeLang,
      Answer: event.Grammar_TapIt_Question4_Answer,
      Image: event.Grammar_TapIt_Question4_Image
        ? event.Grammar_TapIt_Question4_Image
        : '',
      Title: event.Grammar_TapIt_Question4_Title,
      Type: event.Grammar_TapIt_Question4_Type,
      Description: event.Grammar_TapIt_Question4_Description
        ? event.Grammar_TapIt_Question4_Description
        : '',
    };
    const Grammar_Tap_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Grammar_Tap_IT_Answer_Data1],
        },
        Question: {
          Language: [Grammar_Tap_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Grammar_Tap_IT_Answer_Data2],
        },
        Question: {
          Language: [Grammar_Tap_IT_Questions_Data2],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Grammar_Tap_IT_Answer_Data3],
        },
        Question: {
          Language: [Grammar_Tap_IT_Questions_Data3],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Grammar_Tap_IT_Answer_Data4],
        },
        Question: {
          Language: [Grammar_Tap_IT_Questions_Data4],
        },
      },
    ];

    const Grammar_Write_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Grammar_WriteIt_Question1_Answer,
    };

    const Grammar_Write_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.Grammar_WriteIt_Question1_Image
        ? event.Grammar_WriteIt_Question1_Image
        : '',
      Title: event.Grammar_WriteIt_Question1_Title,
      Type: event.Grammar_WriteIt_Question1_Type,
      Description: event.Grammar_WriteIt_Question1_Description
        ? event.Grammar_WriteIt_Question1_Description
        : '',
    };

    const Grammar_Write_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Grammar_Write_IT_Answer_Data1],
        },
        Question: {
          Language: [Grammar_Write_IT_Questions_Data1],
        },
      },
    ];

    const Grammar_Read_IT_Data1 = {
      Language_Code: nativeLang,
      Title: event.Grammar_ReadIt_Title,
      Description: event.Grammar_ReadIt_Description,
    };

    const Grammar_Read_IT_Questions_Values = [Grammar_Read_IT_Data1];

    const Quiz_Tap_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event.Quiz_TapIt_Question1_Option1,
      Option2: event.Quiz_TapIt_Question1_Option2,
      Option3: event.Quiz_TapIt_Question1_Option3,
      Option4: event.Quiz_TapIt_Question1_Option4,
    };
    const Quiz_Tap_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Quiz_TapIt_Question1_Answer,
      Image: event.Quiz_TapIt_Question1_Image
        ? event.Quiz_TapIt_Question1_Image
        : '',
      Title: event.Quiz_TapIt_Question1_Title,
      Type: event.Quiz_TapIt_Question1_Type,
      Description: event.Quiz_TapIt_Question1_Description
        ? event.Quiz_TapIt_Question1_Description
        : '',
    };
    const Quiz_Tap_IT_Answer_Data2 = {
      Language_Code: nativeLang,
      Option1: event.Quiz_TapIt_Question2_Option1,
      Option2: event.Quiz_TapIt_Question2_Option2,
      Option3: event.Quiz_TapIt_Question2_Option3,
      Option4: event.Quiz_TapIt_Question2_Option4,
    };
    const Quiz_Tap_IT_Questions_Data2 = {
      Language_Code: nativeLang,
      Answer: event.Quiz_TapIt_Question2_Answer,
      Image: event.Quiz_TapIt_Question2_Image
        ? event.Quiz_TapIt_Question2_Image
        : '',
      Title: event.Quiz_TapIt_Question2_Title,
      Type: event.Quiz_TapIt_Question2_Type,
      Description: event.Quiz_TapIt_Question2_Description
        ? event.Quiz_TapIt_Question2_Description
        : '',
    };
    const Quiz_Tap_IT_Answer_Data3 = {
      Language_Code: nativeLang,
      Option1: event.Quiz_TapIt_Question3_Option1,
      Option2: event.Quiz_TapIt_Question3_Option2,
      Option3: event.Quiz_TapIt_Question3_Option3,
      Option4: event.Quiz_TapIt_Question3_Option4,
    };

    const Quiz_Tap_IT_Questions_Data3 = {
      Language_Code: nativeLang,
      Answer: event.Quiz_TapIt_Question3_Answer,
      Image: event.Quiz_TapIt_Question3_Image
        ? event.Quiz_TapIt_Question3_Image
        : '',
      Title: event.Quiz_TapIt_Question3_Title,
      Type: event.Quiz_TapIt_Question3_Type,
      Description: event.Quiz_TapIt_Question3_Description
        ? event.Quiz_TapIt_Question3_Description
        : '',
    };

    const Quiz_Tap_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Quiz_Tap_IT_Answer_Data1],
        },
        Question: {
          Language: [Quiz_Tap_IT_Questions_Data1],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Quiz_Tap_IT_Answer_Data2],
        },
        Question: {
          Language: [Quiz_Tap_IT_Questions_Data2],
        },
      },
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Quiz_Tap_IT_Answer_Data3],
        },
        Question: {
          Language: [Quiz_Tap_IT_Questions_Data3],
        },
      },
    ];

    const Quiz_Write_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.Quiz_WriteIt_Question1_Answer,
    };

    const Quiz_Write_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.Quiz_WriteIt_Question1_Image
        ? event.Quiz_WriteIt_Question1_Image
        : '',
      Title: event.Quiz_WriteIt_Question1_Title,
      Type: event.Quiz_WriteIt_Question1_Type,
      Description: event.Quiz_WriteIt_Question1_Description
        ? event.Quiz_WriteIt_Question1_Description
        : '',
    };

    const Quiz_Write_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Quiz_Write_IT_Answer_Data1],
        },
        Question: {
          Language: [Quiz_Write_IT_Questions_Data1],
        },
      },
    ];

    const Quiz_Listen_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event.Quiz_ListenIt_Question1_Option1,
      Option2: event.Quiz_ListenIt_Question1_Option2,
      Option3: event.Quiz_ListenIt_Question1_Option3,
      Option4: event.Quiz_ListenIt_Question1_Option4,
    };

    const Quiz_Listen_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      AudioText: event.Quiz_ListenIt_Question1_AudioText,
      Answer: event.Quiz_ListenIt_Question1_Answer,
    };

    const Quiz_Listen_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [Quiz_Listen_IT_Answer_Data1],
        },
        Question: {
          Language: [Quiz_Listen_IT_Questions_Data1],
        },
      },
    ];

    const AI_Write_IT_Answer_Data1 = {
      Language_Code: nativeLang,
      Answer: event.AI_WriteIt_Question1_Answer,
    };

    const AI_Write_IT_Questions_Data1 = {
      Language_Code: nativeLang,
      Image: event.AI_WriteIt_Question1_Image
        ? event.AI_WriteIt_Question1_Image
        : '',
      Title: event.AI_WriteIt_Question1_Title
        ? event.AI_WriteIt_Question1_Title
        : '',
      Type: event.AI_WriteIt_Question1_Type
        ? event.AI_WriteIt_Question1_Type
        : '',
      Description: event.AI_WriteIt_Question1_Description
        ? event.AI_WriteIt_Question1_Description
        : '',
    };

    const AI_Write_IT_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [AI_Write_IT_Answer_Data1],
        },
        Question: {
          Language: [AI_Write_IT_Questions_Data1],
        },
      },
    ];

    const AI_Pick_Word_Answer_Data1 = {
      Language_Code: nativeLang,
      Option1: event?.Option1_Options?.map((option: any, index: any) => ({
        OptionName: option,
        FakeOptionName: event.Option1_Fake_Options[index],
        OptionImage: event.Option1_Images[index],
      })),
      Option2: event?.Option2_Options?.map((option: any, index: any) => ({
        OptionName: option,
        FakeOptionName: event.Option2_Fake_Options[index],
        OptionImage: event.Option2_Images[index],
      })),
      // Answer: event.AI_Pick_Word_Question1_Question_Sentence1,
      // Index_Option1: event.AI_Pick_Word_Question1_Index_Option1,
      // Index_Option2: event.AI_Pick_Word_Question1_Index_Option2,
    };

    const AI_Pick_Word_Questions_Data1 = {
      Language_Code: nativeLang,
      Question_Sentence1: event.AI_Pick_Word_Question1_Question_Sentence1,
      Question_Sentence2: event.AI_Pick_Word_Question1_Question_Sentence2,
    };

    const AI_Pick_Word_Questions_Values = [
      {
        Question_ID:
          Date.now().toString(36) + Math.random().toString(36).substr(2),
        Answer: {
          Language: [AI_Pick_Word_Answer_Data1],
        },
        Question: {
          Language: [AI_Pick_Word_Questions_Data1],
        },
      },
    ];

    const VocabularySection = {
      Section: 'VocabularySection',
      VocabularyVideoUrl: event.VocabularyVideoUrl,
      Video_Url: event.Vocabulary_Upload_VideoUrl,
      Tap_IT: { Questions: Vocabulary_Tap_IT_Questions_Values },
      Say_IT: { Questions: Vocabulary_Say_IT_Questions_Values },
      Write_IT: { Questions: Vocabulary_Write_IT_Questions_Values },
      Listen_IT: { Questions: Vocabulary_Listen_IT_Questions_Values },
    };

    const ConversationSection = {
      Section: 'ConversationSection',
      Video_Url: event.Conversation_Upload_VideoUrl,
      ConversationVideoUrl: event.ConversationVideoUrl,
      Tap_IT: { Questions: Conversation_Tap_IT_Questions_Values },
      Say_IT: { Questions: Conversation_Say_IT_Questions_Values },
      Write_IT: { Questions: Conversation_Write_IT_Questions_Values },
    };

    const GrammarSection = {
      Section: 'GrammarSection',
      GrammarVideoUrl: event.GrammarVideoUrl,
      Video_Url: event.Grammar_Upload_VideoUrl,
      Tap_IT: { Questions: Grammar_Tap_IT_Questions_Values },
      Read_IT: { Questions: Grammar_Read_IT_Questions_Values },
      Write_IT: { Questions: Grammar_Write_IT_Questions_Values },
    };

    const QuizSection = {
      Section: 'QuizSection',
      // Quiz_Title: event.Quiz_Title,
      // Quiz_Discription: event.Quiz_Description,
      Tap_IT: { Questions: Quiz_Tap_IT_Questions_Values },
      Write_IT: { Questions: Quiz_Write_IT_Questions_Values },
      Listen_IT: { Questions: Quiz_Listen_IT_Questions_Values },
    };

    const Show_Massage_Value = {
      Language_Code: nativeLang,
      Message1: event.AI_Show_Massage1,
      Message2: event.AI_Show_Massage2,
    };

    const Additional_AI_Text_Value = {
      Language_Code: nativeLang,
      HeadText: event.HeadText,
      PhotoHeadText: event.PhotoHeadText,
      Step1: event.Step1,
      Step2: event.Step2,
    };

    const AISection = {
      Section: 'AISection',
      AI_Background_Image_Slide1: event.AI_Background_Image_Slide1,
      AI_Background_Image_Slide2: event.AI_Background_Image_Slide2,
      Write_IT: { Questions: AI_Write_IT_Questions_Values },
      Pick_Word: { Questions: AI_Pick_Word_Questions_Values },
      Show_Massage: { Questions: [Show_Massage_Value] },
      Additional_AI_Text: [Additional_AI_Text_Value],
    };

    let Activity_Data = [];
    // Activity_Data.push((vocabularyComponents && VocabularySection), (conversationComponents && ConversationSection), (grammarComponents && GrammarSection), (quizComponents && QuizSection))
    if (vocabularyComponents) {
      Activity_Data.push(VocabularySection);
    }
    if (conversationComponents) {
      Activity_Data.push(ConversationSection);
    }
    if (grammarComponents) {
      Activity_Data.push(GrammarSection);
    }
    if (quizComponents) {
      Activity_Data.push(QuizSection);
    }
    if (aiActivityComponents) {
      Activity_Data.push(AISection);
    }

    const fromData = {
      LessonName: event.LessonName,
      Age: selectAgeValue,
      Level: selectLevelValue,
      Its_Premium_Lesson: checkPremium,
      Activity_Details: Activity_Data,
      LessonID: event.LessonID,
      AI_Lesson: aiActivityComponents ? true : false,
    };

    lessonsaveReq(fromData);
  };

  const handleChangeLevel = (event: any) => {
    var datas = event.map((result: any) => {
      return result.value;
    });
    var LevelData = datas.toString();
    setValue('Lesson_Level', event.length);
    setSelectLevelError(true);
    setSelectLevelValue(LevelData);
    setSelectLevel(event);
  };

  const handleChangeAge = (event: any) => {
    var datas = event.map((result: any) => {
      return result.label;
    });
    setValue('Lesson_Age', event.length);
    setSelectAgeError(true);
    setSelectAgeValue(datas);
    setSelectAge(event);
  };

  const handleVocabulary = () => {
    setVocabularyComponents(true);
  };

  const handleConversation = () => {
    setConversationComponents(true);
  };

  const handleGrammar = () => {
    setGrammarComponents(true);
  };

  const handleQuiz = () => {
    setQuizComponents(true);
  };

  const handleAIactivity = () => {
    setAIActivityComponents(true);
  };

  const handleCancelLesson = () => {
    navigate('/lesson');
    setCancelConfirmModel(false);
  };

  const handleCloseCancelModel = () => {
    setCancelConfirmModel(false);
  };

  const handleCheckBoxPremium = (event: any) => {
    setCheckPremium(event.target.checked);
  };

  const handleLessonIDFocus = (event: any) => {
    if (event.target.value != '') {
      onCheckLessonIDExistOrNot({ Lesson_ID: event.target.value });
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>Add New Lesson</PageTitle>
      <Loader loading={loading} />
      <ToastContainer />
      <form onSubmit={handleSubmit(onInvalid)}>
        <div className='card mb-10 lesson-main-section p-5'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='add-lesson basic-info mt-3 card-box'>
                {/* <Collapsible
                                    trigger={
                                        <div
                                            style={{ width: "100%", position: "relative" }}>
                                            <h1>
                                                Basic Info <BsChevronDown style={{ position: "absolute", right: "0" }} />
                                            </h1>
                                        </div>
                                    }
                                    // onClick={() => setCollapsibleClass(true)}
                                    open={true}
                                > */}
                <h1>Basic Info</h1>
                <hr></hr>
                <div className='col-md-12 pt-1 pb-3'>
                  <div className='mt-3 col-md-12 form-group pb-3'>
                    <label className='fw-bold form-label'>
                      Lesson Name <span className='astrick'>*</span>
                    </label>
                    <input
                      type='text'
                      className={
                        errors.LessonName ? 'form-control' : 'form-control'
                      }
                      // className={errors.LessonName ? "form-control border-danger" : "form-control"}
                      placeholder='Enter Lesson Name'
                      {...register('LessonName')}
                    />
                    {errors.LessonName && (
                      <p style={{ color: 'red' }}>Please enter lesson name</p>
                    )}
                  </div>
                  <div className='row'>
                    <div className='mt-3 col-md-6 form-group pb-3'>
                      <label className='fw-bold form-label'>
                        Select Age Group <span className='astrick'>*</span>
                      </label>
                      <Select
                        isMulti
                        name='age'
                        classNamePrefix='react-select_age'
                        placeholder='Select Age Group'
                        value={selectAge}
                        options={options}
                        onChange={(e) => {
                          handleChangeAge(e);
                        }}
                      />
                      {selectAgeError
                        ? null
                        : errors.Lesson_Age && (
                            <p style={{ color: 'red' }}>Please select age</p>
                          )}
                      {/* {errors.Lesson_Age && <p style={{ color: "red" }}>Please select age</p>} */}
                    </div>
                    <div className='mt-3 col-md-6 form-group pb-3'>
                      <label className='fw-bold form-label'>
                        Select Level <span className='astrick'>*</span>
                      </label>
                      <Select
                        isMulti
                        name='Level'
                        classNamePrefix='react-select_level'
                        options={levelData}
                        value={selectLevel}
                        placeholder='Select Level'
                        // options="{}"
                        onChange={(e) => {
                          handleChangeLevel(e);
                        }}
                      />
                      {selectLevelError
                        ? null
                        : errors.Lesson_Level && (
                            <p style={{ color: 'red' }}>Please select level</p>
                          )}
                    </div>
                    <div className='mt-3 col-md-6 form-group pb-3'>
                      <label className='fw-bold form-label'>
                        Lesson ID <span className='astrick'>*</span>
                      </label>
                      <div style={{ position: 'relative' }}>
                        <input
                          type='text'
                          className={
                            errors.LessonID ? 'form-control' : 'form-control'
                          }
                          min='1'
                          max='10'
                          // className={errors.LessonName ? "form-control border-danger" : "form-control"}
                          placeholder='Enter Lesson ID'
                          maxLength={10}
                          {...register('LessonID')}
                          onBlur={(event) => handleLessonIDFocus(event)}
                        />
                        {lessonIDErrorLoading && (
                          <span
                            style={{
                              position: 'absolute',
                              right: '7px',
                              top: '10px',
                            }}
                          >
                            <Spinner animation='border' variant='primary' />
                          </span>
                        )}
                      </div>
                      {existIDError ? (
                        <p style={{ color: 'red' }}>
                          Lesson id already exists{' '}
                        </p>
                      ) : (
                        errors.LessonID && (
                          <p style={{ color: 'red' }}>Please enter lesson id</p>
                        )
                      )}
                    </div>
                    <div className='mt-15 col-md-6 form-group pb-3'>
                      <div className='option-section-wrapp'>
                        <div className='mark-answer'>
                          <label className='mark-answer-section mark-answer-text fw-bold form-label'>
                            <input
                              type='checkbox'
                              className='checkmark-box'
                              // value="option1"
                              // checked={checkBoxoption3 === "option1"}
                              onChange={handleCheckBoxPremium}
                              disabled={loading}
                            />
                            Premium
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {vocabularyComponents && (
                <VocabularySection
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              )}
              {conversationComponents && (
                <ConversationSection
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              )}
              {grammarComponents && (
                <GrammarSection
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              )}
              {aiActivityComponents && (
                <AIActivitySection
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              )}
              {quizComponents && (
                <QuizSection
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              )}
            </div>
            <AddSection
              handleQuiz={handleQuiz}
              handleVocabulary={handleVocabulary}
              handleConversation={handleConversation}
              handleGrammar={handleGrammar}
              handleAIactivity={handleAIactivity}
              quizComponents={quizComponents}
              aiActivityComponents={aiActivityComponents}
            />
          </div>
        </div>
        <div className='ps-10 pb-10'>
          <div className='add-lesson-footer-box'>
            <button className='btn btn-primary btn-lg' type='submit'>
              Save
            </button>
            <button
              onClick={() => setCancelConfirmModel(true)}
              className='btn btn-secondary btn-lg'
              type='button'
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <CancelConfirmLessonModel
        cancelConfirmModel={cancelConfirmModel}
        handleCancelLesson={handleCancelLesson}
        handleCloseCancelModel={handleCloseCancelModel}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    lessonList: state.lesson.lessonList,
    lessonLanguageList: state.lesson.lessonLanguageList,
    levellist: state.lesson.levellist,
    lessonIDError: state.lesson.lessonIDError,
    lessonIDErrorLoading: state.lesson.lessonIDErrorLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  lessonsaveReq: (param: any) => dispatch(lessonSave(param)),
  lessonLanguageGetReq: () => dispatch(lessonLanguageGet()),
  ClearSuccesErrorReq: () => dispatch(ClearSuccesError()),
  onCheckLessonIDExistOrNot: (param: any) =>
    dispatch(lessonIDExistorNot(param)),
  onGetAllLevel: (param: any) => dispatch(levelGet(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonAddPage);
