import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';

import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
//import { TablePagination } from "app/components/Table/TablePagination";
import TableInner from 'app/components/Table/TableInner';
import useTableState from 'app/components/Table/useTableState';
import { KTSVG } from 'theme/helpers';
import Swal from 'sweetalert2';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import { deleteLiveClass, getAllLiveClass } from 'store/liveClass/actions';
import LiveClassAddEditModal from './LiveClassContentAddEditModal';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';

const LiveClassContent: React.FC = (props: any) => {
  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});
  const { sort, page, limit, search } = tableState;

  const [deleteID, setDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);

  const [addEditModel, setAddEditModel] = useState({
    id: null,
    show: false,
  });

  const [data, setData] = useState([]);

  const {
    onGetAll,
    onDelete,
    getAllLoading,
    getAllSuccess,
    getAllError,
    deleteLoading,
    deleteSuccess,
    deleteError,
  } = props;

  useEffect(() => {
    getAll();
  }, [sort, page, limit, search]);

  const getAll = () => {
    onGetAll({
      page: page,
      limit: limit,
      search: search,
      sort: sort,
    });
  };

  useEffect(() => {
    if (getAllSuccess?.ResultCode === 'SUCCESS') {
      setData(getAllSuccess?.ResultObject?.data || []);
    }
  }, [getAllSuccess]);

  useEffect(() => {
    if (deleteSuccess?.ResultCode === 'SUCCESS') {
      Swal.fire({
        icon: 'success',
        title: deleteSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: false,
      }).then((result) => {
        getAll();
      });
    }
  }, [deleteSuccess]);

  const handleDeleteRecord = () => {
    const data = {
      _id: deleteID,
    };
    setDeleteModel(false);
    onDelete(data);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseAddEditModel = (props: any) => {
    const { refresh } = props;

    setAddEditModel((prevState) => {
      const data = {
        ...prevState,
        show: false,
      };
      return data;
    });
    if (refresh) {
      getAll();
    }
  };

  const downloadFileAtURL = (url: any) => {
    fetch(url, { mode: 'no-cors' })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const filename = url.split('/').pop();
        const renameFileName = 'HopeCitySchool_Video_' + filename;
        const aTag = document.createElement('a');
        aTag.href = blobURL;
        aTag.setAttribute('download', renameFileName);
        document.body.append(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start text-nowrap'>
            Video URL
          </span>
        ),
        accessor: 'videoURL',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-primary fw-bold d-block'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                onClick={() => {
                  const videoWebUrl = props.row.original.videoURL;
                  downloadFileAtURL(videoWebUrl);
                }}
              >
                <i className='bi bi-camera-video fs-1 text-light mx-5'></i>
              </button>
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='min-w-325px rounded-start text-start text-nowrap'>
            Name of Lesson
          </span>
        ),
        accessor: 'videoName',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.videoName}
            </span>
          );
        },
      },
      {
        Header: 'Age Group',
        accessor: 'ageOfGroup',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.ageOfGroup?.map((item: any) => (
                <span
                  className='badge badge-pill badge-primary'
                  style={{ marginRight: '3px' }}
                >
                  {item.minAge +
                    (item.maxAge === 200 ? '+' : '-' + item.maxAge)}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Target Language',
        accessor: 'targetLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?._targetLanguage?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item.Language_Name}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Native Language',
        accessor: 'nativeLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?._nativeLanguage?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item.Language_Name}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='min-w-325px rounded-start text-start text-nowrap'>
            Language Level
          </span>
        ),
        accessor: 'languageLevel',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.languageLevel?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item.Level_Name}
                </span>
              ))}
            </span>
          );
        },
      },

      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  setAddEditModel((prevState) => {
                    const data = {
                      ...prevState,
                      id: result?._id,
                      show: true,
                    };
                    return data;
                  });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#delete_confirmation_modal'
                onClick={() => {
                  setDeleteModel(true);
                  setDeleteID(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className='card mb-10'>
        <Loader loading={getAllLoading || deleteLoading} />
        <Table
          data={data}
          columns={columns}
          initialState={tableInitialState}
          pageCount={getAllSuccess?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>
                  Live Class Content Data
                </div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
                <div>
                  <button
                    title='Add'
                    className='btn btn-primary'
                    onClick={() => {
                      setAddEditModel((prevState) => {
                        const data = {
                          ...prevState,
                          id: null,
                          show: true,
                        };
                        return data;
                      });
                    }}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Live Class Content
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllLoading}
                    entityLabel='live class'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <DeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
      {addEditModel.show && (
        <LiveClassAddEditModal
          addEditModel={addEditModel}
          handleCloseAddEditModel={handleCloseAddEditModel}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAllLoading: state.LiveClass.getAllLoading,
    getAllSuccess: state.LiveClass.getAllSuccess,
    getAllError: state.LiveClass.getAllError,

    upsertLoading: state.LiveClass.upsertLoading,
    upsertSuccess: state.LiveClass.upsertSuccess,
    upsertError: state.LiveClass.upsertError,

    deleteLoading: state.LiveClass.deleteLoading,
    deleteSuccess: state.LiveClass.deleteSuccess,
    deleteError: state.LiveClass.deleteError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onGetAll: (param: any) => dispatch(getAllLiveClass(param)),
  onDelete: (param: any) => dispatch(deleteLiveClass(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveClassContent);
