import { log } from 'console';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { jwtTokenDecode } from 'hooks/tokenHook';
import { useCountdown } from 'hooks/useCountDown';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Alert, ListGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import moment from 'moment';
import Loader from 'app/components/Loader';
import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';
import { useAuth } from 'app/modules/auth';

const WaitingClassRoom = () => {
  const [showAlert, setShowAlert] = useState(true);

  const { currentUser } = useAuth();
  let { classId } = useParams();
  let { courseInviteId } = useParams();
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const getLiveClassJoiningURL: any = usePostQuery(
    'getLiveClassJoiningURL',
    `liveClass/getLiveClassJoiningURL`,
    {}
  );

  const handleJoinClass = () => {
    window.open(getLiveClassJoiningURL?.data?.ResultObject?.url, '_blank');
  };

  useEffect(() => {
    getLiveClassJoiningURL.mutate({
      userID: jwtTokenDecode().id,
      liveClassID: classId,
      courseInviteId: courseInviteId,
    });
  }, []);

  const handleDismiss = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        getLiveClassJoiningURL?.data?.ResultObject?.data?.type === 'instant'
      ) {
        window.open(getLiveClassJoiningURL?.data?.ResultObject?.url, '_blank');
      }
    }, 500); // Timeout duration in milliseconds

    return () => clearTimeout(timer);
  }, [getLiveClassJoiningURL?.data?.ResultObject]);

  const handleClick = () => {
    if (getLiveClassJoiningURL?.data?.ResultObject?.data?.type === 'instant') {
      const newWindow = window.open(
        getLiveClassJoiningURL?.data?.ResultObject?.url,
        '_blank'
      );
      if (newWindow === null || typeof newWindow === 'undefined') {
        //Popup blocker is enabled.
        return true;
      } else {
        // console.log(newWindow, 'Popup blocker is disabled.');
        <div>
          {showAlert && (
            <Alert variant='warning' onClose={handleDismiss} dismissible>
              <Alert.Heading>Alert</Alert.Heading>
              <p>
                Please Click Allow Button For Open Window or Popup - On Right
                Side Notification Icon.
              </p>
              <Button variant='warning' onClick={handleDismiss}>
                Close
              </Button>
            </Alert>
          )}
        </div>;
      }
    }
  };

  const handleMeetClick = () => {
    if (getLiveClassJoiningURL?.data?.ResultObject?.data?.type === 'Schedule') {
      window.open(getLiveClassJoiningURL?.data?.ResultObject?.url, '_blank');
    }
  };

  const handleSchedClick = () => {
    setOpen(true);
  };

  const CountdownTimer = ({
    targetDate,
    setDays,
    setHours,
    setMinutes,
    setSeconds,
  }: any) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);

    const countdownExpired = days + hours + minutes + seconds === 0;
    const liveClassURL = getLiveClassJoiningURL?.data?.ResultObject?.url;

    const currentDate = new Date();
    const endMeeting = new Date(
      getLiveClassJoiningURL?.data?.ResultObject?.liveClassContentData?.endMeeting
    );
    const endMeetingTime = endMeeting < currentDate;

    useEffect(() => {
      if (countdownExpired && liveClassURL) {
        setTimeout(() => {
          window.open(liveClassURL, '_blank');
        }, 10);
      }
    }, [countdownExpired, liveClassURL]);

    if (
      getLiveClassJoiningURL?.data?.ResultObject?.liveClassContentData
        ?.endMeetingStatus
    ) {
      return (
        <>
          <div className='row'>
            <div className='col-lg-12'>
              <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
                <span className='d-block fw-semibold text-center'>
                  <h1 className='text-center fw-semibold fs-3 text-primary'>
                    {' Meeting has been ended..'}
                  </h1>
                </span>
              </label>
            </div>
          </div>
        </>
      );
    } else if (
      days + hours + minutes + seconds <= 0 &&
      !getLiveClassJoiningURL?.data?.ResultObject?.liveClassContentData
        ?.endMeetingStatus &&
      endMeetingTime
    ) {
      return (
        <>
          <div className='row'>
            <div className='col-lg-12'>
              <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
                <span className='d-block fw-semibold text-center'>
                  <h1 className='text-center fw-semibold fs-3 text-primary'>
                    {' Meeting has been started..'}
                  </h1>
                </span>
              </label>
              <div className='text-center w-100 mt-10'>
                <div className='btn btn-primary fs-4' onClick={handleMeetClick}>
                  Click Here For Join Meeting
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className='d-flex flex-col justify-content-around w-100'>
          <div className='w-150px'>
            <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
              <span className='d-block fw-semibold text-center'>
                <span className='text-dark fw-bold d-block fs-m1 mb-2'>
                  {days}
                </span>
                <span className='fw-semibold fs-6 text-primary'>Days</span>
              </span>
            </label>
          </div>
          <div className='col-lg-1 align-items-center justify-content-center d-flex'>
            {' '}
            <div className='fs-m1 text-primary fw-bold fadeinout'> : </div>
          </div>
          <div className='w-150px'>
            <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
              <span className='d-block fw-semibold text-center'>
                <span className='text-dark fw-bold d-block fs-m1 mb-2'>
                  {hours}
                </span>
                <span className='fw-semibold fs-6 text-primary'>Hours</span>
              </span>
            </label>
          </div>
          <div className='col-lg-1 align-items-center justify-content-center d-flex'>
            {' '}
            <div className='fs-m1 text-primary fw-bold fadeinout'> : </div>
          </div>
          <div className='w-150px'>
            <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
              <span className='d-block fw-semibold text-center'>
                <span className='text-dark fw-bold d-block fs-m1 mb-2'>
                  {minutes}
                </span>
                <span className='text-primary fw-semibold fs-6'>Minutes</span>
              </span>
            </label>
          </div>
          <div className='col-lg-1 align-items-center justify-content-center d-flex'>
            {' '}
            <div className='fs-m1 text-primary fw-bold fadeinout'> : </div>
          </div>
          <div className='w-150px'>
            <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
              <span className='d-block fw-semibold text-center'>
                <span className='text-dark fw-bold d-block fs-m1 mb-2'>
                  {seconds}
                </span>
                <span className='text-primary fw-semibold fs-6'>Seconds</span>
              </span>
            </label>
          </div>
        </div>
      );
    }
  };
  if (getLiveClassJoiningURL?.data?.ResultCode === 'Not Available') {
    return (
      <Row className='h-100 justify-content-center align-items-center'>
        {getLiveClassJoiningURL?.isLoading ? (
          <Loader loading={getLiveClassJoiningURL?.isLoading} />
        ) : (
          <>
            <Col md={6} className='mx-auto'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='card h-xl-100' dir='ltr'>
                    <div className='card-body d-flex flex-column flex-center'>
                      <div className='mb-2'>
                        <h1 className='fw-semibold text-gray-800 text-center lh-lg'>
                          Have you tried
                          <br />
                          <span className='fw-bolder'>
                            {getLiveClassJoiningURL?.data?.ResultMessage}
                          </span>
                        </h1>
                        <div className='py-10 text-center'>
                          <img
                            src={toAbsoluteUrl(
                              '/media/svg/illustrations/easy/1.svg'
                            )}
                            className='theme-light-show w-200px'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='text-center mb-1'>
                        <a className='btn btn-sm btn-primary me-2'>Try now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    );
  } else {
    return (
      <Row className='h-100 justify-content-center align-items-center'>
        {getLiveClassJoiningURL?.isLoading ? (
          <Loader loading={getLiveClassJoiningURL?.isLoading} />
        ) : (
          <Col md={10} className='mx-auto'>
            <div
              className='row stepper stepper-pills stepper-column stepper-multistep'
              id='kt_create_account_stepper'
              data-kt-stepper='true'
            >
              <div className='col-md-5'>
                <div
                  className='d-flex flex-column top-0 bottom-0 bgi-size-cover bgi-position-center'
                  style={{
                    backgroundImage: `url("/media/misc/auth-bg.png")`,
                  }}
                >
                  <div className='d-flex flex-center py-10 py-lg-20'>
                    <div className='fs-5'>
                      <i className='fa fa-clock fa-spin fa-light-clock'></i>
                    </div>
                  </div>
                  <div className='d-flex flex-row-fluid justify-content-center p-10 pb-20'>
                    <div className='stepper-nav'>
                      <div
                        className='stepper-item'
                        data-kt-stepper-element='nav'
                      >
                        <div className='stepper-wrapper'>
                          <div className='stepper-icon rounded-3'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>
                              <i className='fa-regular fa-handshake text-white fs-3'></i>
                            </span>
                          </div>
                          <div className='stepper-label'>
                            <h3 className='stepper-title fs-2'>
                              NOMBRE DEL CURSO
                            </h3>
                            <div className='stepper-desc fw-normal'>
                              {' '}
                              {
                                getLiveClassJoiningURL?.data?.ResultObject?.data
                                  ?.name
                              }
                            </div>
                          </div>
                        </div>
                        <div className='stepper-line h-40px'></div>
                      </div>
                      <div
                        className='stepper-item'
                        data-kt-stepper-element='nav'
                      >
                        <div className='stepper-wrapper'>
                          <div className='stepper-icon rounded-3'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>
                              <i className='fa fa-thin fa-calendar-days text-white fs-3'></i>
                            </span>
                          </div>

                          {getLiveClassJoiningURL?.data?.ResultObject?.data
                            ?.type === 'Schedule' && (
                            <div className='stepper-label'>
                              <h3 className='stepper-title'>
                                HORA DE INICIO DE LA CLASE EN VIVO
                              </h3>
                              <div className='stepper-desc fw-normal'>
                                {getLiveClassJoiningURL?.data?.ResultObject
                                  ?.data?.liveclassschedules?.scheduleDate ? (
                                  moment(
                                    getLiveClassJoiningURL?.data?.ResultObject
                                      ?.data?.liveclassschedules?.scheduleDate
                                  ).format('MM/DD/YYYY hh:mm A')
                                ) : (
                                  <>Sin fecha programada hoy</>
                                )}
                              </div>
                            </div>
                          )}
                          {getLiveClassJoiningURL?.data?.ResultObject?.data
                            ?.type === 'instant' && (
                            <div className='stepper-label'>
                              <h3 className='stepper-title'>
                                HORA DE INICIO DE LA CLASE EN VIVO
                              </h3>
                              <div className='stepper-desc fw-normal'>
                                {' '}
                                {moment().format('MM/DD/YYYY hh:mm A')}{' '}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-7 d-flex'>
                <div className='d-flex flex-center flex-column flex-column-fluid'>
                  <div className='w-100 p-10 p-lg-15 mx-auto'>
                    <div
                      className='my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework'
                      id='kt_create_account_form'
                    >
                      <div
                        className='current'
                        data-kt-stepper-element='content'
                      >
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-15'>
                            <h1 className='fw-bold d-flex align-items-center text-dark'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen014.svg'
                                className='svg-icon svg-icon-2x svg-icon-dark pe-1 me-2'
                              />{' '}
                              Waiting Room
                            </h1>
                          </div>
                          <div className='fv-row fv-plugins-icon-container'>
                            <div className='d-block my-10'>
                              <h1>
                                {!getLiveClassJoiningURL?.data?.ResultObject
                                  ?.liveClassContentData?.endMeetingStatus &&
                                  getLiveClassJoiningURL?.data?.ResultObject
                                    ?.data?.type === 'Schedule' && (
                                    <>
                                      <i className='fa fa-user me-2 fs-3'></i>{' '}
                                      Host Will Start Class in..
                                    </>
                                  )}
                                {getLiveClassJoiningURL?.data?.ResultObject
                                  ?.data?.type === 'instant' && (
                                  <>
                                    <i className='fa fa-user me-2 fs-3'></i>Host
                                    Started Class..
                                  </>
                                )}
                              </h1>
                            </div>
                            {getLiveClassJoiningURL?.data?.ResultObject?.data
                              ?.liveclassschedules?.scheduleDate ? (
                              <CountdownTimer
                                setDays={setDays}
                                setHours={setHours}
                                setMinutes={setMinutes}
                                setSeconds={setSeconds}
                                targetDate={moment(
                                  getLiveClassJoiningURL?.data?.ResultObject
                                    ?.data?.liveclassschedules?.scheduleDate
                                ).format('MM/DD/YYYY hh:mm A')}
                              />
                            ) : (
                              <>
                                <div className='row'>
                                  <div className='col-lg-12'>
                                    <label className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center justify-content-center'>
                                      <span className='d-block fw-semibold text-center'>
                                        <h1 className='text-center fw-semibold fs-3 text-primary'>
                                          {getLiveClassJoiningURL?.data
                                            ?.ResultObject?.data?.type ===
                                          'instant' ? (
                                            <> Meeting has been started..</>
                                          ) : (
                                            <>No Scheduled Today..</>
                                          )}
                                        </h1>
                                      </span>
                                    </label>
                                    {getLiveClassJoiningURL?.data?.ResultObject
                                      ?.data?.type === 'instant' ? (
                                      <div className='text-center w-100 mt-10'>
                                        <div
                                          className='btn btn-primary fs-4'
                                          onClick={handleClick}
                                        >
                                          Click Here For Join Meeting
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {getLiveClassJoiningURL?.data?.ResultObject
                                      ?.data?.liveClassContentData === null ||
                                      (undefined && (
                                        <div className='text-center w-100 mt-10'>
                                          <div
                                            className='btn btn-primary fs-4'
                                            onClick={handleClick}
                                          >
                                            No Live Class
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    );
  }
};

export default WaitingClassRoom;
