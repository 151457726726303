import {
  teacherGetListfailurePayload,
  teacherGetListSuccessPayload,
} from '../teacher/types';

import {
  STUDENT_DELETE,
  STUDENT_DELETE_FAILURE,
  STUDENT_DELETE_SUCCESS,
  STUDENT_GET,
  STUDENT_GET_FAILURE,
  STUDENT_GET_SUCCESS,
  STUDENT_GET_BY_ID,
  STUDENT_GET_BY_ID_SUCCESS,
  STUDENT_GET_BY_ID_FAILURE,
  STUDENT_UPDATE,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAILURE,
  SUCCESS_CLEAR,
} from './actionTypes';
import {
  StudentDelete,
  StudentGet,
  studentGetfailurePayload,
  StudentGetListFailure,
  StudentGetListSuccess,
  studentGetSuccessPayload,
} from './types';

export const studentGet = (payload: any) => ({
  type: STUDENT_GET,
  payload,
});

export const studentGetSuccess = (payload: any) => ({
  type: STUDENT_GET_SUCCESS,
  payload,
});

export const studentGetFailure = (payload: any) => ({
  type: STUDENT_GET_FAILURE,
  payload,
});

export const studentGetById = (payload: any) => ({
  type: STUDENT_GET_BY_ID,
  payload,
});

export const studentUpdate = (payload: any) => ({
  type: STUDENT_UPDATE,
  payload,
});

export const studentUpdateSuccess = (payload: any) => ({
  type: STUDENT_UPDATE_SUCCESS,
  payload,
});

export const studentUpdateFailure = (payload: any) => ({
  type: STUDENT_UPDATE_FAILURE,
  payload,
});

export const studentGetByIdSuccess = (payload: any) => ({
  type: STUDENT_GET_BY_ID_SUCCESS,
  payload,
});

export const studentGetByIdFailure = (payload: any) => ({
  type: STUDENT_GET_BY_ID_FAILURE,
  payload,
});

export const studentDelete = (payload: any) => ({
  type: STUDENT_DELETE,
  payload,
});

export const studentDeleteSuccess = (payload: any) => ({
  type: STUDENT_DELETE_SUCCESS,
  payload,
});

export const studentDeleteFailure = (payload: any) => ({
  type: STUDENT_DELETE_FAILURE,
  payload,
});

export const successClear = () => ({
  type: SUCCESS_CLEAR,
});
