import {
  LIVE_CLASS_GET_ALL,
  LIVE_CLASS_GET_ALL_SUCCESS,
  LIVE_CLASS_GET_ALL_FAIL,
  LIVE_CLASS_GET_BY_ID,
  LIVE_CLASS_GET_BY_ID_SUCCESS,
  LIVE_CLASS_GET_BY_ID_FAIL,
  LIVE_CLASS_ADD,
  LIVE_CLASS_ADD_SUCCESS,
  LIVE_CLASS_ADD_FAIL,
  LIVE_CLASS_UPDATE,
  LIVE_CLASS_UPDATE_SUCCESS,
  LIVE_CLASS_UPDATE_FAIL,
  LIVE_CLASS_DELETE,
  LIVE_CLASS_DELETE_SUCCESS,
  LIVE_CLASS_DELETE_FAIL,
  LIVE_CLASS_CLEAR,
} from './actionTypes';

export const getAllLiveClass = (formData:any) => {
  return {
    type: LIVE_CLASS_GET_ALL,
    payload: { formData },
  };
};
export const getAllLiveClassSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_GET_ALL_SUCCESS,
    payload: response,
  };
};
export const getAllLiveClassFail = (response:any) => {
  return {
    type: LIVE_CLASS_GET_ALL_FAIL,
    payload: response,
  };
};

export const getByIdLiveClass = (param:any) => {
  return {
    type: LIVE_CLASS_GET_BY_ID,
    payload: { param },
  };
};
export const getByIdLiveClassSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_GET_BY_ID_SUCCESS,
    payload: response,
  };
};
export const getByIdLiveClassFail = (response:any) => {
  return {
    type: LIVE_CLASS_GET_BY_ID_FAIL,
    payload: response,
  };
};

export const addLiveClass = (formData:any) => {
  return {
    type: LIVE_CLASS_ADD,
    payload: { formData },
  };
};
export const addLiveClassSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_ADD_SUCCESS,
    payload: response,
  };
};
export const addLiveClassFail = (response:any) => {
  return {
    type: LIVE_CLASS_ADD_FAIL,
    payload: response,
  };
};

export const updateLiveClass = (formData:any) => {
  return {
    type: LIVE_CLASS_UPDATE,
    payload: { formData },
  };
};
export const updateLiveClassSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_UPDATE_SUCCESS,
    payload: response,
  };
};
export const updateLiveClassFail = (response:any) => {
  return {
    type: LIVE_CLASS_UPDATE_FAIL,
    payload: response,
  };
};

export const deleteLiveClass = (param:any) => {
  return {
    type: LIVE_CLASS_DELETE,
    payload: { param },
  };
};
export const deleteLiveClassSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_DELETE_SUCCESS,
    payload: response,
  };
};
export const deleteLiveClassFail = (response:any) => {
  return {
    type: LIVE_CLASS_DELETE_FAIL,
    payload: response,
  };
};

export const clearLiveClass = () => {
  return {
    type: LIVE_CLASS_CLEAR,
  };
};
