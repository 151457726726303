import React, { useEffect } from 'react';
import { CellProps, Column } from 'react-table';

import { PageTitle } from '../../../theme/layout/core';
import useTableState from 'app/components/Table/useTableState';
import { TablePagination } from 'app/components/Table/TablePagination';
import TableInner from 'app/components/Table/TableInner';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import Loader from 'app/components/Loader';
import Table from 'app/components/Table';
import { DateTime } from 'luxon';
import { usePostQuery } from 'hooks/reactQueryHelper';

const LiveClassLogsList: React.FC = () => {
  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Student Name
          </span>
        ),
        accessor: 'usersData.FirstName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {result.usersData[0]?.FirstName
                ? result.usersData[0]?.FirstName
                : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Course Name',
        accessor: 'courseData.name',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.courseData[0]?.name
            ? result?.courseData[0]?.name
            : '-';
        },
      },
      {
        Header: 'Live Class Name',
        accessor: 'liveClassesData.name',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {result?.liveClassesData[0]?.name
                ? result?.liveClassesData[0]?.name
                : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Point',
        accessor: 'Point',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return result.point ? result.point : '-';
        },
      },
      {
        Header: 'Completed Date',
        accessor: 'submittedDate',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          const originalDate = result?.submittedDate;
          const convertedDate = convertDateFormat(originalDate);
          return (
            <span className='pe-4 d-block'>
              {convertedDate === 'Invalid DateTime'
                ? 'Not Available'
                : convertedDate}
            </span>
          );
        },
      },
    ],
    []
  );

  const convertDateFormat = (dateString: any) => {
    const dateTime = DateTime.fromISO(dateString);
    const formattedDate = dateTime.toFormat('LL-dd-yyyy hh:mma');

    return formattedDate;
  };

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageSize: 50,
    });

  const { sort, page, limit, search } = tableState;

  const getLiveClassLogsQuery = usePostQuery(
    'getLiveClassLogsQuery',
    '/userLessonsLogs/GetLiveClassLogs',
    {}
  );

  useEffect(() => {
    getLiveClassLogsQuery.mutate({
      page,
      limit,
      search,
      sort,
    });
  }, [page, limit, search, sort]);

  const list = getLiveClassLogsQuery?.data?.ResultObject?.liveclasslogs ?? [];

  return (
    <React.Fragment>
      <Loader loading={getLiveClassLogsQuery?.isLoading} />
      <PageTitle breadcrumbs={[]}>Live Class Schedule Logs</PageTitle>
      <div className='card mb-10'>
        <Table
          data={list}
          columns={columns}
          initialState={tableInitialState}
          pageCount={
            getLiveClassLogsQuery?.data?.ResultObject?.totalPages ?? -1
          }
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header justify-content-between border-0 pt-5'>
              <h3 className='card-title'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Live Class Schedule Log Data
                </span>
              </h3>
              <span>
                <TableGlobalSearch />
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getLiveClassLogsQuery?.isLoading}
                    entityLabel='live class shedule log'
                  />
                </div>
                <div className='col-12 border-top pt-5'>
                  <TablePagination pageOptions={[5, 10, 20, 50]} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default LiveClassLogsList;
