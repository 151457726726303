export const getHighlightedSection = (hash: any) => {
  if (!hash) return {};

  const currentHash = window.location.hash.slice(1);
  return {
    className: currentHash === hash ? 'selected' : '',
    id: hash,
  };
};


export const scrollHandle = (id: any) => {
  const violation: any = document.getElementById(id);
  setTimeout(() => {
    window.scrollTo({
      top: violation.offsetTop,
      behavior: 'smooth',
    });
  }, 200)
}
