import { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';
import LiveClassPage from './LiveClassContent';

const LiveClassContentWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Live Class Content </PageTitle>
      <LiveClassPage />
    </>
  );
};

export default LiveClassContentWrapper;
