import Loader from 'app/components/Loader';
import React, { useEffect, useState } from 'react';
import useTableState from 'app/components/Table/useTableState';
import { TablePagination } from 'app/components/Table/TablePagination';
import TableInner from 'app/components/Table/TableInner';
import Table from 'app/components/Table';
import { PageTitle } from 'theme/layout/core';
import {
  useDeleteQuery,
  useGetQuery,
  usePostQuery,
} from 'hooks/reactQueryHelper';
import { CellProps, Column } from 'react-table';
import { KTSVG } from 'theme/helpers';
import { useNavigate } from 'react-router';
import EnrollmentHeadPageTab from '../EnrollmentHeadPageTab';
import Swal from 'sweetalert2';
import moment from 'moment';
import useToaster from 'hooks/useToaster';
import { AiFillEye } from 'react-icons/ai';
import PDFViewModel from 'app/components/PDFViewModel';
import useModal from 'hooks/useModal';

const DiplomaCertificate = () => {
  const { successToaster, warningToaster } = useToaster();
  const { show, openModal, closeModal, modalData } = useModal();
  const getAllQuery: any = useGetQuery('getAllQuery', '/certificate');
  const deleteQuery: any = useDeleteQuery('deleteQuery', '/certificate');

  const navigate = useNavigate();

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const { sort, page, limit, search }: any = tableState;

  useEffect(() => {
    getAllQuery.mutateAsync({
      params: {
        page,
        limit,
        sortBy: sort?.fields,
        sortOrder: sort?.sort,
        search,
      },
    });
  }, [page, limit, sort, search]);

  const handleConfirmation = async (id: any) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to proceed with this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteQuery.mutateAsync({ params: { id: id } });
        } catch (error) {
          Swal.fire(
            'Error!',
            'There was an error processing your request.',
            'error'
          );
          console.error('Error:', error);
        }
      }
    });
  };

  useEffect(() => {
    if (deleteQuery.isSuccess) {
      successToaster(deleteQuery.data?.ResultMessage);
      getAllQuery.mutateAsync();
    }
  }, [deleteQuery.isSuccess]);

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Student Name
          </span>
        ),
        accessor: 'user',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {result?.user?.FirstName + ' ' + result?.user?.LastName}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            HighSchool Year
          </span>
        ),
        accessor: 'calendarYearId',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {result.calendarYear.label}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Created Date
          </span>
        ),
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {moment(result.createdAt).format('DD-MM-YYYY hh:mm A')}
              </a>
            </div>
          );
        },
      },

      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='Preview Certificate'
                onClick={() => {
                  openModal({
                    url: result?.url,
                  });
                }}
              >
                <AiFillEye size={17} className='svg-icon-3' />
              </button>

              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  navigate(`/diploma/certificate/edit/${result?._id}`);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                onClick={() => {
                  handleConfirmation(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  console.log(getAllQuery?.data?.ResultObject?.totalItems);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Certificate</PageTitle>
      <div className='card mb-10'>
        <Loader loading={getAllQuery.isLoading || deleteQuery.isLoading} />
        <Table
          data={
            getAllQuery?.data?.ResultObject?.data
              ? getAllQuery?.data?.ResultObject?.data
              : []
          }
          columns={columns}
          initialState={tableInitialState}
          pageCount={getAllQuery?.data?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='pt-5'>
              <EnrollmentHeadPageTab activeTab='diplomaCertificate' />
            </div>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>Certificate</div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div>
                  <button
                    title='Add Certificate'
                    className='btn btn-primary mx-2'
                    onClick={() => {
                      navigate('/diploma/certificate/add');
                    }}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Certificate
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllQuery.isLoading}
                    entityLabel='certificate'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <PDFViewModel
        showDocument={show}
        handleCloseDocument={closeModal}
        documentUrl={modalData.url}
      />
    </>
  );
};

export default DiplomaCertificate;
