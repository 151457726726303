import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form as BForm, Modal, Spinner } from 'react-bootstrap';
import { Formik, Form, FastField, FieldProps, Field } from 'formik';
import * as Yup from 'yup';
import {
  addLiveClass,
  updateLiveClass,
  getByIdLiveClass,
  clearLiveClass,
  getAllLanguage,
} from 'store/actions';
import Swal from 'sweetalert2';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LiveClassAction from './liveClassAction/LiveClassAction';
import MaskedInput from 'react-text-mask';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { get } from 'helpers/api-helper';
import LiveClassContentGradeEarned from './liveClassContentGradeEarned/LiveClassContentGradeEarned';
import { useGetQuery } from 'hooks/reactQueryHelper';

type Props = {
  addEditModel: object;
  handleCloseAddEditModel: any;
};

const premiumOption: any = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const LiveClassContentAddEditModal: React.FC<Props> = (props: any) => {
  const formRef = useRef<any>();
  const videoEl = useRef<any>();
  const previewVideoEl = useRef<any>();
  // const editorPlaceholder = document.querySelector( '#editor' ) as HTMLElement;

  // ClassicEditor.create( editorPlaceholder ).catch( error => {
  //     console.error( error );
  // } );

  const {
    addEditModel,
    handleCloseAddEditModel,

    getLoading,
    getSuccess,
    getError,

    upsertLoading,
    upsertSuccess,
    upsertError,

    onAdd,
    onUpdate,
    onGetById,

    onClear,

    getAllLoading,
    onGetAll,
    getAllSuccess,
  } = props;

  const validationSchema = Yup.object({
    existVideo: Yup.string(),
    videoUrl: Yup.mixed().required('Please Select Video.'),
    existLiveClassVideo: Yup.string(),
    nameOfVideo: Yup.string().required('Please Enter Name of Lesson.'),
    ageGroup: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    targetLanguage: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    nativeLanguage: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    languageLevel: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    contentID: Yup.string().required('Please enter content Id.'),
    playVideoTime: Yup.string().required('Please enter play time.'),
    description: Yup.string().required('Please enter description.'),
    learningOutcomes: Yup.string().required('Please enter learning outcomes.'),
    totalEarnPoint: Yup.number()
      .nullable()
      .required('Please add at least one grade.')
      .min(100, 'Please add at least one grade.'),
    encoderTime: Yup.string().required('Please Enter Encoder Time.'),
    isPremium: Yup.object().required('Please select.'),
  });

  let initialValues = {
    existVideo: '',
    videoUrl: '',
    existLiveClassVideo: '',
    nameOfVideo: '',
    ageGroup: [],
    targetLanguage: [],
    nativeLanguage: [],
    languageLevel: [],
    contentID: '',
    playVideoTime: '',
    description: '',
    learningOutcomes: '',
    totalEarnPoint: '',
    encoderTime: '',
    isPremium: '',
  };

  const [show, setShow] = useState(false);
  const [videoFile, setVideoFile] = useState<any | null>(null);
  const [previewVideoFile, setLiveClassVideoFile] = useState<any | null>(null);
  const [videoPreview, setVideoPreview] = useState<any | null>(null);
  const [previewVideoPreview, setLiveClassVideoPreview] = useState<any | null>(
    null
  );
  const [actionData, setActionData] = useState<any | null>([]);
  const [gradeEarnedData, setGradeEarnedData] = useState<any | null>([]);

  const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];

  const ageGroupOptions: any = [
    { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
    { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
    { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
  ];

  const getAllLevelDataQuery: any = useGetQuery(
    'getAllLevelDataQuery',
    `/level/getAll`
  );

  useEffect(() => {
    onGetAll({});
    getAllLevelDataQuery.mutate({});
    return () => {
      onClear();
    };
  }, []);

  useEffect(() => {
    setShow(addEditModel.show);
  }, [addEditModel.show]);

  const handleClose = () => {
    setShow(false);
    handleCloseAddEditModel({
      refresh: false,
    });
  };

  function validate(evt: any) {
    var theEvent = evt || window.event;
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  useEffect(() => {
    if (addEditModel?.id) {
      onGetById({
        _id: addEditModel?.id,
      });
    }
  }, [addEditModel]);

  const handleSubmitByRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit(); // Call the submitForm method on the form component
    }
  };

  const _handleSubmit = async (values: any, { setErrors, resetForm }: any) => {
    let newAction = actionData.map((item: any) => {
      let newActionData: any = {
        time: item?.time,
        actionName: item?.action?.actionName,
      };

      if (item?.message) {
        newActionData.message = item.message;
      }

      if (item?.breakOutRoomType) {
        newActionData.breakOutRoomType = item.breakOutRoomType;
      }

      return newActionData;
    });

    let formActions = [
      {
        time: values?.playVideoTime,
        actionName: 'Play-Video',
      },
      ...newAction,
    ];
    if (addEditModel.id === null) {
      const formData = {
        videoURL: videoFile,
        videoDuration: videoEl?.current?.duration,
        videoName: values.nameOfVideo,
        ageOfGroup: values.ageGroup,
        targetLanguage: values?.targetLanguage?.map((item: any) => item.value),
        nativeLanguage: values?.nativeLanguage?.map((item: any) => item.value),
        languageLevel: values.languageLevel?.map((item: any) => item.value),
        contentId: values.contentID,
        actions: formActions,
        gradeEarned: gradeEarnedData,
        description: values.description,
        learningOutcomes: values.learningOutcomes,
        encoderTime: values?.encoderTime,
        isPremium: values?.isPremium?.value,
      };

      onAdd(formData);
    } else {
      const formData = {
        _id: addEditModel.id,
        existVideoURL: values.existVideo,
        videoURL: videoFile,
        existLiveClassVideoURL: values.existLiveClassVideo,
        videoDuration: videoEl?.current?.duration,
        videoName: values.nameOfVideo,
        ageOfGroup: values.ageGroup,
        targetLanguage: values?.targetLanguage?.map((item: any) => item.value),
        nativeLanguage: values?.nativeLanguage?.map((item: any) => item.value),
        languageLevel: values.languageLevel?.map((item: any) => item.value),
        contentId: values.contentID,
        actions: formActions,
        gradeEarned: gradeEarnedData,
        description: values.description,
        learningOutcomes: values.learningOutcomes,
        encoderTime: values?.encoderTime,
        isPremium: values?.isPremium?.value,
      };
      onUpdate(formData);
      console.log(videoFile, 'videoURL');
    }
  };

  // const handleCheckBoxPremium = (event: any) => {
  //   setChkPremium(event.target.checked);
  // };

  useEffect(() => {
    if (upsertSuccess?.ResultCode === 'SUCCESS') {
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: upsertSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (upsertSuccess?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: upsertSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (upsertError?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: upsertError?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  }, [upsertSuccess, upsertError]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop={true}
        size='xl'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            useEffect(() => {
              if (gradeEarnedData.length > 0) {
                let totalEarnPoint: number = gradeEarnedData.reduce(
                  (accumulator: any, currentValue: any) => {
                    return accumulator + currentValue?.earnPoint;
                  },
                  0
                );
                setFieldValue('totalEarnPoint', totalEarnPoint);
              }
            }, [gradeEarnedData]);

            useEffect(() => {
              if (addEditModel?.id && getSuccess?.ResultCode === 'SUCCESS') {
                let actionsList = getSuccess?.ResultObject?.liveclassactions
                  .filter((item: any) => item?.actionName != 'Play-Video')
                  .map((item: any) => {
                    return {
                      action: {
                        label: item?.actionName,
                        value: item?.actionId,
                        actionName: item?.actionName,
                      },
                      time: item?.time,
                      message: item?.message,
                      actionName: item?.actionName,
                      breakOutRoomType: {
                        label: item?.breakOutRoomType,
                        value: item?.breakOutRoomType,
                      },
                    };
                  })
                  ?.sort(function (a: any, b: any) {
                    return a?.time?.localeCompare(b?.time);
                  });
                setActionData(actionsList);
                setGradeEarnedData(
                  getSuccess?.ResultObject?.liveClassContentGradeEarneds
                    .map((item: any) => {
                      return {
                        ...item,
                        classRoomEvent: {
                          label: item?.classRoomEvent,
                          value: item?.classRoomEvent,
                        },
                      };
                    })
                    ?.sort(function (a: any, b: any) {
                      return a?.time?.localeCompare(b?.time);
                    })
                );
                setFieldValue('videoUrl', 'Selected');
                setFieldValue('existVideo', getSuccess?.ResultObject?.videoURL);
                setVideoPreview(getSuccess?.ResultObject?.videoURL);

                setFieldValue(
                  'nameOfVideo',
                  getSuccess?.ResultObject?.videoName
                );

                let ageGroupValue = getSuccess?.ResultObject?.ageOfGroup.map(
                  (item: any) => {
                    return {
                      ...item,
                      value:
                        item.minAge +
                        (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                      label:
                        item.minAge +
                        (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                      minAge: item.minAge,
                      maxAge: item.maxAge,
                    };
                  }
                );
                setFieldValue('ageGroup', ageGroupValue);

                let targetLanguage =
                  getSuccess?.ResultObject?.targetLanguage?.map((item: any) => {
                    return {
                      ...item,
                      label: item?.Language_Name,
                      value: item?._id,
                    };
                  });

                setFieldValue('targetLanguage', targetLanguage);

                let nativeLanguage =
                  getSuccess?.ResultObject?.nativeLanguage?.map((item: any) => {
                    return {
                      ...item,
                      label: item?.Language_Name,
                      value: item?._id,
                    };
                  });
                setFieldValue('nativeLanguage', nativeLanguage);

                let languageLevel =
                  getSuccess?.ResultObject?.languageLevel?.map((item: any) => {
                    return {
                      ...item,
                      label: item?.Level_Name,
                      value: item?._id,
                    };
                  });
                setFieldValue('languageLevel', languageLevel);

                setFieldValue('contentID', getSuccess?.ResultObject?.contentId);

                let playVideoTime =
                  getSuccess?.ResultObject?.liveclassactions?.find(
                    (item: any) => item.actionName == 'Play-Video'
                  )?.time;
                setFieldValue('playVideoTime', playVideoTime);

                setFieldValue(
                  'encoderTime',
                  getSuccess?.ResultObject?.countdownTime
                );

                setFieldValue(
                  'description',
                  getSuccess?.ResultObject?.description
                );

                setFieldValue(
                  'learningOutcomes',
                  getSuccess?.ResultObject?.learningOutcomes
                );
                let premium = premiumOption.find((item: any) => {
                  return item?.value === getSuccess?.ResultObject?.isPremium;
                });
                setFieldValue('isPremium', premium ? premium : '');
              }
            }, [getSuccess]);

            return (
              <Container>
                <fieldset disabled={upsertLoading || getLoading}>
                  <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title id='example-custom-modal-styling-title'>
                        {addEditModel.id === null ? 'Add ' : 'Edit '}Live Class
                        Content Details
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='nameOfVideo'>
                            <BForm.Label>
                              Name of Lesson:
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <BForm.Control
                              type='text'
                              name='nameOfVideo'
                              placeholder='Enter Name of Lesson'
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              value={values.nameOfVideo}
                              className={
                                errors.nameOfVideo && touched.nameOfVideo
                                  ? 'border border-danger'
                                  : ''
                              }

                              // style={{
                              //     borderColor: (errors.nameOfVideo && touched.nameOfVideo) ? '#f1416c' : ''
                              // }}
                            />
                            {errors.nameOfVideo && touched.nameOfVideo ? (
                              <div className='text-danger'>
                                {errors.nameOfVideo}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='contentID'>
                            <BForm.Label>
                              Content ID# :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <BForm.Control
                              type='text'
                              name='contentID'
                              placeholder='Enter content ID'
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              value={values.contentID}
                              className={
                                errors.contentID && touched.contentID
                                  ? 'border border-danger'
                                  : ''
                              }
                            />
                            {errors.contentID && touched.contentID ? (
                              <div className='text-danger'>
                                {errors.contentID}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='ageGroup'>
                            <BForm.Label>
                              Age Group :<span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='ageGroup'
                              isMulti
                              component={Select}
                              options={ageGroupOptions}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('ageGroup', option);
                              }}
                              // onBlur={handleBlur}
                              value={values.ageGroup}
                              // classNames={{
                              //     control: (state: any) =>
                              //         state.isFocused ? (errors.ageGroup && touched.ageGroup ? 'border-danger' : 'border-grey-300') : (errors.ageGroup && touched.ageGroup ? 'border-danger' : 'border-grey-300'),
                              // }}

                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.ageGroup && touched.ageGroup
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.ageGroup && touched.ageGroup
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.ageGroup && touched.ageGroup
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.ageGroup && touched.ageGroup
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.ageGroup && touched.ageGroup ? (
                              <div className='text-danger'>
                                {errors.ageGroup}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='targetLanguage'
                          >
                            <BForm.Label>
                              Target Language :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='targetLanguage'
                              isMulti
                              component={Select}
                              options={getAllSuccess?.ResultObject?.data?.map(
                                (item: any) => {
                                  return {
                                    ...item,
                                    label: item.Language_Name,
                                    value: item._id,
                                  };
                                }
                              )}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('targetLanguage', option);
                              }}
                              // onBlur={handleBlur}
                              value={values.targetLanguage}
                              isLoading={getAllLoading}
                              isDisabled={getAllLoading}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.targetLanguage &&
                                        touched.targetLanguage
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.targetLanguage &&
                                        touched.targetLanguage
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.targetLanguage &&
                                        touched.targetLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.targetLanguage &&
                                        touched.targetLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />

                            {errors.targetLanguage && touched.targetLanguage ? (
                              <div className='text-danger'>
                                {errors.targetLanguage}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='nativeLanguage'
                          >
                            <BForm.Label>
                              Native Language :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='nativeLanguage'
                              isMulti
                              component={Select}
                              options={getAllSuccess?.ResultObject?.data?.map(
                                (item: any) => {
                                  return {
                                    ...item,
                                    label: item.Language_Name,
                                    value: item._id,
                                  };
                                }
                              )}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('nativeLanguage', option);
                              }}
                              // onBlur={handleBlur}
                              value={values.nativeLanguage}
                              isLoading={getAllLoading}
                              isDisabled={getAllLoading}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.nativeLanguage &&
                                        touched.nativeLanguage
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.nativeLanguage &&
                                        touched.nativeLanguage
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.nativeLanguage &&
                                        touched.nativeLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.nativeLanguage &&
                                        touched.nativeLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.nativeLanguage && touched.nativeLanguage ? (
                              <div className='text-danger'>
                                {errors.nativeLanguage}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='formCode'>
                            <BForm.Label>
                              Language Level :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='languageLevel'
                              isMulti={true}
                              component={Select}
                              isLoading={getAllLevelDataQuery.isLoading}
                              isDisabled={getAllLevelDataQuery.isLoading}
                              options={getAllLevelDataQuery?.data?.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Level_Name,
                                  };
                                }
                              )}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('languageLevel', option);
                              }}
                              // onBlur={handleBlur}
                              value={values.languageLevel}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.languageLevel &&
                                        touched.languageLevel
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.languageLevel &&
                                        touched.languageLevel
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.languageLevel &&
                                        touched.languageLevel
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.languageLevel &&
                                        touched.languageLevel
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.languageLevel && touched.languageLevel ? (
                              <div className='text-danger'>
                                {errors.languageLevel}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md='6'>
                          <BForm.Group className='mb-3' controlId='videoUrl'>
                            <BForm.Label>
                              Video Upload :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <BForm.Control
                              accept='video/mp4,video/x-m4v,video/*'
                              type='file'
                              name='videoUrl'
                              placeholder='Upload video'
                              onChange={(event) => {
                                const target = event.target as HTMLInputElement;
                                const file: File = (
                                  target.files as FileList
                                )[0];

                                if (file) {
                                  const fileReader = new FileReader();
                                  fileReader.readAsDataURL(file);
                                  // fileReader.onload = () => {
                                  //     if (fileReader.readyState === 2) {
                                  //     }
                                  // };
                                  fileReader.onloadstart = () => {
                                    setVideoPreview(null);
                                  };

                                  fileReader.onloadend = () => {
                                    setVideoPreview(fileReader.result);
                                    {
                                    }
                                  };
                                  setFieldValue('videoUrl', 'Selected');
                                  setVideoFile(file);
                                } else {
                                  setVideoPreview(null);
                                  setFieldValue('videoUrl', '');
                                  setVideoFile('');
                                }
                              }}
                              // onBlur={handleBlur}
                              // value={values.videoUrl}
                              style={{
                                borderColor:
                                  errors.videoUrl && touched.videoUrl
                                    ? '#f1416c'
                                    : '',
                              }}
                            />
                            {/* <span style={{ color: 'red' }}>Only .mp4, .x-m4v, .webm video files are allowed</span> */}
                            {errors.videoUrl && touched.videoUrl ? (
                              <div className='text-danger'>
                                {errors.videoUrl}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        {videoPreview && (
                          <Col md='6' className='justify-content-md-center'>
                            <video
                              controls
                              ref={videoEl}
                              style={{
                                padding: '10px',
                                width: '50%',
                                height: 'auto',
                              }}
                            >
                              <source src={videoPreview} />
                              Your browser does not support the video tag.
                            </video>
                          </Col>
                        )}
                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='playVideoTime'
                          >
                            <BForm.Label>
                              Countdown Timer Before Class Starts :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <FastField
                              name='playVideoTime'
                              render={({ field }: any) => (
                                <MaskedInput
                                  {...field}
                                  onKeyPress={validate}
                                  mask={timeMask}
                                  value={values.playVideoTime}
                                  placeholder='Enter Countdown Start Time'
                                  type='text'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control ${
                                    errors.playVideoTime &&
                                    touched.playVideoTime
                                      ? 'border border-danger'
                                      : ''
                                  }`}
                                />
                              )}
                            />

                            {errors.playVideoTime && touched.playVideoTime ? (
                              <div className='text-danger'>
                                {errors.playVideoTime}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='encoderTime'>
                            <BForm.Label>
                              Encoder Start Time : (Recommended 4 Min)
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <FastField
                              name='encoderTime'
                              render={({ field }: any) => (
                                <MaskedInput
                                  {...field}
                                  onKeyPress={validate}
                                  mask={timeMask}
                                  id='encoderTime'
                                  placeholder='Enter your Encoder time'
                                  type='text'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control ${
                                    errors.encoderTime && touched.encoderTime
                                      ? 'border border-danger'
                                      : ''
                                  }`}
                                />
                              )}
                            />

                            {errors.encoderTime && touched.encoderTime ? (
                              <div className='text-danger'>
                                {errors.encoderTime}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='description'>
                            <BForm.Label>
                              Lesson Description :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <FastField
                              as='textarea'
                              name='description'
                              placeholder='Enter description'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              className={`form-control ${
                                errors.description && touched.description
                                  ? 'border border-danger'
                                  : ''
                              }`}
                            />
                            {errors.description && touched.description ? (
                              <div className='text-danger'>
                                {errors.description}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='isPremium'>
                            <BForm.Label>
                              Premium : <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='isPremium'
                              isMulti={false}
                              component={Select}
                              options={premiumOption}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('isPremium', option);
                              }}
                              onBlur={handleBlur}
                              value={values.isPremium}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.isPremium && touched.isPremium
                                        ? '#F1416C'
                                        : 'grey'
                                      : errors.isPremium && touched.isPremium
                                      ? '#F1416C'
                                      : '#B5B5C3',
                                    boxShadow: '#B5B5C3',
                                    '&:hover': {
                                      borderColor:
                                        errors.isPremium && touched.isPremium
                                          ? '#F1416C'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.isPremium && touched.isPremium
                                          ? '#F1416C'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.isPremium && touched.isPremium ? (
                              <div className='text-danger'>
                                {errors.isPremium}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='description'>
                            <BForm.Label>
                              Learning Outcomes :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <FastField
                              name='learningOutcomes'
                              render={({
                                field,
                                form,
                              }: FieldProps<number | string>) => {
                                return (
                                  <CKEditor
                                    data={values.learningOutcomes}
                                    editor={ClassicEditor as any}
                                    config={{
                                      toolbar: [
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        'blockQuote',
                                        'numberedList',
                                        'bulletedList',
                                        '|',
                                        'undo',
                                        'redo',
                                      ],
                                    }}
                                    onReady={(editor) => {
                                      // You can store the "editor" and use when it is needed.
                                      // console.log(
                                      //   'Editor is ready to use!',
                                      //   editor
                                      // );
                                    }}
                                    onChange={(event, editor: any) => {
                                      const data = editor?.getData();
                                      // console.log({ event, editor, data });
                                      setFieldValue('learningOutcomes', data);
                                    }}
                                    onBlur={(event, editor) => {
                                      // console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                      // console.log('Focus.', editor);
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.learningOutcomes &&
                            touched.learningOutcomes ? (
                              <div className='text-danger'>
                                {errors.learningOutcomes}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className='mb-3'>
                          <LiveClassAction
                            actionData={actionData}
                            setActionData={setActionData}
                          />
                        </Col>
                        <Col md={12} className='mb-3'>
                          <LiveClassContentGradeEarned
                            gradeEarnedData={gradeEarnedData}
                            setGradeEarnedData={setGradeEarnedData}
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant='primary'
                        disabled={isSubmitting || upsertLoading || getLoading}
                        onClick={() => handleSubmitByRef()}
                      >
                        {upsertLoading ? (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='reset'
                        variant='secondary'
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Form>
                </fieldset>
              </Container>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAllLoading: state.Language.getAllLoading,
    getAllSuccess: state.Language.getAllSuccess,
    getAllError: state.Language.getAllError,

    getLoading: state.LiveClass.getLoading,
    getSuccess: state.LiveClass.getSuccess,
    getError: state.LiveClass.getError,

    upsertLoading: state.LiveClass.upsertLoading,
    upsertSuccess: state.LiveClass.upsertSuccess,
    upsertError: state.LiveClass.upsertError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onAdd: (param: any) => dispatch(addLiveClass(param)),
  onUpdate: (param: any) => dispatch(updateLiveClass(param)),
  onGetById: (param: any) => dispatch(getByIdLiveClass(param)),
  onClear: () => dispatch(clearLiveClass()),
  onGetAll: (param: any) => dispatch(getAllLanguage(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveClassContentAddEditModal);
