import { call, put, takeEvery } from 'redux-saga/effects';
import {
  LIVE_CLASS_GET_ALL,
  LIVE_CLASS_GET_BY_ID,
  LIVE_CLASS_ADD,
  LIVE_CLASS_UPDATE,
  LIVE_CLASS_DELETE,
} from './actionTypes';
import { get, post } from '../../helpers/api-helper';
import {
  getAllLiveClassSuccess,
  getAllLiveClassFail,
  getByIdLiveClassSuccess,
  getByIdLiveClassFail,
  addLiveClassSuccess,
  addLiveClassFail,
  updateLiveClassSuccess,
  updateLiveClassFail,
  deleteLiveClassSuccess,
  deleteLiveClassFail,
} from './actions';

const getAllLiveClassCall: any = async (data: any, config: any) =>
  await post('liveClassContent/getAll', data, config);
const addLiveClassCall: any = (data: any) => post('liveClassContent/add', data);
const getByLiveClassCall: any = (data: any) =>
  get('liveClassContent/GetById', data);
const updateLiveClassCall: any = (data: any, config: any) =>
  post('liveClassContent/update', data, config);
const deleteLiveClassCall: any = (data: any, config: any) =>
  post('liveClassContent/Delete', data, config);

function* getAllLiveClass({ payload: { formData } }: any): any {
  try {
    const response = yield call(getAllLiveClassCall, {
      limit: formData.limit,
      page: formData.page,
      search: formData.search,
      sort: formData.sort,
    });

    yield put(getAllLiveClassSuccess(response));
  } catch (error) {
    yield put(getAllLiveClassFail(error));
  }
}

function* getByIdLiveClass({ payload: { param } }: any): any {
  try {
    const response = yield call(getByLiveClassCall, {
      params: {
        _id: param._id,
      },
    });
    yield put(getByIdLiveClassSuccess(response));
  } catch (error) {
    yield put(getByIdLiveClassFail(error));
  }
}

function* addLiveClass({ payload: { formData } }: any): any {
  try {
    const newformData = new FormData();
    newformData.append('videoURL', formData.videoURL);
    newformData.append('videoName', formData.videoName);
    newformData.append('videoDuration', formData.videoDuration);
    newformData.append('ageOfGroup', JSON.stringify(formData.ageOfGroup));
    newformData.append(
      'targetLanguage',
      JSON.stringify(formData.targetLanguage)
    );
    newformData.append(
      'nativeLanguage',
      JSON.stringify(formData.nativeLanguage)
    );
    newformData.append('languageLevel', JSON.stringify(formData.languageLevel));
    newformData.append('contentId', formData.contentId);
    newformData.append('description', formData.description);
    newformData.append('learningOutcomes', formData.learningOutcomes);
    newformData.append('countdownTime', formData?.encoderTime);
    newformData.append('isPremium', formData.isPremium);
    newformData.append('actions', JSON.stringify(formData?.actions));
    newformData.append('gradeEarned', JSON.stringify(formData?.gradeEarned));

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    const response = yield call(addLiveClassCall, newformData, config);
    if (response?.statusCode === 200) {
      yield put(addLiveClassSuccess(response));
    } else {
      yield put(addLiveClassSuccess(response));
    }
  } catch (error) {
    yield put(addLiveClassFail(error));
  }
}

function* UpdateLiveClass({ payload: { formData } }: any): any {
  try {
    const newformData = new FormData();
    newformData.append('_id', formData._id);
    newformData.append('existVideoURL', formData.existVideoURL);
    newformData.append('videoURL', formData.videoURL);
    newformData.append('existpreviewVideoUrl', formData.existpreviewVideoUrl);
    newformData.append('videoName', formData.videoName);
    newformData.append('videoDuration', formData.videoDuration);
    newformData.append('ageOfGroup', JSON.stringify(formData.ageOfGroup));
    newformData.append(
      'targetLanguage',
      JSON.stringify(formData.targetLanguage)
    );
    newformData.append(
      'nativeLanguage',
      JSON.stringify(formData.nativeLanguage)
    );
    newformData.append('languageLevel', JSON.stringify(formData.languageLevel));
    newformData.append('contentId', formData.contentId);
    newformData.append('description', formData.description);
    newformData.append('learningOutcomes', formData.learningOutcomes);
    newformData.append('countdownTime', formData.encoderTime);
    newformData.append('isPremium', formData.isPremium);
    newformData.append('actions', JSON.stringify(formData?.actions));
    newformData.append('gradeEarned', JSON.stringify(formData?.gradeEarned));

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    const response = yield call(updateLiveClassCall, newformData, config);
    if (response?.statusCode === 200) {
      yield put(updateLiveClassSuccess(response));
    } else {
      yield put(updateLiveClassSuccess(response));
    }
  } catch (error) {
    yield put(updateLiveClassFail(error));
  }
}

function* deleteLiveClass({ payload: { param } }: any): any {
  try {
    const response = yield call(deleteLiveClassCall, {
      _id: param._id,
    });
    if (response?.statusCode === 200) {
      yield put(deleteLiveClassSuccess(response));
    } else {
      yield put(deleteLiveClassSuccess(response));
    }
  } catch (error) {
    yield put(deleteLiveClassFail(error));
  }
}

function* LiveClassSaga() {
  yield takeEvery(LIVE_CLASS_GET_ALL, getAllLiveClass);
  yield takeEvery(LIVE_CLASS_ADD, addLiveClass);
  yield takeEvery(LIVE_CLASS_GET_BY_ID, getByIdLiveClass);
  yield takeEvery(LIVE_CLASS_UPDATE, UpdateLiveClass);
  yield takeEvery(LIVE_CLASS_DELETE, deleteLiveClass);
}

export default LiveClassSaga;
