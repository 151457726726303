import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';
import LiveClassSchedulePage from './LiveClass';


const LiveClassWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Live Class</PageTitle>
      <LiveClassSchedulePage />
    </>
  );
};

export default LiveClassWrapper;
