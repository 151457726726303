import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { getHighlightedSection, scrollHandle } from './utils';

const VocabularyListenItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    vocabularyDetail,
    loading,
    nativeLang,
    targetLang,
  } = props;

  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("en");

  const [openTitle1, setOpenTitle1] = useState(false);
  const [openOption1, setOpenOption1] = useState(false);
  const [openOption2, setOpenOption2] = useState(false);
  const [openOption3, setOpenOption3] = useState(false);
  const [openOption4, setOpenOption4] = useState(false);

  const [openTitle2, setOpenTitle2] = useState(false);
  const [openOption12, setOpenOption12] = useState(false);
  const [openOption22, setOpenOption22] = useState(false);
  const [openOption32, setOpenOption32] = useState(false);
  const [openOption42, setOpenOption42] = useState(false);

  {
    /* Do not remove this comment code */
  }

  // const [openTitle3, setOpenTitle3] = useState(false);
  // const [openOption13, setOpenOption13] = useState(false);
  // const [openOption23, setOpenOption23] = useState(false);
  // const [openOption33, setOpenOption33] = useState(false);
  // const [openOption43, setOpenOption43] = useState(false);

  // const [openTitle4, setOpenTitle4] = useState(false);
  // const [openOption14, setOpenOption14] = useState(false);
  // const [openOption24, setOpenOption24] = useState(false);
  // const [openOption34, setOpenOption34] = useState(false);
  // const [openOption44, setOpenOption44] = useState(false);

  const [checkBoxoption1, setCheckBoxoption1] = useState();
  const [checkBoxoption2, setCheckBoxoption2] = useState();

  {
    /* Do not remove this comment code */
  }

  // const [checkBoxoption3, setCheckBoxoption3] = useState();
  // const [checkBoxoption4, setCheckBoxoption4] = useState();

  const urlHashRef = React.useRef<any>({});

  useEffect(() => {
    if (urlHashRef.current.one) {
      const data = getHighlightedSection(urlHashRef.current.one);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
    if (urlHashRef.current.two) {
      const data = getHighlightedSection(urlHashRef.current.two);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
  }, [urlHashRef.current.two, urlHashRef.current.one]);

  useEffect(() => {
    if (vocabularyDetail != '' && vocabularyDetail != undefined) {
      let Question1_Value =
        vocabularyDetail?.Listen_IT?.Questions[0]?.Question?.Language?.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_Answer_Value =
        vocabularyDetail?.Listen_IT?.Questions[0]?.Answer?.Language?.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question1_ID = vocabularyDetail?.Listen_IT?.Questions[0]?.Question_ID;
      urlHashRef.current.one = Question1_ID;

      let Question2_Value =
        vocabularyDetail?.Listen_IT?.Questions[1]?.Question.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question2_Answer_Value =
        vocabularyDetail?.Listen_IT?.Questions[1]?.Answer.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question2_ID = vocabularyDetail?.Listen_IT?.Questions[1]?.Question_ID;
      urlHashRef.current.two = Question2_ID;

      {
        /* Do not remove this comment code */
      }
      // let Question3_Value = vocabularyDetail.Listen_IT.Questions[2].Question.Language.filter((x: any) => (x.Language_Code == nativeLang))[0];
      // let Question3_Answer_Value = vocabularyDetail.Listen_IT.Questions[2].Answer.Language.filter((x: any) => (x.Language_Code == targetLang))[0];
      // let Question3_ID = vocabularyDetail.Listen_IT.Questions[2].Question_ID
      // urlHashRef.current.three = Question3_ID;

      // let Question4_Value = vocabularyDetail.Listen_IT.Questions[3].Question.Language.filter((x: any) => (x.Language_Code == nativeLang))[0];
      // let Question4_Answer_Value = vocabularyDetail.Listen_IT.Questions[3].Answer.Language.filter((x: any) => (x.Language_Code == targetLang))[0];
      // let Question4_ID = vocabularyDetail.Listen_IT.Questions[3].Question_ID

      // urlHashRef.current.four = Question4_ID;

      // ListenIT Questions1
      setValue('Vocabulary_ListenIt_Question1_QuestionID', Question1_ID);
      setValue(
        'Vocabulary_ListenIt_Question1_AudioText',
        Question1_Value?.AudioText
      );
      setCheckBoxoption1(Question1_Value?.Answer);
      setValue('Vocabulary_ListenIt_Question1_Answer', Question1_Value?.Answer);
      setValue(
        'Vocabulary_ListenIt_Question1_Option1',
        Question1_Answer_Value?.Option1
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Option2',
        Question1_Answer_Value?.Option2
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Option3',
        Question1_Answer_Value?.Option3
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Option4',
        Question1_Answer_Value?.Option4
      );

      // ListenIT Questions2
      setValue('Vocabulary_ListenIt_Question2_QuestionID', Question2_ID);
      setValue(
        'Vocabulary_ListenIt_Question2_AudioText',
        Question2_Value?.AudioText
      );
      setCheckBoxoption2(Question2_Value?.Answer);
      setValue('Vocabulary_ListenIt_Question2_Answer', Question2_Value?.Answer);
      setValue(
        'Vocabulary_ListenIt_Question2_Option1',
        Question2_Answer_Value?.Option1
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Option2',
        Question2_Answer_Value?.Option2
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Option3',
        Question2_Answer_Value?.Option3
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Option4',
        Question2_Answer_Value?.Option4
      );

      setValue(
        'Vocabulary_ListenIt_Question1_Roman_AudioText',
        Question1_Value?.AudioText_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Roman_Option1',
        Question1_Answer_Value?.Option1_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Roman_Option2',
        Question1_Answer_Value?.Option2_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Roman_Option3',
        Question1_Answer_Value?.Option3_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Roman_Option4',
        Question1_Answer_Value?.Option4_Roman_Language
      );

      setValue(
        'Vocabulary_ListenIt_Question1_AudioText_Audio',
        Question1_Value?.AudioText_Audio
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Audio_Option1',
        Question1_Answer_Value?.Audio_Option1
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Audio_Option2',
        Question1_Answer_Value?.Audio_Option2
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Audio_Option3',
        Question1_Answer_Value?.Audio_Option3
      );
      setValue(
        'Vocabulary_ListenIt_Question1_Audio_Option4',
        Question1_Answer_Value?.Audio_Option4
      );

      setValue(
        'Vocabulary_ListenIt_Question2_Roman_AudioText',
        Question2_Value?.AudioText_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Roman_Option1',
        Question2_Answer_Value?.Option1_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Roman_Option2',
        Question2_Answer_Value?.Option2_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Roman_Option3',
        Question2_Answer_Value?.Option3_Roman_Language
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Roman_Option4',
        Question2_Answer_Value?.Option4_Roman_Language
      );

      setValue(
        'Vocabulary_ListenIt_Question2_AudioText_Audio',
        Question1_Value?.AudioText_Audio
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Audio_Option1',
        Question2_Answer_Value?.Audio_Option1
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Audio_Option2',
        Question2_Answer_Value?.Audio_Option2
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Audio_Option3',
        Question2_Answer_Value?.Audio_Option3
      );
      setValue(
        'Vocabulary_ListenIt_Question2_Audio_Option4',
        Question2_Answer_Value?.Audio_Option4
      );

      {
        /* Do not remove this comment code */
      }

      // // ListenIT Questions3
      // setValue("Vocabulary_ListenIt_Question3_QuestionID", Question3_ID)
      // setValue("Vocabulary_ListenIt_Question3_AudioText", Question3_Value.AudioText)
      // setCheckBoxoption3(Question3_Value.Answer)
      // setValue("Vocabulary_ListenIt_Question3_Answer", Question3_Value.Answer)
      // setValue("Vocabulary_ListenIt_Question3_Option1", Question3_Answer_Value.Option1)
      // setValue("Vocabulary_ListenIt_Question3_Option2", Question3_Answer_Value.Option2)
      // setValue("Vocabulary_ListenIt_Question3_Option3", Question3_Answer_Value.Option3)
      // setValue("Vocabulary_ListenIt_Question3_Option4", Question3_Answer_Value.Option4)

      // // ListenIT Questions4
      // setValue("Vocabulary_ListenIt_Question4_QuestionID", Question4_ID)
      // setValue("Vocabulary_ListenIt_Question4_AudioText", Question4_Value.AudioText)
      // setCheckBoxoption4(Question4_Value.Answer)
      // setValue("Vocabulary_ListenIt_Question4_Answer", Question4_Value.Answer)
      // setValue("Vocabulary_ListenIt_Question4_Option1", Question4_Answer_Value.Option1)
      // setValue("Vocabulary_ListenIt_Question4_Option2", Question4_Answer_Value.Option2)
      // setValue("Vocabulary_ListenIt_Question4_Option3", Question4_Answer_Value.Option3)
      // setValue("Vocabulary_ListenIt_Question4_Option4", Question4_Answer_Value.Option4)
    }
  }, [vocabularyDetail]);

  const handleChangeCheckbox = (event: any) => {
    setCheckBoxoption1(event.target.value);
    setValue('Vocabulary_ListenIt_Question1_Answer', event.target.value);
  };

  const handleChangeCheckbox2 = (event: any) => {
    setCheckBoxoption2(event.target.value);
    setValue('Vocabulary_ListenIt_Question2_Answer', event.target.value);
  };

  {
    /* Do not remove this comment code */
  }

  // const handleChangeCheckbox3 = (event: any) => {
  //   setCheckBoxoption3(event.target.value);
  //   setValue('Vocabulary_ListenIt_Question3_Answer', event.target.value);
  // };
  // const handleChangeCheckbox4 = (event: any) => {
  //   setCheckBoxoption4(event.target.value);
  //   setValue('Vocabulary_ListenIt_Question4_Answer', event.target.value);
  // };

  const hash = urlHashRef.current;

  return (
    <>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Listen It 1
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.one)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Audio Text <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle1(false)}
                    open={openTitle1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      name='AudioText'
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question1_AudioText')}
                      placeholder='Enter Title'
                      onFocus={() => setOpenTitle1(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question1_AudioText && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question1_AudioText.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5></h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option1'
                      checked={checkBoxoption1 === 'option1'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 1 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption1(false)}
                    open={openOption1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption1(true)}
                      type='text'
                      className='form-control'
                      placeholder='Baby'
                      name='ListenItOption1'
                      {...register('Vocabulary_ListenIt_Question1_Option1')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question1_Option1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question1_Option1.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option2'
                      checked={checkBoxoption1 === 'option2'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 2 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption2(false)}
                    open={openOption2}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption2(true)}
                      type='text'
                      className='form-control'
                      placeholder='Balloon'
                      {...register('Vocabulary_ListenIt_Question1_Option2')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question1_Option2 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question1_Option2.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option3'
                      checked={checkBoxoption1 === 'option3'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 3 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption3(false)}
                    open={openOption3}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption3(true)}
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question1_Option3')}
                      placeholder='Computer'
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question1_Option3 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question1_Option3.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option4'
                      checked={checkBoxoption1 === 'option4'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 4 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption4(false)}
                    open={openOption4}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption4(true)}
                      type='text'
                      className='form-control'
                      placeholder='Float'
                      {...register('Vocabulary_ListenIt_Question1_Option4')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question1_Option4 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question1_Option4.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
        </Collapsible>

        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Listen It 2
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.two)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Audio Text <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle2(false)}
                    open={openTitle2}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      name='AudioText'
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question2_AudioText')}
                      placeholder='Enter Title'
                      onFocus={() => setOpenTitle2(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question2_AudioText && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question2_AudioText.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5></h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option1'
                      checked={checkBoxoption2 === 'option1'}
                      onChange={handleChangeCheckbox2}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 1 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption12(false)}
                    open={openOption12}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption12(true)}
                      type='text'
                      className='form-control'
                      placeholder='Baby'
                      name='ListenItOption1'
                      {...register('Vocabulary_ListenIt_Question2_Option1')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question2_Option1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question2_Option1.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option2'
                      checked={checkBoxoption2 === 'option2'}
                      onChange={handleChangeCheckbox2}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 2 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption22(false)}
                    open={openOption22}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption22(true)}
                      type='text'
                      className='form-control'
                      placeholder='Balloon'
                      {...register('Vocabulary_ListenIt_Question2_Option2')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question2_Option2 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question2_Option2.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option3'
                      checked={checkBoxoption2 === 'option3'}
                      onChange={handleChangeCheckbox2}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 3 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption32(false)}
                    open={openOption32}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption32(true)}
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question2_Option3')}
                      placeholder='Computer'
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question2_Option3 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question2_Option3.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option4'
                      checked={checkBoxoption2 === 'option4'}
                      onChange={handleChangeCheckbox2}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 4 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption42(false)}
                    open={openOption42}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption42(true)}
                      type='text'
                      className='form-control'
                      placeholder='Float'
                      {...register('Vocabulary_ListenIt_Question2_Option4')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question2_Option4 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question2_Option4.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
        </Collapsible>

        {/* Do not remove this comment code */}

        {/* <br/>
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                Listen It 3
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
           containerElementProps={getHighlightedSection(hash.three)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Audio Text <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle3(false)}
                    open={openTitle3}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      name='AudioText'
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question3_AudioText')}
                      placeholder='Enter Title'
                      onFocus={() => setOpenTitle3(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question3_AudioText && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question3_AudioText.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5></h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option1'
                      checked={checkBoxoption3 === 'option1'}
                      onChange={handleChangeCheckbox3}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 1 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption13(false)}
                    open={openOption13}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption13(true)}
                      type='text'
                      className='form-control'
                      placeholder='Baby'
                      name='ListenItOption1'
                      {...register('Vocabulary_ListenIt_Question3_Option1')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question3_Option1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question3_Option1.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option2'
                      checked={checkBoxoption3 === 'option2'}
                      onChange={handleChangeCheckbox3}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 2 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption23(false)}
                    open={openOption23}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption23(true)}
                      type='text'
                      className='form-control'
                      placeholder='Balloon'
                      {...register('Vocabulary_ListenIt_Question3_Option2')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question3_Option2 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question3_Option2.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option3'
                      checked={checkBoxoption3 === 'option3'}
                      onChange={handleChangeCheckbox3}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 3 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption33(false)}
                    open={openOption33}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption33(true)}
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question3_Option3')}
                      placeholder='Computer'
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question3_Option3 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question3_Option3.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option4'
                      checked={checkBoxoption3 === 'option4'}
                      onChange={handleChangeCheckbox3}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 4 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption43(false)}
                    open={openOption43}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption43(true)}
                      type='text'
                      className='form-control'
                      placeholder='Float'
                      {...register('Vocabulary_ListenIt_Question3_Option4')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question3_Option4 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question3_Option4.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br/>
        </Collapsible> */}

        {/* <br/>
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                Listen It 4
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
           containerElementProps={getHighlightedSection(hash.four)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Audio Text <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle4(false)}
                    open={openTitle4}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      name='AudioText'
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question4_AudioText')}
                      placeholder='Enter Title'
                      onFocus={() => setOpenTitle4(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question4_AudioText && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question4_AudioText.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option1'
                      checked={checkBoxoption4 === 'option1'}
                      onChange={handleChangeCheckbox4}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 1 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption14(false)}
                    open={openOption14}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption14(true)}
                      type='text'
                      className='form-control'
                      placeholder='Baby'
                      name='ListenItOption1'
                      {...register('Vocabulary_ListenIt_Question4_Option1')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question4_Option1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question4_Option1.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option2'
                      checked={checkBoxoption4 === 'option2'}
                      onChange={handleChangeCheckbox4}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 2 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption24(false)}
                    open={openOption24}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption24(true)}
                      type='text'
                      className='form-control'
                      placeholder='Balloon'
                      {...register('Vocabulary_ListenIt_Question4_Option2')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question4_Option2 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question4_Option2.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option3'
                      checked={checkBoxoption4 === 'option3'}
                      onChange={handleChangeCheckbox4}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 3 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption34(false)}
                    open={openOption34}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption34(true)}
                      type='text'
                      className='form-control'
                      {...register('Vocabulary_ListenIt_Question4_Option3')}
                      placeholder='Computer'
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question4_Option3 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question4_Option3.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option4'
                      checked={checkBoxoption4 === 'option4'}
                      onChange={handleChangeCheckbox4}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 4 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption44(false)}
                    open={openOption44}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption44(true)}
                      type='text'
                      className='form-control'
                      placeholder='Float'
                      {...register('Vocabulary_ListenIt_Question4_Option4')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Vocabulary_ListenIt_Question4_Option4 && (
                    <p style={{ color: 'red' }}>
                      {errors.Vocabulary_ListenIt_Question4_Option4.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br/>
        </Collapsible> */}
      </div>
    </>
  );
};

export default VocabularyListenItActivity;
