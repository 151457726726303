import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';
import {
  ClearSuccesError,
  teacherGetList,
  teacherDelete,
  teacherInvitation,
  teacherGetById,
  teacherUpdate,
  emailAlreadyExists,
} from '../../../store/teacher/actions';

import Loader from '../../components/Loader';
import DetailEditModal from '../../components/Teacher/DetailEditModal';
import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
import useTableState from '../../components/Table/useTableState';
import callBackendApi from '../../../utils/callBackendApi';
import useSearchByState from '../../../hooks/useSearchByState';
import useBackendApiCaller from '../../../hooks/useBackendApiCaller';
import { urlRoutes } from '../../../shared/apiConfig';
import { TableGlobalSearch } from '../../components/Table/TableGlobalSearch';
import TableInner from '../../components/Table/TableInner';
import { TablePagination } from '../../components/Table/TablePagination';
import { cx } from '../../../utils/classnames';
import { DeleteModal } from '../../components/Teacher/DeleteModal';
import { useQuery } from 'react-query';

const TeacherPage: React.FC = (props: any) => {
  const {
    success,
    teachergetlist,
    onTeacherDelete,
    onTeacherGetById,
    teacherdetails,
    onTeacherEdit,
    successInvitation,
    ClearSuccesError,
    loader,
    inviteLoading,
    emailexistssuccess,
  } = props;

  const [teacherData, setTeacherData] = useState([]);
  const [_loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [deleteID, setDeleteID] = useState('');
  const [teacherDeleteID, setTeacherDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editID, setEditID] = useState('');

  const [invitationCancel, setInvitationCancel] = useState(false);
  const [disable, setDisable] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      setIsLoading((isLoading) => !isLoading);
    };
    loadData();
  }, []);

  const InvitationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email address')
      .required('Please enter email address'),
  });

  const handleShowInvitation = () => {
    formik.resetForm({});
  };

  const handleCloseInvitationModel = () => {
    setInvitationCancel(false);
    formik.resetForm({});
  };

  const initialValues = {
    email: '',
  };

  useEffect(() => {
    setEditModel(false);
    props.teachergetReq();
  }, []);

  useEffect(() => {
    if (teachergetlist?.length > 0) {
      setEditModel(false);
      setTeacherData(teachergetlist);
    } else {
      setTeacherData([]);
    }
  }, [teachergetlist]);

  useEffect(() => {

    if (teacherdetails) {
      setEditModel(true);
    }
  }, [teacherdetails]);

  useEffect(() => {
    if (successInvitation) {
      ClearSuccesError();

      if (successInvitation.ResultCode === 'SUCCESS') {
        Swal.fire(successInvitation.ResultMessage, ' ', 'success');
        formik.resetForm({ errors: {} });
        setLoading(false);
        setInvitationCancel(false);
        document
          .getElementById('kt_modal_invite_friends')
          ?.classList.remove('show');
        document.querySelector('.modal-backdrop')?.classList.remove('show');
      } else if (successInvitation.ResultCode === 'EXISTS') {
        Toast.fire({
          icon: 'warning',
          title: successInvitation.ResultMessage,
        });
      } else {
        Swal.fire(successInvitation.ResultMessage, ' ', 'warning');
      }
    }
  }, [successInvitation]);

  useEffect(() => {
    if (success) {
      ClearSuccesError();
      if (success.ResultCode === 'SUCCESS') {
        document
          .getElementById('Edit_Modal_scrollable')
          ?.classList.remove('show');
        document.querySelector('.modal-backdrop')?.classList.remove('show');
        Swal.fire(success.ResultMessage, ' ', 'success');
        handleCloseEditModel();
        props.teachergetReq();
      } else {
        Swal.fire(success.ResultMessage, ' ', 'warning');
      }
    }
  }, [success]);

  const formik = useFormik({
    initialValues,
    validationSchema: InvitationSchema,
    validateOnChange: true, // this one
    validateOnBlur: false, // and this one
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      try {
        const formData = {
          email: values.email,
        };
        props.teacherInvitationReq(formData);
      } catch (error) {
        setStatus();
      }
    },
  });

  useEffect(() => {

    setEditID(editID);
  }, [editID, success]);

  const handleDelete = (stdId: any) => {
    setDeleteModel(true);
    setDeleteID(stdId);
  };

  const handleDeleteRecord = () => {
    const data = {
      userID: deleteID,
      teacherId: teacherDeleteID,
    };
    onTeacherDelete(data);
    setDeleteModel(false);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseEditModel = () => {
    setEditModel(false);
  };
  const [deletePayload, setDeletePayload] = React.useState<any>(null)

  const [visible, setVisible] = React.useState(false);
  const [editData, setEditData] = React.useState<any>({});

  const toggleVisible = React.useCallback(() => {
    setVisible((p) => !p);
    !visible && setEditData({});
  }, [visible]);

  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Name
          </span>
        ),
        accessor: 'FirstName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-start flex-row align-items-center'>
              <div className='symbol symbol-45px me-5'>
                {result.ProfilePic ? (
                  <img
                    src={result.ProfilePic}
                    alt=''
                    onError={function (ele) {
                      ele.currentTarget.src = toAbsoluteUrl('/media/svg/avatars/blank.svg')
                    }}
                  />
                ) : (
                  <img
                    src={defaultImageUrl}
                    alt=''
                    onError={function (ele) {
                      ele.currentTarget.src =
                        toAbsoluteUrl('/media/svg/avatars/blank.svg')
                    }}
                  />
                )}
              </div>
              <div>
                <a className='text-dark fw-bold fs-6'>
                  {result.FirstName}
                </a>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {result.LastName}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'Email',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <a
              href={'mailto:' + result.Email}
              className='text-dark fw-bold text-hover-primary d-block fs-6'
            >
              {result.Email}
            </a>
          );
        },
      },
      {
        Header: 'Active?',
        accessor: 'isActive',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span
              className={
                result.IsActive
                  ? 'badge badge-success fs-7 fw-bold'
                  : 'badge badge-danger fs-7 fw-bold'
              }
            >
              {result.IsActive ? 'Yes' : 'No'}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title="edit"
                data-bs-toggle='modal'
                data-bs-target='#Edit_Modal_scrollable'
                onClick={() => {
                  toggleVisible();
                  setEditData(result);
                  setEditModel(true);
                  setEditID(result?._id);
                  onTeacherGetById(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title="delete"
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => {
                  setDeletePayload(result || null)
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    [onTeacherGetById]
  );

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const { sort, page, limit, search } = tableState;

  const fetcher = React.useCallback(() => {
    return callBackendApi('post', {
      url: urlRoutes.teachers,
      data: {
        page,
        limit,
        search,
        sort,
      },
    });
  }, [page, limit, sort, search]);

  const [loading, data, fetch] = useBackendApiCaller(fetcher, [fetcher]);
  const list = data?.ResultData ?? [];

  return (
    <>
      <div className='card mb-10'>
        <Loader loading={loading} />
        <Table
          data={list}
          columns={columns}
          initialState={tableInitialState}
          pageCount={data?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Teacher Data
                </span>{' '}
              </h3>
              <span className='d-flex align-items-center'>
                <span className='me-2'>
                  <TableGlobalSearch />
                </span>
                <span>
                  <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <a
                      href='#'
                      className='btn btn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_invite_friends'
                      onClick={handleShowInvitation}
                    >
                      <KTSVG
                        path='media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-3'
                      />
                      Teacher Invitation
                    </a>
                  </div>
                </span>
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={loading} entityLabel='teacher' />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <div className='modal fade' id='kt_modal_invite_friends'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='modal-title'>Teacher Invitation</h2>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary pb-5'
                data-bs-dismiss='modal'
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='currentColor'
                    ></rect>
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='currentColor'
                    ></rect>
                  </svg>
                </span>
              </div>
            </div>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_modal_form_invite_friends'
            >
              <div className='modal-body'>
                <div className='fv-row'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    Email<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    {...formik.getFieldProps('email')}
                    className={`form-control bg-transparent ${formik.errors.email && formik.touched.email ? 'border border-danger' : ''}`}

                    type='email'
                    name='email'
                    autoComplete='off'
                  />
                  {formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn me-2 float-end btn-light'
                  data-bs-dismiss='modal'
                  onClick={handleCloseInvitationModel}
                >
                  Cancel
                </button>
                {!disable && (
                  <button
                    type='submit'
                    className='btn btn-primary me-2 float-end'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!inviteLoading ? (
                      'Send Invitation'
                    ) : (
                      <span
                        className='indicator-progress'
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeleteModal
        onCancel={() => setDeletePayload(null)}
        visible={Boolean(deletePayload)}
        initialValue={deletePayload}
        fetch={fetch}
      />
      <DetailEditModal
        fetch={fetch}
        visible={visible}
        initialValue={editData}
        onCancel={toggleVisible}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loader: state.teacher.loading,
    error: state.teacher.error,
    success: state.teacher.success,
    teachergetlist: state.teacher.teachergetlist,
    teacherdetails: state.teacher.teacherdetails,
    successInvitation: state.teacher.successInvitation,
    inviteLoading: state.teacher.inviteLoading,
    emailexistssuccess: state.teacher.emailexistssuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  teacherInvitationReq: (params: any) => dispatch(teacherInvitation(params)),
  teachergetReq: () => dispatch(teacherGetList()),
  onTeacherGetById: (params: any) => dispatch(teacherGetById(params)),
  onTeacherEdit: (params: any) => dispatch(teacherUpdate(params)),
  ClearSuccesError: () => dispatch(ClearSuccesError()),
  onTeacherDelete: (params: any) => dispatch(teacherDelete(params)),
  emailExists: (params: any) => dispatch(emailAlreadyExists(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherPage);
