import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import LiveClassActionAdd from './LiveClassActionAdd';
import { KTSVG } from 'theme/helpers';

const LiveClassAction = (props: any) => {
  const { actionData, setActionData } = props;

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});

  const onEdit = (item: any, index: any) => {
    if (show) {
      setFormData({});
      setShow(false);
    }

    setTimeout(() => {
      setShow(true);
      item.index = index;
      setFormData(item);
    }, 100);
  }

  const onAdd = () => {
    setFormData({});
    setShow(false);

    setTimeout(() => {
      setShow(true);
    }, 100);
  }

  const onDelete = (array: any, index: any) => {
    array.splice(index, 1);
    setActionData([
      ...array
    ]);
  }

  return (
    <Card className='border bg-light p-0'>
      <Card.Header style={{ minHeight: '50px' }} className='align-items-center bg-secondary bg-gradient'><b>Actions</b></Card.Header>
      <Card.Body>
        <div className='d-flex justify-content-end mb-5'>
          <Button variant="success" size="sm" disabled={show} onClick={() => onAdd()}>
            Add Action
          </Button>
        </div>

        {
          show &&
          <LiveClassActionAdd setShow={setShow} formData={formData} setFormData={setFormData} actionData={actionData} setActionData={setActionData} />
        }
        <Table striped bordered hover size="sm" className='border-secondary'>
          <thead>
            <tr>
              <th>Type</th>
              <th>Time</th>
              <th>Message</th>
              <th>Breakout</th>
              <th style={{ width: '1%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              actionData?.map((item: any, index: any, array: any) => {
                return (
                  <tr>
                    <td className='align-middle'>{item?.action?.label}</td>
                    <td className='align-middle'>{item?.time}</td>
                    <td className='align-middle'>{item?.message ? item?.message : '-'}</td>
                    <td className='align-middle'>{item?.breakOutRoomType?.value ? item?.breakOutRoomType?.value : '-'}</td>
                    <td className='align-middle'>
                      <div className="d-flex flex-shrink-0">
                        <button
                          className="btn btn-icon btn-primary btn-sm me-1"
                          title="edit"
                          onClick={(e) => {
                            e.preventDefault();
                            onEdit(item, index)
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <button
                          className="btn btn-icon btn-danger btn-sm"
                          title="delete"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_confirmation_modal"
                          onClick={(e) => {
                            e.preventDefault();
                            setShow(false);
                            onDelete(array, index)
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
            {
              actionData && actionData.length === 0 &&
              <tr>
                <td colSpan={10} className='text-center'>
                  No action available
                </td>
              </tr>
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card >
  )
}

export default LiveClassAction
