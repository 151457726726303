import React, { useEffect, useState } from 'react';
import useTableState from 'app/components/Table/useTableState';
import { TablePagination } from 'app/components/Table/TablePagination';
import TableInner from 'app/components/Table/TableInner';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import Table from 'app/components/Table';
import Loader from 'app/components/Loader';
import { CellProps, Column } from 'react-table';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { KTSVG } from 'theme/helpers';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from 'theme/layout/core';
import EnrollmentHeadPageTab from './EnrollmentHeadPageTab';
import moment from 'moment';
import EnrollmentDeleteConfirmationModel from './EnrollmentDeleteConfirmationModel';
import Swal from 'sweetalert2';

const ExplanationPage: React.FC = (props: any) => {
  const getAllExplanationsQuery: any = usePostQuery(
    'getAllExplanations',
    '/enrollment/getall/explanation'
  );

  const deleteEnrollFormQuery: any = usePostQuery(
    'declineEnrollForm',
    '/enrollment/delete',
    {}
  );

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [explanationData, setExplanationData] = useState([]);
  const [resultData, setResultData] = useState<any>('');
  const [deleteModel, setDeleteModel] = useState(false);
  const navigate = useNavigate();

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});
  const { sort, page, limit, search } = tableState;

  useEffect(() => {
    getAllExplanationsQuery.mutateAsync({});
  }, []);

  useEffect(() => {
    if (getAllExplanationsQuery.isSuccess) {
      console.log(getAllExplanationsQuery?.data?.ResultObject);
      setExplanationData(getAllExplanationsQuery?.data?.ResultObject);
    }
  }, [getAllExplanationsQuery.isSuccess]);

  const onPreview = (result: any) => {
    if (result) {
    }
  };

  const handleDeleteRecord = () => {
    setDeleteModel(false);
    if (resultData) {
      if (resultData?.SubmitFormType === 'Application') {
        deleteEnrollFormQuery.mutateAsync({
          EnrollmentID: resultData?.ID,
          Email: resultData?.Email,
        });
      }
    }
  };

  useEffect(() => {
    if (deleteEnrollFormQuery?.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: 'Record has been deleted successfully.',
      });
      // getAllExplanationsQuery.mutateAsync({});
    }
  }, [deleteEnrollFormQuery?.isSuccess]);

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            FullName
          </span>
        ),
        accessor: 'User',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {result?.Name}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start '>
            SchoolYear
          </span>
        ),
        accessor: 'SchoolYear',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.SchoolYear;
          return (
            <span className='text-dark fw-bold fs-6'>{result?.label}</span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start justify-content-start'>
            FormType
          </span>
        ),
        accessor: 'FormType',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {result?.FormType == 1
                ? `K 12 ${result?.SubmitFormType}`
                : `K 6 ${result?.SubmitFormType}`}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Created Date
          </span>
        ),
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {moment(result?.createdAt).format('DD-MM-YYYY hh:mm A')}
              </a>
            </div>
          );
        },
      },
      // {
      //   Header: () => (
      //     <span className='ps-4 min-w-325px rounded-start justify-content-start'>
      //       Status
      //     </span>
      //   ),
      //   accessor: 'Status',
      //   Cell: (props: CellProps<any>) => {
      //     const result = props?.row?.original;
      //     return (
      //       <span
      //         className={
      //           result?.Status == 2
      //             ? 'text-center badge badge-success'
      //             : result?.Status == 3
      //             ? 'text-center badge badge-danger'
      //             : result?.Status == 4
      //             ? 'text-center badge badge-danger'
      //             : 'text-center badge badge-secondary'
      //         }
      //       >
      //         {result?.Status == 2
      //           ? 'Accept'
      //           : result?.Status == 3
      //           ? 'Issue Fixed'
      //           : result?.Status == 4
      //           ? 'Decline'
      //           : null}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              {/* <button
                className='btn btn-icon btn-secondary btn-sm me-1'
                title='view'
                onClick={() =>
                  navigate(`/enrollment-details/view/${result?._id}`)
                }
              >
                <BsEyeFill />
              </button> */}
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                // onClick={() => {
                //   setDeleteModel(true);
                //   const formData = {
                //     ID: result?._id,
                //     Email: result?.UserID?.Email,
                //     SubmitFormType: 'Application',
                //   };
                //   setResultData(formData);
                // }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>Explanation</PageTitle>
      <div className='card mb-10'>
        <Loader
          loading={
            getAllExplanationsQuery.isLoading ||
            deleteEnrollFormQuery?.isLoading
          }
        />
        <Table
          data={explanationData}
          columns={columns}
          initialState={tableInitialState}
          pageCount={
            getAllExplanationsQuery?.data?.ResultObject?.totalPages ?? -1
          }
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='pt-5'>
              <EnrollmentHeadPageTab activeTab='Explanation' />
            </div>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>Explanations</div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllExplanationsQuery.isLoading}
                    entityLabel='unenrollment'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <EnrollmentDeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
    </>
  );
};

export default ExplanationPage;
