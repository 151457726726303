import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import Badge from 'react-bootstrap/Badge';
import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
//import { TablePagination } from "app/components/Table/TablePagination";
import TableInner from 'app/components/Table/TableInner';
import useTableState from 'app/components/Table/useTableState';
import { KTSVG } from 'theme/helpers';
import Swal from 'sweetalert2';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import {
  deleteLiveClassSchedule,
  getAllLiveClassSchedule,
} from 'store/liveClassSchedule/actions';
import LiveClassScheduleAddEditModal from './LiveClassAddEditModal';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Stack from 'react-bootstrap/Stack';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import { jwtTokenDecode } from 'hooks/tokenHook';
import LiveClassScheduleDateListModal from './LiveClassScheduleDateListModal';
import { useNavigate } from 'react-router-dom';

const LiveClass: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});
  const { sort, page, limit, search } = tableState;

  const [deleteID, setDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    liveClassScheduleDateListModalShow,
    setLiveClassScheduleDateListModalShow,
  ] = React.useState({
    id: null,
    show: false,
  });

  const [addEditModel, setAddEditModel] = useState({
    id: null,
    show: false,
  });

  const [data, setData] = useState([]);
  const { auth } = useAuth();

  const {
    onGetAll,
    onDelete,
    getAllLoading,
    getAllSuccess,
    getAllError,
    deleteLoading,
    deleteSuccess,
    deleteError,
  } = props;

  useEffect(() => {
    getAll();
  }, [sort, page, limit, search]);

  const getAll = () => {
    onGetAll({
      page: page,
      limit: limit,
      search: search,
      sort: sort,
    });
  };

  useEffect(() => {
    if (getAllSuccess?.ResultCode === 'SUCCESS') {
      setData(getAllSuccess?.ResultObject.data || []);
    }
  }, [getAllSuccess]);

  useEffect(() => {
    if (deleteSuccess?.ResultCode === 'SUCCESS') {
      Swal.fire({
        icon: 'success',
        title: deleteSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: false,
      }).then((result) => {
        getAll();
      });
    }
  }, [deleteSuccess]);

  const handleDeleteRecord = () => {
    const data = {
      _id: deleteID,
    };
    setDeleteModel(false);
    onDelete(data);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseAddEditModel = (props: any) => {
    const { refresh } = props;

    setAddEditModel((prevState) => {
      const data = {
        ...prevState,
        show: false,
      };
      return data;
    });
    if (refresh) {
      getAll();
    }
  };

  const getLiveClassJoiningURL: any = usePostQuery(
    'getLiveClassJoiningURL',
    `liveClass/getLiveClassJoiningURL`,
    {}
  );

 

  useEffect(() => {
    if (
      getLiveClassJoiningURL.isSuccess &&
      getLiveClassJoiningURL?.data?.ResultObject?.url
    ) {
      window.open(getLiveClassJoiningURL?.data?.ResultObject?.url, '_blank');
    }
  }, [getLiveClassJoiningURL.isSuccess]);





  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start text-nowrap'>
            Name
          </span>
        ),
        accessor: 'name',

        Cell: (props: CellProps<any>) => {
          return (
            <span className='ps-4 text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.name}
            </span>
          );
        },
      },
      {
        Header: 'Age Group',
        accessor: 'ageOfGroup',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.ageOfGroup?.map((item: any) => (
                <span
                  className='badge badge-pill badge-primary'
                  style={{ marginRight: '3px' }}
                >
                  {item.minAge +
                    (item.maxAge === 200 ? '+' : '-' + item.maxAge)}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='min-w-325px rounded-start text-start text-nowrap'>
            Language Level
          </span>
        ),
        accessor: 'languageLevel',

        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              <span className='text-dark fw-bold d-block fs-6'>
                {props?.row?.original?.languageLevel?.map((item: any) => (
                  <span
                    className='badge badge-pill badge-secondary'
                    style={{ marginRight: '3px' }}
                  >
                    {item.Level_Name}
                  </span>
                ))}
              </span>
            </span>
          );
        },
      },

      {
        Header: 'Target Language',
        accessor: 'targetLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?._targetLanguage
                ?.map((item: any) => item.Language_Name)
                .join(', ')}
            </span>
          );
        },
      },
      {
        Header: 'Native Language',
        accessor: 'nativeLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?._nativeLanguage?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item.Language_Name}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Type',
        accessor: '',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <>
              <span className='badge badge-primary'>Schedule</span>
              <span
                onClick={() => {
                  setLiveClassScheduleDateListModalShow({
                    ...liveClassScheduleDateListModalShow,
                    show: true,
                    id: props?.row?.original?._id,
                  });
                }}
              >
                <i className='bi bi-info-circle cursor-pointer ms-2'></i>
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  setAddEditModel((prevState) => {
                    const data = {
                      ...prevState,
                      id: result?._id,
                      show: true,
                    };
                    return data;
                  });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#delete_confirmation_modal'
                onClick={() => {
                  setDeleteModel(true);
                  setDeleteID(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {liveClassScheduleDateListModalShow?.show && (
        <LiveClassScheduleDateListModal
          show={liveClassScheduleDateListModalShow.show}
          id={liveClassScheduleDateListModalShow.id}
          onHide={() =>
            setLiveClassScheduleDateListModalShow({
              ...liveClassScheduleDateListModalShow,
              show: false,
            })
          }
        />
      )}

      <div className='card mb-10'>
        <Loader
          loading={
            getAllLoading ||
            deleteLoading ||
            getLiveClassJoiningURL?.isLoading ||
            isLoading
          }
        />
        <Table
          data={data}
          columns={columns}
          initialState={tableInitialState}
          pageCount={getAllSuccess?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>
                  Live Class Data
                </div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
                <div>
                  <button
                    title='Add'
                    className='btn btn-primary'
                    onClick={() => {
                      setAddEditModel((prevState) => {
                        const data = {
                          ...prevState,
                          id: null,
                          show: true,
                        };
                        return data;
                      });
                    }}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Live Class
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllLoading}
                    entityLabel='live class'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>

      <DeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
      {addEditModel.show && (
        <LiveClassScheduleAddEditModal
          addEditModel={addEditModel}
          handleCloseAddEditModel={handleCloseAddEditModel}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAllLoading: state.LiveClassSchedule.getAllLoading,
    getAllSuccess: state.LiveClassSchedule.getAllSuccess,
    getAllError: state.LiveClassSchedule.getAllError,

    upsertLoading: state.LiveClassSchedule.upsertLoading,
    upsertSuccess: state.LiveClassSchedule.upsertSuccess,
    upsertError: state.LiveClassSchedule.upsertError,

    deleteLoading: state.LiveClassSchedule.deleteLoading,
    deleteSuccess: state.LiveClassSchedule.deleteSuccess,
    deleteError: state.LiveClassSchedule.deleteError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onGetAll: (param: any) => dispatch(getAllLiveClassSchedule(param)),
  onDelete: (param: any) => dispatch(deleteLiveClassSchedule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveClass);
