import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Form as BForm, Modal, Spinner } from 'react-bootstrap';
import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
import callBackendApi from '../../../utils/callBackendApi';
import { urlRoutes } from '../../../shared/apiConfig';
import useBackendApiCaller from '../../../hooks/useBackendApiCaller';
import TableInner from 'app/components/Table/TableInner';
import useTableState from '../../components/Table/useTableState';
import moment from 'moment';
import Loader from 'app/components/Loader';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import PaypalLogo from '../../../theme/assets/payment-gateway-logo/paypal.png';
import StripLogo from '../../../theme/assets/payment-gateway-logo/strip.png';

type Props = {
  transactionModel: object;
  handleClosetransactionModel: any;
};
const TransactionDetailModal: React.FC<Props> = (props: any) => {
  const { transactionModel, handleClosetransactionModel } = props;

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(transactionModel.show);
  }, [transactionModel.show]);

  const handleClose = () => {
    setShow(false);
    handleClosetransactionModel({
      show: false,
    });
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Date
          </span>
        ),
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {moment(result?.createdAt).format('MM-DD-YYYY HH:MM A')}
            </span>
          );
        },
      },
      {
        Header: 'Payment Method',
        accessor: 'PaymentMethod',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.PaymentMethod == 'paypal' ? (
            <img src={PaypalLogo} style={{ height: '20px' }} />
          ) : (
            <img src={StripLogo} style={{ height: '20px' }} />
          );
        },
      },

      {
        Header: 'Amount',
        accessor: 'AmountReceived',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return '$  ' + result?.AmountReceived;
        },
      },

      {
        Header: 'TransactionID',
        accessor: 'TransactionID',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.TransactionID;
        },
      },
    ],
    [transactionModel]
  );
  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const { sort, page, limit, search } = tableState;

  const fetcher = React.useCallback(() => {
    return callBackendApi('post', {
      url: urlRoutes.subscriptionPayment,
      data: {
        page,
        limit,
        search,
        sort,
        UserID: transactionModel.userId,
      },
    });
  }, [page, limit, sort, search, transactionModel.userId]);

  const [loading, data, fetch] = useBackendApiCaller(fetcher, [fetcher]);
  const list = data?.ResultObject?.result ?? [];
  return (
    <div>
      <Loader loading={loading} />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop={true}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Transaction History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            data={list}
            columns={columns}
            initialState={tableInitialState}
            pageCount={data?.ResultObject?.totalPages ?? -1}
            onTableStateChange={handleTableStateChange}
          >
            <React.Fragment>
              <div
                className='card-header border-0 w-25'
                style={{ paddingBottom: '50px' }}
              >
                <span className='position-absolute' style={{ right: '28px' }}>
                  <TableGlobalSearch />
                </span>
              </div>
              <div className='card-body py-3'>
                <div className='row'>
                  <div className='col-12'>
                    <TableInner
                      loading={loading}
                      entityLabel='TransectionDetail'
                    />
                  </div>
                  <div className='col-12 border-top pt-5'>
                    <TablePagination />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </Table>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default connect()(TransactionDetailModal);
