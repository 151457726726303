import * as Yup from 'yup';
import {
  Formik,
  FormikConfig,
  Field,
  Form,
  FieldArray,
  ErrorMessage,
} from 'formik';
import {
  Alert,
  Button,
  Form as BForm,
  Nav,
  Overlay,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useGetQuery, usePostQuery, usePutQuery } from 'hooks/reactQueryHelper';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import DatePicker, { DateObject } from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import ImageUpload from 'app/components/ImageUpload';

import PercentageRangeSelectorPopover from './PercentageRangeSelectorPopover';
import Swal from 'sweetalert2';
import { MdDelete } from 'react-icons/md';

export const GET_ALL_LANGUAGE = `/language/getAllLanguages`;
export const GET_ALL_LEVEL = `/level/getAll`;

export function CourseAddEditModal(props: any) {
  const { addEditModel, handleCloseAddEditModel } = props;

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(addEditModel.show);
  }, [addEditModel.show]);

  const addCourseQuery: any = usePostQuery('addCourse', '/course', {});
  const updateCourseQuery: any = usePutQuery('updateCourse', '/course');
  const getCourseQuery: any = useGetQuery('getCourse', '/course/getById');

  const getAllLanguageDataQuery: any = useGetQuery(
    'getAllLanguageDataQuery',
    GET_ALL_LANGUAGE
  );
  const getAllLevelDataQuery: any = useGetQuery(
    'getAllLevelDataQuery',
    GET_ALL_LEVEL
  );

  const validationSchema = Yup.object({
    id: Yup.string(),
    courseName: Yup.string().trim().required('Please enter class name.'),
    targetLanguage: Yup.object().nullable().required('Please select.'),
    languageLevel: Yup.object().nullable().required('Please select.'),
    grade: Yup.string().trim().required('Please enter grade.'),
    ageGroup: Yup.array().min(1, 'Please select at least one.'),
    selfPacedLessons: Yup.boolean().required('Please select.').nullable(),
    liveClasses: Yup.boolean().required('Please select.').nullable(),
    // pointsNeededToCompleteCourse: Yup.number()
    //   .nullable()
    //   .min(0, 'Min value 0.')
    //   .required('Please enter point needed.'),
    isDueDate: Yup.string().required('Please select.').nullable(),
    schoolName: Yup.string().trim().required('Please enter school name.'),
    schoolLogo: Yup.mixed()
      .nullable()
      .test('fileFormat', 'Please select valid image ', (value) => {
        if (value && value.type) {
          return (
            value &&
            ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
              value.type
            )
          );
        } else {
          return true;
        }
      }),

    badge: Yup.mixed()
      .nullable()
      .test('fileFormat', 'Please select valid image ', (value) => {
        if (value && value.type) {
          return (
            value &&
            ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
              value.type
            )
          );
        } else {
          return true;
        }
      }),
    badgeText: Yup.string().trim(),
    gradingRubric: Yup.object().shape({
      A: Yup.object().shape({
        min: Yup.number().min(0, 'Value must be between 0 and 100.'),
        max: Yup.number().min(1, 'Value must be between 0 and 100.'),
      }),
      B: Yup.object().shape({
        min: Yup.number().min(0, 'Value must be between 0 and 100.'),
        max: Yup.number().min(1, 'Value must be between 0 and 100.'),
      }),
      C: Yup.object().shape({
        min: Yup.number().min(0, 'Value must be between 0 and 100.'),
        max: Yup.number().min(1, 'Value must be between 0 and 100.'),
      }),
      D: Yup.object().shape({
        min: Yup.number().min(0, 'Value must be between 0 and 100.'),
        max: Yup.number().min(1, 'Value must be between 0 and 100.'),
      }),
      F: Yup.object().shape({
        min: Yup.number().min(0, 'Value must be between 0 and 100.'),
        max: Yup.number().min(1, 'Value must be between 0 and 100.'),
      }),
    }),
    gradingRubricOverLapRange: Yup.boolean(),
    dueDates: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.string().nullable().required('Please select.'),
          points: Yup.number()
            .nullable()
            // .min(0, 'Min value 0.')
            // .max(10, 'Max value 10.')
            .required('Please enter points.'),
        })
      )
      .test('unique', 'Date are already exists', (values, context) => {
        const dates = values?.map((d) => d.date) || [];
        const index = values?.findIndex(
          (d, i) => dates.includes(d.date) && dates.indexOf(d.date) !== i
        );

        if (index !== undefined && index >= 0) {
          throw context.createError({
            path: context.path + `[${index}].date`,
          });
        }
        return true;
      }),
    dueDateTotalPoint: Yup.mixed().test(
      'isEqual',
      'Total point must be equal to needed points.',
      (value, testContext) => {
        if (value === 0) return true;
        if (
          testContext.parent.isDueDate &&
          testContext.parent.pointsNeededToCompleteCourse == value
        )
          return true;
        return false;
      }
    ),
    // isDiplomaEnglishCourse: Yup.string().required('Please select.').nullable(),
  });

  let initialValues = {
    id: '',
    courseName: '',
    targetLanguage: '',
    languageLevel: '',
    grade: '',
    ageGroup: [],
    selfPacedLessons: '',
    liveClasses: '',
    pointsNeededToCompleteCourse: '',
    isDueDate: false,
    schoolName: '',
    schoolLogo: '',
    badge: '',
    badgeText: '',
    gradingRubric: {
      A: {
        name: 'A',
        min: 90,
        max: 100,
      },
      B: {
        name: 'B',
        min: 80,
        max: 89,
      },
      C: {
        name: 'C',
        min: 70,
        max: 79,
      },
      D: {
        name: 'D',
        min: 60,
        max: 69,
      },
      F: {
        name: 'F',
        min: 1,
        max: 59,
      },
    },
    gradingRubricOverLapRange: false,
    dueDates: [],
    dueDateTotalPoint: 0,
    freeLiveClass: 0,
    isDiplomaEnglishCourse: false,
  };

  const ageGroupOptions: any = [
    { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
    { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
    { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
  ];

  const booleanOptions: any = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  useEffect(() => {
    getAllLanguageDataQuery.mutate({});
    getAllLevelDataQuery.mutate({});
  }, []);

  const handleClose = () => {
    setShow(false);
    handleCloseAddEditModel({
      refresh: false,
    });
  };

  const onSubmit = (values: any) => {
    let gradingRubric: any = Object.values(values?.gradingRubric);

    let formData: any = new FormData();
    formData.append('_id', values?.id);
    formData.append('name', values?.courseName);
    formData.append('targetLanguage', values?.targetLanguage.value);
    formData.append('grade', values?.grade);
    formData.append('languageLevel', values?.languageLevel.value);
    formData.append(
      'ageOfGroup',
      JSON.stringify(
        values?.ageGroup.map(({ minAge, maxAge }: any) => ({ minAge, maxAge }))
      )
    );
    formData.append('selfPacedLessons', values?.selfPacedLessons);
    formData.append('liveClasses', values?.liveClasses);
    formData.append(
      'pointsNeededToCompleteCourse',
      values?.pointsNeededToCompleteCourse
        ? values?.pointsNeededToCompleteCourse
        : ''
    );
    formData.append('isDueDate', values?.isDueDate);
    formData.append('dueDates', JSON.stringify(values?.dueDates));
    formData.append('gradingRubric', JSON.stringify(gradingRubric));
    formData.append('schoolName', values?.schoolName);
    formData.append('schoolLogo', values.schoolLogo);
    formData.append('badge', values.badge);
    formData.append('badgeText', values.badgeText);
    formData.append('isAdmin', true);
    formData.append('freeLiveClass', values.freeLiveClass);
    formData.append('isDiplomaEnglishCourse', values?.isDiplomaEnglishCourse);

    if (values.id) {
      updateCourseQuery.mutateAsync(formData);
    } else {
      addCourseQuery.mutateAsync(formData);
    }
  };

  useEffect(() => {
    if (addCourseQuery?.data?.ResultCode === 'SUCCESS') {
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: addCourseQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result: any) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (addCourseQuery?.data?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: addCourseQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (addCourseQuery?.data?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: addCourseQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (updateCourseQuery?.data?.ResultCode === 'SUCCESS') {
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: updateCourseQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result: any) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (updateCourseQuery?.data?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: updateCourseQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (updateCourseQuery?.data?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: updateCourseQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  }, [addCourseQuery, updateCourseQuery]);

  useEffect(() => {
    if (addEditModel?.id) {
      getCourseQuery.mutate({ params: { id: addEditModel?.id } });
    }
  }, [addEditModel]);

  function validate(evt: any) {
    var theEvent = evt || window.event;

    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered backdrop={true} size='xl'>
      {/* <Loader /> */}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          getFieldProps,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }: any) => {
          console.log('values', values);

          useEffect(() => {
            setFieldValue(
              'dueDateTotalPoint',
              values?.dueDates?.reduce(
                (accumulator: any, currentValue: any) =>
                  accumulator +
                  parseInt(currentValue?.points ? currentValue?.points : 0),
                0
              )
            );
          }, [values?.dueDates]);

          useEffect(() => {
            if (getCourseQuery.isSuccess && !isSubmitting) {
              setFieldValue('id', getCourseQuery?.data?.ResultObject?._id);
              setFieldValue(
                'courseName',
                getCourseQuery?.data?.ResultObject?.name
              );

              setFieldValue('targetLanguage', {
                ...getCourseQuery?.data?.ResultObject?.targetLanguage,
                value: getCourseQuery?.data?.ResultObject?.targetLanguage._id,
                label:
                  getCourseQuery?.data?.ResultObject?.targetLanguage
                    .Language_Name,
              });
              setFieldValue('languageLevel', {
                ...getCourseQuery?.data?.ResultObject?.languageLevel,
                label:
                  getCourseQuery?.data?.ResultObject?.languageLevel?.Level_Name,
                value: getCourseQuery?.data?.ResultObject?.languageLevel?._id,
              });

              setFieldValue('grade', getCourseQuery?.data?.ResultObject?.grade);

              let ageGroupValue =
                getCourseQuery?.data?.ResultObject?.ageOfGroup?.map(
                  (item: any) => {
                    return {
                      ...item,
                      value:
                        item.minAge +
                        (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                      label:
                        item.minAge +
                        (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                      minAge: item.minAge,
                      maxAge: item.maxAge,
                    };
                  }
                );

              setFieldValue('ageGroup', ageGroupValue);

              setFieldValue(
                'selfPacedLessons',
                getCourseQuery?.data?.ResultObject?.selfPacedLessons
              );
              setFieldValue(
                'liveClasses',
                getCourseQuery?.data?.ResultObject?.liveClasses
              );
              setFieldValue(
                'pointsNeededToCompleteCourse',
                getCourseQuery?.data?.ResultObject?.pointsNeededToCompleteCourse
              );
              setFieldValue(
                'isDueDate',
                getCourseQuery?.data?.ResultObject?.isDueDate
              );
              setFieldValue(
                'gradingRubricOverLapRange',
                getCourseQuery?.data?.ResultObject?.gradingRubricOverLapRange
              );
              setFieldValue(
                'dueDates',
                getCourseQuery?.data?.ResultObject?.dueDates
              );

              if (
                getCourseQuery?.data?.ResultObject?.gradingRubric?.length > 0
              ) {
                setFieldValue(
                  'gradingRubric.A',
                  getCourseQuery?.data?.ResultObject?.gradingRubric.find(
                    ({ name }: any) => name === 'A'
                  )
                );
                setFieldValue(
                  'gradingRubric.B',
                  getCourseQuery?.data?.ResultObject?.gradingRubric.find(
                    ({ name }: any) => name === 'B'
                  )
                );
                setFieldValue(
                  'gradingRubric.C',
                  getCourseQuery?.data?.ResultObject?.gradingRubric.find(
                    ({ name }: any) => name === 'C'
                  )
                );
                setFieldValue(
                  'gradingRubric.D',
                  getCourseQuery?.data?.ResultObject?.gradingRubric.find(
                    ({ name }: any) => name === 'D'
                  )
                );
                setFieldValue(
                  'gradingRubric.F',
                  getCourseQuery?.data?.ResultObject?.gradingRubric.find(
                    ({ name }: any) => name === 'F'
                  )
                );
              }

              setFieldValue(
                'schoolName',
                getCourseQuery?.data?.ResultObject?.schoolName
              );
              setFieldValue(
                'schoolLogo',
                getCourseQuery?.data?.ResultObject?.schoolLogo
                  ? getCourseQuery?.data?.ResultObject?.schoolLogo
                  : ''
              );

              setFieldValue(
                'badge',
                getCourseQuery?.data?.ResultObject?.badge
                  ? getCourseQuery?.data?.ResultObject?.badge
                  : ''
              );

              setFieldValue(
                'badgeText',
                getCourseQuery?.data?.ResultObject?.badgeText
              );

              setFieldValue(
                'freeLiveClass',
                getCourseQuery?.data?.ResultObject?.freeLiveClass
              );

              setFieldValue(
                'isDiplomaEnglishCourse',
                getCourseQuery?.data?.ResultObject?.isDiplomaEnglishCourse
              );
            }
          }, [getCourseQuery]);
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <Modal.Header closeButton>
                <Modal.Title id='example-custom-modal-styling-title'>
                  <i className='fa fa-duotone fa-pen-to-square me-2 fs-2'></i>
                  {addEditModel.id === null ? 'Add ' : 'Edit '}Course Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row'>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Diploma English Course?
                    </BForm.Label>
                    <BForm.Check
                      name='isDiplomaEnglishCourse'
                      type='switch'
                      className='form-control-sm'
                      onChange={(event: any) => {
                        setFieldValue(
                          'isDiplomaEnglishCourse',
                          event.target.checked
                        );
                      }}
                      checked={values?.isDiplomaEnglishCourse}
                    />
                  </BForm.Group>
                </div>
                <div className='row'>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Course Name<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <BForm.Control
                      type='text'
                      placeholder='Enter Course Name'
                      {...getFieldProps('courseName')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`rounded-1 form-control-sm  mb-3 mb-lg-0 ${
                        errors.courseName && touched.courseName
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {touched.courseName && errors.courseName && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='courseName' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      School Name<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <BForm.Control
                      type='text'
                      placeholder='Enter School Name'
                      {...getFieldProps('schoolName')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`rounded-1 form-control-sm  mb-3 mb-lg-0 ${
                        errors.schoolName && touched.schoolName
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {touched.schoolName && errors.schoolName && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='schoolName' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Target language<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='targetLanguage'
                      isMulti={false}
                      component={Select}
                      options={getAllLanguageDataQuery?.data?.ResultObject.map(
                        (option: any, index: any) => {
                          return {
                            ...option,
                            value: option._id,
                            label: option.Language_Name,
                          };
                        }
                      )}
                      placeholder='Select'
                      isClearable={true}
                      isDisabled={getAllLanguageDataQuery?.isLoading}
                      isLoading={getAllLanguageDataQuery?.isLoading}
                      classNamePrefix='select'
                      value={values?.targetLanguage}
                      onChange={(option: any) => {
                        setFieldValue('targetLanguage', option);
                      }}
                      // onBlur={handleBlur}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.targetLanguage && touched.targetLanguage
                                ? '#f1416c'
                                : 'grey'
                              : errors.targetLanguage && touched.targetLanguage
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.targetLanguage && touched.targetLanguage
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.targetLanguage && touched.targetLanguage
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.targetLanguage && errors.targetLanguage && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='targetLanguage' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      School Grade<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <BForm.Control
                      type='text'
                      placeholder='ie: 5th Grade'
                      {...getFieldProps('grade')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`rounded-1 form-control-sm mb-3 mb-lg-0 ${
                        errors.grade && touched.grade
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {touched.grade && errors.grade && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='grade' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Language Level<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='languageLevel'
                      isMulti={false}
                      component={Select}
                      options={getAllLevelDataQuery?.data?.map(
                        (option: any, index: any) => {
                          return {
                            ...option,
                            value: option._id,
                            label: option.Level_Name,
                          };
                        }
                      )}
                      placeholder='Select'
                      isClearable={true}
                      isDisabled={getAllLevelDataQuery?.isLoading}
                      isLoading={getAllLevelDataQuery?.isLoading}
                      classNamePrefix='select'
                      value={values?.languageLevel}
                      onChange={(option: any) => {
                        setFieldValue('languageLevel', option);
                      }}
                      // onBlur={handleBlur}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.languageLevel && touched.languageLevel
                                ? '#f1416c'
                                : 'grey'
                              : errors.languageLevel && touched.languageLevel
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.languageLevel && touched.languageLevel
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.languageLevel && touched.languageLevel
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.languageLevel && errors.languageLevel && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='languageLevel' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Age Group<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='ageGroup'
                      isMulti={true}
                      component={Select}
                      options={ageGroupOptions.map(
                        (option: any, index: any) => {
                          return {
                            ...option,
                            value: option.value,
                            label: option.label,
                          };
                        }
                      )}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      value={values?.ageGroup}
                      onChange={(option: any) => {
                        setFieldValue('ageGroup', option);
                      }}
                      // onBlur={handleBlur}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.ageGroup && touched.ageGroup
                                ? '#f1416c'
                                : 'grey'
                              : errors.ageGroup && touched.ageGroup
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.ageGroup && touched.ageGroup
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.ageGroup && touched.ageGroup
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.ageGroup && errors.ageGroup && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='ageGroup' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Self Paced Lessons<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='selfPacedLessons'
                      isMulti={false}
                      component={Select}
                      options={booleanOptions}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      value={booleanOptions?.find(
                        (option: any) =>
                          option?.value === values?.selfPacedLessons
                      )}
                      onChange={(option: any) => {
                        setFieldValue('selfPacedLessons', option?.value);
                      }}
                      // onBlur={handleBlur}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.selfPacedLessons &&
                                touched.selfPacedLessons
                                ? '#f1416c'
                                : 'grey'
                              : errors.selfPacedLessons &&
                                touched.selfPacedLessons
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.selfPacedLessons &&
                                touched.selfPacedLessons
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.selfPacedLessons &&
                                touched.selfPacedLessons
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.selfPacedLessons && errors.selfPacedLessons && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='selfPacedLessons' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Live Classes<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='liveClasses'
                      isMulti={false}
                      component={Select}
                      options={booleanOptions}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      value={booleanOptions?.find(
                        (option: any) => option?.value === values?.liveClasses
                      )}
                      onChange={(option: any) => {
                        setFieldValue('liveClasses', option?.value);
                      }}
                      // onBlur={handleBlur}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.liveClasses && touched.liveClasses
                                ? '#f1416c'
                                : 'grey'
                              : errors.liveClasses && touched.liveClasses
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.liveClasses && touched.liveClasses
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.liveClasses && touched.liveClasses
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.liveClasses && errors.liveClasses && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='liveClasses' />
                      </BForm.Text>
                    )}
                  </BForm.Group>

                  {values?.isDiplomaEnglishCourse === false && (
                    <>
                      <BForm.Group className='mb-7 col-12 col-sm-6'>
                        <BForm.Label className='col-lg-6 fw-bold text-muted'>
                          Points Needed to Complete Course
                          <span style={{ color: 'red' }}>*</span>
                        </BForm.Label>
                        <BForm.Control
                          type='text'
                          // maxlength="10"
                          onKeyPress={validate}
                          placeholder='Enter Point Needed'
                          {...getFieldProps('pointsNeededToCompleteCourse')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`rounded-1 form-control-sm mb-3 mb-lg-0  form-control ${
                            errors.pointsNeededToCompleteCourse &&
                            touched.pointsNeededToCompleteCourse
                              ? 'border border-danger'
                              : ''
                          }`}
                        />
                        {/* {touched.pointsNeededToCompleteCourse &&
                          errors.pointsNeededToCompleteCourse && (
                            <BForm.Text className='text-danger'>
                              <ErrorMessage name='pointsNeededToCompleteCourse' />
                            </BForm.Text>
                          )} */}
                      </BForm.Group>
                      <BForm.Group className='mb-7 col-12 col-sm-6'>
                        <BForm.Label className='col-lg-6 fw-bold text-muted'>
                          Due Date?
                        </BForm.Label>
                        <BForm.Check
                          name='isDueDate'
                          type='switch'
                          className='form-control-sm'
                          onChange={(event: any) => {
                            setFieldValue('isDueDate', event.target.checked);
                            if (event.target.checked) {
                              setFieldValue('dueDates', [
                                {
                                  date: '',
                                  points: '',
                                },
                              ]);
                            } else {
                              setFieldValue('dueDates', []);
                            }
                          }}
                          checked={values?.isDueDate}
                        />
                      </BForm.Group>
                    </>
                  )}

                  {values.isDueDate === true && (
                    <fieldset className='border mb-3 p-5 w-75 m-auto'>
                      <legend className='float-none w-auto mb-0'>
                        <BForm.Label className='fw-bold text-muted mb-0'>
                          Points with Due Date
                        </BForm.Label>
                      </legend>
                      <FieldArray
                        name='dueDates'
                        render={(arrayHelpers: any) => (
                          <>
                            {values.dueDates && values.dueDates.length > 0 && (
                              <>
                                {values.dueDates.map(
                                  (item: any, index: any) => {
                                    const isDuplicateDate =
                                      values.dueDates.some(
                                        (dueDate: any, i: any) =>
                                          dueDate.date === item.date &&
                                          i !== index
                                      );
                                    return (
                                      <div
                                        className='d-flex mb-2 gap-5'
                                        key={index}
                                      >
                                        <div className='w-100'>
                                          <label
                                            htmlFor={`dueDates.${index}.points`}
                                          >
                                            Point
                                          </label>
                                          <Field
                                            type='text'
                                            maxlength='2'
                                            name={`dueDates.${index}.points`}
                                            onKeyPress={validate}
                                            value={item?.points}
                                            placeholder='Enter Point'
                                            // className={`rounded-1  mb-3 mb-lg-0 form-control`}
                                            className={`rounded-1 form-control-sm  mb-3 mb-lg-0  form-control ${
                                              errors?.dueDates &&
                                              errors?.dueDates[index]?.points &&
                                              touched?.dueDates &&
                                              touched?.dueDates[index]?.points
                                                ? 'border border-danger'
                                                : ''
                                            }`}
                                          />
                                          <div className='text-danger'>
                                            <ErrorMessage
                                              name={`dueDates[${index}].points`}
                                            />
                                          </div>
                                        </div>
                                        <div className='w-100'>
                                          <label
                                            htmlFor={`dueDates[${index}].date`}
                                          >
                                            Date
                                          </label>
                                          <Field
                                            id={`dueDates[${index}].date`}
                                            name={`dueDates[${index}].date`}
                                            component={DatePicker}
                                            value={item?.date}
                                            isMulti={false}
                                            minDate={new Date()}
                                            format='MM/DD/YYYY'
                                            placeholder='Select Date'
                                            onChange={(option: any) => {
                                              setFieldValue(
                                                `dueDates[${index}].date`,
                                                option
                                              );
                                            }}
                                            inputClass={`rounded-1 form-control-sm  mb-3 mb-lg-0  form-control ${
                                              errors?.dueDates &&
                                              errors?.dueDates[index]?.date &&
                                              touched?.dueDates &&
                                              touched?.dueDates[index]?.date
                                                ? 'border border-danger'
                                                : ''
                                            }`}
                                            containerStyle={{
                                              width: '100%',
                                            }}
                                          />
                                          <div className='text-danger'>
                                            <ErrorMessage
                                              name={`dueDates[${index}].date`}
                                            />
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column justify-content-end'>
                                          <button
                                            type='button'
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            } // remove a friend from the list
                                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 fs-4'
                                          >
                                            <MdDelete />
                                          </button>
                                          <div
                                            className={`${
                                              (errors?.dueDates &&
                                                errors?.dueDates[index]?.date &&
                                                touched?.dueDates &&
                                                touched?.dueDates[index]
                                                  ?.date) ||
                                              (errors?.dueDates &&
                                                errors?.dueDates[index]
                                                  ?.points &&
                                                touched?.dueDates &&
                                                touched?.dueDates[index]
                                                  ?.points)
                                                ? 'h-25'
                                                : ''
                                            }`}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                            <div>
                              <button
                                type='button'
                                onClick={() => {
                                  arrayHelpers.insert(values.dueDates.length, {
                                    date: '',
                                    points: '',
                                  });
                                }} // insert an empty string at a position
                                className='btn btn-primary btn-sm'
                              >
                                Add Due Date
                              </button>
                            </div>
                            {/* <div>
                                Sum: {values?.dueDates?.reduce((accumulator: any, currentValue: any) => accumulator + parseInt(currentValue?.points ? currentValue?.points : 0), 0)}
                              </div> */}
                            {errors.dueDateTotalPoint && (
                              <div className='mt-3'>
                                <BForm.Text className='text-danger'>
                                  <ErrorMessage name='dueDateTotalPoint' />
                                </BForm.Text>
                              </div>
                            )}
                          </>
                        )}
                      />
                    </fieldset>
                  )}

                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Upload School Logo
                    </BForm.Label>
                    <div className='w-125px'>
                      {' '}
                      <ImageUpload
                        entityLabel='schoolLogo'
                        name='schoolLogo'
                      />{' '}
                    </div>
                  </BForm.Group>

                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Badge
                    </BForm.Label>
                    <div className='w-125px'>
                      <ImageUpload entityLabel='badge' name='badge' />
                    </div>
                  </BForm.Group>

                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Badge text
                    </BForm.Label>
                    <BForm.Control
                      maxlength='300'
                      as='textarea'
                      rows={3}
                      placeholder='Enter badge text'
                      {...getFieldProps('badgeText')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`rounded-1 form-control-sm  mb-3 mb-lg-0 ${
                        errors.badgeText && touched.badgeText
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {values?.badgeText?.length > 0 && (
                      <div className='text-muted'>
                        <b>300</b> character limit,{' '}
                        <b>{300 - values?.badgeText?.length}</b> remaining.
                      </div>
                    )}

                    {touched.badgeText && errors.badgeText && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='badgeText' />
                      </BForm.Text>
                    )}
                  </BForm.Group>

                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Free Live Classes
                      {/* <span style={{ color: 'red' }}>*</span> */}
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      placeholder='How many free live classes ?'
                      {...getFieldProps('freeLiveClass')}
                      onChange={(e) => {
                        const inputValue = parseFloat(e.target.value);
                        if (!isNaN(inputValue) && inputValue >= 0) {
                          handleChange(e);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.freeLiveClass}
                      // className={`rounded-1 form-control-sm  mb-3 mb-lg-0 ${
                      //   errors.courseName && touched.courseName
                      //     ? 'border border-danger'
                      //     : ''
                      // }`}
                      className='rounded-1 form-control-sm  mb-3 mb-lg-0'
                    />
                    {/* {touched.courseName && errors.courseName && (
                      <BForm.Text className='text-danger'>
                        <ErrorMessage name='courseName' />
                      </BForm.Text>
                    )} */}
                  </BForm.Group>

                  <BForm.Group className='mb-7 col-12 col-sm-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Grading Rubric<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <div className='row gap-5'>
                      <div className='d-flex'>
                        <span className='col-1'>A</span>
                        <span className='col-11 d-flex gap-10'>
                          <PercentageRangeSelectorPopover
                            field='gradingRubric'
                            ChildField='A'
                            fieldName='gradingRubric.A'
                            minMaxValue={values?.gradingRubric?.A}
                          />
                          {((errors?.gradingRubric?.A?.max &&
                            touched.gradingRubric?.A?.max) ||
                            (errors?.gradingRubric?.A?.min &&
                              touched.gradingRubric?.A?.min)) && (
                            <div className='text-danger'>
                              {errors.gradingRubric?.A?.max ||
                                errors.gradingRubric?.A?.min}
                            </div>
                          )}
                        </span>
                      </div>
                      <div className='d-flex'>
                        <span className='col-1'>B</span>
                        <span className='col-11 d-flex gap-10'>
                          <PercentageRangeSelectorPopover
                            field='gradingRubric'
                            ChildField='B'
                            fieldName='gradingRubric.B'
                            minMaxValue={values?.gradingRubric?.B}
                          />
                          {((errors?.gradingRubric?.B?.max &&
                            touched.gradingRubric?.B?.max) ||
                            (errors?.gradingRubric?.B?.min &&
                              touched.gradingRubric?.B?.min)) && (
                            <div className='text-danger'>
                              {errors.gradingRubric?.B?.max ||
                                errors.gradingRubric?.B?.min}
                            </div>
                          )}
                        </span>
                      </div>
                      <div className='d-flex'>
                        <span className='col-1'>C</span>
                        <span className='col-11 d-flex gap-10'>
                          <PercentageRangeSelectorPopover
                            field='gradingRubric'
                            ChildField='C'
                            fieldName='gradingRubric.C'
                            minMaxValue={values?.gradingRubric?.C}
                          />
                          {((errors?.gradingRubric?.C?.max &&
                            touched.gradingRubric?.C?.max) ||
                            (errors?.gradingRubric?.C?.min &&
                              touched.gradingRubric?.C?.min)) && (
                            <div className='text-danger'>
                              {errors.gradingRubric?.C?.max ||
                                errors.gradingRubric?.C?.min}
                            </div>
                          )}
                        </span>
                      </div>
                      <div className='d-flex'>
                        <span className='col-1'>D</span>
                        <span className='col-11 d-flex gap-10'>
                          <PercentageRangeSelectorPopover
                            field='gradingRubric'
                            ChildField='D'
                            fieldName='gradingRubric.D'
                            minMaxValue={values?.gradingRubric?.D}
                          />
                          {((errors?.gradingRubric?.D?.max &&
                            touched.gradingRubric?.D?.max) ||
                            (errors?.gradingRubric?.D?.min &&
                              touched.gradingRubric?.D?.min)) && (
                            <div className='text-danger'>
                              {errors.gradingRubric?.D?.max ||
                                errors.gradingRubric?.D?.min}
                            </div>
                          )}
                        </span>
                      </div>

                      <div className='d-flex'>
                        <span className='col-1'>F</span>
                        <span className='col-11 d-flex gap-10'>
                          <PercentageRangeSelectorPopover
                            field='gradingRubric'
                            ChildField='F'
                            fieldName='gradingRubric.F'
                            minMaxValue={values?.gradingRubric?.F}
                          />
                          {((errors?.gradingRubric?.F?.max &&
                            touched.gradingRubric?.F?.max) ||
                            (errors?.gradingRubric?.F?.min &&
                              touched.gradingRubric?.F?.min)) && (
                            <div className='text-danger'>
                              {errors.gradingRubric?.F?.max ||
                                errors.gradingRubric?.F?.min}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                    {values?.gradingRubricOverLapRange == true && (
                      <div className='text-danger'>Range already selected</div>
                    )}
                  </BForm.Group>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant='primary' type='submit'>
                  {addCourseQuery?.isLoading || updateCourseQuery?.isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button type='reset' variant='secondary' onClick={handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
