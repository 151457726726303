import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useGetQuery } from 'hooks/reactQueryHelper';

const LineChart = ({ activeEnrolledStudent }: any) => {
  const getMonthlySignupData = useGetQuery(
    'getMontlySignupData',
    '/dashboard/getSignupOfEachMonthData'
  );
  const getMonthlyLoginData = useGetQuery(
    'getMonthlyLoginData',
    '/dashboard/getLoginEachOfMonthData'
  );
  const getMonthlyCompletedLessonData = useGetQuery(
    'getMonthlyCompletedLessonData',
    '/dashboard/getLessonOfEachMonthData'
  );

  const getMonthlyCompletedInstantClassData = useGetQuery(
    'getMonthlyCompletedInstantClassData',
    '/dashboard/getCompletedEachOfMonthInstantClass'
  );

  useEffect(() => {
    getMonthlySignupData.mutate({});
    getMonthlyLoginData.mutate({});
    getMonthlyCompletedLessonData.mutate({});
    getMonthlyCompletedInstantClassData.mutate({});
  }, []);

  const countList = getMonthlySignupData?.data?.ResultObject || {};
  const countArray = Object.values(countList);

  const countMonthlyLoginList = getMonthlyLoginData?.data?.ResultObject || {};
  const countMonthlyLoginArray = Object.values(countMonthlyLoginList);

  const countMonthlyLessonList =
    getMonthlyCompletedLessonData?.data?.ResultObject || {};
  const countMonthlyLessonArray = Object.values(countMonthlyLessonList);

  const MonthlyCompletedInstantClassList =
    getMonthlyCompletedInstantClassData?.data?.ResultObject || {};
  const countMonthlyCompletedInstantClassArray = Object.values(
    MonthlyCompletedInstantClassList
  );

  let months = countArray.map((e: any) => e.month);
  let joiningCount = countArray.map((e: any) => e.count);
  let loginCount = countMonthlyLoginArray.map((e: any) => e.count);
  let CompleteLessonCount = countMonthlyLessonArray.map((e: any) => e.count);
  let completedInstantClassData = countMonthlyCompletedInstantClassArray.map(
    (e: any) => e.count
  );

  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Joining Students',
        data: joiningCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Login Students',
        data: loginCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        borderColor: 'rgb(53, 162, 235)',
      },
      {
        label: 'Completed Lessons',
        data: CompleteLessonCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        borderColor: 'rgb(0,255,0)',
      },
      {
        label: 'Completed Instant Class',
        data: completedInstantClassData,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(202,54,255, 0.2)',
        borderColor: 'rgb(202,54,255)',
      },
      {
        label: 'Active Enrolled Student',
        data: activeEnrolledStudent?.map((e: any) => e.count),
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(13, 71, 161, 0.2)',
        borderColor: 'rgb(13, 71, 161)',
      },
    ],
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='align-items-center pb-0'>
            <Line data={chartData} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LineChart;
