import axios from 'axios';
import { setToken } from 'helpers/authToken';
import { BASE_URL } from '../../../../shared/apiConfig';

import { AuthModel } from './_models';

const API_URL = BASE_URL;
// export const LOGIN_URL = `${API_URL}/account/adminlogin`;
export const LOGIN_URL = `${API_URL}/sign_in`;
export const REGISTER_URL = `${API_URL}/user/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios
    .post<any>(
      LOGIN_URL,
      {
        email,
        password,
      },
      {
        params: {
          t: 'admin',
        },
      }
    )
    .then((axiosData) => {
      return axiosData;
    });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}
