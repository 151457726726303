/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../theme/layout/core';
import { useQuery } from 'react-query';
import { axiosApi } from 'helpers/api-helper';
import CountUp from 'react-countup';

import _ from 'lodash';
import moment from 'moment';
import LineChart from './LineChart';
import DayChart from './DayChart';
import CountInstantClass from './CountInstantClass';
import { toAbsoluteUrl } from 'theme/helpers';
import LoaderNew from 'app/components/LoaderNew';
import { useGetQuery } from 'hooks/reactQueryHelper';

const DashboardPage: FC = () => {
  const intl = useIntl();
  const utcDate = moment().utc();
  const dateObject = utcDate.toDate();
  const currentOffset = utcDate.utcOffset();

  const fetchDashboardDataQuery: any = useGetQuery(
    'fetchDashboardData',
    '/dashboard/getDashboardData'
  );

  useEffect(() => {
    fetchDashboardDataQuery.mutateAsync({});
  }, []);

  const totalJoininedData =
    fetchDashboardDataQuery?.data?.ResultObject?.getTotalCountData?.count
      .TotalJoinined;
  const totalLoggedInData =
    fetchDashboardDataQuery?.data?.ResultObject?.getTotalCountData?.count
      ?.TotalLoggedIn;
  const totalComppletedLessonData =
    fetchDashboardDataQuery?.data?.ResultObject?.getTotalCountData?.count
      ?.TotalCompletedLesson;
  const resultOfTodaySignup =
    fetchDashboardDataQuery?.data?.ResultObject?.signup.count_todays_signup;
  const resultOfYesterdaySignup =
    fetchDashboardDataQuery?.data?.ResultObject?.signup.count_yesterday_signup;
  const resultOfLast7DaysSignup =
    fetchDashboardDataQuery?.data?.ResultObject?.signup.count_last7days_signup;
  const resultOfTodayLoggedIn =
    fetchDashboardDataQuery?.data?.ResultObject?.login.count_todays_loggedIn;
  const resultOfYesterdayLoggedIn =
    fetchDashboardDataQuery?.data?.ResultObject?.login.count_yesterday_loggedIn;
  const resultOfLast7DaysLoggedIn =
    fetchDashboardDataQuery?.data?.ResultObject?.login.count_last7days_loggedIn;
  const resultOfTodayLesson =
    fetchDashboardDataQuery?.data?.ResultObject?.lesson.count_todays_lesson;
  const resultOfYesterdayLesson =
    fetchDashboardDataQuery?.data?.ResultObject?.lesson.count_yesterday_lesson;
  const resultOfLast7DaysLesson =
    fetchDashboardDataQuery?.data?.ResultObject?.lesson.count_last7days_lesson;

  return (
    <>
      <LoaderNew loading={fetchDashboardDataQuery?.isLoading} />
      <div className='row g-5'>
        <div className='col-md-3'>
          <div className='row g-5'>
            <span className='fw-bold' style={{ fontSize: '2rem' }}>
              Joining Overview
            </span>
            <div className='col-12'>
              <div
                className='card bg-light-primary border-1 border border-primary card-xl-stretch card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
                style={{
                  backgroundImage: 'url("../media/patterns/vector-1.png")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-primary'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='text-dark fw-bold mt-5'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={resultOfTodaySignup ? resultOfTodaySignup : 0}
                    />
                  </div>
                  <div className='card-title fw-bold text-primary fs-5 d-block'>
                    Signup on Today
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-light-dark border-1 border border-dark card-xl-stretch card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
                style={{
                  backgroundImage: 'url("../media/patterns/vector-2.png")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        resultOfYesterdaySignup ? resultOfYesterdaySignup : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold fs-5 d-block text-muted'>
                    Signup on Yesterday
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-light-success border-1 border border-success card-xl-stretch card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
                style={{
                  backgroundImage: 'url("../media/patterns/vector-3.png")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-success'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='text-dark fw-bold mt-5'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        resultOfLast7DaysSignup ? resultOfLast7DaysSignup : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold text-success fs-5 d-block'>
                    Signup on Last 7 Days
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-secondary border-1 border card-xl-stretch bgi-no-repeat'
                style={{
                  backgroundPosition: 'right top',
                  backgroundSize: '30%',
                  backgroundImage: 'url("../media/svg/shapes/abstract-1.svg")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={totalJoininedData ? totalJoininedData : 0}
                    />
                  </div>
                  <div className='card-title fw-bold fs-5 d-block'>
                    Total Signup
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='row g-5'>
            <span className='fw-bold' style={{ fontSize: '2rem' }}>
              Login Overview
            </span>
            <div className='col-12'>
              <div className='card bg-light-danger border-1 border border-danger card-xl-stretch'>
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-danger'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='text-dark fw-bold mt-5'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={resultOfTodayLoggedIn ? resultOfTodayLoggedIn : 0}
                    />
                  </div>
                  <div className='card-title fw-bold text-danger fs-5 d-block'>
                    Logged on Today
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='card bg-light-white border-1 border border-dark card-xl-stretch'>
                <div className='card-body'>
                  <i
                    className='fas fa-user-large'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        resultOfYesterdayLoggedIn
                          ? resultOfYesterdayLoggedIn
                          : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold fs-5 d-block text-muted'>
                    Logged on Yesterday
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='card bg-light-info border-1 border border-info hoverable card-xl-stretch'>
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-info'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='text-dark fw-bold mt-5'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        resultOfLast7DaysLoggedIn
                          ? resultOfLast7DaysLoggedIn
                          : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold text-info fs-5 d-block'>
                    Logged on Last 7 Days
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-secondary border-1 border card-xl-stretch bgi-no-repeat'
                style={{
                  backgroundPosition: 'right top',
                  backgroundSize: '30%',
                  backgroundImage: 'url("../media/svg/shapes/abstract-4.svg")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={totalLoggedInData ? totalLoggedInData : 0}
                    />
                  </div>
                  <div className='card-title fw-bold fs-5 d-block'>
                    Total Logged on
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='row g-5'>
            <span className='fw-bold' style={{ fontSize: '2rem' }}>
              Completed Lesson Overview
            </span>
            <div className='col-12'>
              <div
                className='card bg-primary border-1 border card-xl-stretch bgi-no-repeat'
                style={{
                  backgroundPosition: 'right top',
                  backgroundSize: '30%',
                  backgroundImage: 'url("../media/svg/shapes/abstract-4.svg")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-white'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='text-white fw-bold mt-5'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={resultOfTodayLesson ? resultOfTodayLesson : 0}
                    />
                  </div>
                  <div className='card-title fw-bold text-white fs-5 d-block'>
                    Completed Lesson on Today
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-dark card-xl-stretch bgi-no-repeat'
                style={{
                  backgroundPosition: 'right top',
                  backgroundSize: '30%',
                  backgroundImage: 'url("../media/svg/shapes/abstract-2.svg")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-white'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='fw-bold mt-5 text-white'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        resultOfYesterdayLesson ? resultOfYesterdayLesson : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold fs-5 d-block text-white'>
                    Completed Lesson on Yesterday
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-danger border-1 border card-xl-stretch bgi-no-repeat'
                style={{
                  backgroundPosition: 'right top',
                  backgroundSize: '30%',
                  backgroundImage: 'url("../media/svg/shapes/abstract-1.svg")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large text-white'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div
                    className='fw-bold mt-5 text-white'
                    style={{ fontSize: '3rem' }}
                  >
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        resultOfLast7DaysLesson ? resultOfLast7DaysLesson : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold text-white fs-5 d-block'>
                    Completed Lesson on Last 7 Days
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div
                className='card bg-secondary border-1 border card-xl-stretch bgi-no-repeat'
                style={{
                  backgroundPosition: 'right top',
                  backgroundSize: '30%',
                  backgroundImage: 'url("../media/svg/shapes/abstract-2.svg")',
                }}
              >
                <div className='card-body'>
                  <i
                    className='fas fa-user-large'
                    style={{ fontSize: '3rem' }}
                  ></i>
                  <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        totalComppletedLessonData
                          ? totalComppletedLessonData
                          : 0
                      }
                    />
                  </div>
                  <div className='card-title fw-bold fs-5 d-block'>
                    Total Completed Lesson
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CountInstantClass />

        <div className='col-xl-4'>
          {/*begin::List Widget 6*/}
          <div className='card h-md-100' dir='ltr'>
            {/*begin::Header*/}
            <div className='card-header border-0'>
              <h3 className='card-title fw-bold text-gray-900'>
                Active Enrolled Student
              </h3>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className='card-body pt-0'>
              <div className='d-flex flex-column gap-5 justify-content-around h-100'>
                {/*begin::Item*/}
                <div className='d-flex align-items-center bg-light-warning rounded p-10'>
                  {/*begin::Title*/}
                  <div className='flex-grow-1'>
                    <span className='fw-bold text-gray-800 fs-1'>Today</span>
                  </div>
                  {/*end::Title*/}
                  {/*begin::Lable*/}
                  <span className='fw-bold text-warning py-1 fs-2'>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        fetchDashboardDataQuery?.data?.ResultObject
                          ?.activeEnrollment?.todayData[0]?.count
                          ? fetchDashboardDataQuery?.data?.ResultObject
                              ?.activeEnrollment?.todayData[0]?.count
                          : 0
                      }
                    />
                  </span>
                  {/*end::Lable*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className='d-flex align-items-center bg-light-success rounded p-10'>
                  {/*begin::Title*/}
                  <div className='flex-grow-1'>
                    <span className='fw-bold text-gray-800 fs-1'>
                      Yesterday
                    </span>
                  </div>
                  {/*end::Title*/}
                  {/*begin::Lable*/}
                  <span className='fw-bold text-success py-1 fs-2'>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        fetchDashboardDataQuery?.data?.ResultObject
                          ?.activeEnrollment?.yesterdayData[0]?.count
                          ? fetchDashboardDataQuery?.data?.ResultObject
                              ?.activeEnrollment?.yesterdayData[0]?.count
                          : 0
                      }
                    />
                  </span>
                  {/*end::Lable*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className='d-flex align-items-center bg-light-danger rounded p-10'>
                  {/*begin::Title*/}
                  <div className='flex-grow-1'>
                    <span className='fw-bold text-gray-800 fs-1'>
                      Last week
                    </span>
                  </div>
                  {/*end::Title*/}
                  {/*begin::Lable*/}
                  <span className='fw-bold text-danger py-1 fs-2'>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        fetchDashboardDataQuery?.data?.ResultObject
                          ?.activeEnrollment?.lastWeekData[0]?.count
                          ? fetchDashboardDataQuery?.data?.ResultObject
                              ?.activeEnrollment?.lastWeekData[0]?.count
                          : 0
                      }
                    />
                  </span>
                  {/*end::Lable*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className='d-flex align-items-center bg-light-info rounded p-10 '>
                  {/*begin::Title*/}
                  <div className='flex-grow-1'>
                    <span className='fw-bold text-gray-800 fs-1'>Total</span>
                  </div>
                  {/*end::Title*/}
                  {/*begin::Lable*/}
                  <span className='fw-bold text-info py-1 fs-2'>
                    +
                    <CountUp
                      duration={1}
                      className='counter'
                      end={
                        fetchDashboardDataQuery?.data?.ResultObject
                          ?.activeEnrollment?.totalData[0]?.count
                          ? fetchDashboardDataQuery?.data?.ResultObject
                              ?.activeEnrollment?.totalData[0]?.count
                          : 0
                      }
                    />
                  </span>
                  {/*end::Lable*/}
                </div>
                {/*end::Item*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::List Widget 6*/}
        </div>

        <div className='col-xl-8'>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Student Overview
                </span>
              </h3>
              <div className='card-toolbar p-3  d-flex align-items-right'>
                <ul className='nav'>
                  <li className='nav-item'>
                    <a
                      className='nav-link fs-5 btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                      data-bs-toggle='tab'
                      href='#chart_tab_month'
                    >
                      Month
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link fs-5 btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                      data-bs-toggle='tab'
                      href='#chart_tab_days'
                    >
                      Day
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='tab-content'>
                {/* begin::Tap pane */}
                <div className='tab-pane fade show active' id='chart_tab_month'>
                  <LineChart
                    activeEnrolledStudent={
                      fetchDashboardDataQuery?.data?.ResultObject
                        ?.activeEnrollment?.lastTwelveMonthWiseData
                    }
                  />
                </div>
                {/* end::Tap pane */}
                {/* begin::Tap pane */}
                <div className='tab-pane fade' id='chart_tab_days'>
                  <DayChart
                    activeEnrolledStudent={
                      fetchDashboardDataQuery?.data?.ResultObject
                        ?.activeEnrollment?.lastNinetyDayWiseData
                    }
                  />
                </div>
                {/* end::Tap pane */}
              </div>
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>
    </>
  );
};
const DashboardWrapper: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};
export { DashboardWrapper };
