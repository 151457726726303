import {
    LANGUAGE_GET_ALL,
    LANGUAGE_GET_ALL_SUCCESS,
    LANGUAGE_GET_ALL_FAIL,
    LANGUAGE_GET_BY_ID,
    LANGUAGE_GET_BY_ID_SUCCESS,
    LANGUAGE_GET_BY_ID_FAIL,
    LANGUAGE_ADD,
    LANGUAGE_ADD_SUCCESS,
    LANGUAGE_ADD_FAIL,
    LANGUAGE_UPDATE,
    LANGUAGE_UPDATE_SUCCESS,
    LANGUAGE_UPDATE_FAIL,
    LANGUAGE_DELETE,
    LANGUAGE_DELETE_SUCCESS,
    LANGUAGE_DELETE_FAIL,
    LANGUAGE_CLEAR
} from "./actionTypes";

const INIT_STATE = {
    getAllLoading: false,
    getAllSuccess: [],
    getAllError: {},

    getLoading: false,
    getSuccess: {},
    getError: {},

    deleteLoading: false,
    deleteSuccess: {},
    deleteError: {},

    upsertLoading: false,
    upsertSuccess: {},
    upsertError: {},
};

const Language = (state = INIT_STATE, action:any) => {
    switch (action.type) {
        case LANGUAGE_GET_ALL:
            return {
                ...state,
                getAllLoading: true,
                getAllSuccess: [],
                getAllError: {},

                getLoading: false,
                getSuccess: {},
                getError: {},

                deleteLoading: false,
                deleteSuccess: {},
                deleteError: {},

                upsertLoading: false,
                upsertSuccess: {},
                upsertError: {},
            };

        case LANGUAGE_GET_ALL_SUCCESS:
            return {
                ...state,
                getAllLoading: false,
                getAllSuccess: action.payload,
                getAllError: {},
            };

        case LANGUAGE_GET_ALL_FAIL:
            return {
                ...state,
                getAllLoading: false,
                getAllSuccess: [],
                getAllError: action.payload,
            };

        case LANGUAGE_GET_BY_ID:
            return {
                ...state,
                getLoading: true,
                getSuccess: {},
                getError: {},
            };

        case LANGUAGE_GET_BY_ID_SUCCESS:
            return {
                ...state,
                getLoading: false,
                getSuccess: action.payload,
                getError: {},
            };

        case LANGUAGE_GET_BY_ID_FAIL:
            return {
                ...state,
                getLoading: false,
                getSuccess: {},
                getError: action.payload,
            };

        case LANGUAGE_ADD:
            return {
                ...state,
                upsertLoading: true,
                upsertSuccess: {},
                upsertError: {},
            };

        case LANGUAGE_ADD_SUCCESS:
            return {
                ...state,
                upsertLoading: false,
                upsertSuccess: action.payload,
            };

        case LANGUAGE_ADD_FAIL:
            return {
                ...state,
                upsertLoading: false,
                upsertError: action.payload,
            };

        case LANGUAGE_UPDATE:
            return {
                ...state,
                upsertLoading: true,
                upsertSuccess: {},
                upsertError: {},
            };

        case LANGUAGE_UPDATE_SUCCESS:
            return {
                ...state,
                upsertLoading: false,
                upsertSuccess: action.payload,
            };

        case LANGUAGE_UPDATE_FAIL:
            return {
                ...state,
                upsertLoading: false,
                upsertError: action.payload,
            };

        case LANGUAGE_DELETE:
            return {
                ...state,
                deleteLoading: true,
                deleteSuccess: {},
                deleteError: {},
            };

        case LANGUAGE_DELETE_SUCCESS:
            return {
                ...state,
                deleteLoading: false,
                deleteSuccess: action.payload,
                deleteError: {},
            };

        case LANGUAGE_DELETE_FAIL:
            return {
                ...state,
                deleteLoading: false,
                deleteSuccess: {},
                deleteError: action.payload,
            };
        
        case LANGUAGE_CLEAR:
            return {
                ...state,
                getLoading: false,
                getSuccess: {},
                getError: {},

                deleteLoading: false,
                deleteSuccess: {},
                deleteError: {},

                upsertLoading: false,
                upsertSuccess: {},
                upsertError: {},
            };

        default:
            return state;
    }
};

export default Language;
