import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';

const EnrollmentReportModel = (props: any) => {
    const { setShow, show, fieldID, enrollmentID, UserID, registrationFormID, gradeSubmissionFormID } = props;

    const saveEnrollmentReportQuery: any = usePostQuery('saveEnrollmentReport', '/enrollment/report/save', {});

    const initialValues = {
        reason: '',
    };

    const formvalidationSchema = Yup.object().shape({
        reason: Yup.string().required('Please enter any reason'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: formvalidationSchema,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            const formData: any = {
                Reason: values.reason,
                FieldID: fieldID,
                EnrollmentID: enrollmentID,
                UserID: UserID
            }

            if (registrationFormID) {
                formData.RegistrationFormID = registrationFormID
            }

            if (gradeSubmissionFormID) {
                formData.GradeSubmissionFormID = gradeSubmissionFormID
            }

            if (fieldID) {
                saveEnrollmentReportQuery.mutateAsync(formData)
            } else {
                handleClose()
            }
        },
    });


    useEffect(() => {
        if (saveEnrollmentReportQuery.isSuccess) {
            handleClose()
        }
    }, [saveEnrollmentReportQuery.isSuccess])

    const handleClose = () => {
        formik.resetForm()
        setShow(false)
    }

    return (
        <div>
            <Loader loading={saveEnrollmentReportQuery.isLoading} />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                {...formik.getFieldProps('reason')}
                                className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.reason && formik.errors.reason,
                                    },
                                    {
                                        'is-valid': formik.touched.reason && !formik.errors.reason,
                                    }
                                )}
                            />
                            {formik.touched.reason && formik.errors.reason && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.reason}</span>
                                    </div>
                                </div>
                            )}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" type='submit'>Report</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default EnrollmentReportModel