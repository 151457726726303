import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';
import InstantLiveClassContent from './InstantLiveClassContent';
// import LiveClassSchedulePage from './LiveClass';

const InstantLiveClassContentWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Instant Class Content</PageTitle>
      <InstantLiveClassContent />
    </>
  );
};

export default InstantLiveClassContentWrapper;
