import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { Fragment, useEffect, useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { RxDotFilled } from 'react-icons/rx';
import Loader from 'app/components/Loader';
import moment from 'moment';

const ViewEnrollmentDetails = (props: any) => {
  const getByIdViewEnrollmentsQuery: any = usePostQuery(
    'getByIdViewEnrollments',
    '/enrollment/getbyenrollid',
    {}
  );

  const [priviewData, setPriviewdata] = useState<any>('');
  const { enrollmentID } = useParams();

  useEffect(() => {
    getByIdViewEnrollmentsQuery.mutateAsync({ ID: enrollmentID });
  }, [enrollmentID]);

  useEffect(() => {
    if (getByIdViewEnrollmentsQuery.isSuccess) {
      setPriviewdata(getByIdViewEnrollmentsQuery?.data?.ResultObject);
    }
  }, [getByIdViewEnrollmentsQuery.isSuccess]);

  return (
    <Fragment>
      <Loader loading={getByIdViewEnrollmentsQuery.isLoading} />
      {!getByIdViewEnrollmentsQuery.isLoading && (
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='card'>
                <div className='card-body'>
                  <div className='p-5'>
                    <h1 className='text-center'>Enrollment Form Details</h1>
                  </div>
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>Email</p>
                      <p className='fs-5'>{priviewData?.Email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student First Name
                      </p>
                      <p className='fs-5'>{priviewData?.FirstName}</p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student Last Name{' '}
                      </p>
                      <p className='fs-5'>{priviewData?.LastName}</p>
                    </div>
                  </div>
                  <hr />
                  {priviewData?.EnrollmetType == 'Enrollment' && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Has your student already graduated from high school?
                          </p>
                          <ul className='list-unstyled'>
                            <li className='fs-5'>
                              {priviewData?.Is_High_School_Graduated?.value ==
                              'Yes' ? (
                                <BsCheck2 size={22} />
                              ) : (
                                <RxDotFilled size={22} />
                              )}{' '}
                              Yes
                            </li>
                            <li className='fs-5'>
                              {priviewData?.Is_High_School_Graduated?.value ==
                              'No' ? (
                                <BsCheck2 size={22} />
                              ) : (
                                <RxDotFilled size={22} />
                              )}{' '}
                              No
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student Date of Birth
                      </p>
                      <p className='fs-5'>
                        {priviewData?.DateOfBirth?.value &&
                          moment(priviewData?.DateOfBirth?.value)
                            .utc()
                            .format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        What country does student live in?{' '}
                      </p>
                      <p className='fs-5'>{priviewData?.Country_Name}</p>
                    </div>
                  </div>
                  <hr />
                  {priviewData?.EnrollmetType == 'Enrollment' && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            What is your native language?{' '}
                          </p>
                          <p className='fs-5'>
                            {priviewData?.NativeLanguage?.value}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            What is the student's English language level?{' '}
                          </p>
                          <p className='fs-5'>{priviewData?.Level?.value}</p>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  {priviewData?.EnrollmetType == 'ReEnrollment' && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Your student must make a 60% (D) or higher in
                            classes to be enrolled in Hope City. If your student
                            does not achieve a 60% or higher, they will be
                            dropped and not receive credit for their school year
                            with Hope City.
                          </p>
                          <ul className='list-unstyled'>
                            <li className='fs-5'>
                              {priviewData?.Student_60Percentage_Higher_Classes
                                ?.value ? (
                                <BsCheck2 size={22} />
                              ) : (
                                <RxDotFilled size={22} />
                              )}{' '}
                              YES, I UNDERSTAND
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                      {priviewData?.FormType == 1 && (
                        <>
                          <div className='d-flex'>
                            <div className='flex-grow-1 p-t-12'>
                              <p className='form-label fs-5 fw-bold'>
                                22 Credits to Graduate High School
                              </p>
                              <ul className='list-unstyled'>
                                <li className='fs-5'>
                                  {priviewData?.Credits_Graduate_HighSchool
                                    ?.value == 'Yes' ? (
                                    <BsCheck2 size={22} />
                                  ) : (
                                    <RxDotFilled size={22} />
                                  )}{' '}
                                  Yes, I understand my student will need 22
                                  credits in high school to graduate
                                </li>
                                <li className='fs-5'>
                                  {priviewData?.Credits_Graduate_HighSchool
                                    ?.value == 'No' ? (
                                    <BsCheck2 size={22} />
                                  ) : (
                                    <RxDotFilled size={22} />
                                  )}{' '}
                                  No, I need more explanation
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    </>
                  )}
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        School Year Type
                      </p>
                      <p className='fs-5'>
                        {priviewData?.School_Year_Type?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student's Grade Enrolling for 2023
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Student_Grade != 0
                          ? priviewData?.Student_Grade
                          : 'Kindergarten'}
                      </p>
                    </div>
                  </div>
                  <hr />
                  {priviewData?.EnrollmetType == 'Enrollment' && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Hope City will transfer your grades from your local
                            school to our USA school. Is your student enrolled
                            in{' '}
                            {priviewData?.Student_Grade != 0 &&
                              `${priviewData?.Student_Grade} Grade`}{' '}
                            {priviewData?.Student_Grade == 0 && 'Kindergarten'}{' '}
                            in a local school?
                          </p>
                          {priviewData?.Student_Enrolled_Grade_Local_School
                            ?.value == 'true'
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  {priviewData?.EnrollmetType == 'Enrollment' ||
                    (priviewData?.EnrollmetType == 'ReEnrollment' &&
                      priviewData?.FormType == 2 && (
                        <>
                          <div className='d-flex'>
                            <div className='flex-grow-1 p-t-12'>
                              <p className='form-label fs-5 fw-bold'>
                                My student is enrolled in{' '}
                                {priviewData?.Student_Grade != 0
                                  ? priviewData?.Student_Grade
                                  : 'Kindergarten'}{' '}
                                Grade in a local school.{' '}
                              </p>
                              <ul className='list-unstyled'>
                                <li className='fs-5'>
                                  {priviewData
                                    ?.Student_Enrolled_Grade_Local_School
                                    ?.value == 'true' ? (
                                    <BsCheck2 size={22} />
                                  ) : (
                                    <RxDotFilled size={22} />
                                  )}{' '}
                                  Yes
                                </li>
                                <li className='fs-5'>
                                  {priviewData
                                    ?.Student_Enrolled_Grade_Local_School
                                    ?.value == 'false' ? (
                                    <BsCheck2 size={22} />
                                  ) : (
                                    <RxDotFilled size={22} />
                                  )}{' '}
                                  No
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}
                  {priviewData?.EnrollmetType == 'ReEnrollment' && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Will these requirements be met in your local school?{' '}
                          </p>
                          <ul className='list-unstyled'>
                            <li className='fs-5'>
                              {priviewData?.School_Requirements?.value ==
                              "YES, the student's local school will meet these requirements." ? (
                                <BsCheck2 size={22} />
                              ) : (
                                <RxDotFilled size={22} />
                              )}{' '}
                              YES, the student's local school will meet these
                              requirements.
                            </li>
                            <li className='fs-5'>
                              {priviewData?.School_Requirements?.value ==
                              'I have questions and need to speak with a Hope City staff member.' ? (
                                <BsCheck2 size={22} />
                              ) : (
                                <RxDotFilled size={22} />
                              )}{' '}
                              I have questions and need to speak with a Hope
                              City staff member.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Guardian First Name
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Guardian_Firstname?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Guardian Last Name
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Guardian_Lastname?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Guardian Home Address{' '}
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Guardian_Address?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Guardian WhatsApp Number with country phone entrance
                      </p>
                      <p className='fs-5'>
                        +{priviewData?.Guardian_Number?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Are you the parent/legal guardian of student? (All
                        responsibility of schooling and care of student is your
                        responsibility){' '}
                      </p>
                      {/* <ul className='list-unstyled'>
                        <li className='fs-5'>
                          {priviewData?.Legal_Guardian_Of_Student?.value ==
                          'true' ? (
                            <BsCheck2 size={22} />
                          ) : (
                            <RxDotFilled size={22} />
                          )}{' '}
                          Yes
                        </li>
                        <li className='fs-5'>
                          {priviewData?.Legal_Guardian_Of_Student?.value ==
                          'false' ? (
                            <BsCheck2 size={22} />
                          ) : (
                            <RxDotFilled size={22} />
                          )}{' '}
                          No
                        </li>
                      </ul> */}
                      {priviewData?.Legal_Guardian_Of_Student?.value == 'true'
                        ? 'Yes'
                        : 'No'}
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        What is the Guardian's Relationship to Student
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Guardian_Relationship?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  {priviewData?.EnrollmetType == 'Enrollment' ? (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Why would you like your student to enroll in Hope
                            City School?
                          </p>
                          <p className='fs-5'>
                            {priviewData?.Why_Student_Enroll_HopeCity?.value}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            How did you hear about Hope City school?
                          </p>
                          <p className='fs-5'>
                            {priviewData?.Hear_About_HopeCity?.value}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Are there any other individuals that would have
                            legal rights to the students records? (Another
                            parent/legal guardian) If so, please list their full
                            name, relationship and contact information.{' '}
                          </p>
                          <p className='fs-5'>
                            {
                              priviewData
                                ?.Individuals_Legal_Rights_Of_Students_Records
                                ?.value
                            }
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Terms and Conditions for Enrollment
                          </p>
                          <p className='fs-5'>
                            {priviewData?.Terms_And_Conditions?.value
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        I certify all information is true
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Certify_Information?.value ? 'Yes' : 'No'}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        By typing my name below, I acknowledge that I am the
                        legal guardian of the student and confirm this
                        information is true and accurate, please write your name
                        below.{' '}
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Acknowledge_Name?.value}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ViewEnrollmentDetails;
