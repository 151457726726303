import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/saga';
import teacherSaga from './teacher/saga';
import StudentSaga from './student/saga';
import LessonSaga from './lesson/saga';
import LanguageSaga from './language/saga';
import LiveClassSaga from './liveClass/saga';
import LiveClassScheduleSaga from './liveClassSchedule/saga';
import AIActivityImageSaga from './aiactivityimage/saga';



export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(teacherSaga),
    fork(StudentSaga),
    fork(LessonSaga),
    fork(LanguageSaga),
    fork(LiveClassSaga),
    fork(LiveClassScheduleSaga),
    fork(AIActivityImageSaga),
  ]);
}
