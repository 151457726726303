import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { connect, ConnectedProps } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { imageUpload } from 'store/actions';
import { getHighlightedSection, scrollHandle } from '../utils';
import Tooltip from '@mui/material/Tooltip';
import { IoClose } from 'react-icons/io5';
import { Badge } from 'react-bootstrap';

const AIPickWordActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    loading,
    getValues,
    AIActivityDetail,
    nativeLang,
    targetLang,
    imageUploadReq,
    imageloading,
    imageURL,
  } = props;

  const urlHashRef = React.useRef<any>({});

  const [openSentence1, setSentence1] = useState(false);
  const [openSentence2, setSentence2] = useState(false);

  const [option1, setOption1] = useState<any>([]);
  const [option2, setOption2] = useState<any>([]);

  const [indexOption1, setIndexOption1] = useState<any>([]);
  const [indexOption2, setIndexOption2] = useState<any>([]);

  const [pickwordValue, setPickWordValue] = useState<any>([]);
  const [blankError1, setBlankError1] = useState(false);
  const [blankError2, setBlankError2] = useState(false);

  const [activeKey, setActiveKey] = useState('');
  const [activeIndex, setActiveIndex] = useState('');

  const [imageSizeValidationOption1, setImageSizeValidationOption1] =
    useState<any>([]);
  const [imageSizeValidationOption2, setImageSizeValidationOption2] =
    useState<any>([]);

  const [imageCheck, setImageCheck] = useState<any>([]);

  useEffect(() => {
    if (AIActivityDetail != '' && AIActivityDetail != undefined) {
      let Question1_Value =
        AIActivityDetail?.Pick_Word?.Questions[0]?.Question?.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_Answer_Value =
        AIActivityDetail?.Pick_Word?.Questions[0]?.Answer?.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question1_ID = AIActivityDetail?.Pick_Word?.Questions[0]?.Question_ID;
      urlHashRef.current.one = Question1_ID;
      setValue('AI_Pick_Word_Question1_QuestionID', Question1_ID);
      setValue(
        'AI_Pick_Word_Question1_Question_Sentence1',
        Question1_Value?.Question_Sentence1
      );
      setValue(
        'AI_Pick_Word_Question1_Question_Sentence2',
        Question1_Value?.Question_Sentence2
      );

      // setPickWordValue(Question1_Value?.Question_Sentence?.split(" "))
      // setValue("AI_Pick_Word_Question1_Roman_Option1", Question1_Answer_Value?.Option1_Roman_Language)
      // setValue("AI_Pick_Word_Question1_Roman_Option2", Question1_Answer_Value?.Option2_Roman_Language)
      setValue(
        'AI_Pick_Word_Question1_Roman_Question_Sentence1',
        Question1_Value?.Question_Sentence_Roman_Language1
      );
      setValue(
        'AI_Pick_Word_Question1_Roman_Question_Sentence2',
        Question1_Value?.Question_Sentence_Roman_Language2
      );

      Question1_Answer_Value?.Option1.map((option: any, index: any) => {
        setImageCheck([...imageCheck, `Option1_Images[${index}]`]);
        setValue(`Option1_Options[${index}]`, option?.OptionName);
        setValue(`Option1_Fake_Options[${index}]`, option?.FakeOptionName);
        setValue(`Option1_Images[${index}]`, option?.OptionImage);
        setValue(
          `Option1_Audio_FakeOptionName[${index}]`,
          option?.Audio_FakeOptionName
        );
        setValue(
          `Option1_Audio_OptionName[${index}]`,
          option?.Audio_OptionName
        );
        setValue(
          `Option1_FakeOptionName_Roman_Language[${index}]`,
          option?.FakeOptionName_Roman_Language
        );
        setValue(
          `Option1_OptionName_Roman_Language[${index}]`,
          option?.OptionName_Roman_Language
        );
      });

      Question1_Answer_Value?.Option2.map((option: any, index: any) => {
        setImageCheck([...imageCheck, `Option2_Images[${index}]`]);
        setValue(`Option2_Options[${index}]`, option?.OptionName);
        setValue(`Option2_Fake_Options[${index}]`, option?.FakeOptionName);
        setValue(`Option2_Images[${index}]`, option?.OptionImage);
        setValue(
          `Option2_Audio_FakeOptionName[${index}]`,
          option?.Audio_FakeOptionName
        );
        setValue(
          `Option2_Audio_OptionName[${index}]`,
          option?.Audio_OptionName
        );
        setValue(
          `Option2_FakeOptionName_Roman_Language[${index}]`,
          option?.FakeOptionName_Roman_Language
        );
        setValue(
          `Option2_OptionName_Roman_Language[${index}]`,
          option?.OptionName_Roman_Language
        );
      });
    }
  }, [AIActivityDetail]);

  useEffect(() => {
    setValue('AI_Pick_Word_Question1_Option1', option1);
    setValue('AI_Pick_Word_Question1_Index_Option1', indexOption1);
  }, [option1]);

  useEffect(() => {
    setValue('AI_Pick_Word_Question1_Option2', option2);
    setValue('AI_Pick_Word_Question1_Index_Option2', indexOption2);
  }, [option2]);

  const onHandleAddBlank1 = (blank: any) => {
    let count = 0;
    getValues('AI_Pick_Word_Question1_Question_Sentence1')
      .trim()
      .split(' ')
      .map((word: any, index: number) => {
        if (word.includes('_')) {
          count++;
        }
      });
    if (count < 1) {
      setValue(
        'AI_Pick_Word_Question1_Question_Sentence1',
        getValues('AI_Pick_Word_Question1_Question_Sentence1') + blank
      );
    } else {
      setBlankError1(true);
      setTimeout(() => {
        setBlankError1(false);
      }, 1000);
    }
  };

  const onHandleAddBlank2 = (blank: any) => {
    let count = 0;
    getValues('AI_Pick_Word_Question1_Question_Sentence2')
      .trim()
      .split(' ')
      .map((word: any, index: number) => {
        if (word.includes('_')) {
          count++;
        }
      });
    if (count < 1) {
      setValue(
        'AI_Pick_Word_Question1_Question_Sentence2',
        getValues('AI_Pick_Word_Question1_Question_Sentence2') + blank
      );
    } else {
      setBlankError2(true);
      setTimeout(() => {
        setBlankError2(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (imageURL != '') {
      if (activeKey === `Option1_Images[${activeIndex}]`) {
        setValue(`Option1_Images[${activeIndex}]`, imageURL);
        setActiveKey('');
        setActiveIndex('');
        if (
          imageSizeValidationOption1.includes(`Option1_Images[${activeIndex}]`)
        ) {
          setImageSizeValidationOption1(
            imageSizeValidationOption1.filter(
              (value: any) => value !== `Option1_Images[${activeIndex}]`
            )
          );
        }
      }
      if (activeKey === `Option2_Images[${activeIndex}]`) {
        setValue(`Option2_Images[${activeIndex}]`, imageURL);
        setActiveKey('');
        setActiveIndex('');
        if (
          imageSizeValidationOption1.includes(`Option2_Images[${activeIndex}]`)
        ) {
          setImageSizeValidationOption1(
            imageSizeValidationOption1.filter(
              (value: any) => value !== `Option2_Images[${activeIndex}]`
            )
          );
        }
      }
    }
  }, [imageURL]);

  const handleChnageImage = (event: any, index: any, option: any) => {
    if (event.target.files[0].size <= 2097152) {
      setActiveKey(event.target.name);
      setActiveIndex(index);
      imageUploadReq({ file: event.target.files[0] });
    } else {
      if (option != 'Option2') {
        setImageSizeValidationOption1([
          ...imageSizeValidationOption1,
          event.target.name,
        ]);
      } else {
        setImageSizeValidationOption2([
          ...imageSizeValidationOption2,
          event.target.name,
        ]);
      }
    }
  };

  return (
    <React.Fragment>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                Pick Word
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          // containerElementProps={getHighlightedSection(hash.one)}
        >
          <div className='border-top my-3'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='add-lesson-row-section'>
                  <div className='col-md-12 mt-3 form-group'>
                    <label className='fw-bold form-label'>
                      Sentence 1<span className='astrick'>*</span>
                    </label>
                    <Tooltip
                      title='Native Language'
                      placement='top'
                      PopperProps={{ disablePortal: true }}
                      onClose={() => setSentence1(false)}
                      open={openSentence1}
                      disableHoverListener
                      disableTouchListener
                    >
                      <input
                        type='text'
                        className={
                          errors.AI_Pick_Word_Question1_Question_Sentence1
                            ? 'form-control errors'
                            : 'form-control'
                        }
                        placeholder='Enter Sentence'
                        {...register(
                          'AI_Pick_Word_Question1_Question_Sentence1'
                        )}
                        onFocus={() => setSentence1(true)}
                        disabled={loading}
                      ></input>
                    </Tooltip>
                    {errors.AI_Pick_Word_Question1_Question_Sentence1 && (
                      <p style={{ color: 'red' }}>
                        {
                          errors.AI_Pick_Word_Question1_Question_Sentence1
                            .message
                        }
                      </p>
                    )}
                    <Tooltip
                      title='Only one blank add'
                      placement='left'
                      PopperProps={{ disablePortal: true }}
                      onClose={() => setBlankError1(false)}
                      open={blankError1}
                      disableHoverListener
                      disableTouchListener
                    >
                      <span
                        onClick={() => onHandleAddBlank1(' ________ ')}
                        className='btn btn-primary mt-3'
                      >
                        add blank
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className='add-lesson-row-section'>
                  <div className='col-md-12 mt-3 form-group'>
                    <label className='fw-bold form-label'>
                      Sentence 2<span className='astrick'>*</span>
                    </label>
                    <Tooltip
                      title='Native Language'
                      placement='top'
                      PopperProps={{ disablePortal: true }}
                      onClose={() => setSentence2(false)}
                      open={openSentence2}
                      disableHoverListener
                      disableTouchListener
                    >
                      <input
                        type='text'
                        className={
                          errors.AI_Pick_Word_Question1_Question_Sentence2
                            ? 'form-control errors'
                            : 'form-control'
                        }
                        placeholder='Enter Sentence'
                        {...register(
                          'AI_Pick_Word_Question1_Question_Sentence2'
                        )}
                        onFocus={() => setSentence2(true)}
                        disabled={loading}
                      ></input>
                    </Tooltip>
                    {errors.AI_Pick_Word_Question1_Question_Sentence2 && (
                      <p style={{ color: 'red' }}>
                        {
                          errors.AI_Pick_Word_Question1_Question_Sentence2
                            .message
                        }
                      </p>
                    )}
                    <Tooltip
                      title='Only one blank add'
                      placement='left'
                      PopperProps={{ disablePortal: true }}
                      onClose={() => setBlankError2(false)}
                      open={blankError2}
                      disableHoverListener
                      disableTouchListener
                    >
                      <span
                        onClick={() => onHandleAddBlank2(' ________ ')}
                        className='btn btn-primary mt-3'
                      >
                        add blank
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='collapsible-head mt-5'>
                  <h4 className='text-center'>Option-1</h4>
                </div>
                {Array.from({ length: 4 }).map((data: any, index: any) => {
                  return (
                    <>
                      <div className='add-description-rows'>
                        <div className='add-description-boxes'>
                          <div className='add-lesson-row-section'>
                            <div className='mt-3 col-md-12 form-group'>
                              <label className='fw-bold form-label'>
                                {`Upload Image Option ${index + 1}`}{' '}
                                <span className='astrick'>*</span>
                              </label>
                              <div className='question-image'>
                                <LoadingOverlay
                                  active={
                                    imageloading &&
                                    activeKey === `Option1_Images[${index}]`
                                  }
                                  spinner
                                  className='spinner-animation'
                                >
                                  <img
                                    src={
                                      getValues(`Option1_Images[${index}]`)
                                        ? getValues(`Option1_Images[${index}]`)
                                        : ''
                                    }
                                  />
                                </LoadingOverlay>
                              </div>
                              <div className='main-cercle-div'>
                                <input
                                  type='file'
                                  className='form-control'
                                  accept='.png, .jpg, .jpeg'
                                  name={`Option1_Images[${index}]`}
                                  ref={register(`Option1_Images[${index}]`)}
                                  onChange={(event) => {
                                    handleChnageImage(event, index, 'Option1');
                                  }}
                                />
                              </div>
                              {imageSizeValidationOption1.includes(
                                `Option1_Images[${index}]`
                              ) ? (
                                <p style={{ color: 'red' }}>
                                  Less then 2MB | Only Image File Allowed -
                                  .jpg.png
                                </p>
                              ) : getValues(`Option1_Images[${index}]`) ==
                                undefined ? (
                                errors.Option1_Images &&
                                errors.Option1_Images[index] && (
                                  <p style={{ color: 'red' }}>
                                    {errors.Option1_Images[index].message}
                                  </p>
                                )
                              ) : null}
                            </div>
                            <div className='col-md-12 mt-3 form-group'>
                              <label className='fw-bold form-label'>
                                {`Option ${index + 1}`}{' '}
                                <span className='astrick'>*</span>
                              </label>
                              <div className='input-group'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name={`Option1_Options[${index}]`}
                                  {...register(`Option1_Options[${index}]`)}
                                  // placeholder={`Options${index + 1}`}
                                />
                              </div>
                              {errors.Option1_Options &&
                                errors.Option1_Options[index] && (
                                  <p style={{ color: 'red' }}>
                                    {errors.Option1_Options[index].message}
                                  </p>
                                )}
                            </div>
                            <div className='col-md-12 mt-3 form-group'>
                              <label className='fw-bold form-label'>
                                {`Fake Option ${index + 1}`}{' '}
                                <span className='astrick'>*</span>
                              </label>
                              <div className='input-group'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name={`Option1_Fake_Options[${index}]`}
                                  {...register(
                                    `Option1_Fake_Options[${index}]`
                                  )}
                                />
                              </div>
                              {errors.Option1_Fake_Options &&
                                errors.Option1_Fake_Options[index] && (
                                  <p style={{ color: 'red' }}>
                                    {errors.Option1_Fake_Options[index].message}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className='col-md-6'>
                <div className='collapsible-head mt-5'>
                  <h4 className='text-center'>Option-2</h4>
                </div>
                {Array.from({ length: 4 }).map((data: any, index: any) => {
                  return (
                    <>
                      <div className='add-description-rows'>
                        <div className='add-description-boxes'>
                          <div className='add-lesson-row-section'>
                            <div className='mt-3 col-md-12 form-group'>
                              <label className='fw-bold form-label'>
                                {`Upload Image Option ${index + 1}`}{' '}
                                <span className='astrick'>*</span>
                              </label>
                              <div className='question-image'>
                                <LoadingOverlay
                                  active={
                                    imageloading &&
                                    activeKey === `Option2_Images[${index}]`
                                  }
                                  spinner
                                  className='spinner-animation'
                                >
                                  <img
                                    src={
                                      getValues(`Option2_Images[${index}]`)
                                        ? getValues(`Option2_Images[${index}]`)
                                        : ''
                                    }
                                  />
                                </LoadingOverlay>
                              </div>
                              <div className='main-cercle-div'>
                                <input
                                  type='file'
                                  className='form-control'
                                  accept='.png, .jpg, .jpeg'
                                  name={`Option2_Images[${index}]`}
                                  ref={register(`Option2_Images[${index}]`)}
                                  onChange={(event) => {
                                    handleChnageImage(event, index, 'Option2');
                                  }}
                                />
                              </div>
                              {imageSizeValidationOption1.includes(
                                `Option2_Images[${index}]`
                              ) ? (
                                <p style={{ color: 'red' }}>
                                  Less then 2MB | Only Image File Allowed -
                                  .jpg.png
                                </p>
                              ) : getValues(`Option2_Images[${index}]`) ==
                                undefined ? (
                                errors.Option2_Images &&
                                errors.Option2_Images[index] && (
                                  <p style={{ color: 'red' }}>
                                    {errors.Option2_Images[index].message}
                                  </p>
                                )
                              ) : null}
                            </div>
                            <div className='col-md-12 mt-3 form-group'>
                              <label className='fw-bold form-label'>
                                {`Option ${index + 1}`}{' '}
                                <span className='astrick'>*</span>
                              </label>
                              <div className='input-group'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name={`Option2_Options[${index}]`}
                                  {...register(`Option2_Options[${index}]`)}
                                />
                              </div>
                              {errors.Option2_Options &&
                                errors.Option2_Options[index] && (
                                  <p style={{ color: 'red' }}>
                                    {errors.Option2_Options[index].message}
                                  </p>
                                )}
                            </div>
                            <div className='col-md-12 mt-3 form-group'>
                              <label className='fw-bold form-label'>
                                {`Fake Option ${index + 1}`}{' '}
                                <span className='astrick'>*</span>
                              </label>
                              <div className='input-group'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name={`Option2_Fake_Options[${index}]`}
                                  {...register(
                                    `Option2_Fake_Options[${index}]`
                                  )}
                                />
                              </div>
                              {errors.Option2_Fake_Options &&
                                errors.Option2_Fake_Options[index] && (
                                  <p style={{ color: 'red' }}>
                                    {errors.Option2_Fake_Options[index].message}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </Collapsible>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    imageURL: state.lesson.imageURL,
    imageloading: state.lesson.imageloading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  imageUploadReq: (param: any) => dispatch(imageUpload(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AIPickWordActivity);
