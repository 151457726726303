import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { Fragment, useEffect, useState } from 'react';
import { BsDownload, BsEyeFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Loader from 'app/components/Loader';
import PDFViewModel from '../../../components/PDFViewModel';
import ImageViewModel from 'app/components/ImageViewModel';

const ViewDiplomaRegistationDetails = (props: any) => {
  const getByIdViewDiplomaRegisterQuery: any = usePostQuery(
    'getByIdViewDiplomaRegister',
    '/enrollmentregister/registration/getbyid',
    {}
  );
  const [priviewData, setPriviewdata] = useState<any>('');
  const { diplomaregisterID } = useParams();

  useEffect(() => {
    getByIdViewDiplomaRegisterQuery.mutateAsync({ ID: diplomaregisterID });
  }, [diplomaregisterID]);

  useEffect(() => {
    if (getByIdViewDiplomaRegisterQuery.isSuccess) {
      setPriviewdata(getByIdViewDiplomaRegisterQuery?.data?.ResultObject);
    }
  }, [getByIdViewDiplomaRegisterQuery.isSuccess]);

  const [showDocument, setShowDocument] = useState<any>(false);
  const [documentUrl, setDocumentUrl] = useState<any>('');
  const [showPDF, setShowPDF] = useState<any>(false);

  const handleShowDocument = (url: any) => {
    setDocumentUrl(url);
    setShowDocument(true);
  };

  const handlePDFViewer = (url: any) => {
    setDocumentUrl(url);
    setShowPDF(true);
  };
  const handleCloseDocument = () => setShowDocument(false);

  return (
    <Fragment>
      <Loader loading={getByIdViewDiplomaRegisterQuery.isLoading} />
      {!getByIdViewDiplomaRegisterQuery.isLoading && (
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='card'>
                <div className='card-body'>
                  <div className='p-5'>
                    {priviewData?.FormType == 1 && (
                      <h1 className='text-center'>
                        Diploma Registation Form Details
                      </h1>
                    )}
                    {priviewData?.FormType == 2 && (
                      <h1 className='text-center'>
                        K6 Registation Form Details
                      </h1>
                    )}
                  </div>
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>Email</p>
                      <p className='fs-5'>{priviewData?.Email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student First Name
                      </p>
                      <p className='fs-5'>{priviewData?.Student_Firstname}</p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student Last Name{' '}
                      </p>
                      <p className='fs-5'>{priviewData?.Student_Lastname}</p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Hope City Student ID Number:
                      </p>
                      <p className='fs-5'>{priviewData?.StudentID}</p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Guardian First Name
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Guardian_Firstname?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Guardian Last Name
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Guardian_Lastname?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Your student must make a 60 or higher in classes to
                        receive credit at Hope City. If your student does not
                        achieve a 60 or higher, they could be suspended from
                        Hope City and be dropped and not receive credit for
                        their school year with Hope City. Do you understand and
                        agree to this?
                      </p>
                      <p className='fs-5'>
                        <p className='fs-5'>
                          {priviewData?.I_Agree?.value ? 'Yes' : 'No'}
                        </p>
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Are there any other individuals that would have legal
                        rights to the students records? (Another parent/legal
                        guardian) If so, please list their full name,
                        relationship and contact information.
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Legal_Rights_Of_Student?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Student Photo (The photo should show the student's full
                        face, have a solid background color without shadows,
                        avoid wear tank tops shirts and NOT include any other
                        people in the photo. Picture must be less than 10MB)
                      </p>
                      <div className='col-6'>
                        <ul className='list-group'>
                          {priviewData?.Student_Photo?.value
                            ?.split(',')
                            .map((url: any) => (
                              <li className='list-group-item d-flex justify-content-between align-items-center'>
                                Document
                                <div className='d-flex gap-2'>
                                  <button
                                    className='fs-2 border-0 bg-transparent'
                                    title='View'
                                    onClick={() => {
                                      ['pdf'].includes(
                                        url?.split('.').pop().toLowerCase()
                                      )
                                        ? handlePDFViewer(url)
                                        : handleShowDocument(url);
                                    }}
                                  >
                                    <BsEyeFill />
                                  </button>
                                  <a
                                    href={url}
                                    target='_blank'
                                    className='fs-2 border-0 bg-transparent text-decoration-none text-dark'
                                    title='Download'
                                    download
                                  >
                                    <BsDownload />
                                  </a>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        California requires that students complete a minimum of
                        175 school days. Will your student complete 175 or more
                        school days in the school year?{' '}
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Schooldays_In_The_School_Year?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        ENGLISH REQUIREMENTS: How will your student meet the
                        requirements for
                      </p>
                      <p className='fs-5'>
                        {priviewData?.English_Requirement_Subject?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        MATH REQUIREMENTS: How will your student meet
                        requirements?{' '}
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Math_Requirement_Subject?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        SOCIAL SCIENCE: How will your student meet requirements?
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Social_Science_Requirement_Subject?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        SCIENCE REQUIREMENTS: How will your student meet
                        requirements?
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Science_Requirement_Subject?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        VISUAL AND PERFORMING ARTS: How will your student meet
                        requirements?{' '}
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Visual_Arts_Requirement_Subject?.value}
                      </p>
                    </div>
                  </div>
                  <hr />
                  {priviewData?.FormType == 1 && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Foreign language or languages: How will your student
                            meet requirements?
                          </p>
                          <p className='fs-5'>
                            {
                              priviewData?.Foreign_Language_Requirement_Subject
                                ?.value
                            }
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  {priviewData?.FormType == 2 && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Health: How will your student meet requirements?{' '}
                          </p>
                          <p className='fs-5'>
                            {priviewData?.Health_Requirement_Subject?.value}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        PHYSICAL EDUCATION (PE): How will your student meet
                        requirements?
                      </p>
                      <p className='fs-5'>
                        {
                          priviewData?.Physical_Education_Requirement_Subject
                            ?.value
                        }
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        What Grade is your student?
                      </p>
                      <p className='fs-5'>{priviewData?.School_Grade?.value}</p>
                    </div>
                  </div>
                  <hr />

                  {priviewData?.School_Grade?.value == '6th' && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            6th Grade Course Material
                          </p>
                          <p className='fs-5'>
                            {priviewData?.I_Agree_I_Will_Complete_Material
                              ?.value
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}

                  {(priviewData?.School_Grade?.value == '10th' ||
                    priviewData?.School_Grade?.value == '11th' ||
                    priviewData?.School_Grade?.value == '12th') && (
                    <>
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-t-12'>
                          <p className='form-label fs-5 fw-bold'>
                            Is your student a senior and finishing high school
                            this year?
                          </p>
                          <p className='fs-5'>
                            {
                              priviewData?.Finish_High_School_Year_Question
                                ?.value
                            }
                          </p>
                        </div>
                      </div>
                      <hr />
                      {priviewData?.Finish_High_School_Year_Question?.value ==
                        'Yes' && (
                        <>
                          <div className='d-flex'>
                            <div className='flex-grow-1 p-t-12'>
                              <p className='form-label fs-5 fw-bold'>
                                Senior Year Requirements
                              </p>
                              <p className='fs-5'>
                                {priviewData?.I_Agree_I_Will_Complete_Material
                                  ?.value
                                  ? 'Yes'
                                  : 'No'}
                              </p>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    </>
                  )}
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        I understand and agree to all of the terms and
                        guidelines mentioned in the Handbook and will ensure
                        that my student and I will follow all said guidelines. I
                        understand that it is my responsibility to ask a Hope
                        City Counselor if I have any questions or concerns
                        associated with the Handbook.
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Associated_With_Handbook?.value
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        Transcript Verification Form: This is to certify that
                        all transcripts/report cards submitted on behalf of my
                        student are accurate and true. I agree that it is my
                        responsibility to ensure that all documents submitted
                        are not altered or falsified in any way. I attest that
                        there have been no changes that alter the authenticity
                        of the documents and verify that my student has received
                        the grades with academic integrity. I am aware that if
                        any documents are found to be forged, my student will be
                        immediately expelled from Hope City Private School and
                        will no longer be eligible to re-enroll.*This document
                        will remain in effect for the duration of a student's
                        enrollment at Hope City School.
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Transcript_Verification_Form?.value
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex'>
                    <div className='flex-grow-1 p-t-12'>
                      <p className='form-label fs-5 fw-bold'>
                        By typing my name below, I accept this as an electronic
                        signature alternative:{' '}
                      </p>
                      <p className='fs-5'>
                        {priviewData?.Electronic_Signature_Name?.value}
                      </p>
                    </div>
                  </div>
                  {/* </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDocument && (
        <ImageViewModel
          showDocument={showDocument}
          handleCloseDocument={handleCloseDocument}
          documentUrl={documentUrl}
        />
      )}

      {showPDF && (
        <PDFViewModel
          showDocument={showPDF}
          handleCloseDocument={() => setShowPDF(false)}
          documentUrl={documentUrl}
        />
      )}
    </Fragment>
  );
};

export default ViewDiplomaRegistationDetails;
