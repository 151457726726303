import { call, put, takeEvery } from 'redux-saga/effects';
import {
  LANGUAGE_GET_ALL,
  LANGUAGE_GET_BY_ID,
  LANGUAGE_ADD,
  LANGUAGE_UPDATE,
  LANGUAGE_DELETE,
} from './actionTypes';
import { get, post } from '../../helpers/api-helper';
import {
  getAllLanguageSuccess,
  getAllLanguageFail,
  getByIdLanguageSuccess,
  getByIdLanguageFail,
  addLanguageSuccess,
  addLanguageFail,
  updateLanguageSuccess,
  updateLanguageFail,
  deleteLanguageSuccess,
  deleteLanguageFail,
} from './actions';

const getAllLanguageCall = async (data: any) =>
  await post('language/getAllForGrid', data);

const addLanguageCall = (data: any) => post('language/add', data);
const getByLanguageCall = (data: any) => get('language/GetById', data);
const updateLanguageCall = (data: any) => post('language/update', data);
const deleteLanguageCall: any = (data: any, config: any) =>
  post('language/Delete', data, config);

function* getAllLanguage({ payload: { formData } }: any): any {
  try {
    const response = yield call(getAllLanguageCall, {
      limit: formData.limit,
      page: formData.page,
      search: formData.search,
      sort: formData.sort,
    });
    yield put(getAllLanguageSuccess(response));
  } catch (error) {
    yield put(getAllLanguageFail(error));
  }
}

function* getByIdLanguage({ payload: { param } }: any): any {
  try {
    const response = yield call(getByLanguageCall, {
      params: {
        _id: param._id,
      },
    });
    yield put(getByIdLanguageSuccess(response));
  } catch (error) {
    yield put(getByIdLanguageFail(error));
  }
}

function* addLanguage({ payload: { formData } }: any): any {
  try {
    const response = yield call(addLanguageCall, {
      _id: '',
      Language_Name: formData.Language_Name,
      Language_Name_Native: formData.Language_Name_Native,
      Language_Code: formData.Language_Code,
      Language_Code_Full: formData.Language_Code_Full,
      Voice_Gender: formData.Voice_Gender,
      Voice_Name: formData.Voice_Name,
    });

    if (response?.statusCode === 200) {
      yield put(addLanguageSuccess(response));
    } else {
      yield put(addLanguageSuccess(response));
    }
  } catch (error) {
    yield put(addLanguageFail(error));
  }
}

function* UpdateLanguage({ payload: { formData } }: any): any {
  try {
    const response = yield call(updateLanguageCall, {
      _id: formData._id,
      Language_Name: formData.Language_Name,
      Language_Name_Native: formData.Language_Name_Native,
      Language_Code: formData.Language_Code,
      Language_Code_Full: formData.Language_Code_Full,
      Voice_Gender: formData.Voice_Gender,
      Voice_Name: formData.Voice_Name,
    });

    if (response?.statusCode === 200) {
      yield put(updateLanguageSuccess(response));
    } else {
      yield put(updateLanguageSuccess(response));
    }
  } catch (error) {
    yield put(updateLanguageFail(error));
  }
}

function* deleteLanguage({ payload: { param } }: any): any {
  try {
    const response: any = yield call(deleteLanguageCall, {
      id: param.id,
    });
    if (response?.statusCode === 200) {
      yield put(deleteLanguageSuccess(response));
    } else {
      yield put(deleteLanguageSuccess(response));
    }
  } catch (error) {
    yield put(deleteLanguageFail(error));
  }
}

function* LanguageSaga() {
  yield takeEvery(LANGUAGE_GET_ALL, getAllLanguage);
  yield takeEvery(LANGUAGE_ADD, addLanguage);
  yield takeEvery(LANGUAGE_GET_BY_ID, getByIdLanguage);
  yield takeEvery(LANGUAGE_UPDATE, UpdateLanguage);
  yield takeEvery(LANGUAGE_DELETE, deleteLanguage);
}

export default LanguageSaga;
