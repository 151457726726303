/* eslint-disable react/react-in-jsx-scope */
import { createRoot } from 'react-dom/client';
// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
// Apps
import { Provider } from 'react-redux';

import { ThemeI18nProvider } from './theme/i18n/Themei18n';
import './theme/assets/css/stylenew.css';
import './theme/assets/css/style.css';
import './theme/assets/sass/style.scss';
import './theme/assets/sass/plugins.scss';
import './theme/assets/sass/style.react.scss';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/modules/auth';
import store from './store';
import 'react-toastify/dist/ReactToastify.css';

setupAxios(axios);
Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeI18nProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeI18nProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </Provider>
  );
}
