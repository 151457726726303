import {
  LIVE_CLASS_SCHEDULE_GET_ALL,
  LIVE_CLASS_SCHEDULE_GET_ALL_SUCCESS,
  LIVE_CLASS_SCHEDULE_GET_ALL_FAIL,
  LIVE_CLASS_SCHEDULE_GET_BY_ID,
  LIVE_CLASS_SCHEDULE_GET_BY_ID_SUCCESS,
  LIVE_CLASS_SCHEDULE_GET_BY_ID_FAIL,
  LIVE_CLASS_SCHEDULE_ADD,
  LIVE_CLASS_SCHEDULE_ADD_SUCCESS,
  LIVE_CLASS_SCHEDULE_ADD_FAIL,
  LIVE_CLASS_SCHEDULE_UPDATE,
  LIVE_CLASS_SCHEDULE_UPDATE_SUCCESS,
  LIVE_CLASS_SCHEDULE_UPDATE_FAIL,
  LIVE_CLASS_SCHEDULE_DELETE,
  LIVE_CLASS_SCHEDULE_DELETE_SUCCESS,
  LIVE_CLASS_SCHEDULE_DELETE_FAIL,
  LIVE_CLASS_SCHEDULE_CLEAR,
} from './actionTypes';

const INIT_STATE = {
  getAllLoading: false,
  getAllSuccess: [],
  getAllError: {},

  getLoading: false,
  getSuccess: {},
  getError: {},

  deleteLoading: false,
  deleteSuccess: {},
  deleteError: {},

  upsertLoading: false,
  upsertSuccess: {},
  upsertError: {},
};

const LiveClassSchedule = (state = INIT_STATE, action:any) => {
  switch (action.type) {
    case LIVE_CLASS_SCHEDULE_GET_ALL:
      return {
        ...state,
        getAllLoading: true,
        getAllSuccess: [],
        getAllError: {},

        getLoading: false,
        getSuccess: {},
        getError: {},

        deleteLoading: false,
        deleteSuccess: {},
        deleteError: {},

        upsertLoading: false,
        upsertSuccess: {},
        upsertError: {},
      };

    case LIVE_CLASS_SCHEDULE_GET_ALL_SUCCESS:
      return {
        ...state,
        getAllLoading: false,
        getAllSuccess: action.payload,
        getAllError: {},
      };

    case LIVE_CLASS_SCHEDULE_GET_ALL_FAIL:
      return {
        ...state,
        getAllLoading: false,
        getAllSuccess: [],
        getAllError: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_GET_BY_ID:
      return {
        ...state,
        getLoading: true,
        getSuccess: {},
        getError: {},
      };

    case LIVE_CLASS_SCHEDULE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        getLoading: false,
        getSuccess: action.payload,
        getError: {},
      };

    case LIVE_CLASS_SCHEDULE_GET_BY_ID_FAIL:
      return {
        ...state,
        getLoading: false,
        getSuccess: {},
        getError: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_ADD:
      return {
        ...state,
        upsertLoading: true,
        upsertSuccess: {},
        upsertError: {},
      };

    case LIVE_CLASS_SCHEDULE_ADD_SUCCESS:
      return {
        ...state,
        upsertLoading: false,
        upsertSuccess: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_ADD_FAIL:
      return {
        ...state,
        upsertLoading: false,
        upsertError: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_UPDATE:
      return {
        ...state,
        upsertLoading: true,
        upsertSuccess: {},
        upsertError: {},
      };

    case LIVE_CLASS_SCHEDULE_UPDATE_SUCCESS:
      return {
        ...state,
        upsertLoading: false,
        upsertSuccess: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_UPDATE_FAIL:
      return {
        ...state,
        upsertLoading: false,
        upsertError: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_DELETE:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: {},
        deleteError: {},
      };

    case LIVE_CLASS_SCHEDULE_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: action.payload,
        deleteError: {},
      };

    case LIVE_CLASS_SCHEDULE_DELETE_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: {},
        deleteError: action.payload,
      };

    case LIVE_CLASS_SCHEDULE_CLEAR:
      return {
        ...state,
        getLoading: false,
        getSuccess: {},
        getError: {},

        deleteLoading: false,
        deleteSuccess: {},
        deleteError: {},

        upsertLoading: false,
        upsertSuccess: {},
        upsertError: {},
      };

    default:
      return state;
  }
};

export default LiveClassSchedule;
