export const TEACHER_INVITATION = 'TEACHER_INVITATION';
export const TEACHER_INVITATION_SUCCESS = 'TEACHER_INVITATION_SUCCESS';
export const TEACHER_INVITATION_FAILURE = 'TEACHER_INVITATION_FAILURE';

export const TEACHER_GET_BY_ID = 'TEACHER_GET_BY_ID';
export const TEACHER_GET_BY_ID_SUCCESS = 'TEACHER_GET_BY_ID_SUCCESS';
export const TEACHER_GET_BY_ID_FAILURE = 'TEACHER_GET_BY_ID_FAILURE';

export const CLEAR_SUCCESS_ERROR = 'CLEAR_SUCCESS_ERROR';

export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

export const TEACHER_GET_LIST = 'TEACHER_GET_LIST';
export const TEACHER_GET_LIST_SUCCESS = 'TEACHER_GET_LIST_SUCCESS';
export const TEACHER_GET_LIST_FAILURE = 'TEACHER_GET_LIST_FAILURE';

export const TEACHER_UPDATE = 'TEACHER_UPDATE';
export const TEACHER_UPDATE_SUCCESS = 'TEACHER_UPDATE_SUCCESS';
export const TEACHER_UPDATE_FAILURE = 'TEACHER_UPDATE_FAILURE';

export const TEACHER_DELETE = 'TEACHER_DELETE';
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS';
export const TEACHER_DELETE_FAILURE = 'TEACHER_DELETE_FAILURE';

export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const EMAIL_ALREADY_EXISTS_SUCCESS = 'EMAIL_ALREADY_EXISTS_SUCCESS';
export const EMAIL_ALREADY_EXISTS_FAILURE = 'EMAIL_ALREADY_EXISTS_FAILURE';
