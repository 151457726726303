import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TranscriptDownloadModel = (props: any) => {
  const { showDownloadModel, closeDownloadModel, documentUrl } = props;

  const handleDownloadDocuments = (fileUrl: any) => {
    const parts = fileUrl.split('.');
    const extension = parts[parts.length - 1];
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `documents.${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal show={showDownloadModel} onHide={closeDownloadModel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download Transcript Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <Button
                variant='primary'
                onClick={() => handleDownloadDocuments(documentUrl)}
              >
                Download
              </Button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={closeDownloadModel}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TranscriptDownloadModel;
