import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';

import LanguagesPage from './LanguagesPage';

const LanguagePageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Languages</PageTitle>
      <LanguagesPage />
    </>
  );
};

export default LanguagePageWrapper;
