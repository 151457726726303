import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import useJwt from 'hooks/useJwt';

import { I18nProvider } from '../theme/i18n/i18nProvider';
import { MasterInit } from '../theme/layout/MasterInit';
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core';

import { AuthInit, useAuth } from './modules/auth';

const App = () => {
  const { isTokenValid } = useJwt();
  const { logout } = useAuth();

  useEffect(() => {
    if (!isTokenValid()) {
      logout();
    }
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
