import React from 'react';
import { useNavigate } from 'react-router';

const EnrollmentHeadPageTab = (props: any) => {
  const { activeTab } = props;
  const navigate = useNavigate();

  return (
    <>
      {/* <div className='card-title align-items-start d-flex'>
        <div
          className='fw-bold mb-1 btn btn-light'
          onClick={() => navigate('/enrollments')}
          style={{ marginRight: 10 }}
        >
          Pending Forms
        </div>
        <div
          className='fw-bold mb-1 btn btn-light'
          onClick={() => navigate('/enrollments/active')}
          style={{ marginRight: 10 }}
        >
          Active Registrations
        </div>
        <div className='fw-bold mb-1 btn btn-light' style={{ marginRight: 10 }}>
          Unenrolled
        </div>
        <div className='fw-bold mb-1 btn btn-light' style={{ marginRight: 10 }} onClick={() => navigate('/transcript')}>
          Transcript
        </div>
        <div className='fw-bold mb-1 btn btn-light' style={{ marginRight: 10 }}>
          Diploma
        </div>
        <div className='fw-bold mb-1 btn btn-light' style={{ marginRight: 10 }}>
          Platform Course
        </div>
      </div>
       */}
      <nav>
        <div className='nav nav-tabs' id='nav-tab' role='tablist'>
          <button
            className={`nav-link fs-6 fw-bold
              ${activeTab == 'Enrollment' ? 'active' : ''} `}
            id='nav-home-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-home'
            type='button'
            role='tab'
            aria-controls='nav-home'
            aria-selected='true'
            onClick={() => navigate('/enrollments')}
            style={{ marginRight: 10 }}
          >
            Pending Forms
          </button>
          <button
            className={`nav-link fs-6 fw-bold
              ${activeTab == 'ActiveEnrollment' ? 'active' : ''} `}
            id='nav-contact-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-contact'
            type='button'
            role='tab'
            aria-controls='nav-contact'
            aria-selected='false'
            onClick={() => navigate('/enrollments/active')}
            style={{ marginRight: 10 }}
          >
            Active Registrations
          </button>
          <button
            className={`nav-link fs-6 fw-bold
             ${activeTab == 'FixedIssueEnrollment' ? 'active' : ''} `}
            id='nav-contact-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-contact'
            type='button'
            role='tab'
            aria-controls='nav-contact'
            aria-selected='false'
            onClick={() => navigate('/enrollments/fixed-issue')}
          >
            Forms waiting for Fix
          </button>
          <button
            className={`nav-link fs-6 fw-bold
            ${activeTab == 'UnEnrollment' ? 'active' : ''} `}
            id='nav-profile-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-profile'
            type='button'
            role='tab'
            aria-controls='nav-profile'
            aria-selected='false'
            onClick={() => navigate('/un-enrollment')}
          >
            Unenrolled
          </button>
          <button
            className={`nav-link fs-6 fw-bold
              ${activeTab == 'Transcript' ? 'active' : ''} `}
            id='nav-contact-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-contact'
            type='button'
            role='tab'
            aria-controls='nav-contact'
            aria-selected='false'
            onClick={() => navigate('/transcript')}
          >
            Transcript
          </button>

          <button
            className={`nav-link fs-6 fw-bold
            ${activeTab == 'diplomaCertificate' ? 'active' : ''} `}
            id='nav-contact-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-contact'
            type='button'
            role='tab'
            aria-controls='nav-contact'
            aria-selected='false'
            onClick={() => navigate('/diploma/certificate')}
          >
            Diploma
          </button>
          <button
            className={`nav-link fs-6 fw-bold
            ${activeTab == 'PlatformCourse' ? 'active' : ''} `}
            id='nav-contact-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-contact'
            type='button'
            role='tab'
            aria-controls='nav-contact'
            aria-selected='false'
            onClick={() => navigate('/platform-course')}
          >
            Platform Course
          </button>
          {/* <button
            className={`nav-link fs-6 fw-bold
            ${activeTab == 'Explanation' ? 'active' : ''} `}
            id='nav-contact-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-contact'
            type='button'
            role='tab'
            aria-controls='nav-contact'
            aria-selected='false'
            onClick={() => navigate('/explanation')}
          >
            Explanation
          </button> */}
        </div>
      </nav>
    </>
  );
};

export default EnrollmentHeadPageTab;
