import React from 'react';
import { Button, Modal as BModal } from 'react-bootstrap';

const DescriptionDisplayModal: React.FC<any> = (props) => {
  const { title, visible = false, onCancel, message, description } = props;
  const discription = description?.description;

  return (
    <BModal show={visible} backdrop='static' keyboard={false} onHide={onCancel}>
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <BModal.Body>
        <p>{discription ? discription : 'Not Available'}</p>
      </BModal.Body>
      <BModal.Footer>
        <Button variant='secondary' type='button' onClick={onCancel}>
          OK
        </Button>
      </BModal.Footer>
    </BModal>
  );
};

export default DescriptionDisplayModal;
