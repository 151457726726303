import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';

import { imageUpload } from '../../../store/actions';
import { getHighlightedSection } from './utils';

const ConversationWriteItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    imageURL,
    imageUploadReq,
    loading,
    conversationDetail,
    imageloading,
    nativeLang,
    targetLang,
  } = props;

  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("en");

  const [openTitle, setOpenTitle] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [openDesctiption, setOpenDesctiption] = useState(false);
  const typeOptions = ['None', 'Noun', 'Verb'];
  const [decription1, setDecription1] = useState(true);
  const [sectionOptionValue1, setSectionOptionValue1] = useState('None');

  const defaultImageUrl = 'https://via.placeholder.com/150?text=NA';
  const [activeKey, setActiveKey] = useState('');
  const [conversationWriteITImage1, setConversationWriteITImage1] =
    useState('');

  const [conversationWriteITImageLoader1, setConversationWriteITImageLoader1] =
    useState(false);

  const [
    conversationWriteITImageValidation1,
    setConversationWriteITImageValidation1,
  ] = useState(false);

  const urlHashRef = React.useRef<any>({});

  useEffect(() => {
    if (conversationDetail != '' && conversationDetail != undefined) {
      let Question1_Value =
        conversationDetail.Write_IT.Questions[0].Question.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_Answer_Value =
        conversationDetail.Write_IT.Questions[0].Answer.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question1_ID = conversationDetail.Write_IT.Questions[0].Question_ID;
      urlHashRef.current.one = Question1_ID;

      setValue('Conversation_WriteIt_Question1_QuestionID', Question1_ID);
      setValue('Conversation_WriteIt_Question1_Title', Question1_Value.Title);
      setValue(
        'Conversation_WriteIt_Question1_Answer',
        Question1_Answer_Value.Answer
      );
      handleOptionSelectType1('', Question1_Value.Type);
      setValue('Conversation_WriteIt_Question1_Type', Question1_Value.Type);
      setValue(
        'Conversation_WriteIt_Question1_Description',
        Question1_Value.Description
      );
      setConversationWriteITImage1(Question1_Value.Image);
      setValue('Conversation_WriteIt_Question1_Image', Question1_Value.Image);
    }
  }, [conversationDetail]);

  const handleOptionSelectType1 = (event: any, data: any) => {
    var selectTypeValue1;
    if (event != '') {
      setSectionOptionValue1(event.target.value);
      selectTypeValue1 = event.target.value;
    }
    if (data != '') {
      setSectionOptionValue1(data);
      selectTypeValue1 = data;
    }
    if (selectTypeValue1 == 'None') {
      setDecription1(true);
    }
    if (selectTypeValue1 == 'Noun') {
      setDecription1(false);
    }
    if (selectTypeValue1 == 'Verb') {
      setDecription1(false);
    }
  };

  useEffect(() => {
    if (imageURL != '') {
      if (activeKey === 'Conversation_WriteIt_Question1_Image') {
        setConversationWriteITImage1(imageURL);
        setValue('Conversation_WriteIt_Question1_Image', imageURL);
        setActiveKey('');
      }
    }
  }, [imageURL]);

  useEffect(() => {
    if (imageloading) {
      if (activeKey === 'Conversation_WriteIt_Question1_Image') {
        setConversationWriteITImageLoader1(true);
      }
    }
    if (!imageloading) {
      setConversationWriteITImageLoader1(false);
    }
  }, [imageloading]);

  useEffect(() => {
    setConversationWriteITImage1(conversationWriteITImage1);
    setValue('Conversation_WriteIt_Question1_Image', conversationWriteITImage1);
    setConversationWriteITImageValidation1(false);
  }, [conversationWriteITImage1]);

  useEffect(() => {
    setActiveKey(activeKey);
  }, [activeKey]);

  const handleChnageImage = (event: any) => {
    if (event.target.files[0].size <= 2097152) {
      setActiveKey(event.target.name);
      imageUploadReq({ file: event.target.files[0] });
    } else {
      if (event.target.name == 'Conversation_WriteIt_Question1_Image') {
        setConversationWriteITImageValidation1(true);
      }
    }
  };

  const hash = urlHashRef.current;

  return (
    <>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Write It 1
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.one)}
        >
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle(false)}
                    open={openTitle}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.WriteItTitle
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Title'
                      {...register('Conversation_WriteIt_Question1_Title')}
                      onFocus={() => setOpenTitle(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_WriteIt_Question1_Title && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_WriteIt_Question1_Title.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12'>
                  <label className='fw-bold form-label'>
                    Type <span className='astrick'>*</span>
                  </label>
                  <select
                    id=''
                    className='level-className form-control'
                    value={sectionOptionValue1}
                    name='WriteItType'
                    {...register('Conversation_WriteIt_Question1_Type')}
                    onChange={(e) => {
                      handleOptionSelectType1(e, '');
                    }}
                    defaultValue={typeOptions[0]}
                    disabled={loading}
                  >
                    {typeOptions.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </select>
                </div>
              </div>
              {!decription1 && (
                <div className='add-lesson-row-section'>
                  <div className='mt-3 col-md-12 form-group '>
                    <label className='fw-bold form-label'>
                      Description <span className='astrick'>*</span>
                    </label>
                    <Tooltip
                      title='Native Language'
                      placement='top'
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setOpenDesctiption(false)}
                      open={openDesctiption}
                      disableHoverListener
                      disableTouchListener
                    >
                      <textarea
                        onFocus={() => setOpenDesctiption(true)}
                        typeof='text'
                        className='form-control'
                        name='Question'
                        {...register(
                          'Conversation_WriteIt_Question1_Description'
                        )}
                        placeholder='Enter Description'
                        disabled={loading}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Answer <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenAnswer(false)}
                    open={openAnswer}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_WriteIt_Question1_Answer
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Answer'
                      {...register('Conversation_WriteIt_Question1_Answer')}
                      onFocus={() => setOpenAnswer(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_WriteIt_Question1_Answer && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_WriteIt_Question1_Answer.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              {/* {sectionImage && */}
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Upload Image <span className='astrick'>*</span>
                  </label>
                  <div className='question-image'>
                    <LoadingOverlay
                      active={conversationWriteITImageLoader1}
                      spinner
                      className='spinner-animation'
                    >
                      <img src={conversationWriteITImage1} />
                    </LoadingOverlay>
                  </div>
                  <div className='main-cercle-div'>
                    <input
                      type='file'
                      className='form-control'
                      name='Conversation_WriteIt_Question1_Image'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => handleChnageImage(e)}
                      disabled={loading}
                    />
                    {/* {errors.Conversation_WriteIt_Question1_Image && (
                      <p style={{ color: 'red' }}>
                        {errors.Conversation_WriteIt_Question1_Image.message}
                      </p>
                    )} */}
                    {conversationWriteITImageValidation1 ? (
                      <p style={{ color: 'red' }}>
                        Less then 2MB | Only Image File Allowed - .jpg.png
                      </p>
                    ) : conversationWriteITImage1 == '' ? (
                      errors.Conversation_WriteIt_Question1_Image && (
                        <p style={{ color: 'red' }}>
                          {errors.Conversation_WriteIt_Question1_Image.message}
                        </p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              {/* } */}
            </div>
          </div>
          <br />
        </Collapsible>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    imageURL: state.lesson.imageURL,
    imageloading: state.lesson.imageloading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  imageUploadReq: (param: any) => dispatch(imageUpload(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationWriteItActivity);
