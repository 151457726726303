import Loader from 'app/components/Loader';
import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { TbMessageReport } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import EnrollmentReportModel from './EnrollmentReportModel';
import EnrollmentDetailsFooter from './EnrollmentDetailsFooter';
import { BsDownload, BsEyeFill } from 'react-icons/bs';
import PDFViewModel from '../../components/PDFViewModel';
import ImageViewModel from 'app/components/ImageViewModel';

const EnrollmentGradeSubmissionDetails = () => {
  const getByUserIdGradeQurey: any = usePostQuery(
    'getByUserIdGrade',
    '/enrollment/grade/getbygradesubmissionid',
    {}
  );

  const [fieldID, setFieldID] = useState<any>(null);
  const [priviewData, setPriviewdata] = useState<any>('');
  const [show, setShow] = useState(false);
  const { gradesubmissionID } = useParams();

  useEffect(() => {
    getByUserIdGradeQurey.mutateAsync({ ID: gradesubmissionID });
  }, [gradesubmissionID]);

  useEffect(() => {
    if (getByUserIdGradeQurey.isSuccess) {
      setPriviewdata(getByUserIdGradeQurey?.data?.ResultObject);
    }
  }, [getByUserIdGradeQurey.isSuccess]);

  const handleShow = (fieldId: any) => {
    setFieldID(fieldId);
    if (fieldId) {
      setShow(true);
    }
  };

  const [showDocument, setShowDocument] = useState<any>(false);
  const [documentUrl, setDocumentUrl] = useState<any>('');
  const [showPDF, setShowPDF] = useState<any>(false);

  const handleShowDocument = (url: any) => {
    setDocumentUrl(url);
    setShowDocument(true);
  };

  const handlePDFViewer = (url: any) => {
    setDocumentUrl(url);
    setShowPDF(true);
  };
  const handleCloseDocument = () => setShowDocument(false);

  return (
    <>
      <Loader loading={getByUserIdGradeQurey.isLoading} />
      {!getByUserIdGradeQurey.isLoading && (
        <>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-8'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='p-5'>
                      {priviewData?.FormType == 1 && (
                        <h1 className='text-center'>
                          k12 Grade Submission Form Details
                        </h1>
                      )}
                      {priviewData?.FormType == 2 && (
                        <h1 className='text-center'>
                          K6 Grade Submission Form Details
                        </h1>
                      )}
                    </div>
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>Email</p>
                        <p className='fs-5'>{priviewData?.Email}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Student First Name
                        </p>
                        <p className='fs-5'>{priviewData?.Student_Firstname}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Student Last Name{' '}
                        </p>
                        <p className='fs-5'>{priviewData?.Student_Lastname}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Hope City Student ID
                        </p>
                        <p className='fs-5'>{priviewData?.StudentID}</p>
                      </div>
                      <div className='d-flex align-items-center'></div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          What country does student live in?{' '}
                        </p>
                        <p className='fs-5'>{priviewData?.Country_Name}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          SCHOOL DAYS: How many school days did your student
                          complete from Jan 1, 2022 to December 31, 2022?
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Complete_School_Day?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.Complete_School_Day?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Please confirm your school year is the following:{' '}
                        </p>
                        <p className='fs-5'>
                          {priviewData?.School_Year?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.School_Year?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Please upload a school transcript for 2022 (Both
                          sides)
                        </p>

                        <div className='col-6'>
                          <ul className='list-group'>
                            {priviewData?.Student_School_Transcript_Subject_Document?.value
                              ?.split(',')
                              .map((url: any) => (
                                <li className='list-group-item d-flex justify-content-between align-items-center'>
                                  Document
                                  <div className='d-flex gap-2'>
                                    <button
                                      className='fs-2 border-0 bg-transparent'
                                      title='View'
                                      onClick={() => {
                                        ['pdf'].includes(
                                          url?.split('.').pop().toLowerCase()
                                        )
                                          ? handlePDFViewer(url)
                                          : handleShowDocument(url);
                                      }}
                                    >
                                      <BsEyeFill />
                                    </button>
                                    <a
                                      href={url}
                                      target='_blank'
                                      className='fs-2 border-0 bg-transparent text-decoration-none text-dark'
                                      title='Download'
                                      download
                                    >
                                      <BsDownload />
                                    </a>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(
                              priviewData
                                ?.Student_School_Transcript_Subject_Document?.id
                            );
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          WRITE A LIST of the courses that appear on the
                          transcript you uploaded for 2022.{' '}
                        </p>
                        <p className='fs-5'>
                          {
                            priviewData?.List_Courses_Appear_On_Transcripts
                              ?.value
                          }
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(
                              priviewData?.List_Courses_Appear_On_Transcripts
                                ?.id
                            );
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Did your student fail any subjects? (Final grade of
                          less than 60% on any subjects){' '}
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Student_Fail_Subject?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.Student_Fail_Subject?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Please describe any courses not shown on transcripts
                          uploaded:
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Courses_Not_Shown_Transcripts?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(
                              priviewData?.Courses_Not_Shown_Transcripts?.id
                            );
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          What Grade are you submitting 2022 transcripts for?{' '}
                        </p>
                        <p className='fs-5'>
                          {priviewData?.School_Grade?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.School_Grade?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    {priviewData.FormType == 1 && (
                      <>
                        <div className='d-flex'>
                          <div className='flex-grow-1 p-t-12'>
                            <p className='form-label fs-5 fw-bold'>
                              Did your student finish high school?
                            </p>
                            <p className='fs-5'>
                              {priviewData?.Student_Finish_High_School?.value}
                            </p>
                          </div>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-icon btn-secondary btn-sm'
                              onClick={() => {
                                handleShow(
                                  priviewData?.Student_Finish_High_School?.id
                                );
                              }}
                            >
                              <TbMessageReport size={20} />
                            </button>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                    {priviewData?.School_Grade?.value == '6th' ||
                      (priviewData?.Student_Finish_High_School?.value ==
                        'Yes' && (
                        <>
                          <div className='d-flex'>
                            <div className='flex-grow-1 p-t-12'>
                              <p className='form-label fs-5 fw-bold'>
                                {priviewData?.FormType == 1 ? 'SENIOR ' : '6th'}
                                Grade Hope City Curriculum - Plan{' '}
                              </p>
                              <p className='fs-5'>
                                {
                                  priviewData
                                    ?.Student_Completed_Grade_Curriculum?.value
                                }
                              </p>
                            </div>
                            <div className='d-flex align-items-center'>
                              <button
                                className='btn btn-icon btn-secondary btn-sm'
                                onClick={() => {
                                  handleShow(
                                    priviewData
                                      ?.Student_Completed_Grade_Curriculum?.id
                                  );
                                }}
                              >
                                <TbMessageReport size={20} />
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className='d-flex'>
                            <div className='flex-grow-1 p-t-12'>
                              <p className='form-label fs-5 fw-bold'>
                                Did your student complete the Hope City{' '}
                                {priviewData?.FormType == 1 ? 'SENIOR ' : '6th'}{' '}
                                Grade Curriculum that was sent to you in the
                                following subjects:
                              </p>
                              <p className='fs-5'>
                                {
                                  priviewData
                                    ?.Student_Completed_Grade_Curriculum?.value
                                }
                              </p>
                            </div>
                            <div className='d-flex align-items-center'>
                              <button
                                className='btn btn-icon btn-secondary btn-sm'
                                onClick={() => {
                                  handleShow(
                                    priviewData
                                      ?.Student_Completed_Grade_Curriculum?.id
                                  );
                                }}
                              >
                                <TbMessageReport size={20} />
                              </button>
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}

                    {/* {priviewData?.School_Grade?.value != '' &&
                      priviewData?.School_Grade?.value != '6th' &&
                      priviewData?.School_Grade?.value != 'none' &&
                      priviewData?.Student_Finish_High_School?.value ==
                        'No' && (
                        <> */}

                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Guardian First Name
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Guardian_Firstname?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.Guardian_Firstname?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Guardian Last Name
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Guardian_Lastname?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.Guardian_Lastname?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          WhatsApp number Including your Country Code
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Guardian_Number?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.Guardian_Number?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          Guardian Mailing Address: (Full address)
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Guardian_Address?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(priviewData?.Guardian_Address?.id);
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      <div className='flex-grow-1 p-t-12'>
                        <p className='form-label fs-5 fw-bold'>
                          By typing my name below (Write your name below), I
                          acknowledge that I am the legal guardian of the
                          student and confirm this information is true and
                          accurate.
                        </p>
                        <p className='fs-5'>
                          {priviewData?.Acknowledge_Legal_Guardian_Name?.value}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-icon btn-secondary btn-sm'
                          onClick={() => {
                            handleShow(
                              priviewData?.Acknowledge_Legal_Guardian_Name?.id
                            );
                          }}
                        >
                          <TbMessageReport size={20} />
                        </button>
                      </div>
                    </div>
                    {/* </>
                      )} */}
                  </div>
                </div>
                <EnrollmentDetailsFooter
                  enrollmentID={gradesubmissionID}
                  priviewData={priviewData}
                  submitFormType={'GradeSubmissioFormk6'}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <EnrollmentReportModel
        show={show}
        setShow={setShow}
        fieldID={fieldID}
        enrollmentID={undefined}
        UserID={priviewData.UserID}
        registrationFormID={undefined}
        gradeSubmissionFormID={gradesubmissionID}
      />

      {showDocument && (
        <ImageViewModel
          showDocument={showDocument}
          handleCloseDocument={handleCloseDocument}
          documentUrl={documentUrl}
        />
      )}

      {showPDF && (
        <PDFViewModel
          showDocument={showPDF}
          handleCloseDocument={() => setShowPDF(false)}
          documentUrl={documentUrl}
        />
      )}
    </>
  );
};

export default EnrollmentGradeSubmissionDetails;
