import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';

import { get, post } from '../../helpers/api-helper';

import {
  studentDeleteFailure,
  studentDeleteSuccess,
  studentGetFailure,
  studentGetSuccess,
  studentGetByIdSuccess,
  studentGetByIdFailure,
  studentUpdateFailure,
  studentUpdateSuccess,
} from './actions';
import {
  STUDENT_DELETE,
  STUDENT_GET,
  STUDENT_GET_BY_ID,
  STUDENT_UPDATE,
} from './actionTypes';

// const studentGetListRequest = (data: any) => get('user/studentgetall', data);

const studentGetListRequest = (data: any) => get('user/getStudentIndex', data);
const studentDeleterequest = (data: any) => post('user/usersdelete', data);
const studentGetByIdRequest = (data: any) => post('user/studentgetbyid', data);
const studentEditRequest = (data: any, config: any) =>
  post('user/usersupdate', data, config);

function* studentGetListSaga(action: any) {
  try {
    const response: {
      token: string;
      RoleID: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentGetListRequest, {});
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentGetFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentGetSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(studentGetFailure({ error: error }));
  }
}

function* studentDeleteSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentDeleterequest, {
      userId: action.payload.userID,
      studentId: action.payload.studentId,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentDeleteFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentDeleteSuccess({ success: response }));
    }
  } catch (error: any) {
    yield put(studentDeleteFailure({ error: error }));
  }
}

function* studentGetByIdSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentGetByIdRequest, { id: action.payload });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentGetByIdFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentGetByIdSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(studentGetByIdFailure({ error: error }));
  }
}

function* studentEditSaga(action: any) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  try {
    const formData = new FormData();
    formData.append('ID', action.payload.ID);
    formData.append('FirstName', action.payload.FirstName);
    formData.append('LastName', action.payload.LastName);
    formData.append('Email', action.payload.Email);
    formData.append('file', action.payload.file);
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentEditRequest, formData, config);
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentUpdateFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentUpdateSuccess({ success: response }));
    }
  } catch (error) {
    yield put(studentUpdateFailure({ error: error }));
  }
}

function* authSaga() {
  yield all([takeLatest(STUDENT_GET, studentGetListSaga)]);
  yield all([takeLatest(STUDENT_DELETE, studentDeleteSaga)]);
  yield all([takeEvery(STUDENT_GET_BY_ID, studentGetByIdSaga)]);
  yield all([takeEvery(STUDENT_UPDATE, studentEditSaga)]);
}

export default authSaga;
