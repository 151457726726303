import React from 'react';
import {
  useTable,
  useSortBy,
  UseTableOptions,
  usePagination,
  TableState,
  UsePaginationOptions,
  useGlobalFilter,
  TableInstance,
  useRowSelect,
} from 'react-table';

const TableInstanceContext = React.createContext<TableInstance | null>(null);

export function useTableInstance() {
  return React.useContext(TableInstanceContext) as TableInstance;
}

export type ExtraTableProps<T extends object> = {
  children: React.ReactChild;
  onTableStateChange?: (state: Partial<TableState<T>>) => void;
};

type TableProps<DataType extends object = object> = UseTableOptions<DataType> &
  UsePaginationOptions<DataType> &
  ExtraTableProps<DataType>;

const Table: React.FC<TableProps> = (props) => {
  const {
    data, // make sure to use `useMemo` on data and columns
    columns,
    onTableStateChange = () => null,
    ...restProps
  } = props;

  const tableInstance = useTable(
    {
      ...restProps,
      data,
      columns,
      globalFilter: '',
      autoResetSortBy: false,
      defaultCanSort: false,
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      disableGlobalFilter: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    rows,
    state,
    gotoPage,
    nextPage,
    pageCount,
    prepareRow,
    setPageSize,
    canNextPage,
    headerGroups,
    previousPage,
    getTableProps,
    setGlobalFilter,
    canPreviousPage,
    getTableBodyProps,
  } = tableInstance;

  const stateRef = React.useRef(state);

  React.useEffect(() => {
    (() => {
      if (!state) return;

      if (
        stateRef.current !== state &&
        JSON.stringify(stateRef.current) !== JSON.stringify(state)
      ) {
        stateRef.current = state;
        onTableStateChange(state);
      }
    })();
  }, [onTableStateChange, state]);

  return (
    <TableInstanceContext.Provider value={tableInstance}>
      {props.children}
    </TableInstanceContext.Provider>
  );
};

export default Table;
