import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useGetQuery } from 'hooks/reactQueryHelper';

const DayChart = ({ activeEnrolledStudent }: any) => {
  const getDailySignupDataQuery = useGetQuery(
    'getDailySignupDataQuery',
    '/dashboard/getLastNinetyDaysJoinData'
  );
  const getDailyLoginDataQuery = useGetQuery(
    'getDailyLoginDataQuery',
    '/dashboard/getLastNinetyDaysLoginData'
  );
  const getDailyCompletedLessonQuery = useGetQuery(
    'getDailyCompletedLessonQuery',
    '/dashboard/getLastNinetyDaysCompletedLessonData'
  );
  const getDailyCompletedInstantClassDataQuery = useGetQuery(
    'getDailyCompletedInstantClassDataQuery',
    '/dashboard/getLastNinetyDaysCompletedInstantClassData'
  );

  useEffect(() => {
    getDailySignupDataQuery.mutate({});
    getDailyLoginDataQuery.mutate({});
    getDailyCompletedLessonQuery.mutate({});
    getDailyCompletedInstantClassDataQuery.mutate({});
  }, []);

  const countDaysList = getDailySignupDataQuery?.data?.ResultObject || {};
  const countDayArray = Object.values(countDaysList);

  const countDailyLoginList = getDailyLoginDataQuery?.data?.ResultObject || {};
  const countDailyLoginArray = Object.values(countDailyLoginList);

  const countDailyLessonList =
    getDailyCompletedLessonQuery?.data?.ResultObject || {};
  const countDailyLessonArray = Object.values(countDailyLessonList);

  const countDailyCompletedInstantClass =
    getDailyCompletedInstantClassDataQuery?.data?.ResultObject || {};
  const countDailyCompletedInstantClassArray = Object.values(
    countDailyCompletedInstantClass
  );

  let Days = countDayArray.map((e: any) => e.date);
  let joiningCount = countDayArray.map((e: any) => e.count);
  let loginCount = countDailyLoginArray.map((e: any) => e.count);
  let completeLessonCount = countDailyLessonArray.map((e: any) => e.count);
  let completeInstantClassCount = countDailyCompletedInstantClassArray.map(
    (e: any) => e.count
  );

  const chartData = {
    labels: Days,
    datasets: [
      {
        label: 'Joining Students',
        data: joiningCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Login Students',
        data: loginCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        borderColor: 'rgb(53, 162, 235)',
      },
      {
        label: 'Completed Lessons',
        data: completeLessonCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        borderColor: 'rgb(0,255,0)',
      },
      {
        label: 'Completed Instant Class',
        data: completeInstantClassCount,
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(202,54,255, 0.2)',
        borderColor: 'rgb(202,54,255)',
      },
      {
        label: 'Active Enrolled Student',
        data: activeEnrolledStudent?.map((e: any) => e.count),
        fill: true,
        tension: 0.5,
        backgroundColor: 'rgba(13, 71, 161, 0.2)',
        borderColor: 'rgb(13, 71, 161)',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Last 90 days',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  return (
    <>
      <div className='row g-5 g-xl-8 mb-5 mb-xl-10'>
        <div className='col-md-12'>
          <div className='pt-3 pb-0'>
            <Line data={chartData} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DayChart;
