import { useGetQuery } from 'hooks/reactQueryHelper';
import { useEffect } from 'react';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import moment from 'moment';
import LoaderNew from 'app/components/LoaderNew';

const LiveClassScheduleDateListModal = (props: any) => {
  const getLiveClassScheduleDateByClassId: any = useGetQuery(
    'getLiveClassScheduleDateByClassId',
    `/liveClass/getLiveClassScheduleDateByClassId`
  );

  const joinScheduleClassURLForModerator: any = useGetQuery(
    'joinScheduleClassURLForModerator',
    `/liveClass/joinScheduleClassURLForModerator`
  );

  useEffect(() => {
    getLiveClassScheduleDateByClassId.mutate({
      params: {
        _id: props.id,
      },
    });
  }, []);

  useEffect(() => {
    if (
      joinScheduleClassURLForModerator.isSuccess &&
      joinScheduleClassURLForModerator?.data?.ResultObject?.URL
    ) {
      window.open(
        joinScheduleClassURLForModerator?.data?.ResultObject?.URL,
        '_blank'
      );
    }
  }, [joinScheduleClassURLForModerator.isSuccess]);

  const startEncoderURL = (liveClassRoomId: any) => {
    joinScheduleClassURLForModerator.mutate({
      params: {
        liveClassRoomId: liveClassRoomId,
      },
    });
  };

  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Schedule Date
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoaderNew
          loading={joinScheduleClassURLForModerator?.isLoading}
          isFull={false}
        />
        {getLiveClassScheduleDateByClassId?.isLoading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <ListGroup as='ol' numbered>
            {getLiveClassScheduleDateByClassId?.data?.ResultObject?.map(
              (item: any) => {
                return (
                  <ListGroup.Item
                    as='li'
                    className='d-flex justify-content-between align-items-center'
                  >
                    <span className='ps-1 flex-grow-1'>
                      {moment(item.scheduleDateTime).format('MM/DD/YYYY HH:mm')}
                    </span>
                    {!item?.encoderEventId && (
                      <button
                        className='btn btn-success btn-sm'
                        title='Start'
                        onClick={() => {
                          startEncoderURL(item.liveClassRoomId);
                        }}
                        disabled={moment().isAfter(item.playDateTime)}
                      >
                        Start
                      </button>
                    )}
                  </ListGroup.Item>
                );
              }
            )}
          </ListGroup>
        )}
        {getLiveClassScheduleDateByClassId?.isSuccess &&
          getLiveClassScheduleDateByClassId?.data?.ResultObject?.length ===
            0 && (
            <ListGroup>
              <ListGroup.Item>No Schedule Date Available</ListGroup.Item>
            </ListGroup>
          )}
      </Modal.Body>
    </Modal>
  );
};

export default LiveClassScheduleDateListModal;
