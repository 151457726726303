import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import axios from 'axios';

import { get, post } from '../../helpers/api-helper';

import {
  teacherInvitation,
  teacherInvitationSuccess,
  teacherInvitationFailure,
  teacherGetList,
  teacherGetListSuccess,
  teacherGetListFailure,
  teacherUpdateSuccess,
  teacherDeleteSuccess,
  teacherDeleteFailure,
  teacherGetByIdFailure,
  teacherGetByIdSuccess,
  teacherUpdateFailure,
  emailAlreadyExistsFailure,
  emailAlreadyExistsSuccess,
} from './actions';
import {
  TEACHER_INVITATION,
  TEACHER_GET_LIST,
  TEACHER_UPDATE,
  TEACHER_DELETE,
  TEACHER_GET_BY_ID,
  EMAIL_ALREADY_EXISTS,
} from './actionTypes';

const teacherInvitationRequest = (data: any) =>
  post('invitation/teacherInvitation', data);
const teacherGetListRequest = (data: any) => get('user/teachergetall', data);
const teacherGetByIdRequest = (data: any) => post('user/usersgetbyid', data);
const updateTeacher = (data: any) => post('user/update', data);
const deleteTeacherRequest = (data: any) => post('user/usersdelete', data);
const teacherEditRequest = (data: any, config: any) =>
  post('user/usersupdate', data, config);
const checkEmailExists = (data: any) =>
  post('invitation/checkEmailExists', data);

function* teacherInvitationSaga(action: any) {
  try {
    const response: {
      token: string;
      ResultCode: string;
      ResultMessage: string;
    } = yield call(teacherInvitationRequest, {
      email: action.payload.email,
    });
    if (response.ResultCode !== 'EXISTS') {
      yield put(teacherInvitationFailure({ error: response.ResultMessage }));
    } else {
      yield put(teacherInvitationSuccess({ successInvitation: response }));
    }
    if (response.ResultCode !== 'SUCCESS') {
      yield put(teacherInvitationFailure({ error: response.ResultMessage }));
    } else {
      yield put(teacherInvitationSuccess({ successInvitation: response }));
    }
  } catch (error: any) {
    yield put(teacherInvitationFailure({ error: error }));
  }
}

function* teacherGetListSaga(action: any) {
  try {
    const response: {
      token: string;
      RoleID: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(teacherGetListRequest, {});
    if (response.ResultCode !== 'SUCCESS') {
      yield put(teacherGetListFailure({ error: response.ResultMessage }));
    } else {
      yield put(teacherGetListSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(teacherGetListFailure({ error: error }));
  }
}

function* teacherGetByIdSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(teacherGetByIdRequest, { id: action.payload });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(teacherGetByIdFailure({ error: response.ResultMessage }));
    } else {
      yield put(teacherGetByIdSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(teacherGetByIdFailure({ error: error }));
  }
}

// function* teacherUpdate(action: any) {
//     const config = {
//         headers: {
//             'content-type': 'multipart/form-data'
//         }
//     }
//     try {
//         const formData = new FormData();
//         formData.append('ID', formData.ID);
//         formData.append('FirstName', formData.FirstName);
//         formData.append('LastName', formData.LastName);
//         formData.append('TeacherProfileImage', formData.TeacherProfileImage[0]);
//         formData.append('IsActive', formData.IsActive);

//         const response: { token: string, RoleID: string, ResultCode: string, ResultMessage: string, ResultObject: any } = yield call(updateTeacher, formData)

//         if (response.ResultCode !== "SUCCESS") {
//             yield put(teacherGetListFailure({ error: response.ResultMessage }))
//         } else {
//             yield put(teacherGetListSuccess({ success: response.ResultObject }));
//         }
//     } catch (error: any) {
//         yield put(teacherGetListFailure({ error: error }))
//     }
// }

function* teacherEditSaga(action: any) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  try {
    const formData = new FormData();
    formData.append('ID', action.payload.ID);
    formData.append('FirstName', action.payload.FirstName);
    formData.append('LastName', action.payload.LastName);
    formData.append('Email', action.payload.Email);
    formData.append('file', action.payload.file);
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(teacherEditRequest, formData, config);
    if (response.ResultCode !== 'SUCCESS') {
      yield put(teacherUpdateFailure({ error: response.ResultMessage }));
    } else {
      yield put(teacherUpdateSuccess({ success: response }));
    }
  } catch (error) {
    yield put(teacherUpdateFailure({ error: error }));
  }
}

function* teacherDeleteSaga(action: any) {
  try {
    const response: {
      token: string;
      RoleID: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(deleteTeacherRequest, { userId: action.payload.userID });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(teacherDeleteFailure({ error: response.ResultMessage }));
    } else {
      yield put(teacherDeleteSuccess({ success: response }));
    }
  } catch (error: any) {
    yield put(teacherDeleteFailure({ error: error }));
  }
}

function* checkEmailAlreadyExists(action: any) {
  try {
    const response: {
      token: string;
      RoleID: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(checkEmailExists, { email: action.payload.email });
    if (response.ResultCode !== 'EXISTS') {
      yield put(emailAlreadyExistsFailure({ error: response }));
    } else {
      yield put(emailAlreadyExistsSuccess({ emailexistssuccess: response }));
    }
  } catch (error) {
    yield put(emailAlreadyExistsFailure(error));
  }
}

function* authSaga() {
  yield all([takeLatest(TEACHER_INVITATION, teacherInvitationSaga)]);
  yield all([takeLatest(TEACHER_GET_LIST, teacherGetListSaga)]);
  // yield all([takeLatest(TEACHER_UPDATE, teacherUpdate)]);
  yield all([takeLatest(TEACHER_DELETE, teacherDeleteSaga)]);
  yield all([takeEvery(TEACHER_GET_BY_ID, teacherGetByIdSaga)]);
  yield all([takeEvery(TEACHER_UPDATE, teacherEditSaga)]);
  yield all([takeEvery(EMAIL_ALREADY_EXISTS, checkEmailAlreadyExists)]);
}

export default authSaga;
