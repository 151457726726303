import {
  LIVE_CLASS_SCHEDULE_GET_ALL,
  LIVE_CLASS_SCHEDULE_GET_ALL_SUCCESS,
  LIVE_CLASS_SCHEDULE_GET_ALL_FAIL,
  LIVE_CLASS_SCHEDULE_GET_BY_ID,
  LIVE_CLASS_SCHEDULE_GET_BY_ID_SUCCESS,
  LIVE_CLASS_SCHEDULE_GET_BY_ID_FAIL,
  LIVE_CLASS_SCHEDULE_ADD,
  LIVE_CLASS_SCHEDULE_ADD_SUCCESS,
  LIVE_CLASS_SCHEDULE_ADD_FAIL,
  LIVE_CLASS_SCHEDULE_UPDATE,
  LIVE_CLASS_SCHEDULE_UPDATE_SUCCESS,
  LIVE_CLASS_SCHEDULE_UPDATE_FAIL,
  LIVE_CLASS_SCHEDULE_DELETE,
  LIVE_CLASS_SCHEDULE_DELETE_SUCCESS,
  LIVE_CLASS_SCHEDULE_DELETE_FAIL,
  LIVE_CLASS_SCHEDULE_CLEAR,
} from './actionTypes';

export const getAllLiveClassSchedule = (formData:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_GET_ALL,
    payload: { formData },
  };
};
export const getAllLiveClassScheduleSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_GET_ALL_SUCCESS,
    payload: response,
  };
};
export const getAllLiveClassScheduleFail = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_GET_ALL_FAIL,
    payload: response,
  };
};

export const getByIdLiveClassSchedule = (param:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_GET_BY_ID,
    payload: { param },
  };
};
export const getByIdLiveClassScheduleSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_GET_BY_ID_SUCCESS,
    payload: response,
  };
};
export const getByIdLiveClassScheduleFail = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_GET_BY_ID_FAIL,
    payload: response,
  };
};

export const addLiveClassSchedule = (formData:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_ADD,
    payload: { formData },
  };
};
export const addLiveClassScheduleSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_ADD_SUCCESS,
    payload: response,
  };
};
export const addLiveClassScheduleFail = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_ADD_FAIL,
    payload: response,
  };
};

export const updateLiveClassSchedule = (formData:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_UPDATE,
    payload: { formData },
  };
};
export const updateLiveClassScheduleSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_UPDATE_SUCCESS,
    payload: response,
  };
};
export const updateLiveClassScheduleFail = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_UPDATE_FAIL,
    payload: response,
  };
};

export const deleteLiveClassSchedule = (param:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_DELETE,
    payload: { param },
  };
};
export const deleteLiveClassScheduleSuccess = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_DELETE_SUCCESS,
    payload: response,
  };
};
export const deleteLiveClassScheduleFail = (response:any) => {
  return {
    type: LIVE_CLASS_SCHEDULE_DELETE_FAIL,
    payload: response,
  };
};

export const clearLiveClassSchedule = () => {
  return {
    type: LIVE_CLASS_SCHEDULE_CLEAR,
  };
};
