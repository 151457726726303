import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

import GrammarReadItActivity from '../activity/grammar-read-it-activity';
import GrammarTapItActivity from '../activity/grammar-tap-it-activity';
import GrammarWriteItActivity from '../activity/grammar-write-it-activity';

import { connect, ConnectedProps } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { FcCheckmark } from 'react-icons/fc'
import { videoUpload } from 'store/actions';
import { BiErrorCircle } from 'react-icons/bi';

const GrammarSection: React.FC<any> = (props: any) => {
  const { errors, register, setValue, lessonSingleData, loading, onUploadVideoReq, videoloading, videoURL, getValues, setError } = props;

  const [grammarDetail, setGrammarDetail] = useState("")

  const [checkIconGrammar, setCheckIconGrammar] = useState(false)
  const [grammarSectionType, setGrammarSectionType] = useState("")
  const [grammarVideoLoader, setGrammarVideoLoader] = useState(false)
  const [grammarVideoError, setGrammarVideoError] = useState(false)
  const [grammarVideoErrorMassage, setGrammarVideoErrorMassage] = useState(false)

  useEffect(() => {
    const violation: any = document.getElementById('grammarComponents');
    window.scrollTo({
      top: violation.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (lessonSingleData != "" && lessonSingleData != undefined) {
      setGrammarDetail(lessonSingleData?.Activity_Details?.filter((x: any) => (x.Section == "GrammarSection"))[0])
      const grammarData = lessonSingleData?.Activity_Details?.filter((x: any) => (x.Section == "GrammarSection"))[0]
      setValue("GrammarVideoUrl", grammarData?.GrammarVideoUrl)
      setValue("Grammar_Upload_VideoUrl", grammarData?.Video_Url)
    }
  }, [lessonSingleData])


  const handleFocus = async (event: any, type: any) => {
    if (event.target.value != "") {
      setCheckIconGrammar(false)
      setGrammarSectionType(type)
      onUploadVideoReq({ url: event.target.value })
      setGrammarVideoErrorMassage(false)
    } else {
      setGrammarVideoErrorMassage(true)
      setCheckIconGrammar(false)
      setGrammarVideoError(false)
    }
  }

  useEffect(() => {
    setCheckIconGrammar(checkIconGrammar)
  }, [checkIconGrammar])

  useEffect(() => {
    setGrammarVideoError(grammarVideoError)
  }, [grammarVideoError])

  useEffect(() => {
    setGrammarVideoLoader(grammarVideoLoader)
  }, [grammarVideoLoader])

  useEffect(() => {

    if (videoURL.ResultCode != "ERROR" && videoURL.ResultCode != undefined && grammarSectionType == "GrammarVideoUrl") {

      setValue("Grammar_Upload_VideoUrl", videoURL.ResultObject)
      setGrammarVideoErrorMassage(false)
      setCheckIconGrammar(true)
      setGrammarVideoError(false)
      setGrammarSectionType("")
      setGrammarVideoLoader(false)
    }
    if (videoURL.ResultCode == "ERROR" && grammarSectionType == "GrammarVideoUrl") {
      // setError('GrammarVideoUrl', { type: 'custom', message: 'enter a valid URL' });
      setValue("Grammar_Upload_VideoUrl", "")
      setGrammarVideoErrorMassage(true)
      setGrammarVideoLoader(false)
      setGrammarSectionType("")
      setCheckIconGrammar(false)
      setGrammarVideoError(true)
    }
  }, [videoURL])

  useEffect(() => {
    if (videoloading && grammarSectionType == "GrammarVideoUrl") {
      setGrammarVideoLoader(true)
      // setCheckIconGrammar(false)
      // setGrammarVideoError(false)
    } else {
      setGrammarVideoLoader(false)
    }
  }, [videoloading])
  return (
    <>
      <div
        className='add-lesson mt-3 activity-main card-box'
        id='grammarComponents'
      >
        <Collapsible
          trigger={
            <div style={{ width: '100%', position: 'relative' }}>
              <h1 className="border-bottom pb-5">
                <i className='fa fa-duotone fa-file-lines me-2 fs-2'></i> Grammar Activity{' '}
                <BsChevronDown style={{ position: 'absolute', right: '0' }} />
              </h1>
            </div>
          }
          open={true}
        >
          <div className='add-lesson-row-section'>
            <div className='col-md-12 mt-3 form-group'>
              <label className='fw-bold form-label'>
                Video Embedded URL <span className='astrick'>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type='text'
                  {...register('GrammarVideoUrl')}
                  className={
                    errors.GrammarVideoUrl
                      ? 'form-control errors'
                      : 'form-control'
                  }
                  placeholder='https://www.youtube.com/shorts/7tFTZroMqzE'
                  onBlur={() => handleFocus(event, "GrammarVideoUrl")}
                  disabled={loading}
                ></input>
                {grammarVideoLoader && <span style={{ position: "absolute", right: '7px', top: '10px' }}><Spinner animation="border" variant="primary" /></span>}
                {checkIconGrammar && <span style={{ position: "absolute", right: '7px', top: '10px' }}><FcCheckmark size={25} /></span>}
                {grammarVideoError && <span style={{ position: "absolute", right: '7px', top: '10px', color: "red" }}><BiErrorCircle size={25} /></span>}
              </div>
              {errors.GrammarVideoUrl ? (
                <p style={{ color: 'red' }}>{errors.GrammarVideoUrl.message}</p>
              )
                :
                grammarVideoErrorMassage && (<p style={{ color: 'red' }}>
                  Entered url is corrupted please try different url
                </p>)}
              {/* {grammarVideoErrorMassage && (<p style={{ color: 'red' }}>
                Entered url is corrupted please try different url
              </p>)} */}
            </div>
          </div>
          <GrammarReadItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            grammarDetail={grammarDetail}
            loading={loading}
          />
          <GrammarTapItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            grammarDetail={grammarDetail}
            loading={loading}
          />
          <GrammarWriteItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            grammarDetail={grammarDetail}
            loading={loading}
          />
        </Collapsible>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return ({
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    videoloading: state.lesson.videoloading,
    videoURL: state.lesson.videoURL,
  })
}

const mapDispatchToProps = (dispatch: any) => ({
  onUploadVideoReq: (param: any) => dispatch(videoUpload(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GrammarSection);
