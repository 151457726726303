import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { videoUpload } from 'store/actions';

import ConversationSayItActivity from '../activity/conversation-say-it-activity';
import ConversationTapItActivity from '../activity/conversation-tap-it-activity';
import ConversationWriteItActivity from '../activity/conversation-write-it-activity';
import { connect, ConnectedProps } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { FcCheckmark } from 'react-icons/fc'
import { BiErrorCircle } from 'react-icons/bi';

const ConversationSection: React.FC<any> = (props: any) => {
  const { errors, register, setValue, lessonSingleData, loading, onUploadVideoReq, videoloading, videoURL, getValues, setError } = props;
  const [conversationDetail, setConversationDetail] = useState("")

  const [checkIconConv, setCheckIconConv] = useState(false)
  const [conversationSectionType, setConversationVideoType] = useState("")
  const [conversationVideoLoader, setConversationVideoLoader] = useState(false)
  const [conversationVideoError, setConversationVideoError] = useState(false)
  const [conversationVideoErrorMassage, setConversationVideoErrorMassage] = useState(false)

  useEffect(() => {
    const violation: any = document.getElementById('conversationComponents');
    window.scrollTo({
      top: violation.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (lessonSingleData != "" && lessonSingleData != undefined) {
      setConversationDetail(lessonSingleData?.Activity_Details?.filter((x: any) => (x.Section == "ConversationSection"))[0])
      const conversationData = lessonSingleData?.Activity_Details?.filter((x: any) => (x.Section == "ConversationSection"))[0]
      setValue("ConversationVideoUrl", conversationData?.ConversationVideoUrl)
      setValue("Conversation_Upload_VideoUrl", conversationData?.Video_Url)
    }
  }, [lessonSingleData])


  const handleFocus = async (event: any, type: any) => {
    if (event.target.value != "") {
      setCheckIconConv(false)
      setConversationVideoType(type)
      onUploadVideoReq({ url: event.target.value })
      setConversationVideoErrorMassage(false)
    } else {
      setConversationVideoErrorMassage(true)
      setCheckIconConv(false)
      setConversationVideoError(false)
    }
  }


  useEffect(() => {
    setCheckIconConv(checkIconConv)
  }, [checkIconConv])

  useEffect(() => {
    setConversationVideoError(conversationVideoError)
  }, [conversationVideoError])

  useEffect(() => {
    setConversationVideoLoader(conversationVideoLoader)
  }, [conversationVideoLoader])


  useEffect(() => {
    if (videoURL.ResultCode != "ERROR" && videoURL.ResultCode != undefined && conversationSectionType == "ConversationVideoUrl") {
      setValue("Conversation_Upload_VideoUrl", videoURL.ResultObject)
      setCheckIconConv(true)
      setConversationVideoType("")
      setConversationVideoErrorMassage(false)
      setConversationVideoError(false)
      setConversationVideoLoader(false)
    }
    if (videoURL.ResultCode == "ERROR" && conversationSectionType == "ConversationVideoUrl") {
      setValue("Conversation_Upload_VideoUrl", "")
      setConversationVideoErrorMassage(true)
      setConversationVideoLoader(false)
      setConversationVideoType("")
      setCheckIconConv(false)
      setConversationVideoError(true)
    }
  }, [videoURL])


  useEffect(() => {
    if (videoloading && conversationSectionType == "ConversationVideoUrl") {
      setConversationVideoLoader(true)
      // setCheckIconConv(false)
      // setConversationVideoError(false)
    } else {
      setConversationVideoLoader(false)
    }
  }, [videoloading])

  return (
    <>
      <div
        className='add-lesson mt-3 activity-main card-box'
        id='conversationComponents'
      >
        <Collapsible
          trigger={
            <div style={{ width: '100%', position: 'relative' }}>
              <h1 className="border-bottom pb-5">
                Conversation Activity{' '}
                <BsChevronDown style={{ position: 'absolute', right: '0' }} />
              </h1>
            </div>
          }
          open={true}
        >
          <div className='add-lesson-row-section'>
            <div className='col-md-12 mt-3 form-group'>
              <label className='fw-bold form-label'>
                Video Embedded URL <span className='astrick'>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type='text'
                  {...register('ConversationVideoUrl')}
                  className={
                    errors.ConversationVideoUrl
                      ? 'form-control errors'
                      : 'form-control'
                  }
                  placeholder='https://www.youtube.com/shorts/7tFTZroMqzE'
                  onBlur={() => handleFocus(event, "ConversationVideoUrl")}
                  disabled={loading}
                ></input>
                {conversationVideoLoader && <span style={{ position: "absolute", right: '7px', top: '10px' }}><Spinner animation="border" variant="primary" /></span>}
                {checkIconConv && <span style={{ position: "absolute", right: '7px', top: '10px' }}><FcCheckmark size={25} /></span>}
                {conversationVideoError && <span style={{ position: "absolute", right: '7px', top: '10px', color: "red" }}><BiErrorCircle size={25} /></span>}
              </div>
              {errors.ConversationVideoUrl ? (
                <p style={{ color: 'red' }}>
                  {errors.ConversationVideoUrl.message}
                </p>
              ) :
                conversationVideoErrorMassage && (<p style={{ color: 'red' }}>
                  Entered url is corrupted please try different url
                </p>)
              }
              {/* {conversationVideoErrorMassage && (<p style={{ color: 'red' }}>
                Entered url is corrupted please try different url
              </p>)} */}
            </div>
          </div>

          <ConversationTapItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            conversationDetail={conversationDetail}
            loading={loading}
          />
          <ConversationSayItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            conversationDetail={conversationDetail}
            loading={loading}
          />
          <ConversationWriteItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            conversationDetail={conversationDetail}
            loading={loading}
          />
        </Collapsible>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return ({
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    videoloading: state.lesson.videoloading,
    videoURL: state.lesson.videoURL,
  })
}

const mapDispatchToProps = (dispatch: any) => ({
  onUploadVideoReq: (param: any) => dispatch(videoUpload(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSection);
