import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import LoaderNew from '../LoaderNew';

const PDFViewModel = (props: any) => {
  const { showDocument, handleCloseDocument, documentUrl } = props;

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);

    return () => {
      setLoader(false);
    };
  }, []);

  return (
    <>
      <Modal
        show={showDocument}
        onHide={handleCloseDocument}
        centered
        scrollable
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Documents</Modal.Title>
        </Modal.Header>

        <Modal.Body className='vh-100'>
          <LoaderNew loading={loader} isFull={false} />
          <object
            data={documentUrl}
            type='application/pdf'
            width='100%'
            height={'100%'}
            onLoad={() => setLoader(false)}
          >
            <p>Unable to display PDF file. instead.</p>
          </object>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDocument}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PDFViewModel;
