import {
  TEACHER_DELETE,
  TEACHER_DELETE_FAILURE,
  TEACHER_DELETE_SUCCESS,
  TEACHER_INVITATION,
  TEACHER_INVITATION_SUCCESS,
  TEACHER_INVITATION_FAILURE,
  CLEAR_SUCCESS_ERROR,
  TEACHER_GET_LIST,
  TEACHER_GET_LIST_SUCCESS,
  TEACHER_GET_LIST_FAILURE,
  TEACHER_GET_BY_ID,
  TEACHER_GET_BY_ID_SUCCESS,
  TEACHER_GET_BY_ID_FAILURE,
  TEACHER_UPDATE,
  TEACHER_UPDATE_SUCCESS,
  TEACHER_UPDATE_FAILURE,
  EMAIL_ALREADY_EXISTS,
  EMAIL_ALREADY_EXISTS_SUCCESS,
  EMAIL_ALREADY_EXISTS_FAILURE,
} from './actionTypes';
import { AuthActions, AuthState } from './types';

const initialState: AuthState = {
  loading: false,
  token: '',
  error: '',
  success: '',
  successInvitation: '',
  teachergetlist: '',
  teacherdetails: null,
  inviteLoading: false,
  emailexistssuccess: '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TEACHER_INVITATION:
      return {
        ...state,
        inviteLoading: true,
        successInvitation: '',
      };
    case TEACHER_INVITATION_SUCCESS:
      return {
        ...state,
        successInvitation: action.payload.successInvitation,
        inviteLoading: false,
      };
    case TEACHER_INVITATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        inviteLoading: false,
      };
    case CLEAR_SUCCESS_ERROR:
      return {
        ...state,
        success: '',
        error: '',
        successInvitation: '',
      };
    case TEACHER_GET_LIST:
      return {
        ...state,
      };
    case TEACHER_GET_LIST_SUCCESS:
      return {
        ...state,
        teachergetlist: action.payload.success,
      };
    case TEACHER_GET_LIST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case TEACHER_GET_BY_ID:
      return {
        ...state,
      };
    case TEACHER_GET_BY_ID_SUCCESS:
      return {
        ...state,
        teacherdetails: action.payload.success,
      };
    case TEACHER_GET_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case TEACHER_UPDATE:
      return {
        ...state,
        loading: true,
        success: '',
        studentdetails: '',
      };
    case TEACHER_UPDATE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        loading: false,
      };
    case TEACHER_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case TEACHER_DELETE:
      return {
        ...state,
        loading: true,
        success: '',
      };
    case TEACHER_DELETE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        loading: false,
      };
    case TEACHER_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: '',
        loading: false,
      };
    case EMAIL_ALREADY_EXISTS:
      return {
        ...state,
        inviteLoading: false,
        emailexistssuccess: '',
      };
    case EMAIL_ALREADY_EXISTS_SUCCESS:
      return {
        ...state,
        emailexistssuccess: action.payload.emailexistssuccess,
        inviteLoading: false,
      };
    case EMAIL_ALREADY_EXISTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        inviteLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
