export function getToken() {
  try {
    let token = localStorage.getItem('hopecity_admin_auth_token');
    if (token) {
      token = JSON.parse(token)?.token;
    }
    return token;
  } catch (e) {
    return null;
  }
}

export function setToken(token: string) {
  try {
    localStorage.setItem('hopecity_admin_auth_token', token);
  } catch (e) {
    console.error(e);
  }
}

export function removeToken() {
  try {
    localStorage.removeItem('hopecity_admin_auth_token');
  } catch (e) {
    console.error(e);
  }
}
