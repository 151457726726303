import { Formik } from 'formik';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Modal as BModal,
  Row,
  Spinner,
} from 'react-bootstrap';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { toAbsoluteUrl } from '../../../theme/helpers';
import { imageValidationSchema } from '../../../utils/YupSchema';
import useLoadingState from '../../../hooks/useLoadingState';
import { urlRoutes } from '../../../shared/apiConfig';
import callBackendApi from '../../../utils/callBackendApi';
import ImageUpload from '../ImageUpload';

type PropsType = {
  initialValue: any;
  visible: boolean;
  onCancel: () => void;
  fetch: (...args: any[]) => void;
};

const validationSchema = Yup.object({
  ID: Yup.string(),
  FirstName: Yup.string().required('Please enter first name.'),
  LastName: Yup.string().required('Please enter last name.'),
  Email: Yup.string()
    .required('Please enter email.')
    .email('Please enter valid email address.'),
  ProfilePic: Yup.mixed()
    .nullable()
    .test('fileFormat', 'Please select valid image ', (value) => {
      if (value && value.type) {
        return (
          value &&
          ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
            value.type
          )
        );
      } else {
        return true;
      }
    }),
});

const DetailEditModal: React.FC<React.PropsWithChildren<PropsType>> = (
  props
) => {
  const {
    initialValue: i,
    visible,
    onCancel = () => null,
    fetch = () => null,
  } = props;

  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');

  const initialValues = {
    ID: (i?._id || '') as string,
    FirstName: (i?.FirstName || '') as string,
    LastName: (i?.LastName || '') as string,
    Email: (i?.Email || '') as string,
    ProfilePic: i?.ProfilePic ? i?.ProfilePic : defaultImageUrl,
    IsActive: i?.IsActive as boolean,
  };

  const { loading, startLoading, stopLoading } = useLoadingState({
    loading: false,
  });
  const _handleSubmit = async (values: any) => {
    startLoading();
    const formData = new FormData();
    formData.append('ID', values.ID);

    formData.append('FirstName', values.FirstName);
    formData.append('LastName', values.LastName);
    formData.append('Email', values.Email);
    formData.append(
      'ProfilePic',
      values?.ProfilePic ? values?.ProfilePic : defaultImageUrl
    );
    formData.append('IsActive', values.IsActive);
    formData.append(
      'isEmailChanged',
      Boolean(values.Email !== initialValues.Email).toString()
    );

    const data = await callBackendApi('post', {
      url: urlRoutes.teacherDetailEdit,
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    if (data?.ResultCode === 'SUCCESS') {
      stopLoading();
      onCancel();
      fetch();

      setTimeout(() => {
        Swal.fire(data?.ResultMessage, ' ', 'success');
      }, 500);

      return;
    }

    if (data?.ResultCode === 'EXISTS') {
      Swal.fire(data?.ResultMessage, ' ', 'warning');
    }

    stopLoading();
    onCancel();
  };

  const disabled = loading;

  return (
    <BModal show={visible} size='lg' onHide={onCancel} centered>
      <BModal.Header closeButton>
        <BModal.Title>
          {' '}
          <i className='fa fa-duotone fa-pen-to-square me-2 fs-2'></i> Edit
          Teacher Details
        </BModal.Title>
      </BModal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
        }) => (
          <Form onSubmit={handleSubmit}>
            <BModal.Body className='scroll-y'>
              <Container fluid>
                <Row>
                  <Col xs={9}>
                    {/* First Name */}
                    <Form.Group className='mb-5'>
                      <Form.Label>
                        First name <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name='FirstName'
                        id='FirstName'
                        type='text'
                        isInvalid={
                          touched.FirstName && errors.FirstName !== undefined
                        }
                        value={values.FirstName}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        className={
                          errors.FirstName && touched.FirstName
                            ? 'border border-danger'
                            : ''
                        }
                        disabled={disabled}
                        required
                      />
                      {touched.FirstName && errors.FirstName && (
                        <Form.Text className='text-danger'>
                          {errors.FirstName}
                        </Form.Text>
                      )}
                    </Form.Group>

                    {/* Last Name */}
                    <Form.Group className='mb-5'>
                      <Form.Label>
                        Last name <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name='LastName'
                        id='LastName'
                        type='text'
                        isInvalid={
                          touched.LastName && errors.LastName !== undefined
                        }
                        value={values.LastName}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        disabled={disabled}
                        className={
                          errors.LastName && touched.LastName
                            ? 'border border-danger'
                            : ''
                        }
                      />
                      {touched.LastName && errors.LastName && (
                        <Form.Text className='text-danger'>
                          {errors.LastName}
                        </Form.Text>
                      )}
                    </Form.Group>

                    {/* Email */}
                    <Form.Group className='mb-5'>
                      <Form.Label>
                        Email address<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name='Email'
                        id='Email'
                        type='email'
                        isInvalid={touched.Email && errors.Email !== undefined}
                        value={values.Email}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        disabled={disabled}
                        className={
                          errors.Email && touched.Email
                            ? 'border border-danger'
                            : ''
                        }
                      />
                      {touched.Email && errors.Email && (
                        <Form.Text className='text-danger'>
                          {errors.Email}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group className='mb-5 mt-7' key='TeaIsActiveCheckbox'>
                      <Form.Check
                        type='checkbox'
                        id='StuIsActiveCheckbox'
                        label='Active?'
                        {...getFieldProps('IsActive')}
                        checked={values.IsActive}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs='auto' className='ms-5'>
                    <ImageUpload entityLabel='Teacher' name='ProfilePic' />
                  </Col>
                </Row>
              </Container>
            </BModal.Body>
            <BModal.Footer>
              <Button variant='primary' type='submit' disabled={disabled}>
                {loading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='secondary' onClick={onCancel}>
                Cancel
              </Button>
            </BModal.Footer>
          </Form>
        )}
      </Formik>
    </BModal>
  );
};

export default React.memo(DetailEditModal);
