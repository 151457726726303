import React from 'react';
import { Button, Modal as BModal } from 'react-bootstrap';

type Props = {
  deleteModel: boolean;
  handleDeleteRecord: any;
  handleCloseDeleteModel: any;
};
const EnrollmentDeleteConfirmationModel: React.FC<Props> = (props: any) => {
  const { deleteModel, handleDeleteRecord, handleCloseDeleteModel } = props;

  return (
    <BModal
      show={deleteModel}
      backdrop='static'
      keyboard={false}
      onHide={handleCloseDeleteModel}
      centered
    >
      <BModal.Header closeButton>
        <BModal.Title>Confirmation</BModal.Title>
      </BModal.Header>
      <BModal.Body>Are you sure you want to delete?</BModal.Body>
      <BModal.Footer>
        <Button variant='danger' type='button' onClick={handleDeleteRecord}>
          Yes
        </Button>
        <Button
          variant='secondary'
          type='button'
          onClick={handleCloseDeleteModel}
        >
          No
        </Button>
      </BModal.Footer>
    </BModal>
  );
};

export default EnrollmentDeleteConfirmationModel;
