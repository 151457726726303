import { useGetQuery } from 'hooks/reactQueryHelper';
import React, { useEffect } from 'react';
import CountUp from 'react-countup';

const CountInstantClass = () => {
  const getCountAllInstantClassData = useGetQuery(
    'getCountAllInstantClassData',
    '/dashboard/getCompletedInstantClassData'
  );

  useEffect(() => {
    getCountAllInstantClassData.mutate({});
  }, []);

  const dataOfTodays =
    getCountAllInstantClassData?.data?.ResultObject?.InstantClassData
      ?.count_todays_instantclass;
  const dataOfYesterdays =
    getCountAllInstantClassData?.data?.ResultObject?.InstantClassData
      ?.count_yesterday_instantclass;
  const dataOfLast7days =
    getCountAllInstantClassData?.data?.ResultObject?.InstantClassData
      ?.count_last7days_instantclass;
  const totalData =
    getCountAllInstantClassData?.data?.ResultObject?.InstantClassData
      ?.TotalCompletedInstantclass;
  console.log('getCountAllInstantClassData', getCountAllInstantClassData);

  return (
    <div className='col-md-3'>
      <div className='row g-5'>
        <span className='fw-bold' style={{ fontSize: '2rem' }}>
          Instant Class Overview
        </span>
        <div className='col-12'>
          <div
            className='card bg-light-primary border-1 border border-primary card-xl-stretch card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
            style={{
              backgroundImage: 'url("../media/patterns/vector-1.png")',
            }}
          >
            <div className='card-body'>
              <i
                className='fas fa-user-large text-primary'
                style={{ fontSize: '3rem' }}
              ></i>
              <div
                className='text-dark fw-bold mt-5'
                style={{ fontSize: '3rem' }}
              >
                +
                <CountUp
                  duration={1}
                  className='counter'
                  end={dataOfTodays ? dataOfTodays : 0}
                />
              </div>
              <div className='card-title fw-bold text-primary fs-5 d-block'>
                Completed Instant class on Today
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div
            className='card bg-light-dark border-1 border border-dark card-xl-stretch card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
            style={{
              backgroundImage: 'url("../media/patterns/vector-2.png")',
            }}
          >
            <div className='card-body'>
              <i className='fas fa-user-large' style={{ fontSize: '3rem' }}></i>
              <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                +
                <CountUp
                  duration={1}
                  className='counter'
                  end={dataOfYesterdays ? dataOfYesterdays : 0}
                />
              </div>
              <div className='card-title fw-bold fs-5 d-block text-muted'>
                Completed Instant class on Yesterday
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div
            className='card bg-light-success border-1 border border-success card-xl-stretch card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
            style={{
              backgroundImage: 'url("../media/patterns/vector-3.png")',
            }}
          >
            <div className='card-body'>
              <i
                className='fas fa-user-large text-success'
                style={{ fontSize: '3rem' }}
              ></i>
              <div
                className='text-dark fw-bold mt-5'
                style={{ fontSize: '3rem' }}
              >
                +
                <CountUp
                  duration={1}
                  className='counter'
                  end={dataOfLast7days ? dataOfLast7days : 0}
                />
              </div>
              <div className='card-title fw-bold text-success fs-5 d-block'>
                Completed Instant class on Last 7 Days
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div
            className='card bg-secondary border-1 border card-xl-stretch bgi-no-repeat'
            style={{
              backgroundPosition: 'right top',
              backgroundSize: '30%',
              backgroundImage: 'url("../media/svg/shapes/abstract-1.svg")',
            }}
          >
            <div className='card-body'>
              <i className='fas fa-user-large' style={{ fontSize: '3rem' }}></i>
              <div className='fw-bold mt-5' style={{ fontSize: '3rem' }}>
                +
                <CountUp
                  duration={1}
                  className='counter'
                  end={totalData ? totalData : 0}
                />
              </div>
              <div className='card-title fw-bold fs-5 d-block'>
                Total Completed Instant class
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountInstantClass;
