import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { getHighlightedSection } from '../activity/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { imageUpload } from 'store/actions';
import { connect, ConnectedProps } from 'react-redux';
import AiWriteItActivity from '../activity/ai-activity/ai-write-it-activity';
import AiPickWordActivity from '../activity/ai-activity/ai-pick-word-activity';
import AIShowMassageActivity from '../activity/ai-activity/ai-show-massage-activity';
import AIAddtionalTextActivity from '../activity/ai-activity/ai-additional-text-actitivty';

const AIActivitySection: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    getValues,
    setError,
    imageUploadReq,
    loading,
    imageloading,
    imageURL,
    lessonSingleData,
    nativeLang,
    targetLang,
  } = props;

  const [activeKey, setActiveKey] = useState('');

  const [AIActivityDetail, setAIActivityDetail] = useState('');

  const [backgroundImageSlide1, setBackgroundImageSlide1] = useState('');
  const [backgroundImageSlide2, setBackgroundImageSlide2] = useState('');

  const [imageValidationSlide1, setImageValidationSlide1] = useState(false);
  const [imageValidationSlide2, setImageValidationSlide2] = useState(false);

  const [imageLoaderSlide1, setImageLoaderSlide1] = useState(false);
  const [imageLoaderSlide2, setImageLoaderSlide2] = useState(false);

  useEffect(() => {
    if (lessonSingleData != '' && lessonSingleData != undefined) {
      setAIActivityDetail(
        lessonSingleData?.Activity_Details?.filter(
          (x: any) => x.Section == 'AISection'
        )[0]
      );
      const aiActivityData = lessonSingleData?.Activity_Details?.filter(
        (x: any) => x.Section == 'AISection'
      )[0];
      setTimeout(() => {
        setBackgroundImageSlide1(aiActivityData?.AI_Background_Image_Slide1);
        setBackgroundImageSlide2(aiActivityData?.AI_Background_Image_Slide2);
        setValue(
          'AI_Background_Image_Slide1',
          aiActivityData?.AI_Background_Image_Slide1
        );
        setValue(
          'AI_Background_Image_Slide2',
          aiActivityData?.AI_Background_Image_Slide2
        );
      }, 100);
    }
  }, [lessonSingleData]);

  useEffect(() => {
    const violation: any = document.getElementById('aiActivityComponents');
    window.scrollTo({
      top: violation.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (imageURL != '') {
      if (activeKey === 'AI_Background_Image_Slide1') {
        setBackgroundImageSlide1(imageURL);
        setValue('AI_Background_Image_Slide1', imageURL);
        setActiveKey('');
      }
      if (activeKey === 'AI_Background_Image_Slide2') {
        setBackgroundImageSlide2(imageURL);
        setValue('AI_Background_Image_Slide2', imageURL);
        setActiveKey('');
      }
    }
  }, [imageURL]);

  useEffect(() => {
    if (imageloading) {
      if (activeKey === 'AI_Background_Image_Slide1') {
        setImageLoaderSlide1(true);
      }
      if (activeKey === 'AI_Background_Image_Slide2') {
        setImageLoaderSlide2(true);
      }
    }

    if (!imageloading) {
      setImageLoaderSlide1(false);
      setImageLoaderSlide2(false);
    }
  }, [imageloading]);

  useEffect(() => {
    setBackgroundImageSlide1(backgroundImageSlide1);
    setValue('AI_Background_Image_Slide1', backgroundImageSlide1);
    setImageValidationSlide1(false);
  }, [backgroundImageSlide1]);

  useEffect(() => {
    setBackgroundImageSlide2(backgroundImageSlide2);
    setValue('AI_Background_Image_Slide2', backgroundImageSlide2);
    setImageValidationSlide2(false);
  }, [backgroundImageSlide2]);

  const handleChnageImage = (event: any) => {
    if (event.target.files[0].size <= 2097152) {
      setActiveKey(event.target.name);
      imageUploadReq({ file: event.target.files[0] });
    } else {
      if (event.target.name == 'AI_Background_Image_Slide1') {
        setImageValidationSlide1(true);
      }
      if (event.target.name == 'AI_Background_Image_Slide2') {
        setImageValidationSlide2(true);
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className='col-md-12 add-lesson mt-3 activity-main card-box'
        id='aiActivityComponents'
      >
        <Collapsible
          trigger={
            <div style={{ width: '100%', position: 'relative' }}>
              <h2>
                AI Activity{' '}
                <BsChevronDown style={{ position: 'absolute', right: '0' }} />
              </h2>
            </div>
          }
          open={true}
        >
          <div>
            <br />
            <Collapsible
              trigger={
                <div className='collapsible-head'>
                  <h4>
                    Slide 0
                    <BsChevronDown
                      style={{ position: 'absolute', right: '20px' }}
                      size={20}
                    />
                  </h4>
                </div>
              }
              // containerElementProps={getHighlightedSection(hash.one)}
              open={true}
            >
              <div className='border-top my-3'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='add-lesson-row-section'>
                      <div className='mt-3 col-md-12 form-group'>
                        <label className='fw-bold form-label'>
                          Upload Backgound Image{' '}
                          <span className='astrick'>*</span>
                        </label>
                        <div className='question-image'>
                          <LoadingOverlay
                            active={imageLoaderSlide1}
                            spinner
                            className='spinner-animation'
                          >
                            <img src={backgroundImageSlide1} />
                          </LoadingOverlay>
                        </div>
                        <div className='main-cercle-div'>
                          <input
                            type='file'
                            className='form-control'
                            name='AI_Background_Image_Slide1'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => handleChnageImage(e)}
                            disabled={loading}
                          />
                          {imageValidationSlide1 ? (
                            <p style={{ color: 'red' }}>
                              Less then 2MB | Only Image File Allowed - .jpg.png
                            </p>
                          ) : backgroundImageSlide1 == '' ? (
                            errors.AI_Background_Image_Slide1 && (
                              <p style={{ color: 'red' }}>
                                {errors.AI_Background_Image_Slide1.message}
                              </p>
                            )
                          ) : null}
                          {/* {errors.AI_Background_Image_Slide1 && <p style={{ color: "red" }}>{errors.AI_Background_Image_Slide1.message}</p>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>
            <br />
            <Collapsible
              trigger={
                <div className='collapsible-head'>
                  <h4>
                    Slide 1
                    <BsChevronDown
                      style={{ position: 'absolute', right: '20px' }}
                      size={20}
                    />
                  </h4>
                </div>
              }
              // containerElementProps={getHighlightedSection(hash.one)}
              open={true}
            >
              <div className='border-top my-3'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='add-lesson-row-section'>
                      <div className='mt-3 col-md-12 form-group'>
                        <label className='fw-bold form-label'>
                          Upload Backgound Image{' '}
                          <span className='astrick'>*</span>
                        </label>
                        <div className='question-image'>
                          <LoadingOverlay
                            active={imageLoaderSlide2}
                            spinner
                            className='spinner-animation'
                          >
                            <img src={backgroundImageSlide2} />
                          </LoadingOverlay>
                        </div>
                        <div className='main-cercle-div'>
                          <input
                            type='file'
                            className='form-control'
                            name='AI_Background_Image_Slide2'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => handleChnageImage(e)}
                            disabled={loading}
                          />
                          {imageValidationSlide2 ? (
                            <p style={{ color: 'red' }}>
                              Less then 2MB | Only Image File Allowed - .jpg.png
                            </p>
                          ) : backgroundImageSlide2 == '' ? (
                            errors.AI_Background_Image_Slide2 && (
                              <p style={{ color: 'red' }}>
                                {errors.AI_Background_Image_Slide2.message}
                              </p>
                            )
                          ) : null}
                          {/* {errors.AI_Background_Image_Slide2 && <p style={{ color: "red" }}>{errors.AI_Background_Image_Slide2.message}</p>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>
            <AiPickWordActivity
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              loading={loading}
              AIActivityDetail={AIActivityDetail}
              nativeLang={nativeLang}
              targetLang={targetLang}
            />
            <AiWriteItActivity
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              loading={loading}
              AIActivityDetail={AIActivityDetail}
              nativeLang={nativeLang}
              targetLang={targetLang}
            />
            <AIShowMassageActivity
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              loading={loading}
              AIActivityDetail={AIActivityDetail}
              nativeLang={nativeLang}
              targetLang={targetLang}
            />

            <AIAddtionalTextActivity
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              loading={loading}
              AIActivityDetail={AIActivityDetail}
              nativeLang={nativeLang}
              targetLang={targetLang}
            />
          </div>
        </Collapsible>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    imageURL: state.lesson.imageURL,
    imageloading: state.lesson.imageloading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  imageUploadReq: (param: any) => dispatch(imageUpload(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AIActivitySection);
