import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';
import Course from './Course';


const CourseWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Course</PageTitle>
      <Course />
    </>
  );
};

export default CourseWrapper;
