import Select from 'react-select';
import { Card, Col, InputGroup, Row } from 'react-bootstrap';
import { Form as BForm } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { useFormikContext, FastField } from 'formik';

const InstantLiveClassContentGradeEarnedAdd = (props: any) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  }: any = useFormikContext();

  const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];
  function validate(evt: any) {
    var theEvent = evt || window.event;
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  return (
    <Card className='border bg-light p-0'>
      <Card.Header
        style={{ minHeight: '50px' }}
        className='align-items-center bg-secondary bg-gradient'
      >
        <b>Grade Requirements</b>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <BForm.Group className='mb-3' controlId='time'>
              <BForm.Label>
                Time :<span style={{ color: 'red' }}>*</span>
              </BForm.Label>
              <FastField
                name='formEarnedGrade.time'
                render={({ field }: any) => (
                  <MaskedInput
                    {...field}
                    onKeyPress={validate}
                    mask={timeMask}
                    value={values?.formEarnedGrade?.time}
                    placeholder='Enter time'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      errors?.formEarnedGrade?.time &&
                      touched?.formEarnedGrade?.time
                        ? 'border border-danger'
                        : ''
                    }`}
                  />
                )}
              />

              {errors?.formEarnedGrade?.time &&
              touched?.formEarnedGrade?.time ? (
                <div className='text-danger'>
                  {errors?.formEarnedGrade?.time}
                </div>
              ) : null}
            </BForm.Group>
          </Col>
          <Col md={6}>
            <BForm.Group className='mb-3' controlId='classRoomEvent'>
              <BForm.Label>
                Event : <span style={{ color: 'red' }}>*</span>
              </BForm.Label>
              <FastField
                name='formEarnedGrade.classRoomEvent'
                isMulti={false}
                component={Select}
                options={[
                  { value: 'camera', label: 'Camera' },
                  { value: 'chat', label: 'Chat' },
                ]}
                placeholder='Select an option'
                isClearable={true}
                classNamePrefix='select'
                onChange={(option: any) => {
                  if (values.classRoomEvent === 'camera') {
                    setFieldValue('formEarnedGrade.message', '');
                  }
                  setFieldValue('formEarnedGrade.classRoomEvent', option);
                }}
                onBlur={handleBlur}
                value={values?.formEarnedGrade?.classRoomEvent}
                styles={{
                  control: (baseStyles: any, state: any) => {
                    return {
                      ...baseStyles,
                      borderColor: state.isFocused
                        ? errors?.formEarnedGrade?.classRoomEvent &&
                          touched?.formEarnedGrade?.classRoomEvent
                          ? '#f1416c'
                          : 'grey'
                        : errors?.formEarnedGrade?.classRoomEvent &&
                          touched?.formEarnedGrade?.classRoomEvent
                        ? '#f1416c'
                        : '#b5b5c3',
                      boxShadow: '#b5b5c3',
                      '&:hover': {
                        borderColor:
                          errors?.formEarnedGrade?.classRoomEvent &&
                          touched?.formEarnedGrade?.classRoomEvent
                            ? '#f1416c'
                            : 'none',
                      },
                      '&:focus': {
                        borderColor:
                          errors?.formEarnedGrade?.classRoomEvent &&
                          touched?.formEarnedGrade?.classRoomEvent
                            ? '#f1416c'
                            : 'none',
                      },
                    };
                  },
                }}
              />
              {errors?.formEarnedGrade?.classRoomEvent &&
              touched?.formEarnedGrade?.classRoomEvent ? (
                <div className='text-danger'>
                  {errors?.formEarnedGrade?.classRoomEvent}
                </div>
              ) : null}
            </BForm.Group>
          </Col>
          {values?.formEarnedGrade?.classRoomEvent?.value == 'chat' && (
            <Col md={6}>
              <BForm.Group className='mb-3' controlId='message'>
                <BForm.Label>
                  Message:<span style={{ color: 'red' }}>*</span>
                </BForm.Label>
                <FastField
                  type='text'
                  name='formEarnedGrade.message'
                  placeholder='Enter message'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.formEarnedGrade?.message}
                  className={`form-control ${
                    errors?.formEarnedGrade?.message &&
                    touched?.formEarnedGrade?.message
                      ? 'border border-danger'
                      : ''
                  }`}
                />
                {errors?.formEarnedGrade?.message &&
                touched?.formEarnedGrade?.message ? (
                  <div className='text-danger'>
                    {errors?.formEarnedGrade?.message}
                  </div>
                ) : null}
              </BForm.Group>
            </Col>
          )}
          <Col md='6'>
            <BForm.Group className='mb-3' controlId='earnPoint'>
              <BForm.Label>
                Grade Earned (%):
                <span style={{ color: 'red' }}>*</span>
              </BForm.Label>

              <InputGroup className='mb-3'>
                <FastField
                  readOnly
                  disabled
                  type='text'
                  name='formEarnedGrade.earnPoint'
                  placeholder='Enter Percentage'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.formEarnedGrade?.earnPoint}
                  className={`form-control ${
                    errors?.formEarnedGrade?.earnPoint &&
                    touched?.formEarnedGrade?.earnPoint
                      ? 'border border-danger'
                      : ''
                  }`}
                />
                <InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
              </InputGroup>
              {errors?.formEarnedGrade?.earnPoint &&
                touched?.formEarnedGrade?.earnPoint && (
                  <div className='text-danger'>
                    {errors?.formEarnedGrade?.earnPoint}
                  </div>
                )}
            </BForm.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InstantLiveClassContentGradeEarnedAdd;
