import {
  GET_ALL_LANGUAGE,
  GET_ALL_LANGUAGE_SUCCESS,
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_FAILURE,
  IMAGE_UPLOAD_SUCCESS,
  LESSON_DELETE,
  LESSON_DELETE_SUCCESS,
  LESSON_FAILURE,
  LESSON_GET,
  LESSON_GET_BY_ID,
  LESSON_GET_BY_ID_SUCCESS,
  LESSON_GET_LANGUAGE,
  LESSON_GET_LANGUAGE_SUCCESS,
  LESSON_GET_SUCCESS,
  LESSON_SAVE,
  LESSON_SAVE_SUCCESS,
  LESSON_UPDATE,
  LESSON_UPDATE_SUCCESS,
  UPDATE_LANGUAGE_ACTIVE_STATUS,
  UPDATE_LANGUAGE_ACTIVE_STATUS_SUCCESS,
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_FAILURE,
  UPLOAD_VIDEO_SUCCESS,
  LEVEL_GET_ALL,
  LEVEL_GET_SUCCESS,
  LEVEL_FAILURE,
  LESSON_DETAILS_CLEAR,
  LESSON_ID_EXIST_OR_NOT,
  LESSON_ID_EXIST_OR_NOT_SUCCESS,
  LESSON_UPDATE_MULTIPLE_LANGUAGE,
  LESSON_UPDATE_MULTIPLE_LANGUAGE_SUCCESS
} from "./actionTypes"

export const lessonGet = (payload: any) => ({
  type: LESSON_GET,
  payload
});

export const lessonGetSuccess = (payload: any) => ({
  type: LESSON_GET_SUCCESS,
  payload,
});

export const levelGet = (payload: any) => ({
  type: LEVEL_GET_ALL,
  payload
})

export const levelGetSuccess = (payload: any) => ({
  type: LEVEL_GET_SUCCESS,
  payload,
});

export const lessonSave = (payload: any) => ({
  type: LESSON_SAVE,
  payload,
});

export const lessonSaveSuccess = (payload: any) => ({
  type: LESSON_SAVE_SUCCESS,
  payload,
});

export const imageUpload = (payload: any) => ({
  type: IMAGE_UPLOAD,
  payload,
});

export const imageUploadSuccess = (payload: any) => ({
  type: IMAGE_UPLOAD_SUCCESS,
  payload,
});

export const imageUploadFailure = (payload: any) => ({
  type: IMAGE_UPLOAD_FAILURE,
  payload,
});

export const allLanguageGet = (payload: any) => ({
  type: GET_ALL_LANGUAGE,
  payload,
});

export const allLanguageGetSuccess = (payload: any) => ({
  type: GET_ALL_LANGUAGE_SUCCESS,
  payload,
});

export const updateLanguageActiveStatus = (payload: any) => ({
  type: UPDATE_LANGUAGE_ACTIVE_STATUS,
  payload,
});

export const updateLanguageActiveStatusSuccess = (payload: any) => ({
  type: UPDATE_LANGUAGE_ACTIVE_STATUS_SUCCESS,
  payload,
});

export const lessonLanguageGet = () => ({
  type: LESSON_GET_LANGUAGE,
});

export const lessonLanguageGetSuccess = (payload: any) => ({
  type: LESSON_GET_LANGUAGE_SUCCESS,
  payload,
});


export const lessonDelete = (payload: any) => ({
  type: LESSON_DELETE,
  payload
})

export const lessonDeleteSuccess = (payload: any) => ({
  type: LESSON_DELETE_SUCCESS,
  payload
})

export const lessonGetById = (payload: any) => ({
  type: LESSON_GET_BY_ID,
  payload
})

export const lessonGetByIdSuccess = (payload: any) => ({
  type: LESSON_GET_BY_ID_SUCCESS,
  payload
})

export const lessonUpdate = (payload: any) => ({
  type: LESSON_UPDATE,
  payload
})

export const lessonUpdateSuccess = (payload: any) => ({
  type: LESSON_UPDATE_SUCCESS,
  payload
})


export const lessonFailure = (payload: any) => ({
  type: LESSON_FAILURE,
  payload,
});

export const levelFailure = (payload: any) => ({
  type: LEVEL_FAILURE,
  payload,
});

export const videoUpload = (payload: any) => ({
  type: UPLOAD_VIDEO,
  payload,
});

export const videoUploadSuccess = (payload: any) => ({
  type: UPLOAD_VIDEO_SUCCESS,
  payload,
});

export const videoUploadFailure = (payload: any) => ({
  type: UPLOAD_VIDEO_FAILURE,
  payload,
});


export const lessonDetailsClear = () => ({
  type: LESSON_DETAILS_CLEAR,
});


export const lessonIDExistorNot = (payload: any) => ({
  type: LESSON_ID_EXIST_OR_NOT,
  payload
})

export const lessonIDExistorNotSuccess = (payload: any) => ({
  type: LESSON_ID_EXIST_OR_NOT_SUCCESS,
  payload
})


export const lessonUpdateMultipleLanguage = (payload: any) => ({
  type: LESSON_UPDATE_MULTIPLE_LANGUAGE,
  payload
})

export const lessonUpdateMultipleLanguageSuccess = (payload: any) => ({
  type: LESSON_UPDATE_MULTIPLE_LANGUAGE_SUCCESS,
  payload
})
