/* eslint-disable prettier/prettier */
import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form as BForm,
  Modal as BModal,
  Row,
  Spinner,
} from 'react-bootstrap';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { axiosApi } from 'helpers/api-helper';
// eslint-disable-next-line import/no-unresolved
import generateBirthYears from 'hooks/useGenerateBirthYears';

import { toAbsoluteUrl } from '../../../theme/helpers';
import useLoadingState from '../../../hooks/useLoadingState';
import { urlRoutes } from '../../../shared/apiConfig';
import callBackendApi from '../../../utils/callBackendApi';
import ImageUpload from '../ImageUpload';
import 'react-multi-date-picker/styles/layouts/mobile.css';

type PropsType = {
  initialValue: any;
  visible: boolean;
  onCancel: () => void;
  fetch: (...args: any[]) => void;
};

const validationSchema = Yup.object({
  ID: Yup.string(),
  FirstName: Yup.string().required('Please enter first name.'),
  LastName: Yup.string().required('Please enter last name.'),
  Email: Yup.string()
    .required('Please enter email.')
    .email('Please enter valid email address.'),
  BirthYear: Yup.string().required('Please select Year of Birth.'),
  // Level: Yup.string().required('Please enter Level.'),
  // Target_Language: Yup.string()
  //   .required('Please select target language.')
  //   .notOneOf(
  //     [Yup.ref('Native_Language')],
  //     'Target language and Native language should not be same.'
  //   ),
  Native_Language: Yup.string()
    .required('Please select native language.')
    .notOneOf(
      [Yup.ref('Target_Language')],
      'Target language and Native language should not be same.'
    ),
  Country: Yup.string().required('Please enter Country.'),
  PerfilPic: Yup.mixed()
    .nullable()
    .test('fileFormat', 'Please select valid image ', (value) => {
      if (value && value.type) {
        return (
          value &&
          ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
            value.type
          )
        );
      } else {
        return true;
      }
    }),
});

const DetailEditModal: React.FC<React.PropsWithChildren<PropsType>> = (
  props
) => {
  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');
  const {
    initialValue: i,
    visible,
    onCancel = () => null,
    fetch = () => null,
  } = props;

  const initialValues = {
    studentId: (i?._id || '') as string,
    StudentProfileID: i?.Student_Profile_ID || null,
    Messenger_ID: i?.Messenger_ID || null,
    Instagram_ID: i?.Instagram_ID || null,
    WhatsApp_ID: i?.WhatsApp_ID || null,
    // Target_Language: (i?.Target_Language || '') as string,
    Native_Language: (i?.Native_Language || '') as string,
    Country: (i?.Country || '') as string,
    // Level: (i?.Level || '') as string,
    BirthYear: (i?.BirthYear || '') as string,
    userId: (i?.user?._id || '') as string,
    FirstName: (i?.user?.FirstName || '') as string,
    LastName: (i?.user?.LastName || '') as string,
    Email: (i?.user?.Email || '') as string,
    ProfilePic: i?.user?.ProfilePic ? i?.user?.ProfilePic : defaultImageUrl,
    IsActive: i?.user?.IsActive as boolean,
  };
  const { loading, startLoading, stopLoading } = useLoadingState({
    loading: false,
  });
  // const [existsError, setExistsError] = useState('');
  const _handleSubmit = async (values: any, action: any) => {
    startLoading();
    const formData = new FormData();
    formData.append('studentId', values.studentId);
    formData.append('StudentId', values.StudentProfileID);
    // formData.append('Target_Language', values.Target_Language);
    formData.append('Native_Language', values.Native_Language);
    formData.append('Country', values.Country);
    // formData.append('Level', values.Level);
    formData.append('BirthYear', values.BirthYear);
    formData.append('userId', values.userId);
    formData.append('FirstName', values.FirstName);
    formData.append('LastName', values.LastName);
    formData.append('Email', values.Email);
    formData.append('ProfilePic', values?.ProfilePic ? values?.ProfilePic : '');
    formData.append('IsActive', values.IsActive);
    formData.append(
      'isEmailChanged',
      Boolean(values.Email !== initialValues.Email).toString()
    );

    const data = await callBackendApi('post', {
      url: urlRoutes.studentDetailEdit,
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    if (data?.ResultCode === 'SUCCESS') {
      fetch();
      stopLoading();
      onCancel();
      setTimeout(() => {
        Swal.fire(data?.ResultMessage, ' ', 'success');
      }, 1000);

      return;
    }

    if (data?.ResultCode === 'EXISTS') {
      Swal.fire(data?.ResultMessage, ' ', 'warning');
    }

    stopLoading();
    onCancel();
  };
  const disabled = loading;

  const result = useQuery('fetchLang', () => {
    return axiosApi.post(urlRoutes.language);
  });
  const targetLanguage = result?.data?.data;
  const languageList = targetLanguage?.ResultObject?.data;

  const CountryData = useQuery('fetchCountryData', () => {
    return axiosApi.get(urlRoutes.country);
  });
  const contries = CountryData?.data?.data;
  const countryList = contries?.ResultObject;

  const levelData = useQuery('fetchLevelData', () => {
    return axiosApi.get(urlRoutes.level);
  });
  const levelList = levelData?.data?.data;

  return (
    <BModal
      show={visible}
      size='xl'
      onHide={onCancel}
      backdrop='static'
      keyboard={false}
      centered
    >
      <BModal.Header closeButton>
        <BModal.Title>
          <i className='fa fa-duotone fa-pen-to-square me-2 fs-2'></i> Edit
          Student Details
        </BModal.Title>
      </BModal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <BModal.Body className='scroll-y'>
              <Container fluid>
                <Row className='border-bottom mb-5 pb-5'>
                  <Col xs={10}>
                    <Row>
                      <Col md={3}>
                        <BForm.Group className='mb-5'>
                          <BForm.Label>Student ID#</BForm.Label>
                          <BForm.Control
                            name='StudentProfileID'
                            id='StudentProfileID'
                            value={values.StudentProfileID}
                            disabled
                          />
                        </BForm.Group>
                      </Col>
                      {/* <Col md={3}>
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Target language
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='Target_Language'
                            id='Target_Language'
                            as='select'
                            value={values.Target_Language}
                            onChange={handleChange}
                            className={
                              errors.Target_Language && touched.Target_Language
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          >
                            <option key={''} value={''}>
                              {'Select'}
                            </option>
                            {languageList?.map((option: any, index: any) => (
                              <option key={index} value={option._id}>
                                {option.Language_Name}
                              </option>
                            ))}
                          </BForm.Control>
                          {touched.Target_Language &&
                            errors.Target_Language && (
                              <BForm.Text className='text-danger'>
                                {errors.Target_Language}
                              </BForm.Text>
                            )}
                        </BForm.Group>
                      </Col> */}
                      <Col md={3}>
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Native language
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='Native_Language'
                            id='Native_Language'
                            as='select'
                            type='Native_Language'
                            value={values.Native_Language}
                            onChange={handleChange}
                            className={
                              errors.Native_Language && touched.Native_Language
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          >
                            <option key={''} value={''}>
                              {'Select'}
                            </option>
                            {languageList?.map((option: any, index: any) => (
                              <option key={index} value={option._id}>
                                {option.Language_Name}
                              </option>
                            ))}
                          </BForm.Control>
                          {touched.Native_Language &&
                            errors.Native_Language && (
                              <BForm.Text className='text-danger'>
                                {errors.Native_Language}
                              </BForm.Text>
                            )}
                        </BForm.Group>
                      </Col>
                      <Col md={3}>
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Country<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='Country'
                            id='Country'
                            as='select'
                            type='Country'
                            value={values.Country}
                            onChange={handleChange}
                            className={
                              errors.Country && touched.Country
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          >
                            <option key={''} value={''}>
                              {'Select'}
                            </option>
                            {countryList?.map((option: any, index: any) => (
                              <option key={index} value={option._id}>
                                {option.Country_Name}
                              </option>
                            ))}
                          </BForm.Control>
                          {touched.Country && errors.Country && (
                            <BForm.Text className='text-danger'>
                              {errors.Country}
                            </BForm.Text>
                          )}
                        </BForm.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        {/* First Name */}
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            First name<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='FirstName'
                            id='FirstName'
                            type='text'
                            value={values.FirstName}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            className={
                              errors.FirstName && touched.FirstName
                                ? 'border border-danger'
                                : 'focus'
                            }
                            autoFocus
                          />
                          {touched.FirstName && errors.FirstName && (
                            <BForm.Text className='text-danger'>
                              {errors.FirstName}
                            </BForm.Text>
                          )}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        {/* Last Name */}
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Last name<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='LastName'
                            id='LastName'
                            type='text'
                            value={values.LastName}
                            onChange={handleChange}
                            className={
                              errors.LastName && touched.LastName
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          />
                          {touched.LastName && errors.LastName && (
                            <BForm.Text className='text-danger'>
                              {errors.LastName}
                            </BForm.Text>
                          )}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        {/* Email */}
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Email address<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='Email'
                            id='Email'
                            type='email'
                            value={values.Email}
                            onChange={handleChange}
                            placeholder='Enter Email Address'
                            className={
                              errors.Email && touched.Email
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          />
                          {touched.Email && errors.Email && (
                            <BForm.Text className='text-danger'>
                              {errors.Email}
                            </BForm.Text>
                          )}
                        </BForm.Group>
                      </Col>

                      <Col md={3}>
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Birth year<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='BirthYear'
                            id='BirthYear'
                            as='select'
                            value={values.BirthYear}
                            onChange={handleChange}
                            className={
                              errors.BirthYear && touched.BirthYear
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          >
                            <option key={''} value={''}>
                              {'Select'}
                            </option>
                            {generateBirthYears()?.map(
                              (val: any, index: any) => (
                                <option key={index} value={val}>
                                  {val}
                                </option>
                              )
                            )}
                          </BForm.Control>
                          {touched.BirthYear && errors.BirthYear && (
                            <BForm.Text className='text-danger'>
                              {errors.BirthYear}
                            </BForm.Text>
                          )}
                        </BForm.Group>
                      </Col>
                      {/* <Col md={3}>
                        <BForm.Group className='mb-5'>
                          <BForm.Label>
                            Level<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            name='Level'
                            id='Level'
                            as='select'
                            value={values.Level}
                            onChange={handleChange}
                            className={
                              errors.Level && touched.Level
                                ? 'border border-danger'
                                : ''
                            }
                            disabled={disabled}
                          >
                            <option key={''} value={''}>
                              {'Select'}
                            </option>
                            {levelList?.map((option: any, index: any) => (
                              <option key={index} value={option._id}>
                                {option.Level_Name}
                              </option>
                            ))}
                          </BForm.Control>
                          {touched.Level && errors.Level && (
                            <BForm.Text className='text-danger'>
                              {errors.Level}
                            </BForm.Text>
                          )}
                        </BForm.Group>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col xs={2}>
                    <Row>
                      <Col>
                        <div className='w-125px mx-auto'>
                          {
                            <ImageUpload
                              entityLabel='Student'
                              name='ProfilePic'
                            />
                          }
                        </div>
                      </Col>
                      <Col>
                        <BForm.Group
                          className='mb-5 mt-10 w-50 text-center mx-auto'
                          key='StuIsActiveCheckbox'
                        >
                          <BForm.Check
                            type='checkbox'
                            id='StuIsActiveCheckbox'
                            label='Active?'
                            {...getFieldProps('IsActive')}
                            checked={values.IsActive}
                          />
                        </BForm.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className='py-3'>
                      <i className='fa fa-duotone fa-share-nodes me-2 fs-3'></i>{' '}
                      Social Channels
                    </h3>
                    <ul className='list-group list-group-horizontal'>
                      <li className='list-group-item flex-fill'>
                        {' '}
                        <BForm.Group className='d-flex align-items-center justify-content-center'>
                          <BForm.Label className='mt-2 flex-fill'>
                            Messenger : &nbsp;
                          </BForm.Label>
                          <BForm.Label
                            className={
                              values.Messenger_ID
                                ? 'mt-2 badge badge-success'
                                : 'mt-2 badge badge-danger'
                            }
                          >
                            {values.Messenger_ID ? 'Yes' : 'No'}
                          </BForm.Label>
                        </BForm.Group>
                      </li>
                      <li className='list-group-item flex-fill'>
                        {' '}
                        <BForm.Group className='d-flex align-items-center justify-content-center'>
                          <BForm.Label className='mt-2 flex-fill'>
                            Instagram : &nbsp;
                          </BForm.Label>
                          <BForm.Label
                            className={
                              values.Instagram_ID
                                ? 'mt-2 badge badge-success'
                                : 'mt-2 badge badge-danger'
                            }
                          >
                            {values.Instagram_ID ? 'Yes' : 'No'}
                          </BForm.Label>
                        </BForm.Group>
                      </li>
                      <li className='list-group-item flex-fill'>
                        {' '}
                        <BForm.Group className='d-flex align-items-center justify-content-center'>
                          <BForm.Label className='mt-2 flex-fill'>
                            Whatsapp : &nbsp;
                          </BForm.Label>
                          <BForm.Label
                            className={
                              values.WhatsApp_ID
                                ? 'mt-2 badge badge-success'
                                : 'mt-2 badge badge-danger'
                            }
                          >
                            {values.WhatsApp_ID ? 'Yes' : 'No'}
                          </BForm.Label>
                        </BForm.Group>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </BModal.Body>
            <BModal.Footer>
              <Button variant='primary' type='submit' disabled={disabled}>
                {loading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='secondary' onClick={onCancel}>
                Cancel
              </Button>
            </BModal.Footer>
          </Form>
        )}
      </Formik>
    </BModal>
  );
};

export default React.memo(DetailEditModal);
