import React, { useState, useEffect } from 'react';
import { BiPlus } from "react-icons/bi";

const AddSection: React.FC<any> = (props: any) => {
  const {
    handleVocabulary,
    handleQuiz,
    handleConversation,
    handleGrammar,
    handleAIactivity,
    aiActivityComponents,
    quizComponents,
  } = props;

  return (
    <>
      <div className="col-md-4">
        <div className="add-lesson slidebar-sticky mt-3">
          <h4>All Activity</h4>
          <div className="border-top my-4"></div>
          <div className="add-lesson-row-section">
            <div className="col-md-12 mt-3 form-group">
              <label className='fw-bold'>Vocabulary</label>
              <p>The sentence they learn.</p>
              <div className='add-section-button'>
                <button
                  type="button"
                  onClick={handleVocabulary}
                >
                  <BiPlus style={{ marginBottom: "2px" }} />
                  Add Activity
                </button></div>
            </div>
          </div>
          {/* <div className="add-lesson-row-section">
                  <div className="col-md-12 mt-3 form-group">
                    <label className='fw-bold'>Conversation</label>
                    <p>The sentence they learn.</p>
                    <div className='add-section-button'>
                      <button
                        type="button"
                        onClick={handleConversation}
                      >
                        <BiPlus style={{ marginBottom: "2px" }} />
                        Add Activity
                      </button>
                    </div>
                  </div>
                </div> */}
          {/* Do not change this code */}
          {/* <div className="add-lesson-row-section">
                  <div className="col-md-12 mt-3 form-group">
                    <label className='fw-bold'>Grammar</label>
                    <p>The sentence they learn.</p>
                    <div className='add-section-button'>
                      <button
                        type="button"
                        onClick={handleGrammar}
                      >
                        <BiPlus style={{ marginBottom: "2px" }} />
                        Add Activity
                      </button>
                    </div>
                  </div>
                </div> */}
          {!aiActivityComponents && <div className="add-lesson-row-section">
            <div className="col-md-12 mt-3 form-group">
              <label className='fw-bold'>Quiz</label>
              <p>The sentence they learn.</p>
              <div className='add-section-button'>
                <button
                  type="button"
                  onClick={handleQuiz}
                >
                  <BiPlus style={{ marginBottom: "2px" }} />
                  Add Activity
                </button>
              </div>
            </div>
          </div>}

          {!quizComponents && <div className="add-lesson-row-section">
            <div className="col-md-12 mt-3 form-group">
              <label className='fw-bold'>AI Activity</label>
              <p>The sentence they learn.</p>
              <div className='add-section-button'>
                <button
                  type="button"
                  onClick={handleAIactivity}
                >
                  <BiPlus style={{ marginBottom: "2px" }} />
                  Add Activity
                </button>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}


export default AddSection;
