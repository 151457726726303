import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { aiActivityImageGet, aiActivityImageRemove } from "store/actions";
import useTableState from "app/components/Table/useTableState";
import Swal from "sweetalert2";
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from "app/components/Table/TablePagination";
import { CellProps, Column } from "react-table";
import Table from "../../components/Table";
import TableInner from "app/components/Table/TableInner";
import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';
import { Link } from "react-router-dom";
import { BsArchiveFill } from "react-icons/bs";
import { RiArchiveLine, RiArchiveFill, RiFileCopy2Line } from "react-icons/ri";


const AIActivityImagePage: React.FC = (props: any) => {
    const { AIActivityImageList, success, loading, error, onGetAll, onRemove } = props;

    const [data, setData] = useState([]);

    const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({});
    const { sort, page, limit, search } = tableState;
    const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');

    useEffect(() => {
        onGetAll({
            page: page,
            limit: limit,
            search: search,
            sort: sort,
        });
    }, [page, limit, sort, search])

    useEffect(() => {
        if (AIActivityImageList?.ResultCode === "SUCCESS") {
            setData(AIActivityImageList?.ResultObject?.data || []);
        }
    }, [AIActivityImageList]);

    useEffect(() => {
        if (success.ResultCode == "SUCCESS") {
            onGetAll({
                page: page,
                limit: limit,
                search: search,
                sort: sort,
            });
            Swal.fire(success.ResultMessage, ' ', 'success');
        }
        if (success.ResultCode == "ERROR") {
            Swal.fire(success.ResultMessage, ' ', 'warning');
        }
    }, [success])

    const onRemoveRecord = (id: any) => {
        onRemove({ ID: id })
    }


    const columns = React.useMemo<Column<any>[]>(
        () => [
            {
                Header: () => (
                    <span className="ps-4 rounded-start justify-content-start">
                        AI Image URL
                    </span>
                ),
                accessor: "AI_Activity_Image",
                Cell: (props: CellProps<any>) => {
                    return (
                        <div className="d-flex">
                            <a
                                className="text-dark fw-bold d-block fs-6 text-hover-primary" style={{ maxWidth: "400px" }}>
                                {props?.row?.original?.AI_Activity_Image}
                            </a>
                            <div className="d-flex align-items-center p-5">
                                <CopyButton text={`/imagine ${props?.row?.original?.AI_Activity_Image} as a ${props?.row?.original?.Sentance?.split("||").join(" ")}`} />
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <span className="ps-4 min-w-325px rounded-start justify-content-start">
                        Full Name
                    </span>
                ),
                accessor: "FullName",
                Cell: (props: CellProps<any>) => {
                    return (
                        <span className="text-dark fw-bold d-block fs-6">
                            {props?.row?.original?.FullName}
                        </span>
                    );
                },
            },
            {
                Header: () => (
                    <span className="ps-4 min-w-325px rounded-start justify-content-start" >
                        Sentance
                    </span>
                ),
                accessor: "Sentance",
                Cell: (props: CellProps<any>) => {
                    return (

                        props?.row?.original?.Sentance?.split("||").map((data: any, index: any) => {
                            return (
                                <>
                                    <span className="text-dark fw-bold d-block fs-6" style={{ maxWidth: "325px" }}>
                                        sentance {index + 1} :-  {data}
                                    </span>
                                </>
                            )
                        })


                    );
                },
            },
            {
                Header: () => (
                    <span className='pe-4 d-block text-end rounded-end'>Actions</span>
                ),
                accessor: 'actions',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const result = props.row.original;
                    return (
                        <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                                className='btn btn-icon btn-dark btn-sm'
                                title='remove'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                                onClick={() => {
                                    onRemoveRecord(result?._id)
                                }}
                                style={{ cursor: "pointer !important" }}
                            >
                                {/* {props?.row?.original?.Archive ? <RiArchiveFill size={19} /> : <RiArchiveLine size={19} />} */}
                                <RiArchiveFill size={19} />
                            </button>
                        </div>
                    );
                },
            },
        ],
        []
    );


    const CopyButton = (text: any) => {
        const handleCopy = () => {
            navigator.clipboard.writeText(text?.text);
        };

        return (
            <button onClick={handleCopy} className='btn btn-icon btn-secondary btn-sm'><RiFileCopy2Line size={19} /></button>
        );
    };

    return (
        <>
            <div className="card mb-10">
                <Loader loading={loading} />
                <Table
                    data={data}
                    columns={columns}
                    initialState={tableInitialState}
                    pageCount={AIActivityImageList?.ResultObject?.totalPages ?? -1}
                    onTableStateChange={handleTableStateChange}
                >
                    <React.Fragment>
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <div className="card-label fw-bold fs-3 mb-1">
                                    AI Activity Image Data
                                </div>{" "}
                            </h3>
                            <div className="d-flex align-items-center">
                                <div className='me-2'>
                                    <TableGlobalSearch />
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-3">
                            <div className="row">
                                <div className="col-12 mb-5">
                                    <TableInner
                                        loading={loading}
                                        entityLabel="language"
                                    />
                                </div>
                                <div className='col-12 border-top pt-5'></div>
                                <TablePagination />
                            </div>
                        </div>
                    </React.Fragment>
                </Table>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        success: state.AIActivityImage.success,
        loading: state.AIActivityImage.loading,
        error: state.AIActivityImage.error,
        AIActivityImageList: state.AIActivityImage.AIActivityImageList,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onGetAll: (param: any) => dispatch(aiActivityImageGet(param)),
    onRemove: (param: any) => dispatch(aiActivityImageRemove(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AIActivityImagePage);