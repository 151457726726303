import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Form as BForm,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import Language from 'store/language/reducer';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  addLanguage,
  updateLanguage,
  getByIdLanguage,
  clearLanguage,
} from 'store/actions';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import Swal from 'sweetalert2';

type Props = {
  addEditModel: object;
  handleCloseAddEditModel: any;
};

const validationSchema = Yup.object({
  name: Yup.string().required('Please enter language name'),
  native_name: Yup.string().required('Please enter native language name'),
  code: Yup.string().required('Please enter code'),
  voice_name: Yup.string().required('Please enter voice name'),
  voice_gender: Yup.string().required('Please enter voice gender'),
  language_full_code: Yup.string().required('Please enter full code'),
});

const LanguageAddEditModel: React.FC<Props> = (props: any) => {
  let initialValues = {
    name: '',
    native_name: '',
    code: '',
    voice_name: '',
    voice_gender: '',
    language_full_code: '',
  };

  const {
    addEditModel,
    handleCloseAddEditModel,

    getLoading,
    getSuccess,
    getError,

    upsertLoading,
    upsertSuccess,
    upsertError,

    onAdd,
    onUpdate,
    onGetById,

    onClear,
  } = props;
  const [show, setShow] = useState(addEditModel.show);

  useEffect(() => {
    return () => {
      onClear();
    };
  }, []);

  useEffect(() => {
    setShow(addEditModel.show);
  }, [addEditModel.show]);

  const handleClose = () => {
    setShow(false);
    handleCloseAddEditModel({
      refresh: false,
    });
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (addEditModel?.id) {
      onGetById({
        _id: addEditModel?.id,
      });
    }
  }, [addEditModel]);

  const _handleSubmit = async (values: any, actions: any) => {
    if (addEditModel.id === null) {
      const formData = {
        Language_Name: values.name,
        Language_Name_Native: values.native_name,
        Language_Code: values.code,
        Voice_Name: values.voice_name,
        Voice_Gender: values.voice_gender,
        Language_Code_Full: values.language_full_code,
      };
      onAdd(formData);
    } else {
      const formData = {
        _id: addEditModel.id,
        Language_Name: values.name,
        Language_Name_Native: values.native_name,
        Language_Code: values.code,
        Voice_Name: values.voice_name,
        Voice_Gender: values.voice_gender,
        Language_Code_Full: values.language_full_code,
      };
      onUpdate(formData);
    }
  };

  useEffect(() => {
    if (upsertSuccess?.ResultCode === 'SUCCESS') {
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: upsertSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (upsertSuccess?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: upsertSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (upsertError?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: upsertError?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  }, [upsertSuccess, upsertError]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered backdrop={true}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={true}
          validateOnBlur={addEditModel?.id ? true : false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            useEffect(() => {
              if (addEditModel?.id && getSuccess?.ResultCode === 'SUCCESS') {
                setFieldValue('name', getSuccess?.ResultObject?.Language_Name);
                setFieldValue(
                  'native_name',
                  getSuccess?.ResultObject?.Language_Name_Native
                );
                setFieldValue('code', getSuccess?.ResultObject?.Language_Code);
                setFieldValue(
                  'voice_gender',
                  getSuccess?.ResultObject?.Voice_Gender
                );
                setFieldValue(
                  'voice_name',
                  getSuccess?.ResultObject?.Voice_Name
                );
                setFieldValue(
                  'language_full_code',
                  getSuccess?.ResultObject?.Language_Code_Full
                );
              }
            }, [getSuccess]);

            return (
              <fieldset disabled={upsertLoading || getLoading}>
                <Form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title id='example-custom-modal-styling-title'>
                      <i className='fa fa-duotone fa-pen-to-square me-2 fs-2'></i>
                      {addEditModel.id === null ? 'Add ' : 'Edit '}Language
                      Details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <BForm.Group className='mb-3' controlId='name'>
                      <BForm.Label>Name :</BForm.Label>
                      <BForm.Control
                        type='text'
                        name='name'
                        placeholder='Enter language name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div className='text-danger'>{errors.name}</div>
                      )}
                    </BForm.Group>

                    <BForm.Group className='mb-3' controlId='native_name'>
                      <BForm.Label>Native Name:</BForm.Label>
                      <BForm.Control
                        type='text'
                        name='native_name'
                        placeholder='Enter native language name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.native_name}
                      />
                      {errors.native_name && touched.native_name && (
                        <div className='text-danger'>{errors.native_name}</div>
                      )}
                    </BForm.Group>

                    <BForm.Group className='mb-3' controlId='code'>
                      <BForm.Label>Code :</BForm.Label>
                      <BForm.Control
                        type='text'
                        name='code'
                        placeholder='Enter language code ex:- en'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                      />
                      {errors.code && touched.code && (
                        <div className='text-danger'>{errors.code}</div>
                      )}
                    </BForm.Group>

                    <BForm.Group className='mb-3' controlId='voice_gender'>
                      <BForm.Label>Voice Gender:</BForm.Label>
                      <BForm.Control
                        type='text'
                        name='voice_gender'
                        placeholder='Enter voice gender'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.voice_gender}
                      />
                      {errors.voice_gender && touched.voice_gender && (
                        <div className='text-danger'>{errors.voice_gender}</div>
                      )}
                    </BForm.Group>

                    <BForm.Group className='mb-3' controlId='voice_name'>
                      <BForm.Label>Voice Name:</BForm.Label>
                      <BForm.Control
                        type='text'
                        name='voice_name'
                        placeholder='Enter voice name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.voice_name}
                      />
                      {errors.voice_name && touched.voice_name && (
                        <div className='text-danger'>{errors.voice_name}</div>
                      )}
                    </BForm.Group>

                    <BForm.Group
                      className='mb-3'
                      controlId='language_full_code'
                    >
                      <BForm.Label>Language Full Code</BForm.Label>
                      <BForm.Control
                        type='text'
                        name='language_full_code'
                        placeholder='Enter language code ex:- en-US'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.language_full_code}
                      />
                      {errors.language_full_code &&
                        touched.language_full_code && (
                          <div className='text-danger'>
                            {errors.language_full_code}
                          </div>
                        )}
                    </BForm.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant='primary'
                      type='submit'
                      disabled={isSubmitting || upsertLoading || getLoading}
                    >
                      {upsertLoading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='reset'
                      variant='secondary'
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              </fieldset>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getLoading: state.Language.getLoading,
    getSuccess: state.Language.getSuccess,
    getError: state.Language.getError,

    upsertLoading: state.Language.upsertLoading,
    upsertSuccess: state.Language.upsertSuccess,
    upsertError: state.Language.upsertError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onAdd: (param: any) => dispatch(addLanguage(param)),
  onUpdate: (param: any) => dispatch(updateLanguage(param)),
  onGetById: (param: any) => dispatch(getByIdLanguage(param)),
  onClear: () => dispatch(clearLanguage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageAddEditModel);
