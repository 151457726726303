import React from 'react';
import { Button, Modal as BModal } from 'react-bootstrap';

type PropsType = {
  visible: boolean;
  title: string;
  okText?: string;
  cancelText?: string;
  message: string;
  onOk: () => void;
  onCancel: () => void;
};

const EmbeddedVideoLogDeleteModal: React.FC<PropsType> = (props) => {
  const {
    title,
    visible = false,
    okText = 'Ok',
    cancelText = 'Cancel',
    onOk,
    onCancel,
    message,
  } = props;

  return (
    <BModal show={visible} backdrop='static' keyboard={false} onHide={onCancel}>
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <BModal.Body>{message}</BModal.Body>
      <BModal.Footer>
        <Button variant='danger' type='button' onClick={onOk}>
          {okText}
        </Button>
        <Button variant='secondary' type='button' onClick={onCancel}>
          {cancelText}
        </Button>
      </BModal.Footer>
    </BModal>
  );
};

export default EmbeddedVideoLogDeleteModal;
