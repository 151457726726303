import { isDevelopment } from '../shared/mode';

export function logError(...args: any[]) {
  if (isDevelopment()) {
    console.error('@@Error -- ', ...args);
  }
}

export function logWarn(...args: any[]) {
  if (isDevelopment()) {
    console.warn('@@Warn -- ', ...args);
  }
}

export function logInfo(...args: any[]) {
  if (isDevelopment()) {
    console.info('@@Info -- ', ...args);
  }
}

export function logLog(...args: any[]) {
  if (isDevelopment()) {
    console.log('@@Log -- ', ...args);
  }
}
