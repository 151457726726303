import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Form as BForm,
  Modal,
  Spinner,
  ListGroup,
} from 'react-bootstrap';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  addLiveClassSchedule,
  updateLiveClassSchedule,
  getByIdLiveClassSchedule,
  clearLiveClassSchedule,
  getAllLanguage,
} from 'store/actions';
import Swal from 'sweetalert2';
import Select from 'react-select';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get } from 'helpers/api-helper';
import moment from 'moment';

type Props = {
  addEditModel: object;
  handleCloseAddEditModel: any;
};

const LiveClassAddEditModal: React.FC<Props> = (props: any) => {
  const {
    addEditModel,
    handleCloseAddEditModel,

    getLoading,
    getSuccess,
    getError,

    upsertLoading,
    upsertSuccess,
    upsertError,

    onAdd,
    onUpdate,
    onGetById,

    onClear,
  } = props;

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter class name.'),
    ageOfGroup: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    // languageLevel: Yup.object().nullable().required('Please select.'),
    languageLevel: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    nativeLanguage: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    targetLanguage: Yup.object().nullable().required('Please select.'),
    scheduleDate: Yup.array().min(1, 'Please select.'),
    isEncoder: Yup.boolean().required('Please select.'),
  });

  let initialValues = {
    name: '',
    ageOfGroup: [],
    targetLanguage: [],
    nativeLanguage: [],
    languageLevel: [],
    scheduleDate: [],
    isEncoder: false,
    // enroll: false,
  };

  const [show, setShow] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [levels, setLevels] = useState([]);

  const ageGroupOptions: any = [
    { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
    { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
    { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
  ];

  const booleanOptions: any = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  useEffect(() => {
    getAllLanguage();
    getAllLevel();
    return () => {
      onClear();
    };
  }, []);

  const getAllLanguage = () => {
    return get('/language/getAll', {}).then((res: any) => {
      setLanguages(res?.ResultObject);
    });
  };

  const getAllLevel = () => {
    return get('/level/getAll', {}).then((res: any) => {
      let newLevel = res.map((item: any) => {
        return {
          ...item,
          label: item?.Level_Name,
          value: item?._id,
        };
      });
      setLevels(newLevel);
    });
  };

  useEffect(() => {
    setShow(addEditModel.show);
  }, [addEditModel.show]);

  const handleClose = () => {
    setShow(false);
    handleCloseAddEditModel({
      refresh: false,
    });
  };

  useEffect(() => {
    if (addEditModel?.id) {
      onGetById({
        _id: addEditModel?.id,
      });
    }
  }, [addEditModel]);

  const _handleSubmit = async (values: any, actions: any) => {
    if (addEditModel.id === null) {
      const formData = {
        name: values?.name,
        ageOfGroup: values?.ageOfGroup,
        // languageLevel: values?.languageLevel?.value,
        languageLevel: values?.languageLevel?.map((item: any) => item.value),
        nativeLanguage: values?.nativeLanguage?.map((item: any) => item.value),
        targetLanguage: values?.targetLanguage?.value,
        scheduleDate: values?.scheduleDate,
        isEncoder: values?.isEncoder,
        // enroll: values?.enroll,
      };
      onAdd(formData);
    } else {
      const formData = {
        _id: addEditModel.id,
        name: values?.name,
        ageOfGroup: values?.ageOfGroup,
        // languageLevel: values?.languageLevel.value,
        languageLevel: values?.languageLevel?.map((item: any) => item.value),
        nativeLanguage: values?.nativeLanguage?.map((item: any) => item.value),
        targetLanguage: values?.targetLanguage?.value,
        scheduleDate: values?.scheduleDate,
        isEncoder: values?.isEncoder,
        // enroll: values?.enroll,
        // scheduleDate: values?.scheduleDate?.map((item: any) => {
        //   return (
        //     {
        //       scheduleAt: unixToISO(item)
        //     }
        //   )
        // }),
      };
      onUpdate(formData);
    }
  };

  const Response = ({ data }: any) => {
    return (
      <ListGroup as='ol' numbered>
        {data?.map((item: any) => {
          return (
            <ListGroup.Item
              as='li'
              className='d-flex justify-content-between align-items-center'
            >
              <span className='ps-5 flex-grow-1 text-start'>
                {moment(item.scheduleDate).format('MM/DD/YYYY HH:mm')}
              </span>
              <span
                className={`${
                  item.isContentAvailable ? 'text-success' : 'text-danger'
                }`}
              >
                {item.isContentAvailable ? <>&#x2705;</> : <>&#x274C;</>}
              </span>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  useEffect(() => {
    if (upsertSuccess?.ResultCode === 'SUCCESS') {
      const html = renderToStaticMarkup(
        <Response data={upsertSuccess.ResultObject} />
      );
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: upsertSuccess?.ResultMessage,
        html: html,
        // timer: 200000,
        // timerProgressBar: true,
      }).then((result) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (upsertSuccess?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: upsertSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (upsertError?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: upsertError?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  }, [upsertSuccess, upsertError]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop={true}
        size='xl'
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }: any) => {
            console.log('values', values);

            useEffect(() => {
              if (addEditModel?.id && getSuccess?.ResultCode === 'SUCCESS') {
                setFieldValue('name', getSuccess?.ResultObject?.name);

                let ageGroupValue = getSuccess?.ResultObject?.ageOfGroup.map(
                  (item: any) => {
                    return {
                      ...item,
                      value:
                        item.minAge +
                        (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                      label:
                        item.minAge +
                        (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                      minAge: item.minAge,
                      maxAge: item.maxAge,
                    };
                  }
                );

                setFieldValue('ageOfGroup', ageGroupValue);

                setFieldValue('isEncoder', getSuccess?.ResultObject?.isEncoder);

                let languageLevel =
                  getSuccess?.ResultObject?.languageLevel?.map((item: any) => {
                    return {
                      ...item,
                      label: item?.Level_Name,
                      value: item?._id,
                    };
                  });
                setFieldValue('languageLevel', languageLevel);

                let nativeLanguageValue =
                  getSuccess?.ResultObject?.nativeLanguage?.map((item: any) => {
                    return {
                      ...item,
                      label: item?.Language_Name,
                      value: item?._id,
                    };
                  });

                setFieldValue('nativeLanguage', nativeLanguageValue);

                let targetLanguageValue: any = languages.find(
                  (item: any) =>
                    item._id === getSuccess?.ResultObject?.targetLanguage?._id
                );
                setFieldValue('targetLanguage', {
                  ...targetLanguageValue,
                  label: targetLanguageValue?.Language_Name,
                  value: targetLanguageValue?._id,
                });

                let scheduleDateValue =
                  getSuccess?.ResultObject?.scheduleDate?.map(
                    (item: any) => item?.scheduleDateTime
                  );
                setFieldValue('scheduleDate', scheduleDateValue);

                // let Enroll = getSuccess?.ResultObject?.enroll;
                // setFieldValue('enroll', Enroll);
              }
            }, [getSuccess]);

            return (
              <Container>
                <fieldset disabled={upsertLoading || getLoading}>
                  <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title id='example-custom-modal-styling-title'>
                        {addEditModel.id === null ? 'Add ' : 'Edit '}Live Class
                        Details
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='name'>
                            <BForm.Label>
                              Class Name :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <BForm.Control
                              type='text'
                              name='name'
                              placeholder='Enter class name'
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              value={values.name}
                              className={
                                errors.name && touched.name
                                  ? 'border border-danger'
                                  : ''
                              }
                            />
                            {errors.name && touched.name ? (
                              <div className='text-danger'>{errors.name}</div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='ageOfGroup'>
                            <BForm.Label>
                              Age Group :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='ageOfGroup'
                              isMulti
                              component={Select}
                              options={ageGroupOptions}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('ageOfGroup', option);
                              }}
                              onBlur={handleBlur}
                              value={values.ageOfGroup}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.ageOfGroup && touched.ageOfGroup
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.ageOfGroup && touched.ageOfGroup
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.ageOfGroup && touched.ageOfGroup
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.ageOfGroup && touched.ageOfGroup
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors &&
                            errors.ageOfGroup &&
                            touched.ageOfGroup ? (
                              <div className='text-danger'>
                                {errors.ageOfGroup}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='formCode'>
                            <BForm.Label>
                              Language Level :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='languageLevel'
                              isMulti={true}
                              component={Select}
                              options={levels?.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Level_Name,
                                  };
                                }
                              )}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('languageLevel', option);
                              }}
                              onBlur={handleBlur}
                              value={values.languageLevel}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.languageLevel &&
                                        touched.languageLevel
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.languageLevel &&
                                        touched.languageLevel
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.languageLevel &&
                                        touched.languageLevel
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.languageLevel &&
                                        touched.languageLevel
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.languageLevel && touched.languageLevel ? (
                              <div className='text-danger'>
                                {errors.languageLevel}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='targetLanguage'
                          >
                            <BForm.Label>
                              Target Language :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='targetLanguage'
                              isMulti={false}
                              component={Select}
                              options={languages?.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Language_Name,
                                  };
                                }
                              )}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('targetLanguage', option);
                              }}
                              onBlur={handleBlur}
                              value={values.targetLanguage}
                              // isLoading={getAllLoading}
                              // isDisabled={getAllLoading}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.targetLanguage &&
                                        touched.targetLanguage
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.targetLanguage &&
                                        touched.targetLanguage
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.targetLanguage &&
                                        touched.targetLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.targetLanguage &&
                                        touched.targetLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />

                            {errors.targetLanguage && touched.targetLanguage ? (
                              <div className='text-danger'>
                                {errors.targetLanguage}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>

                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='nativeLanguage'
                          >
                            <BForm.Label>
                              Native Language :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='nativeLanguage'
                              isMulti={true}
                              component={Select}
                              options={languages?.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Language_Name,
                                  };
                                }
                              )}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                // const optionValues = option.find((selected: any) => selected.value === 'All');
                                setFieldValue('nativeLanguage', option);
                                // const allSelected = optionValues;
                                // if (allSelected) {
                                //   handleSelectAll(true);
                                // } else {
                                //   handleSelectAll(false);
                                // }
                              }}
                              onBlur={handleBlur}
                              value={values.nativeLanguage}
                              // isLoading={getAllLoading}
                              // isDisabled={getAllLoading}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.nativeLanguage &&
                                        touched.nativeLanguage
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.nativeLanguage &&
                                        touched.nativeLanguage
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.nativeLanguage &&
                                        touched.nativeLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.nativeLanguage &&
                                        touched.nativeLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.nativeLanguage && touched.nativeLanguage ? (
                              <div className='text-danger'>
                                {errors.nativeLanguage}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>

                        <Col md={6}>
                          <BForm.Group className='mb-7 col-12 '>
                            <BForm.Label className='col-lg-6 fw-bold text-muted'>
                              Encoder
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='isEncoder'
                              isMulti={false}
                              component={Select}
                              options={booleanOptions}
                              placeholder='Select'
                              isClearable={false}
                              classNamePrefix='select'
                              value={booleanOptions?.find(
                                (option: any) =>
                                  option?.value === values?.isEncoder
                              )}
                              onChange={(option: any) => {
                                setFieldValue('isEncoder', option?.value);
                              }}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.isEncoder && touched.isEncoder
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.isEncoder && touched.isEncoder
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.isEncoder && touched.isEncoder
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.isEncoder && touched.isEncoder
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {touched.isEncoder && errors.isEncoder && (
                              <BForm.Text className='text-danger'>
                                <ErrorMessage name='isEncoder' />
                              </BForm.Text>
                            )}
                          </BForm.Group>
                        </Col>

                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='ageOfGroup'>
                            <BForm.Label>
                              Date (UTC+00:00):{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='scheduleDate'
                              component={DatePicker}
                              format='MM/DD/YYYY HH:mm:ss'
                              placeholder='Select Date'
                              multiple
                              minDate={new Date()}
                              value={values.scheduleDate}
                              onChange={(option: any) => {
                                setFieldValue('scheduleDate', option);
                              }}
                              plugins={[
                                <DatePanel />,
                                <TimePicker position='bottom' />,
                              ]}
                              onBlur={handleBlur}
                              style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                height: '40px',
                                padding: '10px',
                                borderColor:
                                  errors.scheduleDate && touched.scheduleDate
                                    ? '#f1416c'
                                    : '',
                              }}
                              // className="w-full p-3 rounded text-sm  outline-none  focus:ring-0 "
                              containerStyle={{
                                width: '100%',
                              }}
                              calendarPosition='bottom-center'
                            />
                            {errors.scheduleDate && touched.scheduleDate ? (
                              <div className='text-danger'>
                                {errors.scheduleDate}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant='primary'
                        type='submit'
                        disabled={isSubmitting || upsertLoading || getLoading}
                      >
                        {upsertLoading ? (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='reset'
                        variant='secondary'
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Form>
                </fieldset>
              </Container>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getLoading: state.LiveClassSchedule.getLoading,
    getSuccess: state.LiveClassSchedule.getSuccess,
    getError: state.LiveClassSchedule.getError,

    upsertLoading: state.LiveClassSchedule.upsertLoading,
    upsertSuccess: state.LiveClassSchedule.upsertSuccess,
    upsertError: state.LiveClassSchedule.upsertError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onAdd: (param: any) => dispatch(addLiveClassSchedule(param)),
  onUpdate: (param: any) => dispatch(updateLiveClassSchedule(param)),
  onGetById: (param: any) => dispatch(getByIdLiveClassSchedule(param)),
  onClear: () => dispatch(clearLiveClassSchedule()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveClassAddEditModal);
