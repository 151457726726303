/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';

import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/teacher'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Teachers'
        fontIcon='bi-layers'
      /> */}
      <SidebarMenuItem
        to='/students'
        title='Students'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <SidebarMenuItem
        to='/lesson'
        title='Lessons'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen020.svg'
      />
      {/* <SidebarMenuItem
        to='/instant-live-class'
        title='Instant Class'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen019.svg'
      /> */}
      <SidebarMenuItem
        to='/schedule-google-meet'
        title='Schedule Google Meet'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen013.svg'
      />
      {/* <SidebarMenuItem
        to='/schedule-live-class'
        title='Live Class Schedule'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen017.svg'
      />
      <SidebarMenuItem
        to='/live-class'
        title='Live Class Contents'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen018.svg'
      /> */}
      <SidebarMenuItem
        to='/course'
        title='Courses'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen062.svg'
      />

      <SidebarMenuItem
        to='/languages'
        title='Languages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen016.svg'
      />

      <SidebarMenuItem
        to='/reported-issues'
        title='Reported Issues'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen023.svg'
      />
      <SidebarMenuItem
        to='/feedbacks'
        title='Feedbacks'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen014.svg'
      />
      <SidebarMenuItem
        to='/embedded-video-log'
        title='Embedded Video Logs'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen005.svg'
      />
      <SidebarMenuItem
        to='/ai-activity-image'
        title='AI Activity Image'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen049.svg'
      />
      <SidebarMenuItem
        to='/enrollments'
        title='Enrollments'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen049.svg'
      />
      <SidebarMenuItemWithSub
        title='User Logs'
        to={''}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen067.svg'
      >
        <SidebarMenuItem
          to='/user-lesson-log'
          title='Lesson Logs'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen049.svg'
        />
        <SidebarMenuItem
          to='/live-class-logs'
          title='Live Class Logs'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen049.svg'
        />
        <SidebarMenuItem
          to='/instant-class-logs'
          title='Instant Class Logs'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen049.svg'
        />
      </SidebarMenuItemWithSub>
    </>
  );
};

export { SidebarMenuMain };
