import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { getHighlightedSection, scrollHandle } from './utils';

const QuizListenItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    quizDetail,
    loading,
    nativeLang,
    targetLang,
  } = props;

  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("en");

  const [openTitle, setOpenTitle] = useState(false);
  const [openOption1, setOpenOption1] = useState(false);
  const [openOption2, setOpenOption2] = useState(false);
  const [openOption3, setOpenOption3] = useState(false);
  const [openOption4, setOpenOption4] = useState(false);

  const [checkBoxoption, setCheckBoxoption] = useState();

  const urlHashRef = React.useRef<any>({});
  useEffect(() => {
    if (urlHashRef.current.one) {
      const data = getHighlightedSection(urlHashRef.current.one);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
  }, [urlHashRef.current.one]);

  useEffect(() => {
    if (quizDetail != '' && quizDetail != undefined) {
      let Question1_Value =
        quizDetail?.Listen_IT?.Questions[0]?.Question?.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_Answer_Value =
        quizDetail?.Listen_IT?.Questions[0]?.Answer?.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question1_ID = quizDetail?.Listen_IT?.Questions[0]?.Question_ID;
      urlHashRef.current.one = Question1_ID;

      setValue('Quiz_ListenIt_Question1_QuestionID', Question1_ID);
      setValue('Quiz_ListenIt_Question1_AudioText', Question1_Value?.AudioText);
      setCheckBoxoption(Question1_Value?.Answer);
      setValue('Quiz_ListenIt_Question1_Answer', Question1_Value?.Answer);
      setValue(
        'Quiz_ListenIt_Question1_Option1',
        Question1_Answer_Value?.Option1
      );
      setValue(
        'Quiz_ListenIt_Question1_Option2',
        Question1_Answer_Value?.Option2
      );
      setValue(
        'Quiz_ListenIt_Question1_Option3',
        Question1_Answer_Value?.Option3
      );
      setValue(
        'Quiz_ListenIt_Question1_Option4',
        Question1_Answer_Value?.Option4
      );

      setValue(
        'Quiz_ListenIt_Question1_Roman_AudioText',
        Question1_Value?.AudioText_Roman_Language
      );
      setValue(
        'Quiz_ListenIt_Question1_Roman_Option1',
        Question1_Answer_Value?.Option1_Roman_Language
      );
      setValue(
        'Quiz_ListenIt_Question1_Roman_Option2',
        Question1_Answer_Value?.Option2_Roman_Language
      );
      setValue(
        'Quiz_ListenIt_Question1_Roman_Option3',
        Question1_Answer_Value?.Option3_Roman_Language
      );
      setValue(
        'Quiz_ListenIt_Question1_Roman_Option4',
        Question1_Answer_Value?.Option4_Roman_Language
      );

      setValue(
        'Quiz_ListenIt_Question1_AudioText_Audio',
        Question1_Value?.AudioText_Audio
      );
      setValue(
        'Quiz_ListenIt_Question1_Audio_Option1',
        Question1_Answer_Value?.Audio_Option1
      );
      setValue(
        'Quiz_ListenIt_Question1_Audio_Option2',
        Question1_Answer_Value?.Audio_Option2
      );
      setValue(
        'Quiz_ListenIt_Question1_Audio_Option3',
        Question1_Answer_Value?.Audio_Option3
      );
      setValue(
        'Quiz_ListenIt_Question1_Audio_Option4',
        Question1_Answer_Value?.Audio_Option4
      );
    }
  }, [quizDetail]);

  const handleChangeCheckbox = (event: any) => {
    setCheckBoxoption(event.target.value);
    setValue('Quiz_ListenIt_Question1_Answer', event.target.value);
  };

  const hash = urlHashRef.current;

  return (
    <>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Listen It 1
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.one)}
        >
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Audio Text <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle(false)}
                    open={openTitle}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      name='AudioText'
                      type='text'
                      className='form-control'
                      {...register('Quiz_ListenIt_Question1_AudioText')}
                      placeholder='Enter Title'
                      onFocus={() => setOpenTitle(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                  {errors.Quiz_ListenIt_Question1_AudioText && (
                    <p style={{ color: 'red' }}>
                      {errors.Quiz_ListenIt_Question1_AudioText.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5></h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option1'
                      checked={checkBoxoption === 'option1'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 1 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption1(false)}
                    open={openOption1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption1(true)}
                      type='text'
                      className='form-control'
                      placeholder='Baby'
                      name='ListenItOption1'
                      {...register('Quiz_ListenIt_Question1_Option1')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Quiz_ListenIt_Question1_Option1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Quiz_ListenIt_Question1_Option1.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option2'
                      checked={checkBoxoption === 'option2'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 2 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption2(false)}
                    open={openOption2}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption2(true)}
                      type='text'
                      className='form-control'
                      placeholder='Balloon'
                      {...register('Quiz_ListenIt_Question1_Option2')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Quiz_ListenIt_Question1_Option2 && (
                    <p style={{ color: 'red' }}>
                      {errors.Quiz_ListenIt_Question1_Option2.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add-description-rows'>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option3'
                      checked={checkBoxoption === 'option3'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 3 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption3(false)}
                    open={openOption3}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption3(true)}
                      type='text'
                      className='form-control'
                      {...register('Quiz_ListenIt_Question1_Option3')}
                      placeholder='Computer'
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Quiz_ListenIt_Question1_Option3 && (
                    <p style={{ color: 'red' }}>
                      {errors.Quiz_ListenIt_Question1_Option3.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='add-description-boxes'>
              <div className='option-section-wrapp'>
                <h5>{/* Option 1 */}</h5>

                <div className='mark-answer'>
                  <label className='mark-answer-section mark-answer-text'>
                    Correct Answer
                    <input
                      type='checkbox'
                      className='checkmark-box'
                      value='option4'
                      checked={checkBoxoption === 'option4'}
                      onChange={handleChangeCheckbox}
                      disabled={loading}
                    />
                  </label>
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Option 4 <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenOption4(false)}
                    open={openOption4}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      onFocus={() => setOpenOption4(true)}
                      type='text'
                      className='form-control'
                      placeholder='Float'
                      {...register('Quiz_ListenIt_Question1_Option4')}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Quiz_ListenIt_Question1_Option4 && (
                    <p style={{ color: 'red' }}>
                      {errors.Quiz_ListenIt_Question1_Option4.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
        </Collapsible>
      </div>
    </>
  );
};

export default QuizListenItActivity;
