import {
  API_ERROR,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
} from './actionTypes';
import { AuthActions, AuthState } from './types';

const initialState: any = {
  loading: false,
  pending: false,
  token: '',
  error: null,
  success: '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        loading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        token: '',
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
