import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { get, post } from '../../helpers/api-helper';
import axios from 'axios';
import {
  GET_ALL_LANGUAGE,
  IMAGE_UPLOAD,
  LESSON_DELETE,
  LESSON_GET,
  LESSON_GET_BY_ID,
  LESSON_GET_LANGUAGE,
  LESSON_ID_EXIST_OR_NOT,
  LESSON_SAVE,
  LESSON_UPDATE,
  LESSON_UPDATE_MULTIPLE_LANGUAGE,
  LEVEL_GET_ALL,
  UPDATE_LANGUAGE_ACTIVE_STATUS,
  UPLOAD_VIDEO,
} from './actionTypes';
import {
  allLanguageGetSuccess,
  imageUploadFailure,
  imageUploadSuccess,
  lessonDeleteSuccess,
  lessonFailure,
  lessonGetByIdSuccess,
  lessonGetSuccess,
  lessonIDExistorNotSuccess,
  lessonLanguageGetSuccess,
  lessonSaveSuccess,
  lessonUpdateMultipleLanguageSuccess,
  lessonUpdateSuccess,
  levelFailure,
  levelGetSuccess,
  updateLanguageActiveStatusSuccess,
  videoUploadFailure,
  videoUploadSuccess,
} from './actions';

const lessonGetListRequest = (data: any) => post('lessons/getAllLesson', data);
// const lessonGetListRequest = (data: any) => get("lessons/getall", data)
const lessonSaveRequest = (data: any) => post('lessons/add', data);
const lessonImageUploadRequest = (data: any, config: any) =>
  post('upload/add/image', data, config);
const languageGetListRequest = (data: any) =>
  post('lessons/lessonlanguage/getall', data);
const updateLanguageActiveStatusRequest = (data: any) =>
  post('lessons/lessonlanguage/update', data);
// const lessonLanguageGetListRequest = (data: any) => get("language/getAllForGrid", data)
const lessonLanguageGetListRequest = (data: any) =>
  get('language/getAllLanguages', data);
const lessonDeleteRequest = (data: any) => post('lessons/delete', data);
const lessonGetByIdListRequest = (data: any) =>
  post('lessons/user/getbyid', data);
const lessonUpdateRequest = (data: any) => post('lessons/edit/:id', data);
const lessonVideoUploadRequest = (data: any) =>
  post('lessons/uploadVideo', data);
const getLevel = (data: any) => get('level/getall', data);
const lessonIDExistorNotCheckRequest = (data: any) =>
  post('lessons/checkexistid', data);

const lessonUpdateMultipleLangugeRequest = (data: any) =>
  post('lessons/edit/language/:id', data);

function* lessonGetListSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonGetListRequest, {
      page: action.payload.page,
      limit: action.payload.limit,
      search: action.payload.search,
      sort: action.payload.sort,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure({ error: response.ResultCode }));
    } else {
      yield put(lessonGetSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* lessonSaveSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonSaveRequest, {
      Lesson_Name: action.payload.LessonName,
      Lesson_Age_Group: action.payload.Age,
      Lesson_Level: action.payload.Level,
      Activity_Details: action.payload.Activity_Details,
      Its_Premium_Lesson: action.payload.Its_Premium_Lesson,
      Lesson_ID: action.payload.LessonID,
      AI_Lesson: action.payload.AI_Lesson,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure(response));
    } else {
      yield put(lessonSaveSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* lessonImageUploadSaga(action: any) {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append('file', action.payload.file);

    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonImageUploadRequest, formData, config);
    yield put(imageUploadSuccess(response));
  } catch (error: any) {
    yield put(imageUploadFailure({ error: error }));
  }
}

function* languageGetListSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(languageGetListRequest, {
      English_Lesson_Id: action.payload.Lesson_Id,
    });
    yield put(allLanguageGetSuccess(response));
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* updateLanguageActiveStatusSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(updateLanguageActiveStatusRequest, {
      IsActive: action.payload.IsActive,
      Language_ID: action.payload.Language_ID,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure(response));
    } else {
      yield put(updateLanguageActiveStatusSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* LessonLanguageGetListSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonLanguageGetListRequest, {});
    yield put(lessonLanguageGetSuccess(response));
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* lessonDeleteSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonDeleteRequest, {
      Lesson_ID: action.payload.Lesson_ID,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure({ error: response.ResultCode }));
    } else {
      yield put(lessonDeleteSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* lessonGetByIdListSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonGetByIdListRequest, {
      Lesson_ID: action.payload.Lesson_ID,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure({ error: response.ResultCode }));
    } else {
      yield put(lessonGetByIdSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* lessonUpdateSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonUpdateRequest, {
      _ID: action.payload.Lesson_ID,
      Lesson_Name: action.payload.LessonName,
      Lesson_Age_Group: action.payload.Age,
      Lesson_Level: action.payload.Level,
      Activity_Details: action.payload.Activity_Details,
      Its_Premium_Lesson: action.payload.Its_Premium_Lesson,
      Lesson_ID: action.payload.LessonID,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure(response));
    } else {
      yield put(lessonUpdateSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* videoUploadSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonVideoUploadRequest, {
      url: action.payload.url,
    });
    yield put(videoUploadSuccess(response));
  } catch (error: any) {
    yield put(videoUploadFailure({ error: error }));
  }
}

function* getAllLevel(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(getLevel, {});

    if (!response) {
      yield put(levelFailure({ error: 'Error' }));
    } else {
      yield put(levelGetSuccess(response));
    }
  } catch (error) {}
}

function* lessonIDExistorNotCheckSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonIDExistorNotCheckRequest, {
      Lesson_ID: action.payload.Lesson_ID,
      ID: action.payload.ID,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure({ error: response.ResultCode }));
    } else {
      yield put(lessonIDExistorNotSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* lessonUpdateMultipleLanguageSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(lessonUpdateMultipleLangugeRequest, {
      _ID: action.payload.Lesson_ID,
      Lesson_Name: action.payload.LessonName,
      Lesson_Age_Group: action.payload.Age,
      Lesson_Level: action.payload.Level,
      Activity_Details: action.payload.Activity_Details,
      Its_Premium_Lesson: action.payload.Its_Premium_Lesson,
      Lesson_ID: action.payload.LessonID,
      Language_Code: action.payload.Language_Code,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(lessonFailure(response));
    } else {
      yield put(lessonUpdateMultipleLanguageSuccess(response));
    }
  } catch (error: any) {
    yield put(lessonFailure({ error: error }));
  }
}

function* authSaga() {
  yield all([takeLatest(LESSON_GET, lessonGetListSaga)]);
  yield all([takeLatest(LESSON_SAVE, lessonSaveSaga)]);
  yield all([takeLatest(IMAGE_UPLOAD, lessonImageUploadSaga)]);
  yield all([takeLatest(GET_ALL_LANGUAGE, languageGetListSaga)]);
  yield all([
    takeLatest(UPDATE_LANGUAGE_ACTIVE_STATUS, updateLanguageActiveStatusSaga),
  ]);
  yield all([takeLatest(LESSON_GET_LANGUAGE, LessonLanguageGetListSaga)]);
  yield all([takeLatest(LESSON_DELETE, lessonDeleteSaga)]);
  yield all([takeLatest(LESSON_GET_BY_ID, lessonGetByIdListSaga)]);
  yield all([takeLatest(LESSON_UPDATE, lessonUpdateSaga)]);
  yield all([takeLatest(UPLOAD_VIDEO, videoUploadSaga)]);
  yield all([takeLatest(LEVEL_GET_ALL, getAllLevel)]);
  yield all([takeLatest(LESSON_ID_EXIST_OR_NOT, lessonIDExistorNotCheckSaga)]);
  yield all([
    takeLatest(
      LESSON_UPDATE_MULTIPLE_LANGUAGE,
      lessonUpdateMultipleLanguageSaga
    ),
  ]);
}

export default authSaga;
