import Loader from 'app/components/Loader';
import React, {
  useEffect,
  useMemo,
  useState,
  HTMLAttributes,
  HTMLProps,
} from 'react';
import useTableState from 'app/components/Table/useTableState';
import { TablePagination } from 'app/components/Table/TablePagination';
import TableInner from 'app/components/Table/TableInner';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import Table from 'app/components/Table';
import { PageTitle } from 'theme/layout/core';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { CellProps, Column } from 'react-table';
import { KTSVG } from 'theme/helpers';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import EnrollmentHeadPageTab from '../EnrollmentHeadPageTab';
import Swal from 'sweetalert2';
import moment from 'moment';
import TranscriptDownloadModel from './TranscriptDownloadModel';

const TranscriptPage = () => {
  const getAllTranscriptQuery: any = usePostQuery(
    'getAllTranscript',
    '/transcript/getall',
    {}
  );

  const deleteTranscriptQuery: any = usePostQuery(
    'deleteTranscript',
    '/transcript/delete',
    {}
  );

  const getStudentTranscriptQuery: any = usePostQuery(
    'getStudentTranscript',
    '/transcript/get/student',
    {}
  );

  const highSchoolTranscriptDocumentQuery: any = usePostQuery(
    'highSchoolTranscriptDocument',
    '/transcript/highschooltranscriptdocument',
    {}
  );

  const navigate = useNavigate();

  const [transcriptData, setTranscriptData] = useState([]);
  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const [student, setStudent] = useState([]);
  const [studentID, setStudentID] = useState('');

  const { sort, page, limit, search } = tableState;

  useEffect(() => {
    // getAllTranscriptQuery.mutateAsync();
    getStudentTranscriptQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getAllTranscriptQuery.isSuccess) {
      setTranscriptData(getAllTranscriptQuery?.data?.ResultObject);
    }
  }, [getAllTranscriptQuery.isSuccess]);

  const onDelete = (id: any) => {
    deleteTranscriptQuery.mutateAsync({ ID: id });
  };

  useEffect(() => {
    if (deleteTranscriptQuery.isSuccess) {
      getAllTranscriptQuery.mutateAsync();
    }
  }, [deleteTranscriptQuery.isSuccess]);

  useEffect(() => {
    if (getStudentTranscriptQuery.isSuccess) {
      setStudent(getStudentTranscriptQuery?.data?.ResultObject);
      const selectedStudent = getStudentTranscriptQuery?.data?.ResultObject;
      setSelectedStudent(selectedStudent ? selectedStudent[0] : '');
      setStudentID(selectedStudent ? selectedStudent[0]?.value : '');
    }
  }, [getStudentTranscriptQuery.isSuccess]);

  const handleSelectStudent = (event: any) => {
    setSelectedStudent(event);
    setStudentID(event ? event?.value : '');
    // getAllTranscriptQuery.mutateAsync({ StudentId: event?.value });
  };

  useEffect(() => {
    if (selectedStudent) {
      getAllTranscriptQuery.mutateAsync({ StudentID: selectedStudent?.value });
    }
  }, [selectedStudent]);

  const [selectedRowIds, setSelectedRowIds] = useState<any>({});

  const toggleRowSelection = (id: string) => {
    setSelectedRowIds((prevSelectedRowIds: any) => ({
      ...prevSelectedRowIds,
      [id]: !prevSelectedRowIds[id],
    }));
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        id: 'selection',
        Header: (props: any) => {
          const { getToggleAllRowsSelectedProps } = props;
          const toggleAll = getToggleAllRowsSelectedProps();
          return (
            <span className='ps-4 rounded-start justify-content-center'>
              {/* <input type='checkbox' {...toggleAll} /> */}
            </span>
          );
        },
        Cell: (props: any) => {
          const { row, state, toggleRowSelected } = props;
          const isSelected = state.selectedRowIds[row.id];
          return (
            <span className='ps-4 rounded-start justify-content-center'>
              <input
                type='checkbox'
                checked={isSelected}
                onChange={() => {
                  toggleRowSelected(row.id);
                  toggleRowSelection(row.original._id);
                }}
                // checked={selectedRowIds[row.original._id]}
                // onChange={() => toggleRowSelection(row.original._id)}
              />
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Student ID
          </span>
        ),
        accessor: 'StudentID',
        Cell: (props: CellProps<any>) => {
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {props?.row?.original?.StudentID?.Student_Profile_ID}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Student Name
          </span>
        ),
        accessor: 'StudentName',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.StudentID;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {result?.UserId?.FirstName + ' ' + result?.UserId?.LastName}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Transcript Year
          </span>
        ),
        accessor: 'TranscriptYear',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {result.TranscriptYear}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            HighSchool Year
          </span>
        ),
        accessor: 'HighSchoolYear',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {result?.HighSchoolYear}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Created Date
          </span>
        ),
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {moment(result.createdAt).format('DD-MM-YYYY hh:mm A')}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start justify-content-start'>
            Status
          </span>
        ),
        accessor: 'Status',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <span
              className={
                result?.Status == 2
                  ? 'text-center badge badge-success'
                  : result?.Status == 3
                  ? 'text-center badge badge-danger'
                  : result?.Status == 4
                  ? 'text-center badge badge-danger'
                  : 'text-center badge badge-secondary'
              }
            >
              {result?.Status == 2
                ? 'Accept'
                : result?.Status == 3
                ? 'Issue Fixed'
                : result?.Status == 4
                ? 'Decline'
                : null}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  navigate(`/transcript/edit/${result?._id}`);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => {
                  onDelete(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: '250px',
      height: '43px',
    }),
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const GenerateHighSchoolTranscript = () => {
    const data = selectedRowIds;
    const trueIds = [];

    for (const key in data) {
      if (data[key] === true) {
        trueIds.push(key);
      }
    }

    const formData = {
      ID: trueIds,
      StudentID: studentID,
    };

    if (trueIds?.length > 0) {
      highSchoolTranscriptDocumentQuery.mutate(formData);
    } else {
      Toast.fire({
        icon: 'warning',
        title: 'Please select one transcript.',
      });
    }
  };

  const [downloadModelShow, setDownloadModelShow] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');

  const showDownloadModel = () => setDownloadModelShow(true);
  const closeDownloadModel = () => setDownloadModelShow(false);

  useEffect(() => {
    if (highSchoolTranscriptDocumentQuery.isSuccess) {
      getStudentTranscriptQuery.mutateAsync();
      const fileUrl = highSchoolTranscriptDocumentQuery?.data?.ResultObject;
      const success = highSchoolTranscriptDocumentQuery?.data?.ResultCode;
      setSelectedRowIds({});

      if (success == 'SUCCESS') {
        setDocumentUrl(fileUrl?.TranscriptPDF);
        showDownloadModel();
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Something want to wrong.',
        });
      }
    }
  }, [highSchoolTranscriptDocumentQuery.isSuccess]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Transcript</PageTitle>
      <div className='card mb-10'>
        <Loader
          loading={
            getAllTranscriptQuery.isLoading ||
            deleteTranscriptQuery.isLoading ||
            getStudentTranscriptQuery.isLoading ||
            highSchoolTranscriptDocumentQuery.isLoading
          }
        />
        <Table
          data={transcriptData}
          columns={columns}
          initialState={tableInitialState}
          pageCount={
            getAllTranscriptQuery?.data?.ResultObject?.totalPages ?? -1
          }
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='pt-5'>
              <EnrollmentHeadPageTab activeTab='Transcript' />
            </div>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>Transcripts</div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='mx-2'>
                  <Select
                    name='student'
                    isMulti={false}
                    isClearable={true}
                    classNamePrefix='select'
                    placeholder='Select student'
                    value={selectedStudent}
                    options={student}
                    onChange={handleSelectStudent}
                    styles={customStyles}
                  />
                </div>
                <div>
                  <button
                    title='Create Multi-Year Transcript'
                    className='btn btn-primary'
                    onClick={() => {
                      // navigate('/transcript/add');
                      GenerateHighSchoolTranscript();
                    }}
                  >
                    Create Multi-Year Transcript
                  </button>
                </div>
                <div>
                  <button
                    title='Add Transcript'
                    className='btn btn-primary mx-2'
                    onClick={() => {
                      navigate('/transcript/add');
                    }}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Transcript
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllTranscriptQuery.isLoading}
                    entityLabel='transcript'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <TranscriptDownloadModel
        showDownloadModel={downloadModelShow}
        closeDownloadModel={closeDownloadModel}
        documentUrl={documentUrl}
      />
    </>
  );
};

export default TranscriptPage;
