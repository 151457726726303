import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

import { login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { loginRequest } from '../../../../store/actions';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .required('Please enter email'),
  password: Yup.string().required('Please enter password'),
});

const Login: React.FC<{}> = (props: any) => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, auth } = useAuth();
  const [cookieData, setCookieData] = useCookies();

  const initialValues = {
    email: '',
    password: '',
    rememberme: false,
  };

  useEffect(() => {
    initialValues.email = cookieData.email ? cookieData.email?.toString() : '';
    initialValues.password = cookieData.password
      ? cookieData.password?.toString()
      : '';
    initialValues.rememberme = cookieData.rememberme == 'true' ? true : false;
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response: any = await login(values.email, values.password);
        setLoading(false);
        if (response.data.ok === true) {
          saveAuth(response.data);
          setCurrentUser(response.data.data);
          if (values.rememberme === true) {
            setCookieData('email', values.email);
            setCookieData('password', values.password);
            setCookieData('rememberme', 'true');
          } else {
            setCookieData('email', '');
            setCookieData('password', '');
            setCookieData('rememberme', 'false');
          }
        } else {
          setSubmitting(false);
          setStatus('Incorrect email or password.');
        }
      } catch (error) {
        saveAuth(undefined);
        setStatus('Incorrect email or password.');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    (document.getElementById('email') as HTMLInputElement).value =
      cookieData.email ? cookieData.email : '';
    (document.getElementById('password') as HTMLInputElement).value =
      cookieData.password ? cookieData.password : '';
    (document.getElementById('rememberme') as HTMLInputElement).checked =
      cookieData.rememberme?.toString() == 'true' ? true : false;
  }, []);

  const onRememberMe = (e: any) => {
    if (e.target.checked == true) {
      const email = document.getElementById('email') as HTMLInputElement;
      const password = document.getElementById('password') as HTMLInputElement;
      const rememberme = document.getElementById(
        'rememberme'
      ) as HTMLInputElement;
      setCookieData('email', email.value);
      setCookieData('password', password.value);
      setCookieData('rememberme', rememberme.checked);
    } else if (e.target.checked == false) {
      setCookieData('email', '');
      setCookieData('password', '');
      setCookieData('rememberme', '');
    }
  };

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Welcome Hope City School ! Please Enter Your Login Details
        </div>
      </div>

      {formik.status ? (
        <div className='alert alert-dismissible bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10'>
          <span className='svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                opacity='0.3'
                x='2'
                y='2'
                width='20'
                height='20'
                rx='10'
                fill='currentColor'
              ></rect>
              <rect
                x='11'
                y='14'
                width='7'
                height='2'
                rx='1'
                transform='rotate(-90 11 14)'
                fill='currentColor'
              ></rect>
              <rect
                x='11'
                y='17'
                width='2'
                height='2'
                rx='1'
                transform='rotate(-90 11 17)'
                fill='currentColor'
              ></rect>
            </svg>
          </span>
          <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
            <h4 className='mb-2 text-light'>Error</h4>
            <span>{formik.status}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Enter email address'
          id='email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          Password
        </label>
        <input
          id='password'
          type='password'
          placeholder='Enter password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            {...formik.getFieldProps('rememberme')}
            id='rememberme'
          />
          <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1 cursor-pointer'>
            Remember Me
          </span>
        </label>
      </div>
      <div className='d-grid mb-10 mt-8'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
        >
          {loading ? (
            <span
              className='spinner-border spinner-border-sm align-middle ms-2'
              aria-hidden='true'
            ></span>
          ) : (
            'Log In'
          )}
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pending: state.login.pending,
    token: state.login.token,
    error: state.login.error,
    loader: state.login.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  loginUsers: (params: any) => dispatch(loginRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
