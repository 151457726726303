import React, { useEffect } from 'react';
import Loader from '../../components/Loader';
import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
import TableInner from 'app/components/Table/TableInner';
import useTableState from 'app/components/Table/useTableState';
import { KTSVG } from 'theme/helpers';
import Swal from 'sweetalert2';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import ScheduleGoogleMeetUpsertModal from './ScheduleGoogleMeetUpsertModal';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';
import {
  useDeleteQuery,
  useGetQuery,
  usePostQuery,
  usePutQuery,
} from 'hooks/reactQueryHelper';
import useModal from 'hooks/useModal';
import moment from 'moment';

const ScheduleGoogleMeet: React.FC = (props: any) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const addQuery: any = usePostQuery(
    'addScheduleGoogleMeet',
    '/scheduleGoogleMeet'
  );
  const updateQuery: any = usePutQuery(
    'updateScheduleGoogleMeet',
    '/scheduleGoogleMeet'
  );
  const getAllScheduleGoogleMeetQuery: any = useGetQuery(
    'getAllScheduleGoogleMeet',
    '/scheduleGoogleMeet/getAll'
  );
  const deleteScheduleGoogleMeetQuery: any = useDeleteQuery(
    'deleteScheduleGoogleMeet',
    '/scheduleGoogleMeet'
  );

  const {
    show: upsertShow,
    openModal: upsertOpenModal,
    closeModal: upsertCloseModal,
    toggleModal: upsertToggleModal,
    modalData: upsertModalData,
  } = useModal();

  const {
    show: deleteShow,
    openModal: deleteOpenModal,
    closeModal: deleteCloseModal,
    toggleModal: deleteToggleModal,
    modalData: deleteModalData,
  } = useModal();

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});
  const { sort, page, limit, search } = tableState;

  const getAll = () => {
    getAllScheduleGoogleMeetQuery.mutateAsync({
      params: {
        page,
        limit,
        sort,
        search,
      },
    });
  };

  useEffect(() => {
    getAllScheduleGoogleMeetQuery.mutateAsync({
      params: {
        page,
        limit,
        sort,
        search,
      },
    });
  }, [page, limit, sort, search]);

  useEffect(() => {
    if (addQuery?.isSuccess && addQuery?.data?.ResultMessage) {
      upsertCloseModal();

      Swal.fire({
        icon: 'success',
        title: addQuery?.data?.ResultMessage,
      }).then((result) => {
        getAll();
      });
    }
  }, [addQuery?.isSuccess]);

  useEffect(() => {
    if (updateQuery?.isSuccess && updateQuery?.data?.ResultMessage) {
      upsertCloseModal();

      Swal.fire({
        icon: 'success',
        title: updateQuery?.data?.ResultMessage,
      }).then((result) => {
        getAll();
      });
    }
  }, [updateQuery?.isSuccess]);

  const handleDeleteRecord = () => {
    deleteScheduleGoogleMeetQuery.mutateAsync({
      params: {
        id: deleteModalData?.id,
      },
    });
  };

  useEffect(() => {
    if (deleteScheduleGoogleMeetQuery.isSuccess) {
      getAll();
    }
  }, [deleteScheduleGoogleMeetQuery.isSuccess]);

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start text-nowrap'>
            Name
          </span>
        ),
        accessor: 'name',

        Cell: (props: CellProps<any>) => {
          return (
            <span className='ps-4 text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.name}
            </span>
          );
        },
      },
      {
        Header: 'Age Group',
        accessor: 'ageOfGroup',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.ageGroup?.map((item: any) => (
                <span
                  className='badge badge-pill badge-primary'
                  style={{ marginRight: '3px' }}
                >
                  {item.minAge +
                    (item.maxAge === 200 ? '+' : '-' + item.maxAge)}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='min-w-325px rounded-start text-start text-nowrap'>
            Language Level
          </span>
        ),
        accessor: 'languageLevel',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              <span className='text-dark fw-bold d-block fs-6'>
                {props?.row?.original?.languageLevel?.map((item: any) => (
                  <span
                    className='badge badge-pill badge-secondary'
                    style={{ marginRight: '3px' }}
                  >
                    {item.Level_Name}
                  </span>
                ))}
              </span>
            </span>
          );
        },
      },

      {
        Header: 'Target Language',
        accessor: 'targetLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.targetLanguage
                ?.map((item: any) => item.Language_Name)
                .join(', ')}
            </span>
          );
        },
      },
      {
        Header: 'Native Language',
        accessor: 'nativeLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.nativeLanguage?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item.Language_Name}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Schedule Date',
        accessor: 'scheduleGoogleMeetDates.date',
        disableSortBy: false,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <>
              {moment(result.scheduleGoogleMeetDates.date).format(
                'MM/DD/YYYY HH:mm'
              )}
            </>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='Copy'
                onClick={() => {
                  // Copy the text inside the text field
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_HOPECITY_URL}/google-meet-earn-point/${result?._id}`
                  );

                  Toast.fire({
                    icon: 'success',
                    title: 'copied',
                  });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen054.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  upsertOpenModal({ id: result?._id });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#delete_confirmation_modal'
                onClick={() => {
                  deleteOpenModal({ id: result?._id });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className='card mb-10'>
        <Loader
          loading={
            getAllScheduleGoogleMeetQuery?.isLoading ||
            deleteScheduleGoogleMeetQuery.isLoading
          }
        />
        <Table
          data={
            getAllScheduleGoogleMeetQuery?.data?.ResultObject?.data
              ? getAllScheduleGoogleMeetQuery?.data?.ResultObject?.data
              : []
          }
          columns={columns}
          initialState={tableInitialState}
          pageCount={
            getAllScheduleGoogleMeetQuery?.data?.ResultObject?.totalPages ?? -1
          }
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>
                  Schedule Google Meet Data
                </div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
                <div>
                  <button
                    title='Add'
                    className='btn btn-primary'
                    onClick={upsertOpenModal}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Schedule Google Meet
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllScheduleGoogleMeetQuery?.isLoading}
                    entityLabel='schedule google meet'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>

      <DeleteConfirmationModel
        deleteModel={deleteShow}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={deleteCloseModal}
      />

      <ScheduleGoogleMeetUpsertModal
        show={upsertShow}
        openModal={upsertOpenModal}
        closeModal={upsertCloseModal}
        toggleModal={upsertToggleModal}
        upsertModalData={upsertModalData}
        callback={getAll}
        addQuery={addQuery}
        updateQuery={updateQuery}
      />
    </React.Fragment>
  );
};

export default ScheduleGoogleMeet;
