import React, { useEffect, useState } from 'react';
import useTableState from 'app/components/Table/useTableState';
import { TablePagination } from 'app/components/Table/TablePagination';
import TableInner from 'app/components/Table/TableInner';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import Table from 'app/components/Table';
import Loader from 'app/components/Loader';
import { CellProps, Column } from 'react-table';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { KTSVG } from 'theme/helpers';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from 'theme/layout/core';
import EnrollmentHeadPageTab from './EnrollmentHeadPageTab';
import moment from 'moment';
import { constants } from 'fs';
import EnrollmentDeleteConfirmationModel from './EnrollmentDeleteConfirmationModel';
import Swal from 'sweetalert2';
import { VscIssueDraft } from 'react-icons/vsc';
import { AiOutlineIssuesClose } from 'react-icons/ai';

const EnrollmentArchivePage: React.FC = (props: any) => {
  const getAllEnrollmentsQuery: any = usePostQuery(
    'getAllEnrollments',
    '/enrollment/getall/archive',
    {}
  );
  const getDiplomaRegistationQuery: any = usePostQuery(
    'getDiplomaRegistation',
    '/enrollmentregister/diplomaregistration/enrollment/getbyid',
    {}
  );

  const deleteEnrollFormQuery: any = usePostQuery(
    'declineEnrollForm',
    '/enrollment/delete/allapplication',
    {}
  );

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [enrollmentData, setEnrollmentData] = useState([]);
  const [enrollmentID, setEnrollmentID] = useState<any>();
  const [userID, setUserID] = useState<any>();
  const [resultData, setResultData] = useState<any>('');
  const [deleteModel, setDeleteModel] = useState(false);
  const [diplomaRegistrationdata, setDiplomaRegistrationdata] = useState<any>();
  const navigate = useNavigate();

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({ pageSize: 50 });
  const { sort, page, limit, search } = tableState;

  useEffect(() => {
    getAllEnrollmentsQuery.mutateAsync({
      page: page,
      limit: limit,
      search: search,
      sort: sort,
    });
  }, [page, limit, sort, search]);

  useEffect(() => {
    if (getAllEnrollmentsQuery.isSuccess) {
      setEnrollmentData(getAllEnrollmentsQuery?.data?.ResultObject?.data);
    }
  }, [getAllEnrollmentsQuery.isSuccess]);

  const [expandedRows, setExpandedRows] = useState<any>({});

  const toggleRowExpansion = (rowId: any) => {
    if (expandedRows[rowId]) {
      setExpandedRows((prevExpandedRows: any) => ({
        ...prevExpandedRows,
        [rowId]: !prevExpandedRows[rowId],
      }));
    } else {
      setExpandedRows({});
      setExpandedRows((prevExpandedRows: any) => ({
        ...prevExpandedRows,
        [rowId]: !prevExpandedRows[rowId],
      }));
    }
  };

  const handleDeleteRecord = () => {
    setDeleteModel(false);
    if (resultData) {
      if (resultData?.SubmitFormType === 'Application') {
        deleteEnrollFormQuery.mutateAsync({
          EnrollmentID: resultData?.ID,
          Email: resultData?.Email,
        });
      }
    }
  };

  useEffect(() => {
    if (deleteEnrollFormQuery.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: 'Record has been deleted successfully.',
      });
      getAllEnrollmentsQuery.mutateAsync({});
    }
  }, [deleteEnrollFormQuery.isSuccess]);

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  useEffect(() => {
    if (enrollmentID) {
      setDiplomaRegistrationdata('');
      getDiplomaRegistationQuery.mutateAsync({
        EnrollmentID: enrollmentID,
        UserID: userID,
      });
    }
  }, [enrollmentID]);

  // const onExpandEnrollmentData = (enrollID: any, userID: any) => {
  //   setDiplomaRegistrationdata('');
  //   getDiplomaRegistationQuery.mutateAsync({
  //     EnrollmentID: enrollmentID,
  //     UserID: userID,
  //   });
  // };

  useEffect(() => {
    if (getDiplomaRegistationQuery.isSuccess) {
      setDiplomaRegistrationdata(
        getDiplomaRegistationQuery?.data?.ResultObject
      );
    }
  }, [getDiplomaRegistationQuery.isSuccess]);

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        id: 'expander',
        Header: () => null,
        accessor: 'expander',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const rowId = props.row.id;
          // const enrollID = props?.row?.original?._id;
          // const userID = props?.row?.original?.UserID;
          const userID = props?.row?.original?._id;
          const enrollID = props?.row?.original?.EnrollmentData?._id;
          return (
            <>
              <div className='d-flex'>
                <div
                  style={{ cursor: 'pointer' }}
                  className='expand-icon'
                  onClick={() => {
                    toggleRowExpansion(rowId);
                    setEnrollmentID(enrollID);
                    setUserID(userID);
                  }}
                >
                  {expandedRows[rowId] ? '▼' : '►'}
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            FullName
          </span>
        ),
        accessor: 'User',
        Cell: (props: CellProps<any>) => {
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {props?.row?.original?.UsersData?.FirstName +
                  ' ' +
                  props?.row?.original?.UsersData?.LastName}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start '>
            SchoolYear
          </span>
        ),
        accessor: 'SchoolYear',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.EnrollmentData;
          return (
            <span className='text-dark fw-bold fs-6'>
              {result?.CalendarTypeData?.description == 'Other'
                ? 'Other'
                : result?.CalendarTypeData?.description?.split('(')[0]}
              {result?.CalendarTypeData?.label}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start justify-content-start'>
            FormType
          </span>
        ),
        accessor: 'FormType',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.EnrollmentData;
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {result?.FormType == 1
                ? `K 12 ${result?.EnrollmetType}`
                : `K 6 ${result?.EnrollmetType}`}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            Created Date
          </span>
        ),
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.EnrollmentData;
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                {moment(result.createdAt).format('DD-MM-YYYY hh:mm A')}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start justify-content-start'>
            Status
          </span>
        ),
        accessor: 'Status',
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.EnrollmentData;
          return (
            <span
              className={
                result?.Status == 2
                  ? 'text-center badge badge-success'
                  : result?.Status == 3
                  ? 'text-center badge badge-danger'
                  : result?.Status == 4
                  ? 'text-center badge badge-danger'
                  : 'text-center badge badge-secondary'
              }
            >
              {result?.Status == 2
                ? 'Accept'
                : result?.Status == 3
                ? 'Issue Fixed'
                : result?.Status == 4
                ? 'Decline'
                : null}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-center rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original?.EnrollmentData;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-secondary btn-sm me-1'
                title='fixed issue'
                onClick={() => navigate(`/enrollment-details/${result?._id}`)}
              >
                <AiOutlineIssuesClose />
              </button>
              <button
                className='btn btn-icon btn-secondary btn-sm me-1'
                title='view'
                onClick={() =>
                  navigate(`/enrollment-details/view/${result?._id}`)
                }
              >
                <BsEyeFill />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => {
                  setDeleteModel(true);
                  const formData = {
                    ID: result?._id,
                    Email: result?.UserID?.Email,
                    SubmitFormType: 'Application',
                  };
                  setResultData(formData);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    [expandedRows]
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>Enrollment</PageTitle>
      <div className='card mb-10'>
        <Loader
          loading={
            getAllEnrollmentsQuery.isLoading ||
            getDiplomaRegistationQuery.isLoading ||
            deleteEnrollFormQuery.isLoading
          }
        />
        <Table
          data={enrollmentData}
          columns={columns}
          initialState={tableInitialState}
          pageCount={
            getAllEnrollmentsQuery?.data?.ResultObject?.totalPages ?? -1
          }
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='pt-5'>
              <EnrollmentHeadPageTab activeTab='ActiveEnrollment' />
            </div>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>
                  Active Enrollments
                </div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getAllEnrollmentsQuery.isLoading}
                    entityLabel='enrollment'
                    expandedRows={expandedRows}
                    diplomaRegistrationdata={diplomaRegistrationdata}
                  />
                </div>
                <div className='col-12 border-top pt-5'>
                  <TablePagination pageOptions={[5, 10, 20, 50]} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <EnrollmentDeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
    </>
  );
};

export default EnrollmentArchivePage;
