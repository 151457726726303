import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';

import { imageUpload } from '../../../store/actions';
import { getHighlightedSection } from './utils';

const ConversationSayItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    imageUploadReq,
    imageURL,
    setValue,
    loading,
    conversationDetail,
    imageloading,
    nativeLang,
    targetLang,
  } = props;

  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("en");

  const [openTitle, setOpenTitle] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);

  const [openTitle1, setOpenTitle1] = useState(false);
  const [openAnswer1, setOpenAnswer1] = useState(false);

  const [openDesctiption, setOpenDesctiption] = useState(false);
  const [openDesctiption2, setOpenDesctiption2] = useState(false);
  const [openDesctiption1, setOpenDesctiption1] = useState(false);

  const [openTitle2, setOpenTitle2] = useState(false);
  const [openAnswer2, setOpenAnswer2] = useState(false);

  const typeOptions = ['None', 'Noun', 'Verb'];
  const [decription1, setDecription1] = useState(true);
  const [decription2, setDecription2] = useState(true);
  const [decription3, setDecription3] = useState(true);
  const [sectionOptionValue1, setSectionOptionValue1] = useState('None');
  const [sectionOptionValue2, setSectionOptionValue2] = useState('None');
  const [sectionOptionValue3, setSectionOptionValue3] = useState('None');

  const defaultImageUrl = 'https://via.placeholder.com/150?text=NA';
  const [activeKey, setActiveKey] = useState('');
  const [conversationSayITImage1, setConversationSayITImage1] = useState('');
  const [conversationSayITImage2, setConversationSayITImage2] = useState('');
  const [conversationSayITImage3, setConversationSayITImage3] = useState('');

  const [conversationSayITImageLoader1, setConversationSayITImageLoader1] =
    useState(false);
  const [conversationSayITImageLoader2, setConversationSayITImageLoader2] =
    useState(false);
  const [conversationSayITImageLoader3, setConversationSayITImageLoader3] =
    useState(false);

  const urlHashRef = React.useRef<any>({});

  useEffect(() => {
    if (conversationDetail != '' && conversationDetail != undefined) {
      let Question1_Value =
        conversationDetail.Say_IT.Questions[0].Question.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_Answer_Value =
        conversationDetail.Say_IT.Questions[0].Answer.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question1_ID = conversationDetail.Say_IT.Questions[0].Question_ID;
      urlHashRef.current.one = Question1_ID;

      let Question2_Value =
        conversationDetail.Say_IT.Questions[1].Question.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question2_Answer_Value =
        conversationDetail.Say_IT.Questions[1].Answer.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question2_ID = conversationDetail.Say_IT.Questions[1].Question_ID;
      urlHashRef.current.two = Question2_ID;

      //Ask Chintan bhai if remove this code
      //Do not remove this comment code

      // let Question3_Value = conversationDetail.Say_IT.Questions[2].Question.Language.filter((x: any) => (x.Language_Code == nativeLang))[0];
      // let Question3_Answer_Value = conversationDetail.Say_IT.Questions[2].Answer.Language.filter((x: any) => (x.Language_Code == targetLang))[0];

      // vocabulary Say_IT Question-1
      setValue('Conversation_SayIt_Question1_QuestionID', Question1_ID);
      setValue('Conversation_SayIt_Question1_Title', Question1_Value.Title);
      setValue('Conversation_SayIt_Question1_Title1', Question1_Value.Title1);
      setValue(
        'Conversation_SayIt_Question1_Answer',
        Question1_Answer_Value.Answer
      );
      handleOptionSelectType1('', Question1_Value.Type);
      setValue('Conversation_SayIt_Question1_Type', Question1_Value.Type);
      setValue(
        'Conversation_SayIt_Question1_Description',
        Question1_Value.Description
      );
      setConversationSayITImage1(Question1_Value.Image);
      setValue('Conversation_SayIt_Question1_Image', Question1_Value.Image);

      // vocabulary Say_IT Question-2
      setValue('Conversation_SayIt_Question2_QuestionID', Question2_ID);
      setValue('Conversation_SayIt_Question2_Title', Question2_Value.Title);
      setValue('Conversation_SayIt_Question2_Title1', Question2_Value.Title1);
      setValue(
        'Conversation_SayIt_Question2_Answer',
        Question2_Answer_Value.Answer
      );
      handleOptionSelectType2('', Question2_Value.Type);
      setValue('Conversation_SayIt_Question2_Type', Question2_Value.Type);
      setValue(
        'Conversation_SayIt_Question2_Description',
        Question2_Value.Description
      );
      setConversationSayITImage2(Question2_Value.Image);
      setValue('Conversation_SayIt_Question2_Image', Question2_Value.Image);

      //Ask Chintan bhai if remove this code
      //Do not remove this comment code

      // vocabulary Say_IT Question-3
      // setValue("Conversation_SayIt_Question3_Title", Question3_Value.Title)
      // setValue("Conversation_SayIt_Question3_Title1", Question3_Value.Title1)
      // setValue("Conversation_SayIt_Question3_Answer", Question3_Answer_Value.Answer)
      // handleOptionSelectType3("", Question3_Value.Type)
      // setValue("Conversation_SayIt_Question3_Type", Question3_Value.Type)
      // setValue("Conversation_SayIt_Question3_Description", Question3_Value.Description)
      // setConversationSayITImage3(Question3_Value.Image)
      // setValue("Conversation_SayIt_Question3_Image", Question3_Value.Image)
    }
  }, [conversationDetail]);

  const handleOptionSelectType1 = (event: any, data: any) => {
    var selectTypeValue1;
    if (event != '') {
      setSectionOptionValue1(event.target.value);
      selectTypeValue1 = event.target.value;
    }
    if (data != '') {
      setSectionOptionValue1(data);
      selectTypeValue1 = data;
    }
    if (selectTypeValue1 == 'None') {
      setDecription1(true);
    }
    if (selectTypeValue1 == 'Noun') {
      setDecription1(false);
    }
    if (selectTypeValue1 == 'Verb') {
      setDecription1(false);
    }
  };

  const handleOptionSelectType2 = (event: any, data: any) => {
    var selectTypeValue2;
    if (event != '') {
      setSectionOptionValue2(event.target.value);
      selectTypeValue2 = event.target.value;
    }
    if (data != '') {
      setSectionOptionValue2(data);
      selectTypeValue2 = data;
    }
    if (selectTypeValue2 == 'None') {
      setDecription2(true);
    }
    if (selectTypeValue2 == 'Noun') {
      setDecription2(false);
    }
    if (selectTypeValue2 == 'Verb') {
      setDecription2(false);
    }
  };

  const handleOptionSelectType3 = (event: any, data: any) => {
    var selectTypeValue3;
    if (event != '') {
      setSectionOptionValue3(event.target.value);
      selectTypeValue3 = event.target.value;
    }
    if (data != '') {
      setSectionOptionValue3(data);
      selectTypeValue3 = data;
    }
    if (selectTypeValue3 == 'None') {
      setDecription3(true);
    }
    if (selectTypeValue3 == 'Noun') {
      setDecription3(false);
    }
    if (selectTypeValue3 == 'Verb') {
      setDecription3(false);
    }
  };

  useEffect(() => {
    if (imageURL != '') {
      if (activeKey === 'Conversation_SayIt_Question1_Image') {
        setConversationSayITImage1(imageURL);
        setValue('Conversation_SayIt_Question1_Image', imageURL);
        setActiveKey('');
      }
      if (activeKey === 'Conversation_SayIt_Question2_Image') {
        setConversationSayITImage2(imageURL);
        setValue('Conversation_SayIt_Question2_Image', imageURL);
        setActiveKey('');
      }
      if (activeKey === 'Conversation_SayIt_Question3_Image') {
        setConversationSayITImage3(imageURL);
        setValue('Conversation_SayIt_Question3_Image', imageURL);
        setActiveKey('');
      }
    }
  }, [imageURL]);

  useEffect(() => {
    if (imageloading) {
      if (activeKey === 'Conversation_SayIt_Question1_Image') {
        setConversationSayITImageLoader1(true);
      }
      if (activeKey === 'Conversation_SayIt_Question2_Image') {
        setConversationSayITImageLoader2(true);
      }
      if (activeKey === 'Conversation_SayIt_Question3_Image') {
        setConversationSayITImageLoader3(true);
      }
    }

    if (!imageloading) {
      setConversationSayITImageLoader1(false);
      setConversationSayITImageLoader2(false);
      setConversationSayITImageLoader3(false);
    }
  }, [imageloading]);

  useEffect(() => {
    setConversationSayITImage1(conversationSayITImage1);
    setValue('Conversation_SayIt_Question1_Image', conversationSayITImage1);
  }, [conversationSayITImage1]);

  useEffect(() => {
    setConversationSayITImage2(conversationSayITImage2);
    setValue('Conversation_SayIt_Question2_Image', conversationSayITImage2);
  }, [conversationSayITImage2]);

  useEffect(() => {
    setConversationSayITImage3(conversationSayITImage3);
    setValue('Conversation_SayIt_Question3_Image', conversationSayITImage3);
  }, [conversationSayITImage3]);

  useEffect(() => {
    setActiveKey(activeKey);
  }, [activeKey]);

  const handleChnageImage = (event: any) => {
    setActiveKey(event.target.name);

    imageUploadReq({ file: event.target.files[0] });
  };

  const hash = urlHashRef.current;

  return (
    <>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Say It 1
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.one)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Word Name (only nouns and verbs) 1
                    <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle(false)}
                    open={openTitle}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_SayIt_Question1_Title
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Title 1'
                      {...register('Conversation_SayIt_Question1_Title')}
                      onFocus={() => setOpenTitle(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_SayIt_Question1_Title && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question1_Title.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title 2<span className='astrick'>*</span>
                  </label>
                  {/* <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle(false)}
                    open={openTitle}
                    disableHoverListener
                    disableTouchListener
                  > */}
                  <input
                    type='text'
                    className={
                      errors.Conversation_SayIt_Question1_Title1
                        ? 'form-control errors'
                        : 'form-control'
                    }
                    placeholder='Enter Title 2'
                    {...register('Conversation_SayIt_Question1_Title1')}
                    // onFocus={() => setOpenTitle(true)}
                    disabled={loading}
                  ></input>
                  {/* </Tooltip> */}
                  {errors.Conversation_SayIt_Question1_Title1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question1_Title1.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12'>
                  <label className='fw-bold form-label'>
                    Type <span className='astrick'>*</span>
                  </label>
                  <select
                    id=''
                    className='level-className form-control'
                    value={sectionOptionValue1}
                    name='Conversation_SayIt_Question1_Type'
                    {...register('Conversation_SayIt_Question1_Type')}
                    onChange={(e) => {
                      handleOptionSelectType1(e, '');
                    }}
                    defaultValue={typeOptions[0]}
                    disabled={loading}
                  >
                    {typeOptions.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </select>
                </div>
              </div>
              {!decription1 && (
                <div className='add-lesson-row-section'>
                  <div className='mt-3 col-md-12 form-group '>
                    <label className='fw-bold form-label'>
                      Description <span className='astrick'>*</span>
                    </label>
                    <Tooltip
                      title='Native Language'
                      placement='top'
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setOpenDesctiption(false)}
                      open={openDesctiption}
                      disableHoverListener
                      disableTouchListener
                    >
                      <textarea
                        onFocus={() => setOpenDesctiption(true)}
                        typeof='text'
                        className='form-control'
                        {...register(
                          'Conversation_SayIt_Question1_Description'
                        )}
                        placeholder='Enter Description'
                        disabled={loading}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Answer <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenAnswer(false)}
                    open={openAnswer}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_SayIt_Question1_Answer
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Answer'
                      {...register('Conversation_SayIt_Question1_Answer')}
                      onFocus={() => setOpenAnswer(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_SayIt_Question1_Answer && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question1_Answer.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* Do not remove this code  */}
            {/* <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Upload Image <span className='astrick'>*</span>
                  </label>
                  <div className='question-image'>
                    <LoadingOverlay
                      active={conversationSayITImageLoader1}
                      spinner
                      className='spinner-animation'
                    >
                      <img src={conversationSayITImage1}  />
                    </LoadingOverlay>
                  </div>
                  <div className='main-cercle-div'>
                    <input
                      type='file'
                      className='form-control'
                      name='Conversation_SayIt_Question1_Image'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => handleChnageImage(e)}
                      disabled={loading}
                    />
                    {errors.Conversation_SayIt_Question1_Image && (
                      <p style={{ color: 'red' }}>
                        {errors.Conversation_SayIt_Question1_Image.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <br />
        </Collapsible>

        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Say It 2
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
          containerElementProps={getHighlightedSection(hash.two)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title 1<span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle1(false)}
                    open={openTitle1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_SayIt_Question2_Title
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Title 1'
                      {...register('Conversation_SayIt_Question2_Title')}
                      onFocus={() => setOpenTitle1(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_SayIt_Question2_Title && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question2_Title.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title 2<span className='astrick'>*</span>
                  </label>
                  {/* <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle(false)}
                    open={openTitle}
                    disableHoverListener
                    disableTouchListener
                  > */}
                  <input
                    type='text'
                    className={
                      errors.Conversation_SayIt_Question2_Title1
                        ? 'form-control errors'
                        : 'form-control'
                    }
                    placeholder='Enter Title 2'
                    {...register('Conversation_SayIt_Question2_Title1')}
                    // onFocus={() => setOpenTitle(true)}
                    disabled={loading}
                  ></input>
                  {/* </Tooltip> */}
                  {errors.Conversation_SayIt_Question2_Title1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question2_Title1.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12'>
                  <label className='fw-bold form-label'>
                    Type <span className='astrick'>*</span>
                  </label>
                  <select
                    id=''
                    className='level-className form-control'
                    value={sectionOptionValue2}
                    name='Conversation_SayIt_Question2_Type'
                    {...register('Conversation_SayIt_Question2_Type')}
                    onChange={(e) => {
                      handleOptionSelectType2(e, '');
                    }}
                    defaultValue={typeOptions[0]}
                    disabled={loading}
                  >
                    {typeOptions.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </select>
                </div>
              </div>
              {!decription2 && (
                <div className='add-lesson-row-section'>
                  <div className='mt-3 col-md-12 form-group '>
                    <label className='fw-bold form-label'>
                      Description <span className='astrick'>*</span>
                    </label>
                    <Tooltip
                      title='Native Language'
                      placement='top'
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setOpenDesctiption1(false)}
                      open={openDesctiption1}
                      disableHoverListener
                      disableTouchListener
                    >
                      <textarea
                        onFocus={() => setOpenDesctiption1(true)}
                        typeof='text'
                        className='form-control'
                        name='Question'
                        {...register(
                          'Conversation_SayIt_Question2_Description'
                        )}
                        placeholder='Enter Description'
                        disabled={loading}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Answer <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenAnswer1(false)}
                    open={openAnswer1}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_SayIt_Question2_Answer
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Answer'
                      {...register('Conversation_SayIt_Question2_Answer')}
                      onFocus={() => setOpenAnswer1(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_SayIt_Question2_Answer && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question2_Answer.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/*  Do not remove this comment code  */}
            {/* <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Upload Image <span className='astrick'>*</span>
                  </label>
                  <div className='question-image'>
                    <LoadingOverlay
                      active={conversationSayITImageLoader2}
                      spinner
                      className='spinner-animation'
                    >
                      <img src={conversationSayITImage2}  />
                    </LoadingOverlay>
                  </div>
                  <div className='main-cercle-div'>
                    <input
                      type='file'
                      className='form-control'
                      name='Conversation_SayIt_Question2_Image'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => handleChnageImage(e)}
                      disabled={loading}
                    />
                    {errors.Conversation_SayIt_Question2_Image && (
                      <p style={{ color: 'red' }}>
                        {errors.Conversation_SayIt_Question2_Image.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <br />
        </Collapsible>

        {/*Ask Chintan bhai if remove this code
        Do not remove this comment code  */}

        {/* <br/>
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
              <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Say It 3
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}

          containerElementProps={getHighlightedSection(hash.three)}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle2(false)}
                    open={openTitle2}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_SayIt_Question3_Title
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Title'
                      {...register('Conversation_SayIt_Question3_Title')}
                      onFocus={() => setOpenTitle2(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_SayIt_Question3_Title && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question3_Title.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title 1<span className='astrick'>*</span>
                  </label>
                  <input
                    type='text'
                    className={errors.Conversation_SayIt_Question3_Title1 ? 'form-control errors' : 'form-control'}
                    placeholder='Enter Title 1'
                    {...register('Conversation_SayIt_Question3_Title1')}
                    disabled={loading}
                  ></input>
                  {errors.Conversation_SayIt_Question3_Title1 && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question3_Title1.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12'>
                  <label className='fw-bold form-label'>
                    Type <span className='astrick'>*</span>
                  </label>
                  <select
                    id=''
                    className='level-className form-control'
                    value={sectionOptionValue3}
                    name='Conversation_SayIt_Question3_Type'
                    {...register('Conversation_SayIt_Question3_Type')}
                    onChange={(e) => {
                      handleOptionSelectType3(e, "");
                    }}
                    defaultValue={typeOptions[0]}
                    disabled={loading}
                  >
                    {typeOptions.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </select>
                </div>
              </div>
              {!decription3 && (
                <div className='add-lesson-row-section'>
                  <div className='mt-3 col-md-12 form-group '>
                    <label className='fw-bold form-label'>
                      Description <span className='astrick'>*</span>
                    </label>
                    <Tooltip
                      title='Native Language'
                      placement='top'
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setOpenDesctiption2(false)}
                      open={openDesctiption2}
                      disableHoverListener
                      disableTouchListener
                    >
                      <textarea
                        onFocus={() => setOpenDesctiption2(true)}
                        typeof='text'
                        className='form-control'
                        name='Question'
                        {...register(
                          'Conversation_SayIt_Question3_Description'
                        )}
                        placeholder='Enter Description'
                        disabled={loading}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Answer <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Target Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenAnswer2(false)}
                    open={openAnswer2}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.Conversation_SayIt_Question3_Answer
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Answer'
                      {...register('Conversation_SayIt_Question3_Answer')}
                      onFocus={() => setOpenAnswer2(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Conversation_SayIt_Question3_Answer && (
                    <p style={{ color: 'red' }}>
                      {errors.Conversation_SayIt_Question3_Answer.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group'>
                  <label className='fw-bold form-label'>
                    Upload Image <span className='astrick'>*</span>
                  </label>
                  <div className='question-image'>
                    <LoadingOverlay
                      active={conversationSayITImageLoader3}
                      spinner
                      className='spinner-animation'
                    >
                      <img src={conversationSayITImage3}  />
                    </LoadingOverlay>
                  </div>
                  <div className='main-cercle-div'>
                    <input
                      type='file'
                      className='form-control'
                      name='Conversation_SayIt_Question3_Image'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => handleChnageImage(e)}
                      disabled={loading}
                    />
                    {errors.Conversation_SayIt_Question3_Image && (
                      <p style={{ color: 'red' }}>
                        {errors.Conversation_SayIt_Question3_Image.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
        </Collapsible> */}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    imageURL: state.lesson.imageURL,
    imageloading: state.lesson.imageloading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  imageUploadReq: (param: any) => dispatch(imageUpload(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationSayItActivity);
