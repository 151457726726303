import React from 'react';
import { CellProps, Column } from 'react-table';
import { PageTitle } from '../../../theme/layout/core';
import useTableState from 'app/components/Table/useTableState';
import { TablePagination } from 'app/components/Table/TablePagination';
import TableInner from 'app/components/Table/TableInner';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import Loader from 'app/components/Loader';
import Table from 'app/components/Table';
import callBackendApi from 'utils/callBackendApi';
import { urlRoutes } from 'shared/apiConfig';
import useBackendApiCaller from 'hooks/useBackendApiCaller';
import { DateTime } from 'luxon';

const UserLessonLogs: React.FC = () => {
  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Student Name
          </span>
        ),
        accessor: 'User.FirstName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {result.User[0]?.FirstName ? result.User[0]?.FirstName : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Student ID#',
        accessor: 'Student.Student_Profile_ID',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result.Student[0]?.Student_Profile_ID
            ? result.Student[0]?.Student_Profile_ID
            : '-';
        },
      },
      {
        Header: 'Course Name',
        accessor: 'CourseName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.Course[0]?.name ? result.Course[0]?.name : '-';
        },
      },
      {
        Header: 'Percentage',
        accessor: 'Percentage',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return result.Percentage ? result.Percentage + '%' : '-';
        },
      },
      {
        Header: 'Point Completed',
        accessor: 'Completedpoints',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result.Completedpoints ? result.Completedpoints : '-';
        },
      },
      {
        Header: 'Lesson Name',
        accessor: 'Lesson.Lesson_Name',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result.Lesson[0]?.Lesson_Name
            ? result.Lesson[0]?.Lesson_Name
            : '-';
        },
      },
      {
        Header: 'Started Date',
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          const originalDate = result.StartedDate;
          const startedDate = convertDateFormat(originalDate);
          return (
            <span className='pe-4 d-block'>
              {startedDate ? startedDate : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Completed Date',
        accessor: 'Student.completedLessons.completedDate',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          const originalDate = result.CompletedDate;
          const convertedDate = convertDateFormat(originalDate);
          return (
            <span className='pe-4 d-block'>
              {convertedDate === 'Invalid DateTime'
                ? 'Not Available'
                : convertedDate}
            </span>
          );
        },
      },
    ],
    []
  );

  const convertDateFormat = (dateString: any) => {
    const dateTime = DateTime.fromISO(dateString);
    const formattedDate = dateTime.toFormat('LL-dd-yyyy hh:mma');

    return formattedDate;
  };

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageSize: 50,
    });

  const { sort, page, limit, search } = tableState;

  const fetcher = React.useCallback(() => {
    return callBackendApi('post', {
      url: urlRoutes.userLessonLogs,
      data: {
        page,
        limit,
        search,
        sort,
      },
    });
  }, [page, limit, sort, search]);

  const [loading, data] = useBackendApiCaller(fetcher, [fetcher]);

  const list = data?.ResultObject?.userdata ?? [];

  console.log('list', list);

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <PageTitle breadcrumbs={[]}>User Lesson Logs</PageTitle>
      <div className='card mb-10'>
        <Table
          data={list}
          columns={columns}
          initialState={tableInitialState}
          pageCount={data?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header justify-content-between border-0 pt-5'>
              <h3 className='card-title'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  User Lesson Log Data
                </span>
              </h3>
              <span>
                <TableGlobalSearch />
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={loading} entityLabel='user lesson log' />
                </div>
                <div className='col-12 border-top pt-5'>
                  <TablePagination pageOptions={[5, 10, 20, 50]} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default UserLessonLogs;
