import { useGetQuery } from 'hooks/reactQueryHelper';
import { Badge, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import moment from 'moment';

const DueDatesDetailModal = (props: any) => {
  const { dueDates } = props;
  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Due Dates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <ListGroup as='ol' numbered>
            {dueDates?.map((item: any) => {
              return (
                <ListGroup.Item as='li'>
                  {moment(item.date).format('MM/DD/YYYY')}{' '}
                  <Badge bg='primary'>{item.points}</Badge>{' '}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        }
        {dueDates.length === 0 && (
          <ListGroup>
            <ListGroup.Item>No schedule Date Available</ListGroup.Item>
          </ListGroup>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DueDatesDetailModal;
