import store from 'store';
import { actionTypes } from './actionTypes';

export function startLoading() {
  store.dispatch({ type: actionTypes.START });
}

export function stopLoading() {
  store.dispatch({ type: actionTypes.STOP });
}
