import {
  GET_ALL_LANGUAGE,
  GET_ALL_LANGUAGE_SUCCESS,
  IMAGE_UPLOAD, IMAGE_UPLOAD_FAILURE,
  IMAGE_UPLOAD_SUCCESS,
  LESSON_DELETE,
  LESSON_DELETE_SUCCESS,
  LESSON_DETAILS_CLEAR,
  LESSON_FAILURE,
  LESSON_GET,
  LESSON_GET_BY_ID,
  LESSON_GET_BY_ID_SUCCESS,
  LESSON_GET_LANGUAGE,
  LESSON_GET_LANGUAGE_SUCCESS,
  LESSON_GET_SUCCESS, LESSON_ID_EXIST_OR_NOT, LESSON_ID_EXIST_OR_NOT_SUCCESS, LESSON_SAVE,
  LESSON_SAVE_SUCCESS,
  LESSON_UPDATE,
  LESSON_UPDATE_MULTIPLE_LANGUAGE,
  LESSON_UPDATE_MULTIPLE_LANGUAGE_SUCCESS,
  LESSON_UPDATE_SUCCESS,
  LEVEL_FAILURE,
  LEVEL_GET_ALL,
  LEVEL_GET_SUCCESS,
  UPDATE_LANGUAGE_ACTIVE_STATUS,
  UPDATE_LANGUAGE_ACTIVE_STATUS_SUCCESS,
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_FAILURE,
  UPLOAD_VIDEO_SUCCESS
} from "./actionTypes"

const initialState: any = {
  loading: false,
  levelloading: false,
  token: "",
  error: "",
  success: "",
  lessonList: "",
  levellist: "",
  imageURL: "",
  languageList: "",
  textValue: "",
  lessonDetails: "",
  videoURL: "",
  videoloading: false,
  imageloading: false,
  lessonIDError: "",
  lessonIDErrorLoading: false
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LESSON_GET:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        lessonDetails: "",
        lessonIDError: ""
      }
    case LESSON_GET_SUCCESS:

      return {
        ...state,
        lessonList: action.payload,
        loading: false,
        success: "",
        lessonDetails: "",
        lessonIDError: ""
      }
    case LESSON_SAVE:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        lessonList: "",
        lessonIDError: ""
      }
    case LESSON_SAVE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
        lessonIDError: ""
      }
    case LEVEL_GET_ALL:
      return {
        ...state,
        levelloading: true,
        success: "",
        error: "",
      }
    case LEVEL_GET_SUCCESS:
      return {
        ...state,
        levellist: action.payload,
        levelloading: false,
        success: "",
      }
    case IMAGE_UPLOAD:
      return {
        ...state,
        imageloading: true,
        success: "",
        error: "",
        imageURL: ""
      }
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        imageURL: action.payload,
        imageloading: false,
      }
    case IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case GET_ALL_LANGUAGE:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        languageList: ""
      }
    case GET_ALL_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageList: action.payload.ResultObject,
        loading: false,
        success: "",
      }
    case UPDATE_LANGUAGE_ACTIVE_STATUS:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case UPDATE_LANGUAGE_ACTIVE_STATUS_SUCCESS:

      return {
        ...state,
        loading: false,
        success: action.payload,
        error: ""
      }
    case LESSON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case LEVEL_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case LESSON_GET_LANGUAGE:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        lessonLanguageList: ""
      }
    case LESSON_GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        lessonLanguageList: action.payload,
        loading: false,
        success: "",
      }
    case LESSON_DELETE:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case LESSON_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: "",
      }
    case LESSON_GET_BY_ID:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        lessonDetails: "",
        lessonIDError: ""
      }
    case LESSON_GET_BY_ID_SUCCESS:
      return {
        ...state,
        lessonDetails: action.payload.ResultObject,
        loading: false,
        success: "",
        lessonIDError: ""
      }
    case LESSON_UPDATE:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        lessonList: "",
        lessonDetails: ""
      }
    case LESSON_UPDATE_SUCCESS:
      return {
        ...state,
        lessonDetails: "",
        success: action.payload,
        loading: false,
      }
    case UPLOAD_VIDEO:
      return {
        ...state,
        videoloading: true,
        success: "",
        error: "",
        videoURL: ""
      }
    case UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        videoURL: action.payload,
        videoloading: false,
      }
    case UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case LESSON_DETAILS_CLEAR:
      return {
        ...state,
        lessonDetails: ""
      }
    case LESSON_ID_EXIST_OR_NOT:
      return {
        ...state,
        lessonIDErrorLoading: true,
        success: "",
        error: "",
        lessonIDError: ""
      }
    case LESSON_ID_EXIST_OR_NOT_SUCCESS:
      return {
        ...state,
        lessonIDError: action.payload.ResultObject,
        lessonIDErrorLoading: false,
        success: "",
      }
    case LESSON_UPDATE_MULTIPLE_LANGUAGE:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
        lessonList: "",
        lessonDetails: ""
      }
    case LESSON_UPDATE_MULTIPLE_LANGUAGE_SUCCESS:
      return {
        ...state,
        lessonDetails: "",
        success: action.payload,
        loading: false,
      }
    default:
      return {
        ...state
      }
  }
}
export default reducer
