import React, { FC } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import AIActivityImagePage from './AIActivityImagePage';

const AIActivityImagePageWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>AI Activity Image Details</PageTitle>
            <AIActivityImagePage />
        </>
    );
};

export default AIActivityImagePageWrapper;
