export const LANGUAGE_GET_ALL = "LANGUAGE_GET_ALL"
export const LANGUAGE_GET_ALL_SUCCESS = "LANGUAGE_GET_ALL_SUCCESS"
export const LANGUAGE_GET_ALL_FAIL = "LANGUAGE_GET_ALL_FAIL"

export const LANGUAGE_GET_BY_ID = "LANGUAGE_GET_BY_ID"
export const LANGUAGE_GET_BY_ID_SUCCESS = "LANGUAGE_GET_BY_ID_SUCCESS"
export const LANGUAGE_GET_BY_ID_FAIL = "LANGUAGE_GET_BY_ID_FAIL"

export const LANGUAGE_ADD = "LANGUAGE_ADD"
export const LANGUAGE_ADD_SUCCESS = "LANGUAGE_ADD_SUCCESS"
export const LANGUAGE_ADD_FAIL = "LANGUAGE_ADD_FAIL"

export const LANGUAGE_UPDATE = "LANGUAGE_UPDATE"
export const LANGUAGE_UPDATE_SUCCESS = "LANGUAGE_UPDATE_SUCCESS"
export const LANGUAGE_UPDATE_FAIL = "LANGUAGE_UPDATE_FAIL"

export const LANGUAGE_DELETE = "LANGUAGE_DELETE"
export const LANGUAGE_DELETE_SUCCESS = "LANGUAGE_DELETE_SUCCESS"
export const LANGUAGE_DELETE_FAIL = "LANGUAGE_DELETE_FAIL"
export const LANGUAGE_CLEAR = "LANGUAGE_CLEAR"

