import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';
import { connect, ConnectedProps } from 'react-redux';
import createNotification from '../../../helpers/alert';
import { useDispatch } from 'react-redux';
import { MdMoreVert, MdOutlineGetApp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  allLanguageGet,
  lessonDelete,
  lessonDetailsClear,
  lessonGet,
  lessonGetById,
  lessonUpdate,
  levelGet,
} from '../../../store/lesson/actions';
import LanguageAddPage from './LanguageAddPage';
import LessonDeleteModel from './LessonDeleteModel';
import Swal from 'sweetalert2';
import { ClearSuccesError } from '../../../store/actions';
import Loader from 'app/components/Loader';
import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import TableInner from 'app/components/Table/TableInner';
import { TablePagination } from 'app/components/Table/TablePagination';
import useTableState from '../../components/Table/useTableState';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const LessonPage: React.FC = (props: any) => {
  const {
    lessongetReq,
    loading,
    success,
    error,
    lessonList,
    languageGetReq,
    languageList,
    lessonDeleteReq,
    ClearSuccesErrorReq,
    lessongetByIdReq,
    levellist,
    onGetAllLevel,
    lessonDetails,
    lessonupdateReq,
  } = props;

  const [lessonData, setLessonData] = useState([]);
  const [languageModel, setLanguageModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [lessonDeleteID, setLessonDeleteID] = useState('');
  const [countTotalPage, setCountTotalPage] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const { sort, page, limit, search } = tableState;
  useEffect(() => {
    setLoadingData(true);
    const data = {
      page,
      limit,
      search,
      sort,
    };
    lessongetReq(data);
    onGetAllLevel();
  }, [page, limit, sort, search]);

  useEffect(() => {
    // setLoadingData(true)
    if (lessonList?.ResultData?.length >= 0) {
      setLoadingData(false);
      setLessonData(lessonList.ResultData);
      setCountTotalPage(lessonList.totalPages);
    }
  }, [lessonList]);

  useEffect(() => {
    if (success) {
      ClearSuccesErrorReq();
      if (success.ResultCode === 'SUCCESS') {
        const data = {
          page,
          limit,
          search,
          sort,
        };
        lessongetReq(data);
        setLoadingData(false);
        Swal.fire(success.ResultMessage, ' ', 'success');
      } else {
        Swal.fire(error.ResultMessage, ' ', 'warning');
      }
    }
  }, [success, error]);

  const handleCloseLanguageModel = () => {
    setLanguageModel(false);
  };

  const handleLanguageData = (id: any) => {
    languageGetReq({ Lesson_Id: id });
    setLanguageModel(true);
  };

  const handleDeleteLesson = () => {
    lessonDeleteReq({ Lesson_ID: lessonDeleteID });
    setDeleteModel(false);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleEditLesson = (id: any) => {
    navigate(`/lesson/edit/${id}`);
  };
  const handleDelete = (id: any) => {
    setDeleteModel(true);
    setLessonDeleteID(id);
  };

  useEffect(() => {
    if (lessonDetails != '') {
      const ConversationSectionData = lessonDetails?.Activity_Details?.filter(
        (x: any) => x.Section == 'ConversationSection'
      )[0];
      const VocabularySectionData = lessonDetails?.Activity_Details?.filter(
        (x: any) => x.Section == 'VocabularySection'
      )[0];
      const GrammarSectionData = lessonDetails?.Activity_Details?.filter(
        (x: any) => x.Section == 'GrammarSection'
      )[0];
      const QuizSectionData = lessonDetails?.Activity_Details?.filter(
        (x: any) => x.Section == 'QuizSection'
      )[0];

      let VocabularySectionSaveValue;
      let QuizSectionSaveValue;

      if (VocabularySectionData != undefined) {
        const VocabularyTapITData = VocabularySectionData.Tap_IT.Questions.map(
          (data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          }
        );

        const VocabularySayITData = VocabularySectionData.Say_IT.Questions.map(
          (data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          }
        );

        const VocabularyWriteITData =
          VocabularySectionData.Write_IT.Questions.map((data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          });

        const VocabularyListenITData =
          VocabularySectionData.Listen_IT.Questions.map((data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          });

        VocabularySectionSaveValue = {
          Section: 'VocabularySection',
          VocabularyVideoUrl: VocabularySectionData.VocabularyVideoUrl,
          Video_Url: VocabularySectionData.Video_Url,
          Tap_IT: { Questions: VocabularyTapITData },
          Say_IT: { Questions: VocabularySayITData },
          Write_IT: { Questions: VocabularyWriteITData },
          Listen_IT: { Questions: VocabularyListenITData },
        };
      }

      if (QuizSectionData != undefined) {
        const QuizTapITData = QuizSectionData.Tap_IT.Questions.map(
          (data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          }
        );

        const QuizWriteITData = QuizSectionData.Write_IT.Questions.map(
          (data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          }
        );

        const QuizListenITData = QuizSectionData.Listen_IT.Questions.map(
          (data: any) => {
            return {
              Question_ID: data.Question_ID,
              Answer: {
                Language: data.Answer.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
              Question: {
                Language: data.Question.Language.filter(
                  (x: any) => x.Language_Code == 'en'
                ),
              },
            };
          }
        );

        QuizSectionSaveValue = {
          Section: 'QuizSection',
          // Quiz_Title: QuizSectionData.Quiz_Title,
          // Quiz_Discription: QuizSectionData.Quiz_Description,
          Tap_IT: { Questions: QuizTapITData },
          Write_IT: { Questions: QuizWriteITData },
          Listen_IT: { Questions: QuizListenITData },
        };
      }

      if (ConversationSectionData != undefined) {
        const ConversationTapITQuestionData =
          ConversationSectionData.Tap_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationTapITAnswerData =
          ConversationSectionData.Tap_IT.Questions.map((data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationSayITQuestionData =
          ConversationSectionData.Say_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationSayITAnswerData =
          ConversationSectionData.Say_IT.Questions.map((data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationWriteITQuestionData =
          ConversationSectionData.Write_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationWriteITAnswerData =
          ConversationSectionData.Write_IT.Questions.map((data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationListenITQuestionData =
          ConversationSectionData.Listen_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const ConversationListenITAnswerData =
          ConversationSectionData.Listen_IT.Questions.map((data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });
      }

      if (GrammarSectionData != undefined) {
        const GrammarTapITQuestionData =
          GrammarSectionData.Tap_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const GrammarTapITAnswerData = GrammarSectionData.Tap_IT.Questions.map(
          (data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          }
        );

        const GrammarWriteITQuestionData =
          GrammarSectionData.Write_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const GrammarWriteITAnswerData =
          GrammarSectionData.Write_IT.Questions.map((data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const GrammarListenITQuestionData =
          GrammarSectionData.Listen_IT.Questions.map((data: any) => {
            return data.Question.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });

        const GrammarListenITAnswerData =
          GrammarSectionData.Listen_IT.Questions.map((data: any) => {
            return data.Answer.Language.filter(
              (x: any) => x.Language_Code == 'en'
            )[0];
          });
      }

      let Activity_Data = [];
      if (VocabularySectionData != undefined) {
        Activity_Data.push(VocabularySectionSaveValue);
      }
      if (ConversationSectionData != undefined) {
        // Activity_Data.push(ConversationSection)
      }
      if (GrammarSectionData != undefined) {
        // Activity_Data.push(GrammarSection)
      }
      if (QuizSectionData != undefined) {
        Activity_Data.push(QuizSectionSaveValue);
      }

      const fromData = {
        Lesson_ID: lessonDetails._id,
        LessonName: lessonDetails.Lesson_Name,
        Age: lessonDetails.Lesson_Age_Group,
        Level: lessonDetails.Lesson_Level,
        Its_Premium_Lesson: lessonDetails.Its_Premium_Lesson,
        Activity_Details: Activity_Data,
        LessonID: lessonDetails.Lesson_ID,
      };
      lessonupdateReq(fromData);
    }
  }, [lessonDetails]);

  const updateLesson = (id: any) => {
    lessongetByIdReq({ Lesson_ID: id });
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>ID</span>
        ),

        accessor: 'Lesson_ID',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='ps-4 d-flex justify-content-start flex-row align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <a
                  href={'mailto:' + result?.Lesson_ID}
                  className='text-dark fw-bold text-hover-primary d-block fs-6'
                >
                  {result?.Lesson_ID}
                </a>
              </div>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Name
          </span>
        ),

        accessor: 'Lesson_Name',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='ps-4 d-flex justify-content-start flex-row align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <a
                  href={'mailto:' + result.Lesson_Level}
                  className='text-dark fw-bold text-hover-primary d-block fs-6'
                >
                  {result.Lesson_Name}
                </a>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Level',
        accessor: 'Lesson_Level',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          var level: any = [];
          level = props.initialState;
          const lesson_level_data: any = [];
          const split_string_Array = result?.Lesson_Level?.split(',');
          split_string_Array?.forEach((el: any) => {
            let findElement =
              level && level?.find((element: any) => element._id == el);
            findElement &&
              lesson_level_data.push({
                label: findElement.Level_Name,
                value: findElement._id,
              });
          });
          var levelData = lesson_level_data?.map((result: any) => {
            return result.label;
          });
          return (
            <span
              // href={'mailto:' + result.Lesson_Level}
              className='text-dark fw-bold text-hover-primary d-block fs-6'
            >
              {levelData.map((i: any) => (
                <span
                  className='mr-3 badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {i}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Age Group',
        accessor: 'Lesson_Age_Group',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span
              // href={'mailto:' + result.Lesson_Age_Group}
              className='text-dark fw-bold text-hover-primary d-block fs-6'
            >
              {result.Lesson_Age_Group.map((item: any) => (
                <span
                  className='badge badge-pill badge-primary'
                  style={{ marginRight: '3px' }}
                >
                  {item?.toString()}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-start flex-row align-items-center'>
              {moment(result.createdAt).format('DD-MM-YYYY HH:MM A')}
            </div>
          );
        },
      },
      {
        Header: () => (
          <span
            className='pe-5 d-block text-end rounded-end'
            style={{ paddingLeft: '12px' }}
          >
            Actions
          </span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: any) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  handleEditLesson(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                onClick={() => {
                  handleDelete(result._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
              {/* <button
                className='btn btn-icon btn-secondary btn-sm'
                data-bs-toggle="modal"
                data-bs-target="#Edit_Modal_scrollable"
                style={{ marginLeft: "4px" }}
                onClick={() => handleLanguageData(result._id)}
              >
                <MdMoreVert size={30} />
              </button> */}
              <div className='dropdown lesson-dropdown-btn'>
                <button
                  className='btn btn-icon btn-secondary btn-sm dropdown-toggle'
                  style={{ marginLeft: '4px' }}
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <MdMoreVert size={30} />
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <button
                      className='dropdown-item'
                      type='button'
                      data-bs-toggle='modal'
                      data-bs-target='#Edit_Modal_scrollable'
                      onClick={() => handleLanguageData(result._id)}
                    >
                      Add Language
                    </button>
                  </li>
                  {/* <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => updateLesson(result._id)}
                    >
                      Update Lesson
                    </button>
                  </li> */}
                </ul>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Loader loading={loading} />
      <div className='card mb-10'>
        <Table
          data={lessonData}
          columns={columns}
          initialState={levellist}
          pageCount={countTotalPage ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Lesson Data
                </span>{' '}
              </h3>
              <span className='d-flex align-items-center'>
                <span className='me-2'>
                  <TableGlobalSearch />
                </span>
                <span>
                  <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <Link to={'/addlesson'} className='btn btn-primary'>
                      <KTSVG
                        path='media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-3'
                      />
                      Add Lesson
                    </Link>
                  </div>
                </span>
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={loading} entityLabel='lesson' />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <LanguageAddPage
        languageModel={languageModel}
        success={success}
        // loading={loading}
        levellist={levellist}
        handleCloseLanguageModel={handleCloseLanguageModel}
        languageList={languageList}
      />
      <LessonDeleteModel
        deleteModel={deleteModel}
        handleDeleteLesson={handleDeleteLesson}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
    </>
  );
};

// export default LessonPage
const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    lessonList: state.lesson.lessonList,
    languageList: state.lesson.languageList,
    lessonDetails: state.lesson.lessonDetails,
    levellist: state.lesson.levellist,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  lessongetReq: (param: any) => dispatch(lessonGet(param)),
  languageGetReq: (param: any) => dispatch(allLanguageGet(param)),
  lessonDeleteReq: (param: any) => dispatch(lessonDelete(param)),
  ClearSuccesErrorReq: () => dispatch(ClearSuccesError()),
  lessongetByIdReq: (param: any) => dispatch(lessonGetById(param)),
  onGetAllLevel: (param: any) => dispatch(levelGet(param)),
  lessonupdateReq: (param: any) => dispatch(lessonUpdate(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonPage);
