import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { Formik, Form, FastField, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Container, InputGroup, Row } from 'react-bootstrap';
import { Button, Form as BForm, Modal, Spinner } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';

const LiveClassContentGradeEarnedAdd = (props: any) => {
  const formRef = useRef<any>();
  const {
    setShow,
    formData,
    setFormData,
    gradeEarnedData,
    setGradeEarnedData,
  } = props;

  const validationSchema = Yup.object({
    time: Yup.string().required('Please enter time.'),
    classRoomEvent: Yup.object().nullable().required('Please select.'),
    message: Yup.object().when(
      'classRoomEvent',
      (classRoomEvent, schema): any => {
        if (classRoomEvent?.value == 'chat') {
          return Yup.string().required('Please enter message.');
        } else {
          return Yup.string().nullable();
        }
      }
    ),
    earnPoint: Yup.string().required('Please enter grade earned.'),
  });

  let initialValues = {
    index: formData?.index,
    time: formData?.time,
    classRoomEvent: formData?.classRoomEvent,
    message: formData?.message,
    // earnPoint: formData?.earnPoint,
    earnPoint: 100,
  };

  const _handleSubmit = async (values: any, { resetForm, setValues }: any) => {
    // const payload = {...values, earnTotalPoint: 101 } // Construct the new payload
    //    setValues(payload) // Updates Formik's internal state
    let newForm: any = {
      time: values?.time,
      classRoomEvent: values?.classRoomEvent,
      earnPoint: values?.earnPoint,
    };

    if (values?.classRoomEvent?.value == 'chat') {
      newForm.message = values?.message;
    }

    if (values?.index === undefined || values?.index < 0) {
      let data = [...gradeEarnedData, newForm]?.sort(function (a: any, b: any) {
        return a?.time?.localeCompare(b?.time);
      });
      setGradeEarnedData(data);
    } else {
      var updatedData = gradeEarnedData
        ?.map((item: any, index: any) => {
          if (index === values.index) {
            return newForm;
          }
          return item;
        })
        ?.sort(function (a: any, b: any) {
          return a?.time?.localeCompare(b?.time);
        });
      setGradeEarnedData(updatedData);
    }

    resetForm();
    setFormData({});
    setShow(false);
  };

  const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];

  const handleSubmitByRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit(); // Call the submitForm method on the form component
    }
  };

  function validate(evt: any) {
    var theEvent = evt || window.event;
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }: any) => {
        return (
          <Form onSubmit={handleSubmit} className='pb-5'>
            <Row>
              <Col md={6}>
                <BForm.Group className='mb-3' controlId='time'>
                  <BForm.Label>
                    Time:<span style={{ color: 'red' }}>*</span>
                  </BForm.Label>
                  <FastField
                    name='time'
                    render={({ field }: any) => (
                      <MaskedInput
                        {...field}
                        onKeyPress={validate}
                        mask={timeMask}
                        value={values.time}
                        placeholder='Enter time'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.time && touched.time
                            ? 'border border-danger'
                            : ''
                        }`}
                      />
                    )}
                  />

                  {errors.time && touched.time ? (
                    <div className='text-danger'>{errors.time}</div>
                  ) : null}
                </BForm.Group>
              </Col>
              <Col md={6}>
                <BForm.Group className='mb-3' controlId='classRoomEvent'>
                  <BForm.Label>
                    Event : <span style={{ color: 'red' }}>*</span>
                  </BForm.Label>
                  <FastField
                    name='classRoomEvent'
                    isMulti={false}
                    component={Select}
                    options={[
                      { value: 'camera', label: 'Camera' },
                      { value: 'chat', label: 'Chat' },
                    ]}
                    placeholder='Select an option'
                    isClearable={true}
                    classNamePrefix='select'
                    onChange={(option: any) => {
                      setFieldValue('classRoomEvent', option);
                    }}
                    onBlur={handleBlur}
                    value={values.classRoomEvent}
                    styles={{
                      control: (baseStyles: any, state: any) => {
                        return {
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? errors.classRoomEvent && touched.classRoomEvent
                              ? '#f1416c'
                              : 'grey'
                            : errors.classRoomEvent && touched.classRoomEvent
                            ? '#f1416c'
                            : '#b5b5c3',
                          boxShadow: '#b5b5c3',
                          '&:hover': {
                            borderColor:
                              errors.classRoomEvent && touched.classRoomEvent
                                ? '#f1416c'
                                : 'none',
                          },
                          '&:focus': {
                            borderColor:
                              errors.classRoomEvent && touched.classRoomEvent
                                ? '#f1416c'
                                : 'none',
                          },
                        };
                      },
                    }}
                  />
                  {errors.classRoomEvent && touched.classRoomEvent ? (
                    <div className='text-danger'>{errors.classRoomEvent}</div>
                  ) : null}
                </BForm.Group>
              </Col>
              {values?.classRoomEvent?.value == 'chat' && (
                <Col md={6}>
                  <BForm.Group className='mb-3' controlId='message'>
                    <BForm.Label>
                      Message:<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <FastField
                      type='text'
                      name='message'
                      placeholder='Enter message'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      className={`form-control ${
                        errors.message && touched.message
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {errors.message && touched.message ? (
                      <div className='text-danger'>{errors.message}</div>
                    ) : null}
                  </BForm.Group>
                </Col>
              )}
              <Col md='6'>
                <BForm.Group className='mb-3' controlId='earnPoint'>
                  <BForm.Label>
                    Grade Earned (%):
                    <span style={{ color: 'red' }}>*</span>
                  </BForm.Label>

                  <InputGroup className='mb-3'>
                    <FastField
                      readOnly
                      disabled
                      type='text'
                      name='earnPoint'
                      placeholder='Enter Percentage'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.earnPoint}
                      className={`form-control ${
                        errors.earnPoint && touched.earnPoint
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    <InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
                  </InputGroup>
                  {errors.earnPoint && touched.earnPoint && (
                    <div className='text-danger'>{errors.earnPoint}</div>
                  )}
                  {errors.earnTotalPoint && touched.earnTotalPoint && (
                    <div className='text-danger'>{errors.earnTotalPoint}</div>
                  )}
                </BForm.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant='success'
                  onClick={() => handleSubmitByRef()}
                  size='sm'
                  className='me-2'
                >
                  Save
                </Button>
                <Button
                  variant='light'
                  onClick={() => setShow(false)}
                  size='sm'
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LiveClassContentGradeEarnedAdd;
