import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';

import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
//import { TablePagination } from "app/components/Table/TablePagination";
import TableInner from 'app/components/Table/TableInner';
import { deleteLanguage, getAllLanguage } from 'store/language/actions';
import useTableState from 'app/components/Table/useTableState';
import { KTSVG } from 'theme/helpers';
import Swal from 'sweetalert2';
import LanguageAddEditModel from './LanguageAddEditModel';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';

const LanguagesPage: React.FC = (props: any) => {
  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});
  const { sort, page, limit, search } = tableState;

  const [deleteID, setDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);

  const [addEditModel, setAddEditModel] = useState({
    id: null,
    show: false,
  });

  const [data, setData] = useState([]);

  const {
    onGetAll,
    onDelete,
    getAllLoading,
    getAllSuccess,
    getAllError,
    deleteLoading,
    deleteSuccess,
    deleteError,
  } = props;

  useEffect(() => {
    getAll();
  }, [sort, page, limit, search]);

  const getAll = () => {
    onGetAll({
      page: page,
      limit: limit,
      search: search,
      sort: sort,
    });
  };

  useEffect(() => {
    if (getAllSuccess?.ResultCode === 'SUCCESS') {
      setData(getAllSuccess?.ResultObject?.data || []);
    }
  }, [getAllSuccess]);

  useEffect(() => {
    if (deleteSuccess?.ResultCode === 'SUCCESS') {
      Swal.fire({
        icon: 'success',
        title: deleteSuccess?.ResultMessage,
        timer: 2000,
        timerProgressBar: false,
      }).then((result) => {
        getAll();
      });
    }
  }, [deleteSuccess]);

  const handleDeleteRecord = () => {
    const data = {
      id: deleteID,
    };
    setDeleteModel(false);
    onDelete(data);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseAddEditModel = (props: any) => {
    const { refresh } = props;

    setAddEditModel((prevState) => {
      const data = {
        ...prevState,
        show: false,
      };
      return data;
    });
    if (refresh) {
      getAll();
    }
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start justify-content-start'>
            Code
          </span>
        ),
        accessor: 'Language_Code',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6 ps-4 justify-content-start'>
              {props.row.original.Language_Code}
            </span>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'Language_Name',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.Language_Name}
            </span>
          );
        },
      },
      {
        Header: 'Native Language',
        accessor: 'Language_Name_Native',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.Language_Name_Native}
            </span>
          );
        },
      },
      {
        Header: 'Voice Gender',
        accessor: 'Voice_Gender',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.Voice_Gender}
            </span>
          );
        },
      },
      {
        Header: 'Voice Name',
        accessor: 'Voice_Name',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.Voice_Name}
            </span>
          );
        },
      },
      {
        Header: 'Language Full Code',
        accessor: 'Language_Code_Full',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props.row.original.Language_Code_Full}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-row align-items-center'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  setAddEditModel((prevState) => {
                    const data = {
                      ...prevState,
                      id: result?._id,
                      show: true,
                    };
                    return data;
                  });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#delete_confirmation_modal'
                onClick={() => {
                  setDeleteModel(true);
                  setDeleteID(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className='card mb-10'>
        <Loader loading={getAllLoading || deleteLoading} />
        <Table
          data={data}
          columns={columns}
          initialState={tableInitialState}
          pageCount={getAllSuccess?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>
                  Language Data
                </div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
                <div>
                  <button
                    title='Add language'
                    className='btn btn-primary'
                    onClick={() => {
                      setAddEditModel((prevState) => {
                        const data = {
                          ...prevState,
                          id: null,
                          show: true,
                        };
                        return data;
                      });
                    }}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Language
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={getAllLoading} entityLabel='language' />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>

      <DeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />

      {addEditModel.show && (
        <LanguageAddEditModel
          addEditModel={addEditModel}
          handleCloseAddEditModel={handleCloseAddEditModel}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAllLoading: state.Language.getAllLoading,
    getAllSuccess: state.Language.getAllSuccess,
    getAllError: state.Language.getAllError,
    deleteLoading: state.Language.deleteLoading,
    deleteSuccess: state.Language.deleteSuccess,
    deleteError: state.Language.deleteError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onGetAll: (param: any) => dispatch(getAllLanguage(param)),
  onDelete: (param: any) => dispatch(deleteLanguage(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesPage);
