import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

import VocabularyListenItActivity from '../activity/vocabulary-listen-it-activity';
import VocabularySayItActivity from '../activity/vocabulary-say-it-activity';
import VocabularyTapItActivity from '../activity/vocabulary-tap-it-activity';
import VocabularyWriteItActivity from '../activity/vocabulary-write-it-activity';
import axios from 'axios';
import { videoUpload } from 'store/actions';
import { connect, ConnectedProps } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { FcCheckmark } from 'react-icons/fc'
import { BiErrorCircle } from 'react-icons/bi'
import { getHighlightedSection } from '../activity/utils';

const VocabularySection: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    lessonSingleData,
    getValues,
    loading,
    onUploadVideoReq,
    videoloading,
    videoURL,
    setError,
    nativeLang,
    targetLang
  } = props;

  const [vocabularyDetail, setVocabularyDetail] = useState("")
  const [checkIcon, setCheckIcon] = useState(false)
  const [vocabularySectionType, setVocabularySectionType] = useState("")
  const [vocabularyVideoLoader, setVocabularyVideoLoader] = useState(false)
  const [vocabularyVideoError, setVocabularyVideoError] = useState(false)
  const [vocabularyVideoErrorMassage, setVocabularyVideoErrorMassage] = useState(false)

  useEffect(() => {
    const violation: any = document.getElementById('vocabularyComponents');
    window.scrollTo({
      top: violation.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (lessonSingleData != "" && lessonSingleData != undefined) {
      // setVocabularyDetail(lessonSingleData.Activity_Details[0])

      setVocabularyDetail(lessonSingleData?.Activity_Details?.filter((x: any) => (x.Section == "VocabularySection"))[0])
      const vocabularyData = lessonSingleData?.Activity_Details?.filter((x: any) => (x.Section == "VocabularySection"))[0]
      setValue("VocabularyVideoUrl", vocabularyData?.VocabularyVideoUrl)
      setValue("Vocabulary_Upload_VideoUrl", vocabularyData?.Video_Url)
    }
  }, [lessonSingleData])

  const handleFocus = async (event: any, type: any) => {
    if (event.target.value != "") {
      setVocabularyVideoErrorMassage(false)
      onUploadVideoReq({ url: event.target.value })
      setCheckIcon(false)
      setVocabularySectionType(type)
    } else {
      setVocabularyVideoErrorMassage(true)
      setCheckIcon(false)
      setVocabularyVideoError(false)
    }
  }

  useEffect(() => {
    setCheckIcon(checkIcon)
  }, [checkIcon])

  useEffect(() => {
    setVocabularyVideoError(vocabularyVideoError)
  }, [vocabularyVideoError])

  useEffect(() => {
    setVocabularyVideoLoader(vocabularyVideoLoader)
  }, [vocabularyVideoLoader])

  useEffect(() => {
    if (videoURL.ResultCode != "ERROR" && videoURL.ResultCode != undefined && vocabularySectionType == "VocabularyVideoUrl") {
      setValue("Vocabulary_Upload_VideoUrl", videoURL.ResultObject)
      setVocabularyVideoErrorMassage(false)
      setCheckIcon(true)
      setVocabularyVideoError(false)
      setVocabularySectionType("")
      setVocabularyVideoLoader(false)
    }
    if (videoURL.ResultCode == "ERROR" && vocabularySectionType == "VocabularyVideoUrl") {
      // setError('VocabularyVideoUrl', { type: 'custom', message: 'enter a valid URL' });
      setValue("Vocabulary_Upload_VideoUrl", "")
      setVocabularyVideoErrorMassage(true)
      setVocabularyVideoLoader(false)
      setVocabularySectionType("")
      setCheckIcon(false)
      setVocabularyVideoError(true)
    }
  }, [videoURL])

  useEffect(() => {
    if (videoloading && vocabularySectionType == "VocabularyVideoUrl") {
      setVocabularyVideoLoader(true)
      setCheckIcon(false)
      setVocabularyVideoError(false)
    } else {
      setVocabularyVideoLoader(false)
    }
  }, [videoloading])

  return (
    <>
      <div className="add-lesson mt-3 activity-main card-box" id="vocabularyComponents">
        <Collapsible
          trigger={
            <div
              style={{ width: "100%", position: "relative" }}>
              <h1 className="border-bottom pb-5">
                <i className='fa fa-duotone fa-file-lines me-2 fs-2'></i> Vocabulary Activity <BsChevronDown style={{ position: "absolute", right: "0" }} />
              </h1>
            </div>
          }
          open={true}
        >
          <div className='add-lesson-row-section' {...getHighlightedSection('VocabularySection')} >
            <div className='row py-5'>
              <div className='col-md-12'>
                <label className='fw-bold form-label'>
                  Video Embedded URL <span className='astrick'>*</span>
                </label>
                <div className='position-relative'>
                  <input
                    type='text'
                    {...register('VocabularyVideoUrl')}
                    className={
                      errors.VocabularyVideoUrl
                        ? 'form-control errors'
                        : 'form-control'
                    }
                    placeholder='https://www.youtube.com/shorts/7tFTZroMqzE'
                    onBlur={() => handleFocus(event, "VocabularyVideoUrl")}
                    disabled={loading}
                  ></input>
                  {vocabularyVideoLoader && <span style={{ position: "absolute", right: '7px', top: '10px' }}><Spinner animation="border" variant="primary" /></span>}
                  {checkIcon && <span style={{ position: "absolute", right: '7px', top: '10px' }}><FcCheckmark size={25} /></span>}
                  {vocabularyVideoError && <span style={{ position: "absolute", right: '7px', top: '10px', color: "red" }}><BiErrorCircle size={25} /></span>}
                </div>
                {errors.VocabularyVideoUrl ? (
                  <p style={{ color: 'red' }}>
                    {errors.VocabularyVideoUrl.message}
                  </p>
                ) :
                  vocabularyVideoErrorMassage && (<p style={{ color: 'red' }}>
                    Entered url is corrupted please try different url
                  </p>)
                }
                {/* {
                errors.Vocabulary_Upload_VideoUrl && (
                  <p style={{ color: 'red' }}>
                    {errors.Vocabulary_Upload_VideoUrl.message}
                  </p>
                )
              } */}
                {/* {vocabularyVideoErrorMassage && (<p style={{ color: 'red' }}>
                Entered url is corrupted please try different url
              </p>)} */}
              </div>
            </div>
          </div>
          <VocabularyTapItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            vocabularyDetail={vocabularyDetail}
            getValues={getValues}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
          <VocabularySayItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            vocabularyDetail={vocabularyDetail}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
          <VocabularyWriteItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            vocabularyDetail={vocabularyDetail}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
          <VocabularyListenItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            vocabularyDetail={vocabularyDetail}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
        </Collapsible>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return ({
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    videoloading: state.lesson.videoloading,
    videoURL: state.lesson.videoURL,
  })
}

const mapDispatchToProps = (dispatch: any) => ({
  onUploadVideoReq: (param: any) => dispatch(videoUpload(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VocabularySection);
