/* eslint-disable import/no-unresolved */

import { useGetQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { Modal } from 'react-bootstrap';
import Select, { OptionsOrGroups } from 'react-select';
import moment from 'moment';
import MonthPicker from 'simple-react-month-picker';

import useTableState from '../Table/useTableState';
import Table from '../Table';
import TableInner from '../Table/TableInner';
import { TablePagination } from '../Table/TablePagination';
import LoaderNew from '../LoaderNew';
import { TableGlobalSearch } from '../Table/TableGlobalSearch';

const ClearableInput = ({ value, onClick }: { value: any; onClick: any }) => (
  <div>
    <input value={value} onClick={onClick} readOnly />
    {value && <button onClick={() => onClick(null)}>Clear</button>}
  </div>
);

const EnrollStudentsDetailsModal = (props: any) => {
  const { id, search: parentSearchText } = props.data;
  const [filterMonth, setFilterMonth] = useState<any>(null);

  const [monthOfYear, setMonthOfYear] = useState<any>(null);

  const getInvitesQuery: any = useGetQuery('getInvites', '/course/invite');

  const monthsOptions: any = [];
  for (let i = 0; i < 12; i++) {
    monthsOptions.push({
      value: moment().subtract(i, 'months').toISOString(),
      label: moment().subtract(i, 'months').format('MMM YYYY'),
    });
  }

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            First name
          </span>
        ),
        accessor: 'user.FirstName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {result?.user?.FirstName ? result?.user?.FirstName : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Last name',
        accessor: 'user.LastName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return result?.user?.LastName ? result?.user?.LastName : '-';
        },
      },
      {
        Header: 'Enroll date',
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return moment(result?.createdAt).format('L')
            ? moment(result?.createdAt).format('L')
            : '-';
        },
      },
      {
        Header: 'Percentage',
        accessor: 'averagePercentage',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return result?.averagePercentage
            ? Math.round(result?.averagePercentage) + '%'
            : '-';
        },
      },
      {
        Header: 'GPA',
        accessor: 'GPA',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return result?.GPA ? Math.round(result?.GPA) : '-';
        },
      },
      {
        Header: 'letter grade',
        accessor: 'grade',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {result?.grade?.name ? result?.grade?.name : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Progress',
        accessor: 'Progress',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <span className='ps-4 min-w-325px rounded-start text-start'>
              {result?.totalPointSum} of{' '}
              {result?.course?.isDiplomaEnglishCourse
                ? result?.requireAchievePoint
                : result?.course?.pointsNeededToCompleteCourse}
            </span>
          );
        },
      },
    ],
    []
  );

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageSize: 50,
      globalFilter: parentSearchText,
    });

  const { page, limit, search } = tableState;

  useEffect(() => {
    getInvitesQuery.mutate({
      params: {
        id: id,
        page,
        limit,
        search: search,
        month: filterMonth?.value,
        monthOfYear: monthOfYear,
      },
    });
  }, [id, page, limit, search, filterMonth, monthOfYear]);

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <LoaderNew isFull={false} loading={getInvitesQuery?.isLoading} />

      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Enroll Students Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <div className='card mb-10'>
            <Table
              data={getInvitesQuery?.data?.ResultObject?.results ?? []}
              columns={columns}
              initialState={tableInitialState}
              pageCount={getInvitesQuery?.data?.ResultObject?.totalPages ?? -1}
              onTableStateChange={handleTableStateChange}
            >
              <React.Fragment>
                <div className='card-header justify-content-between border-0 pt-5'>
                  <h3 className='card-title'>
                    <span className='card-label fw-bold fs-3 mb-1'>
                      Enroll Students Data
                    </span>
                  </h3>
                  <div className='d-flex align-items-end gap-2 dropdowns-left'>
                    <div>
                      <MonthPicker
                        onChange={(range: any) => setMonthOfYear(range)}
                        isClearable={true}
                        isSearchable={true}
                      />
                    </div>
                    <div>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        defaultValue={filterMonth}
                        name='month'
                        placeholder='Select month...'
                        options={monthsOptions}
                        onChange={(e: any) => setFilterMonth(e)}
                      />
                    </div>
                    <div>
                      <TableGlobalSearch />
                    </div>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='row'>
                    <div className='col-12 mb-5'>
                      <TableInner
                        loading={getInvitesQuery.isLoading}
                        entityLabel='enroll student data'
                      />
                    </div>
                    <div className='col-12 border-top pt-5'>
                      <TablePagination />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </Table>
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  );
};

export default EnrollStudentsDetailsModal;
