import React from 'react';

type Props = {
  deleteModel: boolean;
  handleDeleteRecord: any;
  handleCloseDeleteModel: any;
};
const DeleteConfirmationModel: React.FC<Props> = (props: any) => {
  const { deleteModel, handleDeleteRecord, handleCloseDeleteModel } = props;

  return (
    <div
      className={deleteModel ? 'modal fade show' : 'modal fade'}
      id='delete_confirmation_modal'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='w-100'>
              <h3 className='modal-title'>Confirmation</h3>
            </div>
          </div>
          <div className='modal-body'>
            <h4 className='delete_text'>Are you sure you want to delete?</h4>
          </div>
          <div className='modal-footer'>
            <div className='w-100 d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-primary me-3'
                data-bs-dismiss='modal'
                onClick={handleDeleteRecord}
              >
                Yes
              </button>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={handleCloseDeleteModel}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModel;
