import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';

import EnrollmentPage from './EnrollmentPage';

const EnrollmentWrapper: React.FC = (props: any) => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Enrollment</PageTitle>
            <EnrollmentPage />
        </>
    );
};

export default EnrollmentWrapper;
