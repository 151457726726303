import {
  CLEAR_SUCCESS_ERROR,
  TEACHER_INVITATION,
  TEACHER_INVITATION_FAILURE,
  TEACHER_INVITATION_SUCCESS,
  TEACHER_GET_LIST,
  TEACHER_GET_LIST_SUCCESS,
  TEACHER_GET_LIST_FAILURE,
  TEACHER_UPDATE,
  TEACHER_UPDATE_SUCCESS,
  TEACHER_DELETE,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_FAILURE,
  TEACHER_GET_BY_ID,
  TEACHER_GET_BY_ID_SUCCESS,
  TEACHER_GET_BY_ID_FAILURE,
  TEACHER_UPDATE_FAILURE,
  EMAIL_ALREADY_EXISTS,
  EMAIL_ALREADY_EXISTS_SUCCESS,
  EMAIL_ALREADY_EXISTS_FAILURE,
} from './actionTypes';
import {
  teacherInvitationSuccessPayload,
  teacherInvitationPayload,
  teacherInvitationfailurePayload,
  TeacherInvitation,
  TeacherInvitationSucces,
  TeacherInvitationFailure,
  clearSuccesError,
  teacherGetListPayload,
  teacherGetListSuccessPayload,
  teacherGetListfailurePayload,
  TeacherGetList,
  TeacherGetListSuccess,
  TeacherGetListFailure,
  TeacherUpdate,
  TeacherUpdateSuccess,
  TeacherDelete,
  TeacherDeleteSuccess,
  teacherUpdateSuccessPayload,
  teacherDeleteSuccessPayload,
} from './types';

export const teacherInvitation = (
  payload: teacherInvitationPayload
): TeacherInvitation => ({
  type: TEACHER_INVITATION,
  payload,
});

export const teacherInvitationSuccess = (
  payload: teacherInvitationSuccessPayload
): TeacherInvitationSucces => ({
  type: TEACHER_INVITATION_SUCCESS,
  payload,
});

export const teacherInvitationFailure = (
  payload: teacherInvitationfailurePayload
): TeacherInvitationFailure => ({
  type: TEACHER_INVITATION_FAILURE,
  payload,
});

export const ClearSuccesError = (): clearSuccesError => ({
  type: CLEAR_SUCCESS_ERROR,
});

export const teacherGetList = (): TeacherGetList => ({
  type: TEACHER_GET_LIST,
});
export const teacherGetListSuccess = (
  payload: teacherGetListSuccessPayload
): TeacherGetListSuccess => ({
  type: TEACHER_GET_LIST_SUCCESS,
  payload,
});

export const teacherGetListFailure = (
  payload: teacherGetListfailurePayload
): TeacherGetListFailure => ({
  type: TEACHER_GET_LIST_FAILURE,
  payload,
});

export const teacherGetById = (payload: any) => ({
  type: TEACHER_GET_BY_ID,
  payload,
});

export const teacherGetByIdSuccess = (payload: any) => ({
  type: TEACHER_GET_BY_ID_SUCCESS,
  payload,
});

export const teacherGetByIdFailure = (payload: any) => ({
  type: TEACHER_GET_BY_ID_FAILURE,
  payload,
});

export const teacherUpdate = (payload: any) => ({
  type: TEACHER_UPDATE,
  payload,
});

export const teacherUpdateSuccess = (payload: any) => ({
  type: TEACHER_UPDATE_SUCCESS,
  payload,
});

export const teacherUpdateFailure = (payload: any) => ({
  type: TEACHER_UPDATE_FAILURE,
  payload,
});

export const teacherDelete = (payload: any) => ({
  type: TEACHER_DELETE,
  payload,
});

export const teacherDeleteSuccess = (payload: any) => ({
  type: TEACHER_DELETE_SUCCESS,
  payload,
});

export const teacherDeleteFailure = (payload: any) => ({
  type: TEACHER_DELETE_FAILURE,
  payload,
});

export const emailAlreadyExists = (payload: any) => ({
  type: EMAIL_ALREADY_EXISTS,
  payload,
});

export const emailAlreadyExistsSuccess = (payload: any) => ({
  type: EMAIL_ALREADY_EXISTS_SUCCESS,
  payload,
});

export const emailAlreadyExistsFailure = (payload: any) => ({
  type: EMAIL_ALREADY_EXISTS_FAILURE,
  payload,
});
