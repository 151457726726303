import Loader from 'app/components/Loader';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { PageTitle } from 'theme/layout/core';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { RxCross2 } from 'react-icons/rx';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EnrollmentHeadPageTab from '../EnrollmentHeadPageTab';

const AddTranscript = () => {
  const getStudentQuery: any = usePostQuery(
    'getStudent',
    '/transcript/get/student',
    {}
  );

  const getStudentDetailsQuery: any = usePostQuery(
    'getStudentDetails',
    '/transcript/get/studentdetails',
    {}
  );

  const saveTrancriptQuery: any = usePostQuery(
    'saveTrancript',
    '/transcript/add',
    {}
  );

  const getByIDTrancriptQuery: any = usePostQuery(
    'getByIDTrancript',
    '/transcript/get',
    {}
  );

  const getSchoolYearQuery: any = useGetQuery(
    'getSchoolYear',
    '/enrollment/calendartype/get'
  );

  const navigate = useNavigate();
  const { transcriptID } = useParams();

  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const [student, setStudent] = useState([]);
  const fieldAppend = { SubjectName: '', FinalGrade: '', Credit: '' };

  useEffect(() => {
    // getStudentQuery.mutateAsync();
    getSchoolYearQuery.mutateAsync();
  }, []);

  useEffect(() => {
    getByIDTrancriptQuery.mutateAsync({ TranscriptID: transcriptID });
  }, [transcriptID]);

  useEffect(() => {
    if (getStudentQuery.isSuccess) {
      setStudent(getStudentQuery?.data?.ResultObject);
    }
  }, [getStudentQuery.isSuccess]);

  useEffect(() => {
    if (getByIDTrancriptQuery.isSuccess) {
      const transcriptData = getByIDTrancriptQuery?.data?.ResultObject;
      if (transcriptData) {
        formik.setFieldValue(
          'Student',
          transcriptData?.StudentID?._id ? transcriptData?.StudentID?._id : ''
        );
        formik.setFieldValue(
          'SubjectInfo',
          transcriptData?.Subjects ? transcriptData?.Subjects : [fieldAppend]
        );
        formik.setFieldValue(
          'HighSchoolYear',
          transcriptData?.HighSchoolYear
            ? transcriptData?.HighSchoolYear
            : false
        );

        formik.setFieldValue(
          'ID',
          transcriptData?._id ? transcriptData?._id : undefined
        );

        formik.setFieldValue(
          'School_Year',
          transcriptData?.CalendarYearID ? transcriptData?.CalendarYearID : ''
        );

        formik.setFieldValue(
          'Transcript_Year',
          transcriptData?.TranscriptYear ? transcriptData?.TranscriptYear : ''
        );

        setSelectedYear({
          value: transcriptData?.TranscriptYear,
          label: transcriptData?.TranscriptYear?.split('-')[0],
        });
        const selectStudentData: any = {
          value: transcriptData?.StudentID?._id,
          label:
            transcriptData?.StudentID?.UserId?.FirstName +
            ' ' +
            transcriptData?.StudentID?.UserId?.LastName,
        };
        setSelectedStudent(selectStudentData);
      }
    }
  }, [getByIDTrancriptQuery.isSuccess]);

  const initialValues: any = {
    StudentName: '',
    Student: '',
    StudentID: '',
    BirthDate: '',
    SubjectInfo: [fieldAppend],
    // Senior: false,
    // Junior: false,
    // Sophmore: false,
    // Freshman: false,
    ID: undefined,
    School_Year: '',
    Transcript_Year: '',
    HighSchoolYear: '',
  };

  const formvalidationSchema: any = Yup.object().shape({
    Student: Yup.string().required('Please select student'),
    StudentName: Yup.string().required('Please enter first name'),
    StudentID: Yup.string().required('Please enter student id'),
    BirthDate: Yup.string().required('Please enter birthdate'),
    SubjectInfo: Yup.array().of(
      Yup.object().shape({
        SubjectName: Yup.string().required('Please enter subject name'),
        FinalGrade: Yup.string().required('Please enter final grade'),
        Credit: Yup.number()
          .typeError('Credit must be a number')
          .required('Please enter credit')
          .positive('Credit must be a positive number'),
      })
    ),
    School_Year: Yup.string().required('Please select school year'),
    Transcript_Year: Yup.string().required('Please select transcript year'),
    HighSchoolYear: Yup.string().required('Please select high school grade'),
  });

  const formik: any = useFormik({
    initialValues,
    // validate,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      debugger;
      saveTrancriptQuery.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveTrancriptQuery.isSuccess) {
      navigate('/transcript');
    }
  }, [saveTrancriptQuery.isSuccess]);

  useEffect(() => {
    if (formik.values.School_Year) {
      getStudentQuery.mutateAsync({ School_Year: formik.values.School_Year });
    }
  }, [formik.values.School_Year]);

  const handleSelectStudent = (event: any) => {
    setSelectedStudent(event);
    formik.setFieldValue('Student', event?.value);
  };

  useEffect(() => {
    if (formik.values.Student) {
      getStudentDetailsQuery.mutateAsync({ StudentID: formik.values.Student });
    }
  }, [formik.values.Student]);

  useEffect(() => {
    if (getStudentDetailsQuery.isSuccess) {
      const StudentDetails = getStudentDetailsQuery?.data?.ResultObject;
      formik.setFieldValue(
        'BirthDate',
        StudentDetails?.BirthDate ? StudentDetails?.BirthDate : ''
      );
      formik.setFieldValue(
        'StudentID',
        StudentDetails?.StudentProfileID ? StudentDetails?.StudentProfileID : ''
      );
      formik.setFieldValue(
        'StudentName',
        StudentDetails?.FullName ? StudentDetails?.FullName : ''
      );
    }
  }, [getStudentDetailsQuery.isSuccess]);

  const appendField = () => {
    formik.setFieldValue('SubjectInfo', [
      ...formik.values.SubjectInfo,
      {
        SubjectName: '',
        FinalGrade: '',
        Credit: '',
      },
    ]);
  };

  const removeSubject = (index: any) => {
    const newSubjects = [...formik.values.SubjectInfo];
    newSubjects.splice(index, 1);
    formik.setFieldValue('SubjectInfo', newSubjects);
  };

  const handleCheckboxChange = (field: any) => {
    formik.setValues({
      ...formik.values,
      Senior: false,
      Junior: false,
      Sophmore: false,
      Freshman: false,
      [field]: true,
    });
  };

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<any>({
    value: `${currentYear}-${currentYear + 1}`,
    label: currentYear,
  });

  useEffect(() => {
    if (selectedYear) {
      formik.setFieldValue('Transcript_Year', selectedYear?.value);
    } else {
      formik.setFieldValue('Transcript_Year', '');
    }
  }, [selectedYear]);

  const yearOptions: any = [];
  for (let year = currentYear - 20; year <= currentYear + 10; year++) {
    yearOptions.push({
      value: `${year}-${year + 1}`,
      label: year,
    });
  }

  const onTranscriptYearChange = (e: any) => {
    const selectedValue = e.target.value;
    const selectedOption = yearOptions.find(
      (option: any) => option.value === selectedValue
    );
    formik.setFieldValue('Transcript_Year', selectedOption?.value);
    setSelectedYear(selectedOption);
  };

  const isCalanderYear = useMemo(() => {
    const schoolYear = formik.values.School_Year;
    const selectedOption = getSchoolYearQuery?.data?.ResultObject.find(
      (option: any) => option._id === schoolYear
    );
    return selectedOption?.calendarType == 'Calendar year' ? true : false;
  }, [formik.values.School_Year]);

  const finalGrade = ['A', 'B', 'C', 'D', 'F'];
  const HighSchoolYear = [
    '1st Grade',
    '2nd Grade',
    '3rd Grade',
    '4th Grade',
    '5th Grade',
    '6th Grade',
    '7th Grade',
    '8th Grade',
    '9th Grade',
    '10th Grade',
    '11th Grade',
    '12th Grade',
    'Junior',
    'Kinder',
    'Senior',
    'Sophmore',
    'Freshman',
  ];

  return (
    <>
      <Loader
        loading={
          getStudentQuery.isLoading ||
          getStudentDetailsQuery.isLoading ||
          saveTrancriptQuery.isLoading ||
          getByIDTrancriptQuery.isLoading
        }
      />

      <div>
        <div className='card text-end mt-5 p-6'>
          <div className='pt-5'>
            <EnrollmentHeadPageTab activeTab='Transcript' />
          </div>
          <div className='card-body p-6'>
            <h1>Add Transcript</h1>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='card mt-3 mb-3'>
            <div className='card-body p-6'>
              <div className='row'>
                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    School Year: <span className='text-danger'> * </span>
                  </label>
                  <select
                    aria-label='Default select example'
                    {...formik.getFieldProps('School_Year')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.School_Year &&
                          formik.errors.School_Year,
                      },
                      {
                        'is-valid':
                          formik.touched.School_Year &&
                          !formik.errors.School_Year,
                      }
                    )}
                  >
                    <option selected value=''>
                      Choose
                    </option>
                    {getSchoolYearQuery?.data?.ResultObject.map((data: any) => {
                      return (
                        <option value={data._id}>{data.description}</option>
                      );
                    })}
                  </select>
                  {formik.touched.School && formik.errors.School && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.School}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Transcript Year <span className='text-danger'> * </span>
                  </label>
                  <select
                    aria-label='Default select example'
                    value={selectedYear?.value}
                    onChange={onTranscriptYearChange}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.Transcript_Year &&
                          formik.errors.Transcript_Year,
                      },
                      {
                        'is-valid':
                          formik.touched.Transcript_Year &&
                          !formik.errors.Transcript_Year,
                      }
                    )}
                  >
                    <option selected value=''>
                      Choose
                    </option>
                    {yearOptions.map((yearOption: any, index: any) => (
                      <option key={index} value={yearOption.value}>
                        {isCalanderYear ? yearOption.label : yearOption.value}
                      </option>
                    ))}
                  </select>
                  {formik.touched.Transcript_Year &&
                    formik.errors.Transcript_Year && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            {formik.errors.Transcript_Year}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Student <span className='text-danger'> * </span>
                  </label>
                  <Select
                    name='student'
                    isMulti={false}
                    isClearable={true}
                    classNamePrefix='select'
                    placeholder='Select student'
                    value={selectedStudent}
                    options={student}
                    onChange={handleSelectStudent}
                    isDisabled={formik.values.School_Year ? false : true}
                  />
                  {formik.touched.Student && formik.errors.Student && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Student}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Student Name <span className='text-danger'> * </span>
                  </label>
                  <input
                    type='text'
                    placeholder='Your name'
                    autoComplete='off'
                    {...formik.getFieldProps('StudentName')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.StudentName &&
                          formik.errors.StudentName,
                      },
                      {
                        'is-valid':
                          formik.touched.StudentName &&
                          !formik.errors.StudentName,
                      }
                    )}
                    disabled={true}
                  />
                  {formik.touched.StudentName && formik.errors.StudentName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.StudentName}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Student ID <span className='text-danger'> * </span>
                  </label>
                  <input
                    type='text'
                    placeholder='Your student id'
                    autoComplete='off'
                    {...formik.getFieldProps('StudentID')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.StudentID && formik.errors.StudentID,
                      },
                      {
                        'is-valid':
                          formik.touched.StudentID && !formik.errors.StudentID,
                      }
                    )}
                    disabled={true}
                  />
                  {formik.touched.StudentID && formik.errors.StudentID && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.StudentID}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    BirthDate <span className='text-danger'> * </span>
                  </label>
                  <input
                    type='text'
                    placeholder='Your birth date'
                    autoComplete='off'
                    {...formik.getFieldProps('BirthDate')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.BirthDate && formik.errors.BirthDate,
                      },
                      {
                        'is-valid':
                          formik.touched.BirthDate && !formik.errors.BirthDate,
                      }
                    )}
                    disabled={true}
                  />
                  {formik.touched.BirthDate && formik.errors.BirthDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.BirthDate}</span>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className='p-3 col-md-3'>
                  <div className='form-check mt-2'>
                    <input
                      type='checkbox'
                      {...formik.getFieldProps('Senior')}
                      value='Yes'
                      checked={formik.values.Senior}
                      onChange={() => handleCheckboxChange('Senior')}
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.Senior && formik.errors.Senior,
                        },
                        {
                          'is-valid':
                            formik.touched.Senior && !formik.errors.Senior,
                        }
                      )}
                    />
                    <label className='form-label'>Senior</label>
                  </div>
                </div>
                <div className='p-3 col-md-3'>
                  <div className='form-check mt-2'>
                    <input
                      type='checkbox'
                      {...formik.getFieldProps('Junior')}
                      value='Yes'
                      checked={formik.values.Junior}
                      onChange={() => handleCheckboxChange('Junior')}
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.Junior && formik.errors.Junior,
                        },
                        {
                          'is-valid':
                            formik.touched.Junior && !formik.errors.Junior,
                        }
                      )}
                    />
                    <label className='form-label'>Junior</label>
                  </div>
                </div>
                <div className='p-3 col-md-3'>
                  <div className='form-check mt-2'>
                    <input
                      type='checkbox'
                      {...formik.getFieldProps('Sophmore')}
                      onChange={() => handleCheckboxChange('Sophmore')}
                      value='Yes'
                      checked={formik.values.Sophmore}
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.Sophmore && formik.errors.Sophmore,
                        },
                        {
                          'is-valid':
                            formik.touched.Sophmore && !formik.errors.Sophmore,
                        }
                      )}
                    />
                    <label className='form-label'>Sophmore</label>
                  </div>
                </div>
                <div className='p-3 col-md-3'>
                  <div className='form-check mt-2'>
                    <input
                      type='checkbox'
                      {...formik.getFieldProps('Freshman')}
                      value='Yes'
                      checked={formik.values.Freshman}
                      onChange={() => handleCheckboxChange('Freshman')}
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.Freshman && formik.errors.Freshman,
                        },
                        {
                          'is-valid':
                            formik.touched.Freshman && !formik.errors.Freshman,
                        }
                      )}
                    />
                    <label className='form-label'>Freshman</label>
                  </div>
                </div>
      
                {formik.errors.checkboxGroup && (
                  <div className='text-danger'>
                    {formik.errors.checkboxGroup}
                  </div>
                )} */}

                {/* {HighSchoolYear.map((field: any, index: any) => {
                  return (
                    <div className={`p-3 col-md-2`}>
                      <div className='form-check mt-2'>
                        <input
                          type='radio'
                          {...formik.getFieldProps('HighSchoolYear')}
                          value={field}
                          id={field}
                          checked={formik.values.HighSchoolYear == field}
                          className={clsx('form-check-input cursor-pointer')}
                          onClick={(e) => {
                            let element: any = e.target;
                            if (element.checked) {
                              element.checked = false;
                              formik.setFieldValue('HighSchoolYear', false);
                            }
                          }}
                        />
                        <label
                          className='form-label cursor-pointer'
                          htmlFor={field}
                        >
                          {field}
                        </label>
                      </div>
                    </div>
                  );
                })} */}

                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    HighSchool Grade :<span className='text-danger'> * </span>
                  </label>
                  <select
                    aria-label='Default select example'
                    {...formik.getFieldProps('HighSchoolYear')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.HighSchoolYear &&
                          formik.errors.HighSchoolYear,
                      },
                      {
                        'is-valid':
                          formik.touched.HighSchoolYear &&
                          !formik.errors.HighSchoolYear,
                      }
                    )}
                  >
                    <option selected value=''>
                      Choose
                    </option>
                    {HighSchoolYear.map((data: any) => {
                      return <option value={data}>{data}</option>;
                    })}
                  </select>
                  {formik.touched.HighSchoolYear &&
                    formik.errors.HighSchoolYear && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            {formik.errors.HighSchoolYear}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              {formik.values.SubjectInfo.map((field: any, index: any) => {
                return (
                  <div className='row' key={index}>
                    <div className='p-3 col-md-6'>
                      <label className='form-label'>
                        Subject Name <span className='text-danger'> * </span>
                      </label>
                      <input
                        type='text'
                        placeholder='Your subject name'
                        {...formik.getFieldProps(
                          `SubjectInfo.${index}.SubjectName`
                        )}
                        className={clsx('form-control', {
                          'is-invalid':
                            formik.touched.SubjectInfo?.[index]?.SubjectName &&
                            formik.errors.SubjectInfo?.[index]?.SubjectName,
                          'is-valid':
                            formik.touched.SubjectInfo?.[index]?.SubjectName &&
                            !formik.errors.SubjectInfo?.[index]?.SubjectName,
                        })}
                      />
                      {formik.touched.SubjectInfo?.[index]?.SubjectName &&
                        formik.errors.SubjectInfo?.[index]?.SubjectName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>
                                {' '}
                                {formik.errors.SubjectInfo[index].SubjectName}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className='p-3  col-md-3'>
                      <label className='form-label'>
                        Final Grade <span className='text-danger'> * </span>
                      </label>
                      <select
                        aria-label='Default select example'
                        {...formik.getFieldProps(
                          `SubjectInfo.${index}.FinalGrade`
                        )}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.SubjectInfo?.[index]?.FinalGrade &&
                              formik.errors.SubjectInfo?.[index]?.FinalGrade,
                          },
                          {
                            'is-valid':
                              formik.touched.SubjectInfo?.[index]?.FinalGrade &&
                              !formik.errors.SubjectInfo?.[index]?.FinalGrade,
                          }
                        )}
                      >
                        <option selected value=''>
                          Choose
                        </option>
                        {finalGrade.map((data: any) => {
                          return <option value={data}>{data}</option>;
                        })}
                      </select>
                      {formik.touched.SubjectInfo?.[index]?.FinalGrade &&
                        formik.errors.SubjectInfo?.[index]?.FinalGrade && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>
                                {formik.errors.SubjectInfo?.[index]?.FinalGrade}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>

                    <div className='p-3 col-md-2'>
                      <label className='form-label'>
                        Credit <span className='text-danger'> * </span>
                      </label>
                      <input
                        type='text'
                        placeholder='Your credit'
                        {...formik.getFieldProps(`SubjectInfo.${index}.Credit`)}
                        className={clsx('form-control', {
                          'is-invalid':
                            formik.touched.SubjectInfo?.[index]?.Credit &&
                            formik.errors.SubjectInfo?.[index]?.Credit,
                          'is-valid':
                            formik.touched.SubjectInfo?.[index]?.Credit &&
                            !formik.errors.SubjectInfo?.[index]?.Credit,
                        })}
                      />
                      {formik.touched.SubjectInfo?.[index]?.Credit &&
                        formik.errors.SubjectInfo?.[index]?.Credit && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>
                                {' '}
                                {formik.errors.SubjectInfo[index].Credit}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                    {index != 0 && (
                      <>
                        <div className='p-3 col-md-1'>
                          <div className='mt-8'>
                            <Button
                              className='btn btn-danger'
                              onClick={() => removeSubject(index)}
                            >
                              <RxCross2 size={20} />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              <div>
                <Button
                  className='btn btn-primary btn-sm'
                  onClick={appendField}
                >
                  Add Subject
                </Button>
              </div>
              <div className='float-end'>
                <Button className='btn btn-primary' type='submit'>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTranscript;
