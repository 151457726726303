import jwt_decode from 'jwt-decode';

function jwtTokenDecode(): any {
  const localStorageData: any = localStorage.getItem(
    'hopecity_admin_auth_token'
  );
  const token: string = localStorageData
    ? JSON.parse(localStorageData).token
    : {};
  const jwt_decode_token = jwt_decode(token);

  return jwt_decode_token as object;
}

function getJwtToken(): any {
  const localStorageData: any = localStorage.getItem(
    'hopecity_admin_auth_token'
  );
  const token: string = localStorageData
    ? JSON.parse(localStorageData).token
    : null;

  return token;
}

export { getJwtToken, jwtTokenDecode };
