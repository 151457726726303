import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';

import LessonPage from './LessonPage';

const LessonPageWrapper: React.FC = (props: any) => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Lesson</PageTitle>
      <LessonPage />
    </>
  );
};

export default LessonPageWrapper;
