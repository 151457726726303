import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import { get, post } from "../../helpers/api-helper";
import { aiActivityImageFailure, aiActivityImageGetSuccess, aiActivityImageRemoveSuccess } from "./actions";
import { AI_ACTIVITY_IMAGE_DETAILS_GET, AI_ACTIVITY_IMAGE_DETAILS_REMOVE } from "./actionTypes";


const aiActivityImageGetRequest = (data: any) => post("aiactivityimage/getall", data)
const aiActivityImageRemoveRequest = (data: any) => post("aiactivityimage/remove", data)

function* aiActivityImageGetListSaga(action: any) {
    try {
        const response: { ResultCode: string; ResultMessage: string; ResultObject: any; } = yield call(aiActivityImageGetRequest, {
            page: action.payload.page,
            limit: action.payload.limit,
            search: action.payload.search,
            sort: action.payload.sort
        });
        if (response.ResultCode !== 'SUCCESS') {
            yield put(aiActivityImageFailure({ error: response.ResultCode }));
        } else {
            yield put(aiActivityImageGetSuccess(response));
        }
    } catch (error: any) {
        yield put(aiActivityImageFailure({ error: error }));
    }
}


function* aiActivityImageRemoveSaga(action: any) {
    try {
        const response: { ResultCode: string; ResultMessage: string; ResultObject: any; } = yield call(aiActivityImageRemoveRequest, {
            ID: action.payload.ID,
        });
        if (response.ResultCode !== 'SUCCESS') {
            yield put(aiActivityImageFailure(response));
        } else {
            yield put(aiActivityImageRemoveSuccess(response));
        }
    } catch (error: any) {
        yield put(aiActivityImageFailure({ error: error }));
    }
}

function* authSaga() {
    yield all([takeLatest(AI_ACTIVITY_IMAGE_DETAILS_GET, aiActivityImageGetListSaga)]);
    yield all([takeLatest(AI_ACTIVITY_IMAGE_DETAILS_REMOVE, aiActivityImageRemoveSaga)]);
}

export default authSaga;
