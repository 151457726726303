import { actionTypes } from './actionTypes';

export type State = {
  state: boolean;
  count: number;
};

type Action = {
  type: typeof actionTypes[keyof typeof actionTypes];
  payload?: Partial<State>;
};

const initialState: State = {
  state: false,
  count: 0,
};

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.INIT: {
      return {
        ...state,
      };
    }

    case actionTypes.START: {
      return {
        state: true,
        count: state.count + 1,
      } as State;
    }

    case actionTypes.STOP: {
      return {
        state: false,
        count: state.count - 1,
      } as State;
    }

    case actionTypes.SET_STATE: {
      return {
        ...state,
        ...action.payload,
      } as State;
    }

    default: {
      return state;
    }
  }
}
