import { removeAuth } from 'app/modules/auth';
import axios from 'axios';
import { BASE_URL } from '../shared/apiConfig';
import { getToken } from './authToken';

const API_URL = BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosApi.interceptors.request.use((config: any) => {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

//validate response
axiosApi.interceptors.response.use(
  (response) => {
    // if (response.data.StatusCode === 401) {
    //   return (window.location.href = '/auth');
    // }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeAuth();
      return (window.location.href = '/login');
    }

    return Promise.reject(error);
  }
);

export { axiosApi };

export async function get(url: any, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: any, data: any, config = {}) {
  return axiosApi
    .post(url, data, config)
    .then((response) => response.data)
    .catch((error) => error.response.data);
}

export async function update(url: any, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((error) => error.response.data);
}

export async function del(url: any, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function getAxiosInstance() {
  return axiosApi;
}
