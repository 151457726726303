import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CellProps, Column } from 'react-table';

import Swal from 'sweetalert2';

import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';
import createNotification from '../../../helpers/alert';
import {
  studentDelete,
  studentGet,
  studentGetById,
  studentUpdate,
  successClear,
} from '../../../store/student/actions';
import Table from '../../components/Table';
import callBackendApi from '../../../utils/callBackendApi';

import useTableState from '../../components/Table/useTableState';
import useBackendApiCaller from '../../../hooks/useBackendApiCaller';

import { DeleteModal } from 'app/components/Student/DeleteModal';
import { urlRoutes } from '../../../shared/apiConfig';
import TableInner from 'app/components/Table/TableInner';
import Loader from 'app/components/Loader';
import { TablePagination } from 'app/components/Table/TablePagination';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import DetailEditModal from 'app/components/Student/DetailEditModal';
import TransactionDetailModal from './TransactionDetailModal';
import moment from 'moment';
import { DateTime } from 'luxon';

const StudentsPage: React.FC = (props: any) => {
  const {
    success,
    onStudentDelete,
    studentDetail,
    onStudentGetById,
    onSuccessClear,
  } = props;

  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');
  const [deleteID, setDeleteID] = useState('');
  const [studentDeleteID, setStudentDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editID, setEditID] = useState('');

  useEffect(() => {
    setEditID(editID);
  }, [editID, success]);

  useEffect(() => {
    onSuccessClear();
    if (success) {
      if (success.ResultCode === 'SUCCESS') {
        Swal.fire(success.ResultMessage, ' ', 'success');
        document
          .getElementById('Edit_Modal_scrollable')
          ?.classList.remove('show');
        document.querySelector('.modal-backdrop')?.classList.remove('show');
      } else {
        createNotification('warning', success.ResultMessage);
      }
    }
  }, [onSuccessClear, success]);

  useEffect(() => {
    if (studentDetail) {
      setEditModel(true);
    }
  }, [studentDetail]);

  const handleDelete = (id: any, stdId: any) => {
    setDeleteModel(true);
    setDeleteID(id);
    setStudentDeleteID(stdId);
  };

  const handleDeleteRecord = () => {
    const data = {
      userID: deleteID,
      studentId: studentDeleteID,
    };
    onStudentDelete(data);
    setDeleteModel(false);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseEditModel = () => {
    setEditModel(false);
  };

  const [deletePayload, setDeletePayload] = React.useState<any>(null);

  const [visible, setVisible] = React.useState(false);
  const [editData, setEditData] = React.useState<any>({});
  const [transectionDataModel, setTransectionDataModel] = useState({
    userId: null,
    show: false,
  });

  const toggleVisible = React.useCallback(() => {
    setVisible((p) => !p);
    !visible && setEditData({});
  }, [visible]);

  const convertDateFormat = (dateString: any) => {
    const dateTime = DateTime.fromISO(dateString);
    const formattedDate = dateTime.toFormat('LL-dd-yyyy hh:mma');

    return formattedDate;
  };

  const handleCloseTransectionDataModel = (props: any) => {
    setTransectionDataModel((prevState) => {
      const data = {
        ...prevState,
        show: false,
      };
      return data;
    });
  };

  const imgError = (e: any) => {
    e.target.src = defaultImageUrl; // You can specify a default/error image here
    e.target.alt = 'Failed to load image';
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Name
          </span>
        ),
        accessor: 'user.FirstName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <div className='d-flex justify-content-start flex-row align-items-center'>
              <div className='symbol symbol-45px me-5'>
                {result?.user?.ProfilePic ? (
                  <img
                    src={result?.user?.ProfilePic}
                    alt='Image'
                    onError={imgError}
                  />
                ) : (
                  <img
                    src={defaultImageUrl}
                    alt='No Image'
                    onError={imgError}
                  />
                )}
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <a className='text-dark fw-bold fs-6'>
                  {result.user?.FirstName ? result.user?.FirstName : '-'}
                </a>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {result.user?.LastName ? result.user?.LastName : '-'}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'user.Email',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result.user?.Email ? (
            <a
              href={'mailto:' + result.user?.Email}
              className={'text-dark fw-bold text-hover-primary d-block fs-6'}
            >
              {result.user?.Email}
            </a>
          ) : (
            <span className='text-muted fw-bold text-hover-primary d-block fs-6'>
              Not Available
            </span>
          );
        },
      },
      {
        Header: 'Student ID#',
        accessor: 'Student_Profile_ID',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.Student_Profile_ID ? result?.Student_Profile_ID : '-';
        },
      },
      // {
      //   Header: 'Whatsapp no.',
      //   accessor: 'Whatsapp',
      //   Cell: (props: CellProps<any>) => {
      //     const result = props.row.original;
      //     return '1234567890';
      //   },
      // },
      {
        Header: 'Joining date',
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result.user?.createdAt
            ? moment(result.user?.createdAt).utc().format('DD-MM-YYYY HH:MM A')
            : '-';
        },
      },
      {
        Header: 'Last Logged In',
        accessor: 'lastLoginDate',

        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.lastLoginDate ? (
            <span className='pe-4 d-block'>
              {moment(result.lastLoginDate).utc().format('DD-MM-YYYY HH:MM A')}
            </span>
          ) : (
            <span className='pe-4 d-block text-center'>-</span>
          );
        },
      },
      {
        Header: 'Subscription',
        accessor: 'subscriptionData',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return result?.subscriptionData?.ValidTo ? (
            <div>
              <span
                className={
                  moment(moment(moment().endOf('day').utc())).isAfter(
                    result?.subscriptionData?.ValidTo
                  )
                    ? 'badge badge-danger'
                    : 'badge badge-primary'
                }
              >
                {moment(moment(moment().endOf('day').utc())).isAfter(
                  result?.subscriptionData?.ValidTo
                )
                  ? 'Expired'
                  : 'Subscribed'}
              </span>
              <i
                className={'bi bi-info-circle cursor-pointer'}
                style={{ paddingLeft: '10px' }}
                onClick={() => {
                  setTransectionDataModel((prevState) => {
                    const data = {
                      ...prevState,
                      userId: result?.subscriptionData?.UserID,
                      show: true,
                    };
                    return data;
                  });
                }}
              ></i>
            </div>
          ) : (
            <div>
              <span className={'badge badge-secondary'}>Free Trial</span>
            </div>
          );
        },
      },
      {
        Header: 'Active?',
        accessor: 'isActive',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <span
              className={
                result?.user?.IsActive
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              }
            >
              {result?.user?.IsActive ? 'Yes' : 'No'}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                type='button'
                title='edit'
                onClick={() => {
                  toggleVisible();
                  setEditData(result);
                }}
                data-bs-toggle='modal'
                data-bs-target='#Edit_Modal_scrollable'
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => {
                  setDeletePayload(result || null);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    [onStudentGetById]
  );

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageSize: 50,
    });

  const { sort, page, limit, search } = tableState;

  const fetcher = React.useCallback(() => {
    return callBackendApi('post', {
      url: urlRoutes.students,
      data: {
        page,
        limit,
        search,
        sort,
      },
    });
  }, [page, limit, sort, search]);

  const [loading, data, fetch] = useBackendApiCaller(fetcher, [fetcher]);
  const list = data?.ResultObject?.userdata ?? [];
  const reversedRecords = [...list].reverse();
  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className='card mb-10'>
        <Table
          data={list}
          columns={columns}
          initialState={tableInitialState}
          pageCount={data?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header justify-content-between border-0 pt-5'>
              <h3 className='card-title'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Student Data
                </span>
              </h3>
              <span>
                <TableGlobalSearch />
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={loading} entityLabel='student' />
                </div>
                <div className='col-12 border-top pt-5'>
                  <TablePagination pageOptions={[5, 10, 20, 50]} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <DeleteModal
        onCancel={() => setDeletePayload(null)}
        visible={Boolean(deletePayload)}
        initialValue={deletePayload}
        fetch={fetch}
      />
      {visible && (
        <DetailEditModal
          fetch={fetch}
          visible={visible}
          initialValue={editData}
          onCancel={toggleVisible}
        />
      )}

      {/* <StudentEditModel
        editModel={editModel}
        // handleEditRecord={handleEditRecord}
        handleCloseEditModel={handleCloseEditModel}
        studentDetail={studentDetail}
        success={success}
        onStudentEdit={onStudentEdit}
        loading={loading}
      /> */}
      {transectionDataModel.show && (
        <TransactionDetailModal
          transactionModel={transectionDataModel}
          handleClosetransactionModel={handleCloseTransectionDataModel}
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    loading: state.student.loading,
    error: state.student.error,
    success: state.student.success,
    studentlist: state.student.studentlist,
    studentDetail: state.student.studentdetails,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  studentgetReq: (params: any) => dispatch(studentGet(params)),
  onStudentDelete: (params: any) => dispatch(studentDelete(params)),
  onStudentGetById: (params: any) => dispatch(studentGetById(params)),
  onStudentEdit: (params: any) => dispatch(studentUpdate(params)),
  onSuccessClear: () => dispatch(successClear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsPage);
