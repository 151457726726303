import { call, put, takeLatest, all } from 'redux-saga/effects';

import { post } from '../../helpers/api-helper';
import { logError } from '../../utils/logs';

import {
  loginSuccess,
  loginFailure,
  registartionSuccessRequest,
  registartionFailure,
} from './actions';
import { LOGIN_USER, REGISTER_USER } from './actionTypes';

const login = (data: any) => post('account/Adminlogin', data);
const registeration = (data: any) => post('user/register', data);

function* loginSaga(action: any) {
  try {
    const response: {
      token: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(login, {
      email: action.payload.email,
      password: action.payload.password,
      rememberMe: action.payload.rememberMe,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(loginFailure({ error: response.ResultMessage }));
    } else {
      const lsValue = JSON.stringify(response);
      localStorage.setItem('UserInfo', lsValue);
      yield put(loginSuccess({ token: response }));
    }
  } catch (e: any) {
    yield put(loginFailure({ error: e.massage }));
  }
}

function* register(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(registeration, {});
    if (response.ResultCode !== 'SUCCESS') {
      yield put(registartionFailure({ error: response }));
    } else {
      yield put(registartionSuccessRequest({ success: response }));
    }
  } catch (error) {
    logError(error);
  }
}

function* authSaga() {
  yield all([takeLatest(LOGIN_USER, loginSaga)]);
  yield all([takeLatest(REGISTER_USER, register)]);
}

export default authSaga;
