import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { Image, Stack } from 'react-bootstrap';

import { toAbsoluteUrl } from '../../../theme/helpers';
import { isString } from '../../../utils/isString';

type PropsType = {
  entityLabel: string;
  name: string;
};

// NOTE: Only works if you are using Formik

const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');

const PreviewImage: React.FC<{ file: Blob | null | string }> = ({ file }) => {
  const [preview, setPreview] = React.useState<any>(file);

  const init = React.useCallback(() => {
    if (!file) {
      return;
    }
    if (isString(file)) {
      setPreview(file);

      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setPreview(fileReader.result);
    };
    fileReader.onerror = () => {
      setPreview(defaultImageUrl);
    };
  }, [file]);

  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <Image src={preview} className='image-input-wrapper object-fit-cover' />
  );
};

const ImageUpload: React.FC<PropsType> = ({ name, entityLabel }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const imageUrlOrBlobOrNull = getIn(values, name);

  const file =
    imageUrlOrBlobOrNull !== '' ? imageUrlOrBlobOrNull : defaultImageUrl;

  const error = getIn(errors, name);
  const _touched = getIn(touched, name);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-debugger
    debugger;
    setFieldValue(name, e.target.files?.[0]);
  };

  const handleRemoveImage = (e: any) => {
    // eslint-disable-next-line no-debugger
    debugger;
    e.preventDefault();
    setFieldValue(name, '', true);
  };

  return (
    <Stack>
      <div
        className='image-input image-input-outline'
        data-kt-image-input='true'
        style={{
          backgroundImage: 'url(../../../../media/svg/avatars/blank.svg)',
        }}
      >
        {/*begin::Image preview wrapper*/}

        {/*end::Image preview wrapper*/}
        {/*begin::Edit button*/}
        <label
          className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          data-bs-dismiss='click'
          title={`Change ${entityLabel} avatar`}
        >
          <i className='bi bi-pencil-fill fs-7' />
          {/*begin::Inputs*/}
          <input
            type='file'
            name={name}
            accept='image/*'
            onChange={handleChange}
          />
          <input type='hidden' name='avatar_remove' />
          {/*end::Inputs*/}
        </label>
        <PreviewImage file={file} />

        {/*end::Edit button*/}
        {/*begin::Cancel button*/}
        {/* <span
        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        data-bs-dismiss='click'
        title={`Remove ${entityLabel} avatar`}
      >
        <i className='bi bi-x fs-2' />
      </span> */}
        {/*end::Cancel button*/}
        {/*begin::Remove button*/}
        {file !== defaultImageUrl && (
          <button
            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='remove'
            data-bs-toggle='tooltip'
            data-bs-dismiss='click'
            title={`Remove ${entityLabel} avatar`}
            onClick={handleRemoveImage}
          >
            <i className='bi bi-x fs-2' />
          </button>
        )}

        {/*end::Remove button*/}
      </div>
      {error && _touched && (
        <span className='text-danger' style={{ width: '100px' }}>
          {error}
        </span>
      )}
    </Stack>
  );
};

export default ImageUpload;
