import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay-ts';
import { IoMdClose } from 'react-icons/io';

import {
  allLanguageGet,
  updateLanguageActiveStatus,
} from '../../../store/actions';
import Loader from 'app/components/Loader';

const LanguageAddPage: FC<any> = (props: any) => {
  const [languageData, setLanguageData] = useState([]);
  const [IsActive, setIsActive] = useState(false);
  const {
    languageList,
    updateLanguageActiveReq,
    success,
    languageGetReq,
    loading,
  } = props;

  useEffect(() => {

    if (success) {
      // languageGetReq({ Lesson_Id: success.English_Lesson_Id })
      Swal.fire("Language Edit Successfully", ' ', 'success')
    }
  }, [success]);

  useEffect(() => {
    if (languageList.length == 0) {
      setLanguageData([]);
    }
    if (languageList.length > 0) {
      setLanguageData(languageList);
    }
  }, [languageList]);

  const handleIsActive = (event: any, id: any) => {
    if (event == "on") {
      setIsActive(event == "on" ? true : false);
    }
    const formData = {
      IsActive: IsActive,
      Language_ID: id,
    };
    updateLanguageActiveReq(formData);
    languageData.map((r: any) => {
      if (r._id == id) {
        r.IsActive = event;
      }
    });
    // Swal.fire("Language Edit Successfully", ' ', 'success')
  };

  return (
    <>
      <Loader loading={loading} />
      <div className='modal fade' id='Edit_Modal_scrollable'>
        <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered mw-650px'>
          {/* <LoadingOverlay
            active={loading}
            spinner
            className='model-loading-overlay'
          > */}
          <div className='modal-content'>
            <IoMdClose
              data-bs-dismiss='modal'
              className='modal-close-icon'
              size={25}
            />
            <div className='modal-header'>
              <h1 className='modal-title fw-bold'>Add Language </h1>
            </div>
            <div className='modal-body scroll-y'>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted bg-dark'>
                        {/* <th className='min-w-50px ps-4 rounded-start'>No</th> */}
                        <th className='min-w-150px ps-4 rounded-start text-start'>
                          Name
                        </th>
                        <th className='min-w-140px'>Code</th>
                        <th className='min-w-100px text-end rounded-end pe-4'>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {languageData.map((result: any) => {
                        return (
                          <tr className={result?.Language_Id?.Language_Name == undefined ? 'd-none' : ''}>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary d-block fs-6 ps-2'
                              >
                                {result?.Language_Id?.Language_Name}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary d-block fs-6'
                              >
                                {result?.Language_Id?.Language_Code}
                              </a>
                            </td>
                            <td className='text-end switch_chk_button'>
                              <div className='form-check form-check-solid form-switch fv-row pe-2'>
                                <input
                                  className='form-check-input w-45px h-30px'
                                  type='checkbox'
                                  id='language'
                                  data-toggle="switchbutton"
                                  defaultChecked={result?.IsActive}
                                  onChange={(event) => {
                                    handleIsActive(event.target.value, result?._id);
                                  }}
                                />
                                <label className='form-check-label'></label>
                              </div>
                              {/* <BootstrapSwitchButton
                                  onlabel='ON'
                                  onstyle='success'
                                  offlabel='OFF'
                                  offstyle='secondary'
                                  style='mx-3'
                                  checked={result.IsActive}
                                  onChange={(event) => {
                                    handleIsActive(event, result._id);
                                  }}
                                /> */}
                              {/* <Link
                                      to={"/lesson/preview"}
                                      className='btn btn-icon btn-secondary'
                                      style={{ marginLeft: "10px", width: "80px" }}
                                  // data-bs-dismiss="modal"
                                  >
                                      Preview
                                  </Link> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* </LoadingOverlay> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    error: state.lesson.error,
    success: state.lesson.success,
    lessonList: state.lesson.lessonList,
    languageList: state.lesson.languageList,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  languageGetReq: (param: any) => dispatch(allLanguageGet(param)),
  updateLanguageActiveReq: (param: any) =>
    dispatch(updateLanguageActiveStatus(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAddPage);
