import React from 'react';

export default function useLoadingState(initialState = {}) {
  const [state, setState] = React.useState({
    count: 0,
    loading: false,
    ...initialState,
  });

  const startLoading = React.useCallback(() => {
    setState((prev) => ({ ...prev, count: prev.count + 1, loading: true }));
  }, []);

  const stopLoading = React.useCallback(() => {
    setState((prev) => ({ ...prev, count: prev.count - 1, loading: false }));
  }, []);

  return { ...state, startLoading, stopLoading };
}
