import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../components/Loader';
import { CellProps, Column } from 'react-table';
import Table, { useTableInstance } from '../../components/Table';
import TableInner from 'app/components/Table/TableInner';
import useTableState from 'app/components/Table/useTableState';
import { KTSVG, toAbsoluteUrl } from 'theme/helpers';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import Select from 'react-select';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';
import { useDeleteQuery, useGetQuery } from 'hooks/reactQueryHelper';
import EnrollmentHeadPageTab from './EnrollmentHeadPageTab';
import DueDatesDetailModal from '../course/DueDatesDetailModal';
import { PageTitle } from 'theme/layout/core';
import EnrollStudentsDetailsModal from 'app/components/EnrollStudentsDetailsModal/EnrollStudentsDetailsModal';
import useModal from 'hooks/useModal';

const PlatformCoursePage: React.FC = (props: any) => {
  const {
    show: enrollModalShow,
    openModal: openEnrollModal,
    closeModal: closeEnrollModal,
    modalData: enrollModalData,
  } = useModal();

  const defaultImageUrl = 'https://via.placeholder.com/150?text=N/A';

  const getAllCourseQuery: any = useGetQuery(
    'getAllDiplomaEnglishCourse',
    '/course/getAllDiplomaEnglishCourse'
  );
  const deleteCourseQuery: any = useDeleteQuery('deleteCourse', '/course');

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageSize: 50,
    });
  const { sort, page, limit, search } = tableState;

  const [deleteID, setDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);

  const [dueDatesModal, setDueDatesModal] = React.useState<any>({
    show: false,
    dueDates: [],
  });

  useEffect(() => {
    getAllCourseQuery.mutateAsync({
      params: {
        page,
        limit,
        sort,
        search,
      },
    });
  }, [page, limit, sort, search]);

  const handleDeleteRecord = () => {
    setDeleteModel(false);
    deleteCourseQuery.mutateAsync({
      params: {
        id: deleteID,
      },
    });
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  useEffect(() => {
    if (deleteCourseQuery.isSuccess) {
      getAllCourseQuery.mutateAsync();
    }
  }, [deleteCourseQuery.isSuccess]);

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: '',
        accessor: 'schoolLogo',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              <div className='symbol symbol-45px me-5'>
                {props?.row?.original?.schoolLogo ? (
                  <img src={props?.row?.original?.schoolLogo} alt='Logo' />
                ) : (
                  <img src={defaultImageUrl} />
                )}
              </div>
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start text-nowrap'>
            Name
          </span>
        ),
        accessor: 'name',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='ps-4 text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.name}
            </span>
          );
        },
        disableSortBy: false,
      },
      {
        Header: 'Target Language',
        accessor: 'targetLanguage',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              <span
                className='badge badge-pill badge-secondary'
                style={{ marginRight: '3px' }}
              >
                {props?.row?.original?.targetLanguage?.Language_Name}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Grade',
        accessor: 'grade',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.grade}
            </span>
          );
        },
      },
      {
        Header: 'Language Level',
        accessor: 'languageLevel',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.languageLevel?.Level_Name}
            </span>
          );
        },
      },
      {
        Header: 'Age Group',
        accessor: 'ageOfGroup',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.ageOfGroup?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item?.minAge}{' '}
                  {item?.maxAge < 200 ? '- ' + item?.maxAge : '+'}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Needed Points',
        accessor: 'pointsNeededToCompleteCourse',
        disableSortBy: false,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.pointsNeededToCompleteCourse}
            </span>
          );
        },
      },
      {
        Header: 'Due Date',
        accessor: 'Due Date',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return props?.row?.original?.isDueDate ? (
            <>
              <span className='badge badge-primary'>Yes</span>
              <span
                onClick={() => {
                  setDueDatesModal({
                    ...dueDatesModal,
                    show: true,
                    dueDates: props?.row?.original?.dueDates,
                  });
                }}
              >
                <i className={'bi bi-info-circle cursor-pointer ms-2'}></i>
              </span>
            </>
          ) : (
            <span className='badge badge-secondary'>No</span>
          );
        },
      },
      {
        Header: 'Enroll Students',
        accessor: 'Enroll',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex align-items-center'>
              {result?.enrolledCourseCount > 0 ? (
                <>
                  <span className='text-dark fw-bold d-block fs-6'>
                    {result?.enrolledCourseCount}
                  </span>
                  <span
                    onClick={() => {
                      openEnrollModal({
                        id: result?._id,
                        search: search,
                      });
                    }}
                  >
                    <i className={'fa-solid fa-eye cursor-pointer ms-2'}></i>
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#delete_confirmation_modal'
                onClick={() => {
                  setDeleteModel(true);
                  setDeleteID(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    [search]
  );
  return (
    <>
      {dueDatesModal.show && (
        <DueDatesDetailModal
          show={dueDatesModal.show}
          onHide={() =>
            setDueDatesModal({
              ...dueDatesModal,
              show: false,
            })
          }
          dueDates={dueDatesModal.dueDates}
        />
      )}
      {enrollModalShow && (
        <EnrollStudentsDetailsModal
          show={enrollModalShow}
          onHide={closeEnrollModal}
          data={enrollModalData}
        />
      )}
      <PageTitle breadcrumbs={[]}>Platform Course</PageTitle>
      <div className='card mb-10'>
        <React.Fragment>
          <div className='pt-5'>
            <EnrollmentHeadPageTab activeTab='PlatformCourse' />
          </div>

          <div className='card-body py-3'>
            <div className='row'>
              <div className='col-12 mb-5'>
                <React.Fragment>
                  <div className='card mb-10'>
                    <Loader
                      loading={
                        getAllCourseQuery?.isLoading ||
                        deleteCourseQuery.isLoading
                      }
                    />
                    <Table
                      data={
                        getAllCourseQuery?.data?.ResultObject?.data
                          ? getAllCourseQuery?.data?.ResultObject?.data
                          : []
                      }
                      //@ts-ignore
                      pageIndex={3}
                      columns={columns}
                      initialState={tableInitialState}
                      pageCount={
                        getAllCourseQuery?.data?.ResultObject?.totalPages ?? -1
                      }
                      onTableStateChange={handleTableStateChange}
                    >
                      <React.Fragment>
                        <div className='card-header border-0 pt-5'>
                          <h3 className='card-title align-items-start flex-column'>
                            <div className='card-label fw-bold fs-3 mb-1'>
                              Platform Courses
                            </div>{' '}
                          </h3>
                          <div className='d-flex align-items-end'>
                            <div className='me-2'>
                              <TableGlobalSearch />
                            </div>
                          </div>
                        </div>
                        <div className='card-body py-3'>
                          <div className='row'>
                            <div className='col-12 mb-5'>
                              <TableInner
                                loading={false}
                                entityLabel='Courses'
                              />
                            </div>
                            <div className='col-12 border-top pt-5'></div>
                            <TablePagination pageOptions={[5, 10, 20, 50]} />
                          </div>
                        </div>
                      </React.Fragment>
                    </Table>
                  </div>

                  <DeleteConfirmationModel
                    deleteModel={deleteModel}
                    handleDeleteRecord={handleDeleteRecord}
                    handleCloseDeleteModel={handleCloseDeleteModel}
                  />
                </React.Fragment>
              </div>
              <div className='col-12 border-top pt-5'></div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};

export default PlatformCoursePage;
