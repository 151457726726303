import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';

const AIAddtionalTextActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    loading,
    getValues,
    AIActivityDetail,
    nativeLang,
    targetLang,
    imageUploadReq,
    imageloading,
    imageURL,
  } = props;

  useEffect(() => {
    setValue('HeadText', 'Create an AI Photo');
    setValue('PhotoHeadText', 'Upload a Photo of Yourself');
    setValue('Step1', 'Step1');
    setValue('Step2', 'Step2');
  }, []);

  return (
    <React.Fragment>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                AI Additional Text
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
        >
          <div className='border-top my-3'></div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>AI Head Text</label>
                  <input
                    disabled={true}
                    type='text'
                    className={'form-control'}
                    {...register('HeadText')}
                    name='HeadText'
                    value={getValues('HeadText')}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <br />
        </Collapsible>
      </div>
    </React.Fragment>
  );
};

export default AIAddtionalTextActivity;
