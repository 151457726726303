export const LESSON_GET = 'LESSON_GET';
export const LESSON_GET_SUCCESS = 'LESSON_GET_SUCCESS';

export const LESSON_SAVE = 'LESSON_SAVE';
export const LESSON_SAVE_SUCCESS = 'LESSON_SAVE_SUCCESS';

export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE';

export const GET_ALL_LANGUAGE = 'GET_ALL_LANGUAGE';
export const GET_ALL_LANGUAGE_SUCCESS = 'GET_ALL_LANGUAGE_SUCCESS';

export const LESSON_GET_LANGUAGE = 'LESSON_GET_LANGUAGE';
export const LESSON_GET_LANGUAGE_SUCCESS = 'LESSON_GET_LANGUAGE_SUCCESS';

export const UPDATE_LANGUAGE_ACTIVE_STATUS = 'UPDATE_LANGUAGE_ACTIVE_STATUS';
export const UPDATE_LANGUAGE_ACTIVE_STATUS_SUCCESS =
  'UPDATE_LANGUAGE_ACTIVE_STATUS_SUCCESS';


export const LESSON_DELETE = 'LESSON_DELETE';
export const LESSON_DELETE_SUCCESS = 'LESSON_DELETE_SUCCESS';

export const LESSON_GET_BY_ID = 'LESSON_GET_BY_ID';
export const LESSON_GET_BY_ID_SUCCESS = 'LESSON_GET_BY_ID_SUCCESS';

export const LESSON_UPDATE = 'LESSON_UPDATE';
export const LESSON_UPDATE_SUCCESS = 'LESSON_UPDATE_SUCCESS';

export const LEVEL_GET_ALL = "LEVEL_GET_ALL"
export const LEVEL_GET_SUCCESS = "LEVEL_GET_SUCCESS"

export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILURE = 'UPLOAD_VIDEO_FAILURE';

export const LESSON_FAILURE = 'LESSON_FAILURE';
export const LEVEL_FAILURE = 'LEVEL_FAILURE';

export const LESSON_DETAILS_CLEAR = 'LESSON_DETAILS_CLEAR';

export const LESSON_ID_EXIST_OR_NOT = 'LESSON_ID_EXIST_OR_NOT';
export const LESSON_ID_EXIST_OR_NOT_SUCCESS = 'LESSON_ID_EXIST_OR_NOT_SUCCESS';


export const LESSON_UPDATE_MULTIPLE_LANGUAGE = 'LESSON_UPDATE_MULTIPLE_LANGUAGE';
export const LESSON_UPDATE_MULTIPLE_LANGUAGE_SUCCESS = 'LESSON_UPDATE_MULTIPLE_LANGUAGE_SUCCESS';
