import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'


const CancelConfirmLessonModel: React.FC<any> = (props: any) => {
    const { cancelConfirmModel, handleCancelLesson, handleCloseCancelModel } = props

    const handleSubmit = (e: any) => {
        e.preventDefault()
        handleCancelLesson()
    }
    return (
        <Modal show={Boolean(cancelConfirmModel)} onHide={handleCloseCancelModel} centered>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h4 className='delete-text'>Are you sure you want to cancel add lesson?</h4>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='primary' type='submit'>
                        Yes
                    </Button>
                    <Button variant='secondary' type='button' onClick={handleCloseCancelModel}>
                        No
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CancelConfirmLessonModel
