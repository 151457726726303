/* eslint-disable import/no-unresolved */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Button, Form, Modal as BModal, Spinner } from 'react-bootstrap';
import useLoadingState from 'hooks/useLoadingState';
import { urlRoutes } from 'shared/apiConfig';
import callBackendApi from 'utils/callBackendApi';
import Swal from 'sweetalert2';

type PropsType = {
  visible: boolean;
  initialValue: any;
  fetch: () => any;
  onCancel: () => void;
};

const DeleteModal: React.FC<React.PropsWithChildren<PropsType>> = (props) => {
  const {
    visible,
    onCancel = () => null,
    fetch = () => null,
    initialValue: i,
  } = props;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = await callBackendApi('post', {
      url: urlRoutes.studentDelete,
      data: {
        studentId: i?._id,
        userId: i?.user?._id,
      },
    });

    if (data?.ResultCode === 'SUCCESS') {
      onCancel();
      fetch();
      setTimeout(() => {
        Swal.fire(data?.ResultMessage, ' ', 'success');
      }, 1000);

      return;
    }

    onCancel();
  };

  return (
    <>
      <BModal show={visible} onHide={onCancel} centered>
        <Form onSubmit={handleSubmit}>
          <BModal.Header closeButton>
            <BModal.Title>Confirmation</BModal.Title>
          </BModal.Header>

          <BModal.Body>
            <h4 className='delete_text'>Are you sure you want to delete?</h4>
          </BModal.Body>

          <BModal.Footer>
            <Button variant='primary' type='submit'>
              Yes
            </Button>
            <Button variant='secondary' type='button' onClick={onCancel}>
              No
            </Button>
          </BModal.Footer>
        </Form>
      </BModal>
    </>
  );
};

export { DeleteModal };
