import {
    LANGUAGE_GET_ALL,
    LANGUAGE_GET_ALL_SUCCESS,
    LANGUAGE_GET_ALL_FAIL,
    LANGUAGE_GET_BY_ID,
    LANGUAGE_GET_BY_ID_SUCCESS,
    LANGUAGE_GET_BY_ID_FAIL,
    LANGUAGE_ADD,
    LANGUAGE_ADD_SUCCESS,
    LANGUAGE_ADD_FAIL,
    LANGUAGE_UPDATE,
    LANGUAGE_UPDATE_SUCCESS,
    LANGUAGE_UPDATE_FAIL,
    LANGUAGE_DELETE,
    LANGUAGE_DELETE_SUCCESS,
    LANGUAGE_DELETE_FAIL,
    LANGUAGE_CLEAR
} from "./actionTypes"

export  const getAllLanguage = ( formData:any ) => {
    return {
        type: LANGUAGE_GET_ALL,
        payload: { formData },
    }
}
export  const getAllLanguageSuccess = ( response:any ) => {
    return {
        type: LANGUAGE_GET_ALL_SUCCESS,
        payload:  response ,
    }
}
export  const getAllLanguageFail = ( response:any ) => {
    return {
        type: LANGUAGE_GET_ALL_FAIL,
        payload:  response ,
    }
}


export  const getByIdLanguage = ( param :any) => {
    return {
        type: LANGUAGE_GET_BY_ID,
        payload: { param },
    }
}
export  const getByIdLanguageSuccess = ( response:any) => {
    return {
        type: LANGUAGE_GET_BY_ID_SUCCESS,
        payload:  response ,
    }
}
export  const getByIdLanguageFail = ( response:any) => {
    return {
        type: LANGUAGE_GET_BY_ID_FAIL,
        payload:  response ,
    }
}


export  const addLanguage = (formData:any) => {
    return {
        type: LANGUAGE_ADD,
        payload: { formData },
    }
}
export  const addLanguageSuccess = (response:any) => {
    return {
        type: LANGUAGE_ADD_SUCCESS,
        payload:  response ,
    }
}
export  const addLanguageFail = (response:any) => {
    return {
        type: LANGUAGE_ADD_FAIL,
        payload:  response ,
    }
}


export  const updateLanguage = (formData:any) => {
    return {
        type: LANGUAGE_UPDATE,
        payload: { formData },
    }
}
export  const updateLanguageSuccess = (response:any) => {
    return {
        type: LANGUAGE_UPDATE_SUCCESS,
        payload: response ,
    }
}
export  const updateLanguageFail = (response:any) => {
    return {
        type: LANGUAGE_UPDATE_FAIL,
        payload: response ,
    }
}


export  const deleteLanguage = (param:any) => {
    return {
        type: LANGUAGE_DELETE,
        payload: { param },
    }
}
export  const deleteLanguageSuccess = (response:any) => {
    return {
        type: LANGUAGE_DELETE_SUCCESS,
        payload:  response ,
    }
}
export  const deleteLanguageFail = (response:any) => {
    return {
        type: LANGUAGE_DELETE_FAIL,
        payload:  response ,
    }
}

export  const clearLanguage = () => {
    return {
        type: LANGUAGE_CLEAR,
    }
}
