import { call, put, takeEvery } from 'redux-saga/effects';
import {
  LIVE_CLASS_SCHEDULE_GET_ALL,
  LIVE_CLASS_SCHEDULE_GET_BY_ID,
  LIVE_CLASS_SCHEDULE_ADD,
  LIVE_CLASS_SCHEDULE_UPDATE,
  LIVE_CLASS_SCHEDULE_DELETE,
} from './actionTypes';
import { get, post } from '../../helpers/api-helper';
import {
  getAllLiveClassScheduleSuccess,
  getAllLiveClassScheduleFail,
  getByIdLiveClassScheduleSuccess,
  getByIdLiveClassScheduleFail,
  addLiveClassScheduleSuccess,
  addLiveClassScheduleFail,
  updateLiveClassScheduleSuccess,
  updateLiveClassScheduleFail,
  deleteLiveClassScheduleSuccess,
  deleteLiveClassScheduleFail,
} from './actions';

const getAllLiveClassScheduleCall = async (data: any) =>
  await post('liveClass/getAll', data);
const addLiveClassScheduleCall: any = (data: any) =>
  post('liveClass/add', data);
const getByLiveClassScheduleCall: any = (data: any) =>
  get('liveClass/getById', data);
const updateLiveClassScheduleCall: any = (data: any) =>
  post('liveClass/update', data);
const deleteLiveClassScheduleCall: any = (data: any, config: any) =>
  post('liveClass/delete', data, config);

function* getAllLiveClassSchedule({ payload: { formData } }: any): any {
  try {
    const response = yield call(getAllLiveClassScheduleCall, {
      limit: formData.limit,
      page: formData.page,
      search: formData.search,
      sort: formData.sort,
    });

    yield put(getAllLiveClassScheduleSuccess(response));
  } catch (error) {
    yield put(getAllLiveClassScheduleFail(error));
  }
}

function* getByIdLiveClassSchedule({ payload: { param } }: any): any {
  try {
    const response = yield call(getByLiveClassScheduleCall, {
      params: {
        _id: param._id,
      },
    });
    yield put(getByIdLiveClassScheduleSuccess(response));
  } catch (error) {
    yield put(getByIdLiveClassScheduleFail(error));
  }
}

function* addLiveClassSchedule({ payload: { formData } }: any): any {
  try {
    const newFormData = {
      name: formData.name,
      ageOfGroup: formData.ageOfGroup,
      languageLevel: formData.languageLevel,
      targetLanguage: formData.targetLanguage,
      nativeLanguage: formData.nativeLanguage,
      scheduleDate: formData.scheduleDate,
      isEncoder: formData.isEncoder,
    };
    const response = yield call(addLiveClassScheduleCall, newFormData);
    if (response?.statusCode === 200) {
      yield put(addLiveClassScheduleSuccess(response));
    } else {
      yield put(addLiveClassScheduleSuccess(response));
    }
  } catch (error) {
    yield put(addLiveClassScheduleFail(error));
  }
}

function* UpdateLiveClassSchedule({ payload: { formData } }: any): any {
  try {
    const response = yield call(updateLiveClassScheduleCall, {
      _id: formData._id,
      name: formData.name,
      ageOfGroup: formData.ageOfGroup,
      languageLevel: formData.languageLevel,
      targetLanguage: formData.targetLanguage,
      nativeLanguage: formData.nativeLanguage,
      scheduleDate: formData.scheduleDate,
      isEncoder: formData.isEncoder,
    });

    if (response?.statusCode === 200) {
      yield put(updateLiveClassScheduleSuccess(response));
    } else {
      yield put(updateLiveClassScheduleSuccess(response));
    }
  } catch (error) {
    yield put(updateLiveClassScheduleFail(error));
  }
}

function* deleteLiveClassSchedule({ payload: { param } }: any): any {
  try {
    const response = yield call(deleteLiveClassScheduleCall, {
      _id: param._id,
    });
    if (response?.statusCode === 200) {
      yield put(deleteLiveClassScheduleSuccess(response));
    } else {
      yield put(deleteLiveClassScheduleSuccess(response));
    }
  } catch (error) {
    yield put(deleteLiveClassScheduleFail(error));
  }
}

function* LiveClassScheduleSaga() {
  yield takeEvery(LIVE_CLASS_SCHEDULE_GET_ALL, getAllLiveClassSchedule);
  yield takeEvery(LIVE_CLASS_SCHEDULE_ADD, addLiveClassSchedule);
  yield takeEvery(LIVE_CLASS_SCHEDULE_GET_BY_ID, getByIdLiveClassSchedule);
  yield takeEvery(LIVE_CLASS_SCHEDULE_UPDATE, UpdateLiveClassSchedule);
  yield takeEvery(LIVE_CLASS_SCHEDULE_DELETE, deleteLiveClassSchedule);
}

export default LiveClassScheduleSaga;
