import React, { useEffect, useRef, useState } from 'react';
import { Formik, FastField, Form, FieldProps, Field } from 'formik';
import { Button, Form as BForm, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import InstantLiveClassAction from './instantLiveClassAction/InstantLiveClassAction';
import InstantLiveClassContentGradeEarnedAdd from './instantLiveClassContentGradeEarned/InstantLiveClassContentGradeEarnedAdd';
export const GET_ALL_LANGUAGE = `/language/getAllLanguages`;
export const GET_ALL_LEVEL = `/level/getAll`;

const premiumOption: any = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const InstantLiveClassContentAddEditModal: React.FC<any> = (props: any) => {
  const videoEl = useRef<any>();
  const previewVideoEl = useRef<any>();

  const { addEditModel, handleCloseAddEditModel }: any = props;

  const validationSchema = Yup.object({
    existVideo: Yup.string(),
    videoUrl: Yup.mixed().required('Please Select Video.'),
    existLiveClassVideo: Yup.string(),
    previewVideoUrl: Yup.mixed().required(
      'Please Select Live Class Video Example.'
    ),
    nameOfVideo: Yup.string().required('Please Enter Name of Lesson.'),
    ageGroup: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    targetLanguage: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    nativeLanguage: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    languageLevel: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    contentID: Yup.string().required('Please enter content Id.'),
    description: Yup.string().required('Please enter description.'),
    learningOutcomes: Yup.string().required('Please enter learning outcomes.'),
    isPremium: Yup.object().nullable().required('Please select.'),
    playVideoTime: Yup.string().required('Please enter play time.'),
    formEarnedGrade: Yup.object({
      time: Yup.string().required('Please enter time.'),
      classRoomEvent: Yup.object().nullable().required('Please select.'),
      message: Yup.object().when(
        'classRoomEvent',
        (classRoomEvent, schema): any => {
          if (classRoomEvent?.value == 'chat') {
            return Yup.string().required('Please enter message.');
          } else {
            return Yup.string().nullable();
          }
        }
      ),
      earnPoint: Yup.string().required('Please enter grade earned.'),
    }),
  });

  let initialValues = {
    existVideo: '',
    videoUrl: '',
    previewVideoUrl: '',
    existLiveClassVideo: '',
    nameOfVideo: '',
    ageGroup: [],
    targetLanguage: '',
    nativeLanguage: [],
    languageLevel: [],
    contentID: '',
    playVideoTime: '00:00',
    description: '',
    learningOutcomes: '',
    encoderTime: '00:00',
    isPremium: '',
    type: '',
    formEarnedGrade: {
      time: '',
      classRoomEvent: '',
      message: '',
      earnPoint: 100,
    },
  };

  const [show, setShow] = useState(false);
  const [videoFile, setVideoFile] = useState<any | null>(null);
  const [previewVideoFile, setLiveClassVideoFile] = useState<any | null>(null);
  const [videoPreview, setVideoPreview] = useState<any | null>(null);
  const [previewVideoPreview, setLiveClassVideoPreview] = useState<any | null>(
    null
  );
  const [actionData, setActionData] = useState<any | null>([]);

  const addInstantLiveClassContentQuery: any = usePostQuery(
    'addInstantLiveClassContentQuery',
    '/instantClass/add',
    {}
  );

  const updateInstantLiveClassContentQuery: any = usePostQuery(
    'updateInstantLiveClassContentQuery',
    '/instantClass/update',
    {}
  );

  const getInstantLiveClassContentQuery: any = useGetQuery(
    'getCourse',
    '/instantClass/getById'
  );

  const getAllLanguageDataQuery: any = useGetQuery(
    'getAllLanguageDataQuery',
    GET_ALL_LANGUAGE
  );
  const getAllLevelDataQuery: any = useGetQuery(
    'getAllLevelDataQuery',
    GET_ALL_LEVEL
  );

  const ageGroupOptions: any = [
    { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
    { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
    { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
  ];

  useEffect(() => {
    getAllLanguageDataQuery.mutate({});
    getAllLevelDataQuery.mutate({});
  }, []);

  useEffect(() => {
    setShow(addEditModel.show);
  }, [addEditModel.show]);

  const handleClose = () => {
    setShow(false);
    handleCloseAddEditModel({
      refresh: false,
    });
  };

  const onSubmit = (values: any) => {
    let newAction = actionData.map((item: any) => {
      let newActionData: any = {
        time: item?.time,
        actionName: item?.action?.actionName,
      };

      if (item?.message) {
        newActionData.message = item.message;
      }

      if (item?.breakOutRoomType) {
        newActionData.breakOutRoomType = item.breakOutRoomType;
      }

      return newActionData;
    });

    let formActions = [
      {
        time: values?.playVideoTime,
        actionName: 'Play-Video',
      },
      ...newAction,
    ];
    let formData: any = new FormData();
    formData.append('_id', values?.id);
    formData.append('videoURL', videoFile);
    formData.append('existVideoURL', values?.existVideo);
    formData.append('previewVideoUrl', previewVideoFile);
    formData.append('existLiveClassVideoURL', values?.existLiveClassVideoURL);
    formData.append('videoDuration', videoEl?.current?.duration);
    formData.append('videoName', values?.nameOfVideo);

    formData.append(
      'ageOfGroup',
      JSON.stringify(
        values?.ageGroup.map(({ minAge, maxAge }: any) => ({ minAge, maxAge }))
      )
    );
    formData.append(
      'targetLanguage',
      JSON.stringify(values?.targetLanguage?.map((item: any) => item.value))
    );
    formData.append(
      'nativeLanguage',
      JSON.stringify(values?.nativeLanguage?.map((item: any) => item.value))
    );
    formData.append(
      'languageLevel',
      JSON.stringify(values.languageLevel?.map((item: any) => item.value))
    );
    formData.append('contentId', values?.contentID);
    formData.append('actions', JSON.stringify(formActions));
    formData.append('gradeEarned', JSON.stringify(values.formEarnedGrade));
    formData.append('description', values?.description);
    formData.append('learningOutcomes', values?.learningOutcomes);
    formData.append('encoderTime', '00:00');
    formData.append('isPremium', values?.isPremium?.value);
    if (values?.id) {
      updateInstantLiveClassContentQuery.mutateAsync(formData);
    } else {
      addInstantLiveClassContentQuery.mutateAsync(formData);
    }
  };

  useEffect(() => {
    if (addEditModel?.id) {
      getInstantLiveClassContentQuery.mutate({
        params: { id: addEditModel?.id },
      });
    }
  }, [addEditModel]);

  useEffect(() => {
    if (addInstantLiveClassContentQuery?.data?.ResultCode === 'SUCCESS') {
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: addInstantLiveClassContentQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result: any) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (addInstantLiveClassContentQuery?.data?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: addInstantLiveClassContentQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (addInstantLiveClassContentQuery?.data?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: addInstantLiveClassContentQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (updateInstantLiveClassContentQuery?.data?.ResultCode === 'SUCCESS') {
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: updateInstantLiveClassContentQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result: any) => {
        handleCloseAddEditModel({
          refresh: true,
        });
      });
    }

    if (updateInstantLiveClassContentQuery?.data?.ResultCode === 'WARNING') {
      Swal.fire({
        icon: 'warning',
        title: updateInstantLiveClassContentQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }

    if (updateInstantLiveClassContentQuery?.data?.ResultCode === 'ERROR') {
      Swal.fire({
        icon: 'error',
        title: updateInstantLiveClassContentQuery?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  }, [addInstantLiveClassContentQuery, updateInstantLiveClassContentQuery]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop={true}
        size='xl'
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }: any) => {
            console.log('values', values);
            console.log('errors', errors);

            useEffect(() => {
              if (
                addEditModel?.id &&
                getInstantLiveClassContentQuery?.data?.ResultCode ===
                  'SUCCESS' &&
                !isSubmitting
              ) {
                setFieldValue(
                  'id',
                  getInstantLiveClassContentQuery?.data?.ResultObject?._id
                );
                let actionsList =
                  getInstantLiveClassContentQuery?.data?.ResultObject?.instantLiveclassactions
                    ?.filter((item: any) => item?.actionName != 'Play-Video')
                    ?.map((item: any) => {
                      return {
                        action: {
                          label: item?.actionName,
                          value: item?.actionId,
                          actionName: item?.actionName,
                        },
                        time: item?.time,
                        message: item?.message,
                        actionName: item?.actionName,
                        breakOutRoomType: {
                          label: item?.breakOutRoomType,
                          value: item?.breakOutRoomType,
                        },
                      };
                    })
                    ?.sort(function (a: any, b: any) {
                      return a?.time?.localeCompare(b?.time);
                    });
                setActionData(actionsList);

                setFieldValue(
                  'formEarnedGrade.time',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.instantClassGradeEarneds?.time
                    ? getInstantLiveClassContentQuery?.data?.ResultObject
                        ?.instantClassGradeEarneds?.time
                    : ''
                );

                setFieldValue(
                  'formEarnedGrade.classRoomEvent',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.instantClassGradeEarneds?.classRoomEvent
                    ? {
                        value:
                          getInstantLiveClassContentQuery?.data?.ResultObject
                            ?.instantClassGradeEarneds?.classRoomEvent,
                        label:
                          getInstantLiveClassContentQuery?.data?.ResultObject
                            ?.instantClassGradeEarneds?.classRoomEvent,
                      }
                    : ''
                );

                setFieldValue(
                  'formEarnedGrade.message',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.instantClassGradeEarneds?.message
                    ? getInstantLiveClassContentQuery?.data?.ResultObject
                        ?.instantClassGradeEarneds?.message
                    : ''
                );

                setFieldValue('videoUrl', 'Selected');
                setFieldValue(
                  'existVideo',
                  getInstantLiveClassContentQuery?.data?.ResultObject?.videoURL
                );
                setVideoPreview(
                  getInstantLiveClassContentQuery?.data?.ResultObject?.videoURL
                );

                setFieldValue('previewVideoUrl', 'Selected');
                setFieldValue(
                  'existLiveClassVideo',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.previewVideoUrl
                );
                setLiveClassVideoPreview(
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.previewVideoUrl
                );

                setFieldValue(
                  'nameOfVideo',
                  getInstantLiveClassContentQuery?.data?.ResultObject?.videoName
                );

                let ageGroupValue =
                  getInstantLiveClassContentQuery?.data?.ResultObject?.ageOfGroup?.map(
                    (item: any) => {
                      return {
                        ...item,
                        value:
                          item.minAge +
                          (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                        label:
                          item.minAge +
                          (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                        minAge: item.minAge,
                        maxAge: item.maxAge,
                      };
                    }
                  );
                setFieldValue('ageGroup', ageGroupValue);

                let targetLanguage =
                  getInstantLiveClassContentQuery?.data?.ResultObject?.targetLanguage?.map(
                    (item: any) => {
                      return {
                        ...item,
                        label: item?.Language_Name,
                        value: item?._id,
                      };
                    }
                  );

                setFieldValue('targetLanguage', targetLanguage);

                let nativeLanguage =
                  getInstantLiveClassContentQuery?.data?.ResultObject?.nativeLanguage?.map(
                    (item: any) => {
                      return {
                        ...item,
                        label: item?.Language_Name,
                        value: item?._id,
                      };
                    }
                  );
                setFieldValue('nativeLanguage', nativeLanguage);

                let languageLevel =
                  getInstantLiveClassContentQuery?.data?.ResultObject?.languageLevel?.map(
                    (item: any) => {
                      return {
                        ...item,
                        label: item?.Level_Name,
                        value: item?._id,
                      };
                    }
                  );
                setFieldValue('languageLevel', languageLevel);

                setFieldValue(
                  'contentID',
                  getInstantLiveClassContentQuery?.data?.ResultObject?.contentId
                );

                setFieldValue(
                  'encoderTime',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.countdownTime
                );

                setFieldValue(
                  'description',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.description
                );

                setFieldValue(
                  'learningOutcomes',
                  getInstantLiveClassContentQuery?.data?.ResultObject
                    ?.learningOutcomes
                );
                let premium = premiumOption.find((item: any) => {
                  return (
                    item?.value ===
                    getInstantLiveClassContentQuery?.data?.ResultObject
                      ?.isPremium
                  );
                });
                setFieldValue('isPremium', premium ? premium : '');
              }
            }, [getInstantLiveClassContentQuery?.isSuccess]);

            return (
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title id='example-custom-modal-styling-title'>
                      <i className='fa fa-duotone fa-pen-to-square me-2 fs-2'></i>
                      {addEditModel.id === null ? 'Add ' : 'Edit '}Instant Class
                      Content Details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='nameOfVideo'>
                          <BForm.Label>
                            Name of Lesson:
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            type='text'
                            name='nameOfVideo'
                            placeholder='Enter Name of Lesson'
                            onChange={handleChange}
                            value={values.nameOfVideo}
                            className={
                              errors.nameOfVideo && touched.nameOfVideo
                                ? 'border border-danger'
                                : ''
                            }
                          />
                          {errors.nameOfVideo && touched.nameOfVideo ? (
                            <div className='text-danger'>
                              {errors.nameOfVideo}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='contentID'>
                          <BForm.Label>
                            Content ID# :<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            type='text'
                            name='contentID'
                            placeholder='Enter content ID'
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            value={values.contentID}
                            className={
                              errors.contentID && touched.contentID
                                ? 'border border-danger'
                                : ''
                            }
                          />
                          {errors.contentID && touched.contentID ? (
                            <div className='text-danger'>
                              {errors.contentID}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='ageGroup'>
                          <BForm.Label>
                            Age Group :<span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <Field
                            name='ageGroup'
                            isMulti
                            component={Select}
                            options={ageGroupOptions}
                            placeholder='Select an option'
                            isClearable={true}
                            classNamePrefix='select'
                            onChange={(option: any) => {
                              setFieldValue('ageGroup', option);
                            }}
                            // onBlur={handleBlur}
                            value={values.ageGroup}
                            styles={{
                              control: (baseStyles: any, state: any) => {
                                return {
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? errors.ageGroup && touched.ageGroup
                                      ? '#f1416c'
                                      : 'grey'
                                    : errors.ageGroup && touched.ageGroup
                                    ? '#f1416c'
                                    : '#b5b5c3',
                                  boxShadow: '#b5b5c3',
                                  '&:hover': {
                                    borderColor:
                                      errors.ageGroup && touched.ageGroup
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                  '&:focus': {
                                    borderColor:
                                      errors.ageGroup && touched.ageGroup
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                };
                              },
                            }}
                          />
                          {errors.ageGroup && touched.ageGroup ? (
                            <div className='text-danger'>{errors.ageGroup}</div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group
                          className='mb-3'
                          controlId='targetLanguage'
                        >
                          <BForm.Label>
                            Target Language :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <Field
                            name='targetLanguage'
                            isMulti
                            component={Select}
                            options={getAllLanguageDataQuery?.data?.ResultObject.map(
                              (option: any) => {
                                return {
                                  ...option,
                                  value: option._id,
                                  label: option.Language_Name,
                                };
                              }
                            )}
                            placeholder='Select an option'
                            isClearable={true}
                            classNamePrefix='select'
                            onChange={(option: any) => {
                              setFieldValue('targetLanguage', option);
                            }}
                            // onBlur={handleBlur}
                            value={values.targetLanguage}
                            isLoading={getAllLanguageDataQuery.isLoading}
                            isDisabled={getAllLanguageDataQuery.isLoading}
                            styles={{
                              control: (baseStyles: any, state: any) => {
                                return {
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? errors.targetLanguage &&
                                      touched.targetLanguage
                                      ? '#f1416c'
                                      : 'grey'
                                    : errors.targetLanguage &&
                                      touched.targetLanguage
                                    ? '#f1416c'
                                    : '#b5b5c3',
                                  boxShadow: '#b5b5c3',
                                  '&:hover': {
                                    borderColor:
                                      errors.targetLanguage &&
                                      touched.targetLanguage
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                  '&:focus': {
                                    borderColor:
                                      errors.targetLanguage &&
                                      touched.targetLanguage
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                };
                              },
                            }}
                          />

                          {errors.targetLanguage && touched.targetLanguage ? (
                            <div className='text-danger'>
                              {errors.targetLanguage}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group
                          className='mb-3'
                          controlId='nativeLanguage'
                        >
                          <BForm.Label>
                            Native Language :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <Field
                            name='nativeLanguage'
                            isMulti
                            component={Select}
                            options={getAllLanguageDataQuery?.data?.ResultObject.map(
                              (option: any, index: any) => {
                                return {
                                  ...option,
                                  value: option._id,
                                  label: option.Language_Name,
                                };
                              }
                            )}
                            placeholder='Select an option'
                            isClearable={true}
                            classNamePrefix='select'
                            onChange={(option: any) => {
                              setFieldValue('nativeLanguage', option);
                            }}
                            // onBlur={handleBlur}
                            value={values.nativeLanguage}
                            isLoading={getAllLanguageDataQuery.isLoading}
                            isDisabled={getAllLanguageDataQuery.isLoading}
                            styles={{
                              control: (baseStyles: any, state: any) => {
                                return {
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? errors.nativeLanguage &&
                                      touched.nativeLanguage
                                      ? '#f1416c'
                                      : 'grey'
                                    : errors.nativeLanguage &&
                                      touched.nativeLanguage
                                    ? '#f1416c'
                                    : '#b5b5c3',
                                  boxShadow: '#b5b5c3',
                                  '&:hover': {
                                    borderColor:
                                      errors.nativeLanguage &&
                                      touched.nativeLanguage
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                  '&:focus': {
                                    borderColor:
                                      errors.nativeLanguage &&
                                      touched.nativeLanguage
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                };
                              },
                            }}
                          />
                          {errors.nativeLanguage && touched.nativeLanguage ? (
                            <div className='text-danger'>
                              {errors.nativeLanguage}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='formCode'>
                          <BForm.Label>
                            Language Level :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <Field
                            name='languageLevel'
                            isMulti={true}
                            component={Select}
                            isLoading={getAllLevelDataQuery.isLoading}
                            isDisabled={getAllLevelDataQuery.isLoading}
                            options={getAllLevelDataQuery?.data?.map(
                              (option: any, index: any) => {
                                return {
                                  ...option,
                                  value: option._id,
                                  label: option.Level_Name,
                                };
                              }
                            )}
                            placeholder='Select an option'
                            isClearable={true}
                            classNamePrefix='select'
                            onChange={(option: any) => {
                              setFieldValue('languageLevel', option);
                            }}
                            // onBlur={handleBlur}
                            value={values.languageLevel}
                            styles={{
                              control: (baseStyles: any, state: any) => {
                                return {
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? errors.languageLevel &&
                                      touched.languageLevel
                                      ? '#f1416c'
                                      : 'grey'
                                    : errors.languageLevel &&
                                      touched.languageLevel
                                    ? '#f1416c'
                                    : '#b5b5c3',
                                  boxShadow: '#b5b5c3',
                                  '&:hover': {
                                    borderColor:
                                      errors.languageLevel &&
                                      touched.languageLevel
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                  '&:focus': {
                                    borderColor:
                                      errors.languageLevel &&
                                      touched.languageLevel
                                        ? '#f1416c'
                                        : 'none',
                                  },
                                };
                              },
                            }}
                          />
                          {errors.languageLevel && touched.languageLevel ? (
                            <div className='text-danger'>
                              {errors.languageLevel}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md='6'>
                        <BForm.Group className='mb-3' controlId='videoUrl'>
                          <BForm.Label>
                            Video Upload :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            accept='video/mp4,video/x-m4v,video/*'
                            type='file'
                            name='videoUrl'
                            placeholder='Upload video'
                            onChange={(event) => {
                              const target = event.target as HTMLInputElement;
                              const file: File = (target.files as FileList)[0];

                              if (file) {
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(file);
                                fileReader.onloadstart = () => {
                                  setVideoPreview(null);
                                };

                                fileReader.onloadend = () => {
                                  setVideoPreview(fileReader.result);
                                  {
                                  }
                                };
                                setFieldValue('videoUrl', 'Selected');
                                setVideoFile(file);
                              } else {
                                setVideoPreview(null);
                                setFieldValue('videoUrl', '');
                                setVideoFile('');
                              }
                            }}
                            // onBlur={handleBlur}
                            // value={values.videoUrl}
                            style={{
                              borderColor:
                                errors.videoUrl && touched.videoUrl
                                  ? '#f1416c'
                                  : '',
                            }}
                          />
                          {errors.videoUrl && touched.videoUrl ? (
                            <div className='text-danger'>{errors.videoUrl}</div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group
                          className='mb-3'
                          controlId='previewVideoUrl'
                        >
                          <BForm.Label>
                            Live Class Video Example :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <BForm.Control
                            accept='video/mp4,video/x-m4v,video/*'
                            type='file'
                            name='previewVideoUrl'
                            placeholder='Upload Example video'
                            onChange={(event) => {
                              const target = event.target as HTMLInputElement;
                              const videoFile: File = (
                                target.files as FileList
                              )[0];

                              if (videoFile) {
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(videoFile);
                                fileReader.onloadstart = () => {
                                  setLiveClassVideoPreview(null);
                                };
                                fileReader.onloadend = () => {
                                  setLiveClassVideoPreview(fileReader.result);
                                  {
                                  }
                                };
                                setFieldValue('previewVideoUrl', 'Selected');
                                setLiveClassVideoFile(videoFile);
                              } else {
                                setLiveClassVideoPreview(null);
                                setFieldValue('previewVideoUrl', '');
                                setLiveClassVideoFile('');
                              }
                            }}
                            style={{
                              borderColor:
                                errors.previewVideoUrl &&
                                touched.previewVideoUrl
                                  ? '#f1416c'
                                  : '',
                            }}
                          />
                          {errors.previewVideoUrl && touched.previewVideoUrl ? (
                            <div className='text-danger'>
                              {errors.previewVideoUrl}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      {videoPreview && (
                        <Col md='6' className='justify-content-md-center'>
                          <video
                            controls
                            ref={videoEl}
                            style={{
                              padding: '10px',
                              width: '50%',
                              height: 'auto',
                            }}
                          >
                            <source src={videoPreview} />
                            Your browser does not support the video tag.
                          </video>
                        </Col>
                      )}
                      {previewVideoPreview && (
                        <Col md='6' className='justify-content-md-center'>
                          <video
                            controls
                            ref={previewVideoEl}
                            style={{
                              padding: '10px',
                              width: '50%',
                              height: 'auto',
                            }}
                          >
                            <source src={previewVideoPreview} />
                            Your browser does not support the video tag.
                          </video>
                        </Col>
                      )}
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='description'>
                          <BForm.Label>
                            Lesson Description :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <FastField
                            as='textarea'
                            name='description'
                            placeholder='Enter description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            className={`form-control ${
                              errors.description && touched.description
                                ? 'border border-danger'
                                : ''
                            }`}
                          />
                          {errors.description && touched.description ? (
                            <div className='text-danger'>
                              {errors.description}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='isPremium'>
                          <BForm.Label>
                            Premium : <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <Field
                            name='isPremium'
                            isMulti={false}
                            component={Select}
                            options={premiumOption}
                            placeholder='Select an option'
                            isClearable={true}
                            classNamePrefix='select'
                            onChange={(option: any) => {
                              setFieldValue('isPremium', option);
                            }}
                            onBlur={handleBlur}
                            value={values.isPremium}
                            styles={{
                              control: (baseStyles: any, state: any) => {
                                return {
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? errors.isPremium && touched.isPremium
                                      ? '#F1416C'
                                      : 'grey'
                                    : errors.isPremium && touched.isPremium
                                    ? '#F1416C'
                                    : '#B5B5C3',
                                  boxShadow: '#B5B5C3',
                                  '&:hover': {
                                    borderColor:
                                      errors.isPremium && touched.isPremium
                                        ? '#F1416C'
                                        : 'none',
                                  },
                                  '&:focus': {
                                    borderColor:
                                      errors.isPremium && touched.isPremium
                                        ? '#F1416C'
                                        : 'none',
                                  },
                                };
                              },
                            }}
                          />
                          {errors.isPremium && touched.isPremium ? (
                            <div className='text-danger'>
                              {errors.isPremium}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                      <Col md={6}>
                        <BForm.Group className='mb-3' controlId='description'>
                          <BForm.Label>
                            Learning Outcomes :
                            <span style={{ color: 'red' }}>*</span>
                          </BForm.Label>
                          <FastField
                            name='learningOutcomes'
                            render={({
                              field,
                              form,
                            }: FieldProps<number | string>) => {
                              return (
                                <CKEditor
                                  data={values.learningOutcomes}
                                  editor={ClassicEditor as any}
                                  config={{
                                    toolbar: [
                                      'heading',
                                      '|',
                                      'bold',
                                      'italic',
                                      'blockQuote',
                                      'numberedList',
                                      'bulletedList',
                                      '|',
                                      'undo',
                                      'redo',
                                    ],
                                  }}
                                  onChange={(event, editor: any) => {
                                    const data = editor?.getData();
                                    setFieldValue('learningOutcomes', data);
                                  }}
                                />
                              );
                            }}
                          />
                          {errors.learningOutcomes &&
                          touched.learningOutcomes ? (
                            <div className='text-danger'>
                              {errors.learningOutcomes}
                            </div>
                          ) : null}
                        </BForm.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className='mb-3'>
                        <InstantLiveClassAction
                          actionData={actionData}
                          setActionData={setActionData}
                        />
                      </Col>
                      <Col md={12} className='mb-3'>
                        <InstantLiveClassContentGradeEarnedAdd />
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='primary' type='submit'>
                      {addInstantLiveClassContentQuery?.isLoading ||
                      updateInstantLiveClassContentQuery?.isLoading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='reset'
                      variant='secondary'
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              </Container>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default InstantLiveClassContentAddEditModal;
