import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Collapsible from 'react-collapsible';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { BsChevronDown } from 'react-icons/bs';

const getSrcFromFile = (file: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};

const GrammarReadItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    grammarDetail,
    loading,
    nativeLang,
    targetLang,
  } = props;

  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("en");

  const [openTitle, setOpenTitle] = useState(false);
  const [openDesctiption, setOpenDesctiption] = useState(false);

  const urlHashRef = React.useRef<any>({});

  useEffect(() => {
    if (grammarDetail != '' && grammarDetail != undefined) {
      let Question1_Value = grammarDetail?.Read_IT?.Questions.filter(
        (x: any) => x.Language_Code == nativeLang
      )[0];
      setValue('Grammar_ReadIt_Title', Question1_Value.Title);
      setValue('Grammar_ReadIt_Description', Question1_Value.Description);
    }
  }, [grammarDetail]);

  return (
    <>
      <div>
        <br />
        <Collapsible
          trigger={
            <div className='collapsible-head'>
              <h4>
                <i className='fa fa-solid fa-caret-right me-2 fs-2'></i>
                Read IT 1
                <BsChevronDown
                  style={{ position: 'absolute', right: '20px' }}
                  size={20}
                />
              </h4>
            </div>
          }
          open={true}
        >
          <div className='row'>
            <div className='col-md-6'>
              <div className='add-lesson-row-section'>
                <div className='col-md-12 mt-3 form-group'>
                  <label className='fw-bold form-label'>
                    Title <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTitle(false)}
                    open={openTitle}
                    disableHoverListener
                    disableTouchListener
                  >
                    <input
                      type='text'
                      className={
                        errors.WriteItTitle
                          ? 'form-control errors'
                          : 'form-control'
                      }
                      placeholder='Enter Title'
                      {...register('Grammar_ReadIt_Title')}
                      onFocus={() => setOpenTitle(true)}
                      disabled={loading}
                    ></input>
                  </Tooltip>
                  {errors.Grammar_ReadIt_Title && (
                    <p style={{ color: 'red' }}>
                      {errors.Grammar_ReadIt_Title.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='add-lesson-row-section'>
                <div className='mt-3 col-md-12 form-group '>
                  <label className='fw-bold form-label'>
                    Description <span className='astrick'>*</span>
                  </label>
                  <Tooltip
                    title='Native Language'
                    placement='top'
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenDesctiption(false)}
                    open={openDesctiption}
                    disableHoverListener
                    disableTouchListener
                  >
                    <textarea
                      onFocus={() => setOpenDesctiption(true)}
                      typeof='text'
                      className='form-control'
                      name='Question'
                      placeholder='Enter Description'
                      {...register('Grammar_ReadIt_Description')}
                      disabled={loading}
                    />
                  </Tooltip>
                  {errors.Grammar_ReadIt_Description && (
                    <p style={{ color: 'red' }}>
                      {errors.Grammar_ReadIt_Description.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
        </Collapsible>
      </div>
    </>
  );
};

export default GrammarReadItActivity;
