export const BASE_LAYOUT_CONFIG_KEY = '';
export const THEME_NAME = 'theme';

export const FORMAT_ISSUES_TYPE = {
  TEXT: 'Text',
  AUDIO: 'Audio',
  IMAGE: 'Image',
  VIDEO: 'Video',
  WRONG_ANSWER_PROVIDED_IN_QUESTION: 'Their answer was right',
  OTHER: 'Other',
} as any;
