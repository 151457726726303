export const LIVE_CLASS_SCHEDULE_GET_ALL = 'LIVE_CLASS_SCHEDULE_GET_ALL';
export const LIVE_CLASS_SCHEDULE_GET_ALL_SUCCESS = 'LIVE_CLASS_SCHEDULE_GET_ALL_SUCCESS';
export const LIVE_CLASS_SCHEDULE_GET_ALL_FAIL = 'LIVE_CLASS_SCHEDULE_GET_ALL_FAIL';

export const LIVE_CLASS_SCHEDULE_GET_BY_ID = 'LIVE_CLASS_SCHEDULE_GET_BY_ID';
export const LIVE_CLASS_SCHEDULE_GET_BY_ID_SUCCESS = 'LIVE_CLASS_SCHEDULE_GET_BY_ID_SUCCESS';
export const LIVE_CLASS_SCHEDULE_GET_BY_ID_FAIL = 'LIVE_CLASS_SCHEDULE_GET_BY_ID_FAIL';

export const LIVE_CLASS_SCHEDULE_ADD = 'LIVE_CLASS_SCHEDULE_ADD';
export const LIVE_CLASS_SCHEDULE_ADD_SUCCESS = 'LIVE_CLASS_SCHEDULE_ADD_SUCCESS';
export const LIVE_CLASS_SCHEDULE_ADD_FAIL = 'LIVE_CLASS_SCHEDULE_ADD_FAIL';

export const LIVE_CLASS_SCHEDULE_UPDATE = 'LIVE_CLASS_SCHEDULE_UPDATE';
export const LIVE_CLASS_SCHEDULE_UPDATE_SUCCESS = 'LIVE_CLASS_SCHEDULE_UPDATE_SUCCESS';
export const LIVE_CLASS_SCHEDULE_UPDATE_FAIL = 'LIVE_CLASS_SCHEDULE_UPDATE_FAIL';

export const LIVE_CLASS_SCHEDULE_DELETE = 'LIVE_CLASS_SCHEDULE_DELETE';
export const LIVE_CLASS_SCHEDULE_DELETE_SUCCESS = 'LIVE_CLASS_SCHEDULE_DELETE_SUCCESS';
export const LIVE_CLASS_SCHEDULE_DELETE_FAIL = 'LIVE_CLASS_SCHEDULE_DELETE_FAIL';
export const LIVE_CLASS_SCHEDULE_CLEAR = 'LIVE_CLASS_SCHEDULE_CLEAR';
