import {
  STUDENT_DELETE,
  STUDENT_DELETE_FAILURE,
  STUDENT_DELETE_SUCCESS,
  STUDENT_GET,
  STUDENT_GET_BY_ID,
  STUDENT_GET_BY_ID_FAILURE,
  STUDENT_GET_BY_ID_SUCCESS,
  STUDENT_GET_FAILURE,
  STUDENT_GET_SUCCESS,
  STUDENT_UPDATE,
  STUDENT_UPDATE_FAILURE,
  STUDENT_UPDATE_SUCCESS,
  SUCCESS_CLEAR,
} from './actionTypes';
import { AuthState } from './types';

const initialState: AuthState = {
  loading: false,
  token: '',
  error: '',
  success: '',
  studentlist: '',
  studentdetails: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STUDENT_GET:
      return {
        ...state,
        loading: true,
        success: '',
        error: '',
      };
    case STUDENT_GET_SUCCESS:
      return {
        ...state,
        studentlist: action.payload.success,
        loading: false,
        success: '',
      };
    case STUDENT_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case STUDENT_GET_BY_ID:
      return {
        ...state,
      };
    case STUDENT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        studentdetails: action.payload,
      };
    case STUDENT_GET_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case STUDENT_UPDATE:
      return {
        ...state,
        loading: true,
        success: '',
        studentdetails: '',
      };
    case STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        loading: false,
      };
    case STUDENT_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case STUDENT_DELETE:
      return {
        ...state,
      };
    case STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
      };
    case STUDENT_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case SUCCESS_CLEAR:
      return {
        ...state,
        success: '',
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
