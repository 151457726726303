import React from 'react';
import { TableState } from 'react-table';

import { PAGE_SIZE_OPTIONS } from './Table.utils';

import { ExtraTableProps } from './index';

type useTableStateReturnType<T> = {
  limit?: number;
  page?: number;
  sort?: { sort: 'desc' | 'asc'; fields: string } | undefined;
  search?: string;
};

type PartialTableState<T extends object> = Partial<TableState<T>>;

function transform<T extends object = object>(
  rawState: PartialTableState<T>
): useTableStateReturnType<T> {
  return {
    page: (rawState.pageIndex || 0) + 1,
    limit: rawState.pageSize || PAGE_SIZE_OPTIONS[1],
    sort: rawState?.sortBy?.map((item) => ({
      sort: item.desc ? ('desc' as const) : ('asc' as const),
      fields: item.id,
    }))?.[0],
    search: rawState?.globalFilter || undefined,
  };
}

export default function useTableState<T extends object = object>(
  initialState: PartialTableState<T>
): [
  PartialTableState<T>,
  useTableStateReturnType<T>,
  NonNullable<ExtraTableProps<T>['onTableStateChange']>
] {
  const initialStateRef = React.useRef(initialState);

  const [state, setState] = React.useState(transform(initialStateRef.current));

  const handleStateChange = React.useCallback<
    NonNullable<ExtraTableProps<T>['onTableStateChange']>
  >((state) => {
    setState(transform(state));
  }, []);

  return [initialStateRef.current, state, handleStateChange];
}
