import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { imageUpload } from '../../../store/actions';
import { connect, ConnectedProps } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getHighlightedSection, scrollHandle } from './utils';

const VocabularyTapItActivity: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    imageURL,
    imageUploadReq,
    loading,
    vocabularyDetail,
    getValues,
    imageloading,
    nativeLang,
    targetLang,
  } = props;

  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("en");

  const [decription1, setDecription1] = useState(true);
  const [decription2, setDecription2] = useState(true);
  const [decription3, setDecription3] = useState(true);
  const [openTitle, setOpenTitle] = useState(false);
  const [openDesctiption, setOpenDesctiption] = useState(false);
  const [openOption1, setOpenOption1] = useState(false);
  const [openOption2, setOpenOption2] = useState(false);
  const [openOption3, setOpenOption3] = useState(false);
  const [openOption4, setOpenOption4] = useState(false);

  const [openTitle1, setOpenTitle1] = useState(false);
  const [openDesctiption1, setOpenDesctiption1] = useState(false);
  const [openOption11, setOpenOption11] = useState(false);
  const [openOption21, setOpenOption21] = useState(false);
  const [openOption31, setOpenOption31] = useState(false);
  const [openOption41, setOpenOption41] = useState(false);

  const [openTitle2, setOpenTitle2] = useState(false);
  const [openDesctiption2, setOpenDesctiption2] = useState(false);
  const [openOption12, setOpenOption12] = useState(false);
  const [openOption22, setOpenOption22] = useState(false);
  const [openOption32, setOpenOption32] = useState(false);
  const [openOption42, setOpenOption42] = useState(false);
  const typeOptions = ['None', 'Noun', 'Verb'];
  const [sectionValue1, setSectionValue1] = useState('None');
  const [sectionValue2, setSectionValue2] = useState('None');
  const [sectionValue3, setSectionValue3] = useState('None');

  const [checkBoxoption1, setCheckBoxoption1] = useState();
  const [checkBoxoption2, setCheckBoxoption2] = useState();
  const [checkBoxoption3, setCheckBoxoption3] = useState();

  const defaultImageUrl = 'https://via.placeholder.com/150?text=NA';

  const [activeKey, setActiveKey] = useState('');
  const [vocabularyTapITImage1, setVocabularyTapITImage1] = useState('');
  const [vocabularyTapITImage2, setVocabularyTapITImage2] = useState('');
  const [vocabularyTapITImage3, setVocabularyTapITImage3] = useState('');

  const [vocabularyTapITImageLoader1, setVocabularyTapITImageLoader1] =
    useState(false);
  const [vocabularyTapITImageLoader2, setVocabularyTapITImageLoader2] =
    useState(false);
  const [vocabularyTapITImageLoader3, setVocabularyTapITImageLoader3] =
    useState(false);

  const [vocabularyTapITImageValidation1, setVocabularyTapITImageValidation1] =
    useState(false);
  const [vocabularyTapITImageValidation2, setVocabularyTapITImageValidation2] =
    useState(false);
  const [vocabularyTapITImageValidation3, setVocabularyTapITImageValidation3] =
    useState(false);

  const urlHashRef = React.useRef<any>({});

  useEffect(() => {
    if (urlHashRef.current.one) {
      const data = getHighlightedSection(urlHashRef.current.one);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
    if (urlHashRef.current.two) {
      const data = getHighlightedSection(urlHashRef.current.two);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
    if (urlHashRef.current.three) {
      const data = getHighlightedSection(urlHashRef.current.three);
      if (data.className != '') {
        scrollHandle(data.id);
      }
    }
  }, [
    urlHashRef.current.two,
    urlHashRef.current.three,
    urlHashRef.current.one,
  ]);

  useEffect(() => {
    if (vocabularyDetail != '' && vocabularyDetail != undefined) {
      let Question1_Value =
        vocabularyDetail?.Tap_IT?.Questions[0]?.Question?.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question1_ID = vocabularyDetail?.Tap_IT?.Questions[0]?.Question_ID;
      urlHashRef.current.one = Question1_ID;
      let Question1_Answer_Value =
        vocabularyDetail.Tap_IT.Questions[0].Answer.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];

      let Question2_Value =
        vocabularyDetail?.Tap_IT?.Questions[1]?.Question?.Language.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question2_Answer_Value =
        vocabularyDetail?.Tap_IT.Questions[1]?.Answer?.Language.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question2_ID = vocabularyDetail?.Tap_IT?.Questions[1]?.Question_ID;
      urlHashRef.current.two = Question2_ID;

      let Question3_Value =
        vocabularyDetail?.Tap_IT?.Questions[2].Question?.Language?.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
      let Question3_Answer_Value =
        vocabularyDetail?.Tap_IT?.Questions[2]?.Answer?.Language?.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
      let Question3_ID = vocabularyDetail?.Tap_IT?.Questions[2]?.Question_ID;
      urlHashRef.current.three = Question3_ID;

      // vocabulary Tap_IT Question-1
      setValue('Vocabulary_TapIt_Question1_QuestionID', Question1_ID);
      setValue('Vocabulary_TapIt_Question1_Title', Question1_Value?.Title);
      setValue('Vocabulary_TapIt_Question1_Type', Question1_Value?.Type);
      setSectionValue1(Question1_Value?.Type);
      handleSelectType1('', Question1_Value?.Type);
      setValue(
        'Vocabulary_TapIt_Question1_Description',
        Question1_Value.Description
      );
      setVocabularyTapITImage1(Question1_Value?.Image);
      // setValue("Vocabulary_TapIt_Question1_Image", Question1_Value.Image)
      setCheckBoxoption1(Question1_Value?.Answer);
      setValue('Vocabulary_TapIt_Question1_Answer', Question1_Value?.Answer);
      setValue(
        'Vocabulary_TapIt_Question1_Option1',
        Question1_Answer_Value?.Option1
      );
      setValue(
        'Vocabulary_TapIt_Question1_Option2',
        Question1_Answer_Value?.Option2
      );
      setValue(
        'Vocabulary_TapIt_Question1_Option3',
        Question1_Answer_Value?.Option3
      );
      setValue(
        'Vocabulary_TapIt_Question1_Option4',
        Question1_Answer_Value?.Option4
      );

      setValue(
        'Vocabulary_TapIt_Question1_Roman_Title',
        Question1_Value?.Title_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question1_Roman_Type',
        Question1_Value?.Type_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question1_Roman_Description',
        Question1_Value?.Description_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question1_Roman_Option1',
        Question1_Answer_Value?.Option1_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question1_Roman_Option2',
        Question1_Answer_Value?.Option2_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question1_Roman_Option3',
        Question1_Answer_Value?.Option3_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question1_Roman_Option4',
        Question1_Answer_Value?.Option4_Roman_Language
      );

      setValue(
        'Vocabulary_TapIt_Question1_Audio_Title',
        Question1_Value?.Audio_Title
      );
      setValue(
        'Vocabulary_TapIt_Question1_Audio_Option1',
        Question1_Answer_Value?.Audio_Option1
      );
      setValue(
        'Vocabulary_TapIt_Question1_Audio_Option2',
        Question1_Answer_Value?.Audio_Option2
      );
      setValue(
        'Vocabulary_TapIt_Question1_Audio_Option3',
        Question1_Answer_Value?.Audio_Option3
      );
      setValue(
        'Vocabulary_TapIt_Question1_Audio_Option4',
        Question1_Answer_Value?.Audio_Option4
      );

      // vocabulary Tap_IT Question-2
      setValue('Vocabulary_TapIt_Question2_QuestionID', Question2_ID);
      setValue('Vocabulary_TapIt_Question2_Title', Question2_Value?.Title);
      setValue('Vocabulary_TapIt_Question2_Type', Question2_Value?.Type);
      setSectionValue2(Question2_Value.Type);
      handleSelectType2('', Question2_Value.Type);
      setValue(
        'Vocabulary_TapIt_Question2_Description',
        Question2_Value.Description
      );
      setVocabularyTapITImage2(Question2_Value?.Image);
      // setValue("Vocabulary_TapIt_Question2_Image", Question2_Value.Image)
      setCheckBoxoption2(Question2_Value?.Answer);
      setValue('Vocabulary_TapIt_Question2_Answer', Question2_Value?.Answer);
      setValue(
        'Vocabulary_TapIt_Question2_Option1',
        Question2_Answer_Value?.Option1
      );
      setValue(
        'Vocabulary_TapIt_Question2_Option2',
        Question2_Answer_Value?.Option2
      );
      setValue(
        'Vocabulary_TapIt_Question2_Option3',
        Question2_Answer_Value?.Option3
      );
      setValue(
        'Vocabulary_TapIt_Question2_Option4',
        Question2_Answer_Value?.Option4
      );

      setValue(
        'Vocabulary_TapIt_Question2_Roman_Title',
        Question2_Value?.Title_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question2_Roman_Type',
        Question2_Value?.Type_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question2_Roman_Description',
        Question2_Value?.Description_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question2_Roman_Option1',
        Question2_Answer_Value?.Option1_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question2_Roman_Option2',
        Question2_Answer_Value?.Option2_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question2_Roman_Option3',
        Question2_Answer_Value?.Option3_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question2_Roman_Option4',
        Question2_Answer_Value?.Option4_Roman_Language
      );

      setValue(
        'Vocabulary_TapIt_Question2_Audio_Title',
        Question2_Value?.Audio_Title
      );
      setValue(
        'Vocabulary_TapIt_Question2_Audio_Option1',
        Question2_Answer_Value?.Audio_Option1
      );
      setValue(
        'Vocabulary_TapIt_Question2_Audio_Option2',
        Question2_Answer_Value?.Audio_Option2
      );
      setValue(
        'Vocabulary_TapIt_Question2_Audio_Option3',
        Question2_Answer_Value?.Audio_Option3
      );
      setValue(
        'Vocabulary_TapIt_Question2_Audio_Option4',
        Question2_Answer_Value?.Audio_Option4
      );

      // vocabulary Tap_IT Question-3
      setValue('Vocabulary_TapIt_Question3_QuestionID', Question3_ID);
      setValue('Vocabulary_TapIt_Question3_Title', Question3_Value?.Title);
      setValue('Vocabulary_TapIt_Question3_Type', Question3_Value?.Type);
      setSectionValue3(Question3_Value?.Type);
      handleSelectType3('', Question3_Value?.Type);
      setValue(
        'Vocabulary_TapIt_Question3_Description',
        Question3_Value?.Description
      );
      setVocabularyTapITImage3(Question3_Value?.Image);
      // setValue("Vocabulary_TapIt_Question3_Image", Question3_Value.Image)
      setCheckBoxoption3(Question3_Value?.Answer);
      setValue('Vocabulary_TapIt_Question3_Answer', Question3_Value?.Answer);
      setValue(
        'Vocabulary_TapIt_Question3_Option1',
        Question3_Answer_Value?.Option1
      );
      setValue(
        'Vocabulary_TapIt_Question3_Option2',
        Question3_Answer_Value?.Option2
      );
      setValue(
        'Vocabulary_TapIt_Question3_Option3',
        Question3_Answer_Value?.Option3
      );
      setValue(
        'Vocabulary_TapIt_Question3_Option4',
        Question3_Answer_Value?.Option4
      );

      setValue(
        'Vocabulary_TapIt_Question3_Roman_Title',
        Question3_Value.Title_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question3_Roman_Type',
        Question3_Value?.Type_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question3_Roman_Description',
        Question3_Value?.Description_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question3_Roman_Option1',
        Question3_Answer_Value?.Option1_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question3_Roman_Option2',
        Question3_Answer_Value?.Option2_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question3_Roman_Option3',
        Question3_Answer_Value?.Option3_Roman_Language
      );
      setValue(
        'Vocabulary_TapIt_Question3_Roman_Option4',
        Question3_Answer_Value?.Option4_Roman_Language
      );

      setValue(
        'Vocabulary_TapIt_Question3_Audio_Title',
        Question3_Value?.Audio_Title
      );
      setValue(
        'Vocabulary_TapIt_Question3_Audio_Option1',
        Question3_Answer_Value?.Audio_Option1
      );
      setValue(
        'Vocabulary_TapIt_Question3_Audio_Option2',
        Question3_Answer_Value?.Audio_Option2
      );
      setValue(
        'Vocabulary_TapIt_Question3_Audio_Option3',
        Question3_Answer_Value?.Audio_Option3
      );
      setValue(
        'Vocabulary_TapIt_Question3_Audio_Option4',
        Question3_Answer_Value?.Audio_Option4
      );
    }
  }, [vocabularyDetail]);

  const handleSelectType1 = (event: any, data: any) => {
    var selectTypeValue1;
    if (event != '') {
      setSectionValue1(event.target.value);
      selectTypeValue1 = event.target.value;
    }
    if (data != '') {
      setSectionValue1(data);
      selectTypeValue1 = data;
    }
    if (selectTypeValue1 == 'None') {
      setDecription1(true);
    }
    if (selectTypeValue1 == 'Noun') {
      setDecription1(false);
    }
    if (selectTypeValue1 == 'Verb') {
      setDecription1(false);
    }
  };

  const handleSelectType2 = (event: any, data: any) => {
    var selectTypeValue2;
    if (event != '') {
      setSectionValue2(event.target.value);
      selectTypeValue2 = event.target.value;
    }
    if (data != '') {
      setSectionValue2(data);
      selectTypeValue2 = data;
    }

    if (selectTypeValue2 == 'None') {
      setDecription2(true);
    }
    if (selectTypeValue2 == 'Noun') {
      setDecription2(false);
    }
    if (selectTypeValue2 == 'Verb') {
      setDecription2(false);
    }
  };

  const handleSelectType3 = (event: any, data: any) => {
    var selectTypeValue3;
    if (event != '') {
      setSectionValue3(event.target.value);
      selectTypeValue3 = event.target.value;
    }
    if (data != '') {
      setSectionValue3(data);
      selectTypeValue3 = data;
    }
    if (selectTypeValue3 == 'None') {
      setDecription3(true);
    }
    if (selectTypeValue3 == 'Noun') {
      setDecription3(false);
    }
    if (selectTypeValue3 == 'Verb') {
      setDecription3(false);
    }
  };

  const handleChangeCheckbox1 = (event: any) => {
    setCheckBoxoption1(event.target.value);
    setValue('Vocabulary_TapIt_Question1_Answer', event.target.value);
  };

  const handleChangeCheckbox2 = (event: any) => {
    setCheckBoxoption2(event.target.value);
    setValue('Vocabulary_TapIt_Question2_Answer', event.target.value);
  };

  const handleChangeCheckbox3 = (event: any) => {
    setCheckBoxoption3(event.target.value);
    setValue('Vocabulary_TapIt_Question3_Answer', event.target.value);
  };

  useEffect(() => {
    if (imageURL != '') {
      if (activeKey === 'Vocabulary_TapIt_Question1_Image') {
        setVocabularyTapITImage1(imageURL);
        setValue('Vocabulary_TapIt_Question1_Image', imageURL);
        setActiveKey('');
      }
      if (activeKey === 'Vocabulary_TapIt_Question2_Image') {
        setVocabularyTapITImage2(imageURL);
        setValue('Vocabulary_TapIt_Question2_Image', imageURL);
        setActiveKey('');
      }
      if (activeKey === 'Vocabulary_TapIt_Question3_Image') {
        setVocabularyTapITImage3(imageURL);
        setValue('Vocabulary_TapIt_Question3_Image', imageURL);
        setActiveKey('');
      }
    }
  }, [imageURL]);

  useEffect(() => {
    if (imageloading) {
      if (activeKey === 'Vocabulary_TapIt_Question1_Image') {
        setVocabularyTapITImageLoader1(true);
      }
      if (activeKey === 'Vocabulary_TapIt_Question2_Image') {
        setVocabularyTapITImageLoader2(true);
      }
      if (activeKey === 'Vocabulary_TapIt_Question3_Image') {
        setVocabularyTapITImageLoader3(true);
      }
    }

    if (!imageloading) {
      setVocabularyTapITImageLoader1(false);
      setVocabularyTapITImageLoader2(false);
      setVocabularyTapITImageLoader3(false);
    }
  }, [imageloading]);

  useEffect(() => {
    setVocabularyTapITImage1(vocabularyTapITImage1);
    setValue('Vocabulary_TapIt_Question1_Image', vocabularyTapITImage1);
    setVocabularyTapITImageValidation1(false);
  }, [vocabularyTapITImage1]);

  useEffect(() => {
    setVocabularyTapITImage2(vocabularyTapITImage2);
    setValue('Vocabulary_TapIt_Question2_Image', vocabularyTapITImage2);
    setVocabularyTapITImageValidation2(false);
  }, [vocabularyTapITImage2]);

  useEffect(() => {
    setVocabularyTapITImage3(vocabularyTapITImage3);
    setValue('Vocabulary_TapIt_Question3_Image', vocabularyTapITImage3);
    setVocabularyTapITImageValidation3(false);
  }, [vocabularyTapITImage3]);

  useEffect(() => {
    setActiveKey(activeKey);
  }, [activeKey]);

  const handleChnageImage = (event: any) => {
    if (event.target.files[0].size <= 2097152) {
      setActiveKey(event.target.name);
      imageUploadReq({ file: event.target.files[0] });
    } else {
      if (event.target.name == 'Vocabulary_TapIt_Question1_Image') {
        setVocabularyTapITImageValidation1(true);
      }
      if (event.target.name == 'Vocabulary_TapIt_Question2_Image') {
        setVocabularyTapITImageValidation2(true);
      }
      if (event.target.name == 'Vocabulary_TapIt_Question3_Image') {
        setVocabularyTapITImageValidation3(true);
      }
    }
  };
  const hash = urlHashRef.current;
  return (
    <>
      <div className='collapsible_tab'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <Collapsible
              trigger={
                <div className='collapsible-head'>
                  <h4>
                    <i className='fa fa-solid fa-caret-right me-2 fs-2'></i> Tap
                    It 1
                    <BsChevronDown
                      style={{ position: 'absolute', right: '20px' }}
                      size={20}
                    />
                  </h4>
                </div>
              }
              containerElementProps={getHighlightedSection(hash.one)}
              open={true}
            >
              <div className='row'>
                <div className='col-md-6'>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Title <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Native Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenTitle(false)}
                        open={openTitle}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          type='text'
                          className='form-control'
                          {...register('Vocabulary_TapIt_Question1_Title')}
                          placeholder='Enter Title'
                          onFocus={() => setOpenTitle(true)}
                          disabled={loading}
                        />
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question1_Title && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question1_Title.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12'>
                      <label className='fw-bold form-label'>
                        Type <span className='astrick'>*</span>
                      </label>
                      <select
                        className='level-className form-control'
                        value={sectionValue1}
                        name='Vocabulary_TapIt_Type'
                        {...register('Vocabulary_TapIt_Question1_Type', {
                          required: true,
                        })}
                        onChange={(e) => {
                          handleSelectType1(e, '');
                        }}
                        defaultValue={typeOptions[0]}
                        disabled={loading}
                      >
                        {typeOptions.map((result) => {
                          return <option value={result}>{result}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  {!decription1 && (
                    <div className='add-lesson-row-section'>
                      <div className='mt-3 col-md-12 form-group '>
                        <label className='fw-bold form-label'>
                          Description <span className='astrick'>*</span>
                        </label>
                        <Tooltip
                          title='Native Language'
                          placement='top'
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => setOpenDesctiption(false)}
                          open={openDesctiption}
                          disableHoverListener
                          disableTouchListener
                        >
                          <textarea
                            onFocus={() => setOpenDesctiption(true)}
                            typeof='text'
                            className='form-control'
                            placeholder='Enter Description'
                            {...register(
                              'Vocabulary_TapIt_Question1_Description'
                            )}
                            disabled={loading}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6'>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Upload Image <span className='astrick'>*</span>
                      </label>
                      <div className='question-image'>
                        <LoadingOverlay
                          active={vocabularyTapITImageLoader1}
                          spinner
                          className='spinner-animation'
                        >
                          <img
                            src={vocabularyTapITImage1}
                            //
                          />
                        </LoadingOverlay>
                      </div>
                      <div className='main-cercle-div'>
                        <input
                          type='file'
                          className='form-control'
                          name='Vocabulary_TapIt_Question1_Image'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e) => handleChnageImage(e)}
                          disabled={loading}
                        />
                        {vocabularyTapITImageValidation1 ? (
                          <p style={{ color: 'red' }}>
                            Less then 2MB | Only Image File Allowed - .jpg.png
                          </p>
                        ) : vocabularyTapITImage1 == '' ? (
                          errors.Vocabulary_TapIt_Question1_Image && (
                            <p style={{ color: 'red' }}>
                              {errors.Vocabulary_TapIt_Question1_Image.message}
                            </p>
                          )
                        ) : null}
                        {/* {errors.Vocabulary_TapIt_Question1_Image && <p style={{ color: "red" }}>{errors.Vocabulary_TapIt_Question1_Image.message}</p>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='add-description-rows'>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option1'
                          name='check'
                          checked={checkBoxoption1 === 'option1'}
                          onChange={handleChangeCheckbox1}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 1 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption1(false)}
                        open={openOption1}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption1(true)}
                          type='text'
                          className='form-control'
                          placeholder='Baby'
                          name='Vocabulary_TapIt_Question1_Option1'
                          {...register('Vocabulary_TapIt_Question1_Option1')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question1_Option1 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question1_Option1.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5>{/* Option 1 */}</h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option2'
                          name='check'
                          checked={checkBoxoption1 === 'option2'}
                          onChange={handleChangeCheckbox1}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 2 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption2(false)}
                        open={openOption2}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption2(true)}
                          type='text'
                          className='form-control'
                          placeholder='Balloon'
                          {...register('Vocabulary_TapIt_Question1_Option2')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question1_Option2 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question1_Option2.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='add-description-rows'>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5>{/* Option 1 */}</h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option3'
                          name='check'
                          checked={checkBoxoption1 === 'option3'}
                          onChange={handleChangeCheckbox1}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 3 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption3(false)}
                        open={openOption3}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption3(true)}
                          type='text'
                          className='form-control'
                          {...register('Vocabulary_TapIt_Question1_Option3')}
                          placeholder='Computer'
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question1_Option3 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question1_Option3.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5>{/* Option 1 */}</h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option4'
                          name='check'
                          checked={checkBoxoption1 === 'option4'}
                          onChange={handleChangeCheckbox1}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 4 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption4(false)}
                        open={openOption4}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption4(true)}
                          type='text'
                          className='form-control'
                          placeholder='Float'
                          {...register('Vocabulary_TapIt_Question1_Option4')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question1_Option4 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question1_Option4.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </Collapsible>
          </div>
          <div className='col-md-12 mb-3'>
            <Collapsible
              trigger={
                <div className='collapsible-head'>
                  <h4>
                    <i className='fa fa-solid fa-caret-right me-2 fs-2'></i> Tap
                    It 2
                    <BsChevronDown
                      style={{ position: 'absolute', right: '20px' }}
                      size={20}
                    />
                  </h4>
                </div>
              }
              containerElementProps={getHighlightedSection(hash.two)}
              open={true}
            >
              <div className='row'>
                <div className='col-md-6'>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Title <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Native Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenTitle1(false)}
                        open={openTitle1}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          type='text'
                          className='form-control'
                          {...register('Vocabulary_TapIt_Question2_Title')}
                          placeholder='Enter Title'
                          onFocus={() => setOpenTitle1(true)}
                          disabled={loading}
                        />
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question2_Title && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question2_Title.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12'>
                      <label className='fw-bold form-label'>
                        Type <span className='astrick'>*</span>
                      </label>
                      <select
                        className='level-className form-control'
                        value={sectionValue2}
                        {...register('Vocabulary_TapIt_Question2_Type', {
                          required: true,
                        })}
                        onChange={(e) => {
                          handleSelectType2(e, '');
                        }}
                        defaultValue={typeOptions[0]}
                        disabled={loading}
                      >
                        {typeOptions.map((result) => {
                          return <option value={result}>{result}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  {!decription2 && (
                    <div className='add-lesson-row-section'>
                      <div className='mt-3 col-md-12 form-group '>
                        <label className='fw-bold form-label'>
                          Description <span className='astrick'>*</span>
                        </label>
                        <Tooltip
                          title='Native Language'
                          placement='top'
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => setOpenDesctiption1(false)}
                          open={openDesctiption1}
                          disableHoverListener
                          disableTouchListener
                        >
                          <textarea
                            onFocus={() => setOpenDesctiption1(true)}
                            typeof='text'
                            className='form-control'
                            placeholder='Enter Description'
                            {...register(
                              'Vocabulary_TapIt_Question2_Description'
                            )}
                            disabled={loading}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6'>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Upload Image <span className='astrick'>*</span>
                      </label>
                      <div className='question-image'>
                        <LoadingOverlay
                          active={vocabularyTapITImageLoader2}
                          spinner
                          className='spinner-animation'
                        >
                          <img
                            src={vocabularyTapITImage2}
                            //
                          />
                        </LoadingOverlay>
                      </div>
                      <div className='main-cercle-div'>
                        <input
                          type='file'
                          className='form-control'
                          name='Vocabulary_TapIt_Question2_Image'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e) => handleChnageImage(e)}
                        />
                        {/* {errors.Vocabulary_TapIt_Question2_Image && <p style={{ color: "red" }}>{errors.Vocabulary_TapIt_Question2_Image.message}</p>} */}
                        {vocabularyTapITImageValidation2 ? (
                          <p style={{ color: 'red' }}>
                            Less then 2MB | Only Image File Allowed - .jpg.png
                          </p>
                        ) : vocabularyTapITImage2 == '' ? (
                          errors.Vocabulary_TapIt_Question2_Image && (
                            <p style={{ color: 'red' }}>
                              {errors.Vocabulary_TapIt_Question2_Image.message}
                            </p>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='add-description-rows'>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option1'
                          checked={checkBoxoption2 === 'option1'}
                          onChange={handleChangeCheckbox2}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 1 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption11(false)}
                        open={openOption11}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption11(true)}
                          type='text'
                          className='form-control'
                          placeholder='Baby'
                          name='Vocabulary_TapIt_Question1_Option1'
                          {...register('Vocabulary_TapIt_Question2_Option1')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question2_Option1 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question2_Option1.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option2'
                          checked={checkBoxoption2 === 'option2'}
                          onChange={handleChangeCheckbox2}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 2 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption21(false)}
                        open={openOption21}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption21(true)}
                          type='text'
                          className='form-control'
                          placeholder='Balloon'
                          {...register('Vocabulary_TapIt_Question2_Option2')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question2_Option2 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question2_Option2.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='add-description-rows'>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option3'
                          checked={checkBoxoption2 === 'option3'}
                          onChange={handleChangeCheckbox2}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 3 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption31(false)}
                        open={openOption31}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption31(true)}
                          type='text'
                          className='form-control'
                          {...register('Vocabulary_TapIt_Question2_Option3')}
                          placeholder='Computer'
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question2_Option3 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question2_Option3.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option4'
                          checked={checkBoxoption2 === 'option4'}
                          onChange={handleChangeCheckbox2}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 4 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption41(false)}
                        open={openOption41}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption41(true)}
                          type='text'
                          className='form-control'
                          placeholder='Float'
                          {...register('Vocabulary_TapIt_Question2_Option4')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question2_Option4 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question2_Option4.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </Collapsible>
          </div>
          <div className='col-md-12 mb-3'>
            <Collapsible
              trigger={
                <div className='collapsible-head'>
                  <h4>
                    <i className='fa fa-solid fa-caret-right me-2 fs-2'></i> Tap
                    It 3
                    <BsChevronDown
                      style={{ position: 'absolute', right: '20px' }}
                      size={20}
                    />
                  </h4>
                </div>
              }
              containerElementProps={getHighlightedSection(hash.three)}
              open={true}
            >
              <div className='border-top my-3'></div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Title <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Native Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenTitle2(false)}
                        open={openTitle2}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          type='text'
                          className='form-control'
                          {...register('Vocabulary_TapIt_Question3_Title')}
                          placeholder='Enter Title'
                          onFocus={() => setOpenTitle2(true)}
                          disabled={loading}
                        />
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question3_Title && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question3_Title.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12'>
                      <label className='fw-bold form-label'>
                        Type <span className='astrick'>*</span>
                      </label>
                      <select
                        className='level-className form-control'
                        value={sectionValue3}
                        {...register('Vocabulary_TapIt_Question3_Type', {
                          required: true,
                        })}
                        onChange={(e) => {
                          handleSelectType3(e, '');
                        }}
                        defaultValue={typeOptions[0]}
                        disabled={loading}
                      >
                        {typeOptions.map((result) => {
                          return <option value={result}>{result}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  {!decription3 && (
                    <div className='add-lesson-row-section'>
                      <div className='mt-3 col-md-12 form-group '>
                        <label className='fw-bold form-label'>
                          Description <span className='astrick'>*</span>
                        </label>
                        <Tooltip
                          title='Native Language'
                          placement='top'
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => setOpenDesctiption2(false)}
                          open={openDesctiption2}
                          disableHoverListener
                          disableTouchListener
                        >
                          <textarea
                            onFocus={() => setOpenDesctiption2(true)}
                            typeof='text'
                            className='form-control'
                            placeholder='Enter Description'
                            {...register(
                              'Vocabulary_TapIt_Question3_Description'
                            )}
                            disabled={loading}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6'>
                  <div className='add-lesson-row-section'>
                    <div className='mt-3 col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Upload Image <span className='astrick'>*</span>
                      </label>
                      <div className='question-image'>
                        <LoadingOverlay
                          active={vocabularyTapITImageLoader3}
                          spinner
                          className='spinner-animation'
                        >
                          <img
                            src={vocabularyTapITImage3}
                            //
                          />
                        </LoadingOverlay>
                      </div>
                      <div className='main-cercle-div'>
                        <input
                          type='file'
                          className='form-control'
                          name='Vocabulary_TapIt_Question3_Image'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e) => handleChnageImage(e)}
                        />
                        {/* {errors.Vocabulary_TapIt_Question3_Image && <p style={{ color: "red" }}>{errors.Vocabulary_TapIt_Question3_Image.message}</p>} */}
                        {vocabularyTapITImageValidation3 ? (
                          <p style={{ color: 'red' }}>
                            Less then 2MB | Only Image File Allowed - .jpg.png
                          </p>
                        ) : vocabularyTapITImage3 == '' ? (
                          errors.Vocabulary_TapIt_Question3_Image && (
                            <p style={{ color: 'red' }}>
                              {errors.Vocabulary_TapIt_Question3_Image.message}
                            </p>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='add-description-rows'>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option1'
                          checked={checkBoxoption3 === 'option1'}
                          onChange={handleChangeCheckbox3}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 1 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption12(false)}
                        open={openOption12}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption12(true)}
                          type='text'
                          className='form-control'
                          placeholder='Baby'
                          name='Vocabulary_TapIt_Question1_Option1'
                          {...register('Vocabulary_TapIt_Question3_Option1')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question3_Option1 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question3_Option1.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option2'
                          checked={checkBoxoption3 === 'option2'}
                          onChange={handleChangeCheckbox3}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 2 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption22(false)}
                        open={openOption22}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption22(true)}
                          type='text'
                          className='form-control'
                          placeholder='Balloon'
                          {...register('Vocabulary_TapIt_Question3_Option2')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question3_Option2 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question3_Option2.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='add-description-rows'>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option3'
                          checked={checkBoxoption3 === 'option3'}
                          onChange={handleChangeCheckbox3}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 3 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption32(false)}
                        open={openOption32}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption32(true)}
                          type='text'
                          className='form-control'
                          {...register('Vocabulary_TapIt_Question3_Option3')}
                          placeholder='Computer'
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question3_Option3 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question3_Option3.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='add-description-boxes'>
                  <div className='option-section-wrapp'>
                    <h5></h5>

                    <div className='mark-answer'>
                      <label className='mark-answer-section mark-answer-text'>
                        Correct Answer
                        <input
                          type='checkbox'
                          className='checkmark-box'
                          value='option4'
                          checked={checkBoxoption3 === 'option4'}
                          onChange={handleChangeCheckbox3}
                          disabled={loading}
                        />
                      </label>
                    </div>
                  </div>
                  <div className='add-lesson-row-section'>
                    <div className='col-md-12 form-group'>
                      <label className='fw-bold form-label'>
                        Option 4 <span className='astrick'>*</span>
                      </label>
                      <Tooltip
                        title='Target Language'
                        placement='top'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenOption42(false)}
                        open={openOption42}
                        disableHoverListener
                        disableTouchListener
                      >
                        <input
                          onFocus={() => setOpenOption42(true)}
                          type='text'
                          className='form-control'
                          placeholder='Float'
                          {...register('Vocabulary_TapIt_Question3_Option4')}
                          disabled={loading}
                        ></input>
                      </Tooltip>
                      {errors.Vocabulary_TapIt_Question3_Option4 && (
                        <p style={{ color: 'red' }}>
                          {errors.Vocabulary_TapIt_Question3_Option4.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </Collapsible>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lesson.loading,
    error: state.lesson.error,
    success: state.lesson.success,
    imageURL: state.lesson.imageURL,
    imageloading: state.lesson.imageloading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  imageUploadReq: (param: any) => dispatch(imageUpload(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VocabularyTapItActivity);
