import { AxiosRequestConfig, Method } from 'axios';

import { axiosApi } from '../helpers/api-helper';
import { urlRoutes } from '../shared/apiConfig';
import { startLoading, stopLoading } from '../store/loading/helpers';

import { logError, logLog } from './logs';

export type callBackendApiOptions<T, TFallbackType = any> = {
  fallBackData?: TFallbackType;
  url: typeof urlRoutes[keyof typeof urlRoutes];
  showLoader?: boolean;
};

// any default config should be go here...
const defaultConfig: AxiosRequestConfig = {};

export default async function callBackendApi<T = any, TFallbackType = any>(
  method: Method,
  config: Omit<AxiosRequestConfig<T>, 'method' | 'baseURL' | 'url'> &
    callBackendApiOptions<T, TFallbackType>
) {
  const { showLoader = true, fallBackData = null, ...axiosConfig } = config;
  try {
    if (showLoader) startLoading();
    const response = await axiosApi.request({
      ...defaultConfig,
      ...axiosConfig,
      method,
    });

    logLog(response);

    return response.data;
  } catch (error) {
    logError(error);
  } finally {
    if (showLoader) stopLoading();
  }

  return fallBackData;
}
