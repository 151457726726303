import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';

import { PageTitle } from '../../../theme/layout/core';
import { axiosApi } from '../../../helpers/api-helper';
import Loader from '../Loader';
import Table from '../Table';
import { TableGlobalSearch } from '../Table/TableGlobalSearch';
import TableInner from '../Table/TableInner';
import { TablePagination } from '../Table/TablePagination';
import useTableState from '../Table/useTableState';
import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';

import FeedBackDeleteModal from './FeedBackDeleteModal';
import CommentDisplayModal from './CommentDisplayModal';

const Feedbacks: React.FC = () => {
  //const [feedbackId, setFeedBackId] = React.useState<any>(null);

  const [comment, setComment] = React.useState(null);
  const [deleteModel, setDeleteModel] = useState(false);
  const [feedbackDeleteID, setFeedbackDeleteID] = useState('');

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Student&apos;s Name
          </span>
        ),
        accessor: 'fullName',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span className='ps-4'>{result?.fullName}</span>;
        },
      },
      {
        Header: 'Student ID',
        accessor: 'student_profile_id',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return result?.student_profile_id ? (
            <span># {result?.student_profile_id}</span>
          ) : (
            <span className='text-center d-block'>-</span>
          );
        },
      },
      {
        Header: 'Age',
        accessor: 'age',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span>{result?.age}</span>;
        },
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span>{result?.level}</span>;
        },
      },
      {
        Header: 'Native Lan.',
        accessor: 'nativeLanguage',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span>{result?.nativeLanguage}</span>;
        },
      },
      {
        Header: 'Target Lan.',
        accessor: 'targetLanguage',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span>{result?.targetLanguage}</span>;
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Lesson Name
          </span>
        ),
        accessor: 'lesson',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          const path = ['/lesson', 'edit', result?.lessonId || ''].join('/');
          const hash = result?.urlHash ? `#${result.urlHash}` : '';
          const pathname = path + hash;

          return (
            <div className='text-ellipsis-lesson'>
              <Link to={pathname}>
                <abbr title={result?.lesson}>{result?.lesson}</abbr>
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Ratings',
        accessor: 'stars',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span>{result?.stars}</span>;
        },
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <div className='text-center'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='View'
                onClick={() => {
                  // setEditData(result);
                  setComment(result);
                }}
                data-bs-toggle='modal'
                data-bs-target='#CommentDisplayModal'
              >
                <i className='fa fa-eye'></i>
              </button>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Date
          </span>
        ),
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          const dt = result?.createdAt;
          let datetime;
          if (dt) {
            const Date = moment(dt).utc().format('MM-DD-YYYY HH:MMA');
            datetime = Date;
          }

          return <span className='pe-4 d-block'>{datetime}</span>;
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                onClick={() => {
                  setDeleteId(result.id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const { sort, page, limit, search } = tableState;

  const { isLoading, data, refetch } = useQuery(
    ['fetchFeedbacks', sort, page, limit, search],
    () =>
      axiosApi.get('/feedbacks', {
        params: {
          ...sort,
          page,
          limit,
          search,
        },
      })
  );

  const loading = isLoading;
  const list = data?.data.list ?? [];
  const [deleteId, setDeleteId] = React.useState(null);

  const deleteMutation = useMutation({
    mutationFn: (id) =>
      axiosApi.delete('/feedbacks', {
        data: {
          id,
        },
      }),
    onSuccess: () => {
      refetch();
      if (data?.statusText === 'OK') {
        Swal.fire('Feedback deleted successfully', '', 'success');
      }
    },
    onSettled: () => {
      setDeleteId(null);
    },
  });

  const handleDeleteFeedback = () => {
    if (deleteId) {
      deleteMutation.mutate(deleteId);
    }
  };

  const f = React.useMemo(() => {
    return list.map((item: any) => {
      return {
        _original: item,
        ...item,
        stars: Number.isInteger(item.stars)
          ? Array.from({ length: item.stars }).fill('⭐')
          : '-',
        createdAt: new Date(item.createdAt).toISOString(),
        fullName: [item?.user?.FirstName, item?.user?.LastName].join(' '),
        student_profile_id: [item?.student?.Student_Profile_ID].join(' '),
        age: [item?.student?.Age].join(' '),
        nativeLanguage: [item?.nativeLanguage?.Language_Name].join(' '),
        targetLanguage: [item?.targetLanguage?.Language_Name].join(' '),
        level: [item?.level?.Level_Name].join(' '),
        lessonId: [item?.lesson?._id].join(' '),
        lesson: item?.lesson?.Lesson_Name || '-',
      };
    });
  }, [list]);

  return (
    <React.Fragment>
      <CommentDisplayModal
        visible={comment !== null}
        onCancel={() => setComment(null)}
        title='Comment'
        comment={comment}
      />
      <Loader loading={loading} />
      <PageTitle breadcrumbs={[]}>Feedbacks</PageTitle>
      <div className='card mb-10'>
        <Table
          data={f}
          columns={columns}
          initialState={tableInitialState}
          pageCount={data?.data?.pageCount ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header justify-content-between border-0 pt-5'>
              <h3 className='card-title'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Feedback Data
                </span>
              </h3>
              <span>
                <TableGlobalSearch />
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={loading} entityLabel='feedback' />
                </div>
                <div className='col-12 border-top pt-5'>
                  <TablePagination />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <FeedBackDeleteModal
        visible={deleteId !== null}
        okText='Yes'
        cancelText='No'
        message='Are you sure want to Delete?'
        onCancel={() => setDeleteId(null)}
        onOk={handleDeleteFeedback}
        title='Delete feedback'
      />
    </React.Fragment>
  );
};

export default Feedbacks;
