import React from 'react';
import { TableInstance } from 'react-table';

import { cx } from '../../../utils/classnames';

import {
  getPageNumbers,
  PAGE_SIZE_OPTIONS,
  PAGINATION_DOTS,
} from './Table.utils';

import { useTableInstance } from './index';

export const TablePaginationSelectOption: React.FC<{
  pageOptions?: number[];
}> = ({ pageOptions }) => {
  const tableInstance = useTableInstance();

  const { state, setPageSize } = tableInstance;

  const { pageSize } = state;

  return (
    <select
      value={pageSize}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
      }}
      className={'form-select rt_pagination_select'}
    >
      {(pageOptions || PAGE_SIZE_OPTIONS).map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
  );
};

export const TablePagination: React.FC<{
  pageOptions?: number[];
}> = ({ pageOptions }) => {
  const tableInstance = useTableInstance();

  const {
    state,
    pageCount,
    gotoPage,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
  } = tableInstance;

  const { pageIndex } = state;

  const formattedPages = React.useMemo(
    () =>
      getPageNumbers({
        currentPage: pageIndex + 1,
        lastPageNumber: pageCount,
      }),
    [pageCount, pageIndex]
  );

  return (
    <div className='row align-items-center gx-2'>
      {/* Pagination Select Options */}
      <div className='col-auto'>
        <TablePaginationSelectOption pageOptions={pageOptions} />
      </div>
      {/* Pagination */}
      {formattedPages.length > 0 ? (
        <div className='col-auto'>
          <nav className='pagination'>
            <div
              className={cx('page-item', 'previous', {
                disabled: !canPreviousPage,
              })}
            >
              <button
                aria-label='Previous Page'
                onClick={previousPage}
                className='page-link'
              >
                <i className='previous'></i>
              </button>
            </div>

            {formattedPages.map((page) => (
              <div className='page-item' key={page}>
                {page === PAGINATION_DOTS ? (
                  <span className='page-link'>{page}</span>
                ) : (
                  <button
                    onClick={() => gotoPage((page as number) - 1)}
                    className={cx('page-link', {
                      active: pageIndex === (page as number) - 1,
                    })}
                  >
                    <span>{page}</span>
                  </button>
                )}
              </div>
            ))}

            <div
              className={cx('page-item', 'next', {
                disabled: !canNextPage,
              })}
            >
              <button
                aria-label='Next Page'
                className='page-link'
                onClick={nextPage}
              >
                <i className='next'></i>
              </button>
            </div>
          </nav>
        </div>
      ) : null}
    </div>
  );
};
