import React, { useRef } from 'react';
import Select from 'react-select';
import { Formik, Form, FastField, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form as BForm, Modal, Spinner } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';

let actionList = [
  {
    value: 'Send-Message',
    label: 'Send-Message (send to breakout room)',
    actionName: 'Send-Message',
  },
  {
    value: 'Show-Text',
    label: 'Show-Text (sends message to main room) ',
    actionName: 'Show-Text',
  },
  {
    value: 'Start-Breakout-Room',
    label: 'Start-Breakout-Room',
    actionName: 'Start-Breakout-Room',
  },
  {
    value: 'Closed-Breakout-Room',
    label: 'Closed-Breakout-Room',
    actionName: 'Closed-Breakout-Room',
  },
  // { value: "End-Call", label: "End-Call", actionName: "End-Call" },
  {
    value: 'End-Meeting',
    label: 'End-Meeting',
    actionName: 'End-Meeting',
  },
];

let breakoutRoomList = [
  { value: 'Big', label: 'Big' },
  { value: 'Small', label: 'Small' },
];

const LiveClassActionAdd = (props: any) => {
  const formRef = useRef<any>();
  const { setShow, formData, setFormData, actionData, setActionData } = props;

  const validationSchema = Yup.object({
    time: Yup.string().required('Please enter time.'),
    action: Yup.object().nullable().required('Please select.'),
    message: Yup.object().when('action', (action, schema): any => {
      if (
        action?.actionName == 'Send-Message' ||
        action?.actionName == 'Show-Text'
      ) {
        return Yup.string().required('Please enter message.');
      } else {
        return Yup.string().nullable();
      }
    }),
    breakOutRoomType: Yup.object().when('action', (action, schema): any => {
      if (action?.actionName == 'Start-Breakout-Room') {
        return Yup.object().nullable().required('Please select.');
      }
    }),
  });

  let initialValues = {
    index: formData?.index,
    time: formData?.time,
    action: formData?.action ? formData?.action : '',
    message: formData?.message,
    breakOutRoomType: formData?.breakOutRoomType
      ? formData?.breakOutRoomType
      : '',
  };

  const _handleSubmit = async (values: any, { resetForm }: any) => {
    let newForm: any = {
      action: values?.action,
      time: values?.time,
    };
    if (
      values.action?.actionName == 'Send-Message' ||
      values.action?.actionName == 'Show-Text'
    ) {
      newForm.message = values?.message;
    }

    if (values.action?.actionName == 'Start-Breakout-Room') {
      newForm.breakOutRoomType = values?.breakOutRoomType;
    }

    if (values?.index === undefined || values?.index < 0) {
      let data = [...actionData, newForm]?.sort(function (a: any, b: any) {
        return a?.time?.localeCompare(b?.time);
      });
      setActionData(data);
    } else {
      var updatedData = actionData
        .map((item: any, index: any) => {
          if (index === values.index) {
            return newForm;
          }
          return item;
        })
        ?.sort(function (a: any, b: any) {
          return a?.time?.localeCompare(b?.time);
        });
      setActionData(updatedData);
    }

    resetForm();
    setFormData({});
    setShow(false);
  };

  const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];

  const handleSubmitByRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit(); // Call the submitForm method on the form component
    }
  };

  function validate(evt: any) {
    var theEvent = evt || window.event;
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }: any) => {
        return (
          <Form onSubmit={handleSubmit} className='pb-5'>
            <Row>
              <Col md={6}>
                <BForm.Group className='mb-3' controlId='action'>
                  <BForm.Label>
                    Action : <span style={{ color: 'red' }}>*</span>
                  </BForm.Label>
                  <FastField
                    name='action'
                    isMulti={false}
                    component={Select}
                    options={actionList}
                    placeholder='Select an option'
                    isClearable={true}
                    classNamePrefix='select'
                    onChange={(option: any) => {
                      setFieldValue('action', option);
                    }}
                    onBlur={handleBlur}
                    value={values.action}
                    styles={{
                      control: (baseStyles: any, state: any) => {
                        return {
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? errors.action && touched.action
                              ? '#f1416c'
                              : 'grey'
                            : errors.action && touched.action
                            ? '#f1416c'
                            : '#b5b5c3',
                          boxShadow: '#b5b5c3',
                          '&:hover': {
                            borderColor:
                              errors.action && touched.action
                                ? '#f1416c'
                                : 'none',
                          },
                          '&:focus': {
                            borderColor:
                              errors.action && touched.action
                                ? '#f1416c'
                                : 'none',
                          },
                        };
                      },
                    }}
                  />
                  {errors.action && touched.action ? (
                    <div className='text-danger'>{errors.action}</div>
                  ) : null}
                </BForm.Group>
              </Col>
              <Col md={6}>
                <BForm.Group className='mb-3' controlId='time'>
                  <BForm.Label>
                    Time:<span style={{ color: 'red' }}>*</span>
                  </BForm.Label>
                  <FastField
                    name='time'
                    render={({ field }: any) => (
                      <MaskedInput
                        {...field}
                        onKeyPress={validate}
                        mask={timeMask}
                        value={values.time}
                        placeholder='Enter time'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.time && touched.time
                            ? 'border border-danger'
                            : ''
                        }`}
                      />
                    )}
                  />

                  {errors.time && touched.time ? (
                    <div className='text-danger'>{errors.time}</div>
                  ) : null}
                </BForm.Group>
              </Col>
              {(values?.action?.actionName == 'Send-Message' ||
                values?.action?.actionName == 'Show-Text') && (
                <Col md={6}>
                  <BForm.Group className='mb-3' controlId='message'>
                    <BForm.Label>
                      Message:<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <FastField
                      as='textarea'
                      name='message'
                      placeholder='Enter message'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      className={`form-control ${
                        errors.message && touched.message
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {errors.message && touched.message ? (
                      <div className='text-danger'>{errors.message}</div>
                    ) : null}
                  </BForm.Group>
                </Col>
              )}

              {values?.action?.actionName == 'Start-Breakout-Room' && (
                <Col md={6}>
                  <BForm.Group className='mb-3' controlId='breakOutRoomType'>
                    <BForm.Label>
                      Breakout-Room Size :{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <FastField
                      name='breakOutRoomType'
                      isMulti={false}
                      component={Select}
                      options={breakoutRoomList}
                      placeholder='Select an option'
                      isClearable={true}
                      classNamePrefix='select'
                      onChange={(option: any) => {
                        setFieldValue('breakOutRoomType', option);
                      }}
                      onBlur={handleBlur}
                      value={values.breakOutRoomType}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.breakOutRoomType &&
                                touched.breakOutRoomType
                                ? '#f1416c'
                                : 'grey'
                              : errors.breakOutRoomType &&
                                touched.breakOutRoomType
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.breakOutRoomType &&
                                touched.breakOutRoomType
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.breakOutRoomType &&
                                touched.breakOutRoomType
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {errors.breakOutRoomType && touched.breakOutRoomType ? (
                      <div className='text-danger'>
                        {errors.breakOutRoomType}
                      </div>
                    ) : null}
                  </BForm.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Button
                  variant='success'
                  onClick={() => handleSubmitByRef()}
                  size='sm'
                  className='me-2'
                >
                  Save
                </Button>
                <Button
                  variant='light'
                  onClick={() => setShow(false)}
                  size='sm'
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LiveClassActionAdd;
