/* eslint-disable */
import React, { useEffect, useState } from 'react';
import isString from 'lodash/isString';

import { cx } from '../../../utils/classnames';

import { useTableInstance } from './index';
import { KTSVG } from 'theme/helpers';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Swal from 'sweetalert2';
import EnrollmentDeleteConfirmationModel from 'app/pages/enrollment/EnrollmentDeleteConfirmationModel';
import { AiOutlineIssuesClose } from 'react-icons/ai';

// type TableInnerProps = {
//   loading: boolean;
//   entityLabel: string;
// };

// const TableInner: React.FC<TableInnerProps> = (props: any) => {
const TableInner = (props: any) => {
  const { loading, entityLabel, expandedRows, diplomaRegistrationdata } = props;
  const tableInstance = useTableInstance();

  if (!tableInstance) return null;

  const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps } =
    tableInstance;

  const isListEmpty = rows.length === 0;

  return (
    <div className='table-responsive'>
      <table
        {...getTableProps({
          className:
            'table table-row-gray-300 align-middle gs-0 gy-4 table-row-dashed',
        })}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                className: 'fw-bold text-muted bg-dark',
              })}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    className: cx(
                      column.canSort &&
                        `table-sort-${
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : ''
                        }`
                    ),
                    ...(column.canSort
                      ? column.getSortByToggleProps({
                          title: `Sort By${
                            isString(column.Header)
                              ? column.Header
                              : 'this column'
                          }`,
                        })
                      : {}),
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {expandedRows &&
                diplomaRegistrationdata &&
                expandedRows[row.id] ? (
                  <ExpandingRow
                    diplomaRegistrationdata={diplomaRegistrationdata}
                  />
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
      {isListEmpty && !loading ? (
        <p className={cx('text-center')}>No {entityLabel} found.</p>
      ) : null}
    </div>
  );
};

export default TableInner;

const ExpandingRow = (props: any) => {
  const { diplomaRegistrationdata } = props;
  const navigate = useNavigate();
  const deleteEnrollmentRegistrationQuery: any = usePostQuery(
    'declineEnrollmentRegistration',
    '/enrollmentregister/delete',
    {}
  );

  const deleteEnrollmentGradeSubmissonQuery: any = usePostQuery(
    'declineEnrollmentGradeSubmisson',
    '/enrollment/grade/delete',
    {}
  );

  const deleteInnerEnrollFormQuery: any = usePostQuery(
    'deleteInnerEnrollForm',
    '/enrollment/delete',
    {}
  );

  const deleteInnerReEnrollFormQuery: any = usePostQuery(
    'deleteInnerReEnrollForm',
    '/reenrollment/unenrollment',
    {}
  );

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [resultData, setResultData] = useState<any>('');
  const [deleteModel, setDeleteModel] = useState(false);

  const handleDeleteRecord = () => {
    setDeleteModel(false);
    if (resultData) {
      if (resultData?.FormTypeText === 'Enrollment') {
        deleteInnerReEnrollFormQuery.mutateAsync({
          EnrollmentID: resultData?._id,
          Email: resultData?.UserID?.Email,
        });
      } else if (resultData?.FormTypeText === 'Registration') {
        deleteEnrollmentRegistrationQuery.mutateAsync({
          EnrollmentID: resultData?._id,
          Email: resultData?.Email,
        });
      } else {
        deleteEnrollmentGradeSubmissonQuery.mutateAsync({
          EnrollmentID: resultData?._id,
          Email: resultData?.Email,
        });
      }
    }
  };

  useEffect(() => {
    if (
      deleteEnrollmentRegistrationQuery?.isSuccess ||
      deleteEnrollmentGradeSubmissonQuery?.isSuccess
    ) {
      Toast.fire({
        icon: 'success',
        title: 'Record has been deleted successfully.',
      });
    }
  }, [
    deleteEnrollmentRegistrationQuery?.isSuccess,
    deleteEnrollmentGradeSubmissonQuery?.isSuccess,
  ]);

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  return (
    <>
      {diplomaRegistrationdata.map((diplomaRegistrationdata: any) => {
        return (
          <tr>
            <td></td>
            <td>
              <div className='d-flex'>
                <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                  {diplomaRegistrationdata.FormTypeText == 'Enrollment' ? (
                    <>
                      {' '}
                      {diplomaRegistrationdata?.UserID?.FirstName +
                        ' ' +
                        diplomaRegistrationdata?.UserID?.LastName}
                    </>
                  ) : (
                    <>
                      {diplomaRegistrationdata?.Student_Firstname +
                        ' ' +
                        diplomaRegistrationdata?.Student_Lastname}
                    </>
                  )}
                </a>
              </div>
            </td>
            <td>
              <div className='d-flex'>
                <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                  {diplomaRegistrationdata?.CalendarYearID?.label}
                </a>
              </div>
            </td>
            <td>
              <span className='text-dark fw-bold d-block fs-6'>
                {diplomaRegistrationdata.FormTypeText == 'Registration' &&
                  diplomaRegistrationdata?.FormType == 1 &&
                  'K 12 Registration'}
                {diplomaRegistrationdata.FormTypeText == 'Registration' &&
                  diplomaRegistrationdata?.FormType == 2 &&
                  'K 6 Registration'}
                {diplomaRegistrationdata.FormTypeText == 'GradeSubmissions' &&
                  diplomaRegistrationdata?.FormType == 1 &&
                  'K 12 Grade Submissions'}
                {diplomaRegistrationdata.FormTypeText == 'GradeSubmissions' &&
                  diplomaRegistrationdata?.FormType == 2 &&
                  'K 6 Grade Submissions'}

                {diplomaRegistrationdata.FormTypeText == 'Enrollment' &&
                  diplomaRegistrationdata?.FormType == 1 &&
                  'K 12 Enrollment'}
                {diplomaRegistrationdata.FormTypeText == 'Enrollment' &&
                  diplomaRegistrationdata?.FormType == 2 &&
                  'K 6 Enrollment'}
              </span>
            </td>
            <td>
              <div className='d-flex'>
                <a className='text-dark fw-bold d-block fs-6 text-hover-primary text-center'>
                  {moment(diplomaRegistrationdata?.createdAt).format(
                    'DD-MM-YYYY HH:MM A'
                  )}
                </a>
              </div>
            </td>
            <td>
              <span
                className={
                  diplomaRegistrationdata?.Status == 2
                    ? 'text-center badge badge-success'
                    : diplomaRegistrationdata?.Status == 3
                    ? 'text-center badge badge-danger'
                    : diplomaRegistrationdata?.Status == 4
                    ? 'text-center badge badge-danger'
                    : 'text-center badge badge-secondary'
                }
              >
                {diplomaRegistrationdata?.Status == 2
                  ? 'Accept'
                  : diplomaRegistrationdata?.Status == 3
                  ? 'Issue Fixed'
                  : diplomaRegistrationdata?.Status == 4
                  ? 'Decline'
                  : null}
              </span>
            </td>
            <td>
              <div className='d-flex justify-content-end flex-shrink-0'>
                <button
                  className='btn btn-icon btn-secondary btn-sm me-1'
                  title='fixed issue'
                  onClick={() => {
                    {
                      diplomaRegistrationdata.FormTypeText ==
                        'GradeSubmissions' &&
                        navigate(
                          `/grade-submission-details/${diplomaRegistrationdata?._id}`
                        );
                    }
                    {
                      diplomaRegistrationdata.FormTypeText == 'Registration' &&
                        navigate(
                          `/diploma-registration-details/${diplomaRegistrationdata?._id}`
                        );
                    }
                    {
                      diplomaRegistrationdata.FormTypeText == 'Enrollment' &&
                        navigate(
                          `/enrollment-details/${diplomaRegistrationdata?._id}`
                        );
                    }
                  }}
                >
                  <AiOutlineIssuesClose />
                </button>
                <button
                  className='btn btn-icon btn-secondary btn-sm me-1'
                  title='preview'
                  onClick={() => {
                    {
                      diplomaRegistrationdata.FormTypeText ==
                        'GradeSubmissions' &&
                        navigate(
                          `/grade-submission-details/view/${diplomaRegistrationdata?._id}`
                        );
                    }
                    {
                      diplomaRegistrationdata.FormTypeText == 'Registration' &&
                        navigate(
                          `/diploma-registration-details/view/${diplomaRegistrationdata?._id}`
                        );
                    }
                    {
                      diplomaRegistrationdata.FormTypeText == 'Enrollment' &&
                        navigate(
                          `/enrollment-details/view/${diplomaRegistrationdata?._id}`
                        );
                    }
                  }}
                >
                  <BsEyeFill />
                </button>
                <button
                  className='btn btn-icon btn-danger btn-sm'
                  title='delete'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  onClick={() => {
                    setDeleteModel(true);
                    setResultData(diplomaRegistrationdata);
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='svg-icon-3'
                  />
                </button>
              </div>
            </td>
          </tr>
        );
      })}
      <EnrollmentDeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
    </>
  );
};
/* eslint-enable */
