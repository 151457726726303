import Loader from 'app/components/Loader';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';
import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnrollmentDeleteConfirmationModel from './EnrollmentDeleteConfirmationModel';
import Swal from 'sweetalert2';

const EnrollmentDetailsFooter = (props: any) => {
  const { enrollmentID, priviewData, submitFormType } = props;
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [languageCode, setLanguageCode] = useState('es');

  const changestatusQuery: any = usePostQuery(
    'changestatusEnrollments',
    '/enrollment/changestatus',
    {}
  );
  const changestatusRegistrationQuery: any = usePostQuery(
    'changestatusRegistration',
    '/enrollmentregister/changestatus',
    {}
  );
  const changestatusGradeSubmissonQuery: any = usePostQuery(
    'changestatusGradeSubmisson',
    '/enrollment/grade/changestatus',
    {}
  );

  const declineEnrollFormQuery: any = usePostQuery(
    'declineEnrollForm',
    '/enrollment/delete',
    {}
  );

  const declineReEnrollFormQuery: any = usePostQuery(
    'declineReEnrollForm',
    '/reenrollment/unenrollment',
    {}
  );

  const declineEnrollmentRegistrationQuery: any = usePostQuery(
    'declineEnrollmentRegistration',
    '/enrollmentregister/delete',
    {}
  );
  const declineEnrollmentGradeSubmissonQuery: any = usePostQuery(
    'declineEnrollmentGradeSubmisson',
    '/enrollment/grade/delete',
    {}
  );

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [actionType, setActionType] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (changestatusQuery.isSuccess) {
      navigate(
        `/enrollments${
          actionType == 'Accept'
            ? '/active'
            : actionType == 'IssueFixed'
            ? '/fixed-issue'
            : ''
        }`
      );
    }
  }, [changestatusQuery.isSuccess]);

  useEffect(() => {
    if (changestatusRegistrationQuery.isSuccess) {
      navigate(
        `/enrollments${
          actionType == 'Accept'
            ? '/active'
            : actionType == 'IssueFixed'
            ? '/fixed-issue'
            : ''
        }`
      );
    }
  }, [changestatusRegistrationQuery.isSuccess]);

  useEffect(() => {
    if (changestatusGradeSubmissonQuery.isSuccess) {
      navigate(
        `/enrollments${
          actionType == 'Accept'
            ? '/active'
            : actionType == 'IssueFixed'
            ? '/fixed-issue'
            : ''
        }`
      );
    }
  }, [changestatusGradeSubmissonQuery.isSuccess]);

  const onChangeStatus = (buttonType: any) => {
    setActionType(buttonType);
    if (submitFormType == 'ApplicationFormk12') {
      const formdata = {
        ID: enrollmentID,
        LanguageCode: languageCode,
        StatusType: buttonType,
      };
      changestatusQuery.mutateAsync(formdata);
    } else if (submitFormType == 'RegisterFormk12') {
      const formdata = {
        ID: enrollmentID,
        LanguageCode: languageCode,
        StatusType: buttonType,
      };
      changestatusRegistrationQuery.mutateAsync(formdata);
    } else {
      const formdata = {
        ID: enrollmentID,
        LanguageCode: languageCode,
        StatusType: buttonType,
      };
      changestatusGradeSubmissonQuery.mutateAsync(formdata);
    }
  };

  const onChangeUnEnroll = () => {
    setDeleteModel(false);
    if (submitFormType == 'ApplicationFormk12') {
      if (priviewData.EnrollmetType != 'ReEnrollment') {
        declineEnrollFormQuery.mutateAsync({
          EnrollmentID: deleteID,
          Email: priviewData?.Email,
        });
      } else {
        declineReEnrollFormQuery.mutateAsync({
          EnrollmentID: deleteID,
          Email: priviewData?.Email,
        });
      }
    }

    if (submitFormType == 'RegisterFormk12') {
      declineEnrollmentRegistrationQuery.mutateAsync({
        EnrollmentID: deleteID,
        Email: priviewData?.Email,
      });
    }

    if (submitFormType == 'GradeSubmissioFormk6') {
      declineEnrollmentGradeSubmissonQuery.mutateAsync({
        EnrollmentID: deleteID,
        Email: priviewData?.Email,
      });
    }
  };

  useEffect(() => {
    if (
      declineEnrollFormQuery.isSuccess ||
      declineEnrollmentRegistrationQuery.isSuccess ||
      declineEnrollmentGradeSubmissonQuery.isSuccess ||
      declineReEnrollFormQuery?.isSuccess
    ) {
      navigate(`/enrollments`);
      Toast.fire({
        icon: 'success',
        title: 'Record has been deleted successfully.',
      });
    }
  }, [
    declineEnrollFormQuery.isSuccess,
    declineEnrollmentRegistrationQuery.isSuccess,
    declineEnrollmentGradeSubmissonQuery.isSuccess,
    declineReEnrollFormQuery?.isSuccess,
  ]);

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  return (
    <div className='position-relative'>
      <Loader
        loading={
          changestatusQuery.isLoading ||
          changestatusRegistrationQuery.isLoading ||
          changestatusGradeSubmissonQuery.isLoading ||
          declineEnrollmentRegistrationQuery.isLoading ||
          declineEnrollmentGradeSubmissonQuery.isLoading ||
          declineEnrollFormQuery.isLoading ||
          declineReEnrollFormQuery?.isLoading
        }
      />
      <EnrollmentDeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={onChangeUnEnroll}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
      <button
        className='float-start btn btn-primary mb-5 mt-5'
        style={{ marginRight: '10px' }}
        type='button'
        onClick={() => {
          onChangeStatus('Accept');
        }}
      >
        Accept
      </button>
      <button
        className='float-start btn btn-primary mb-5 mt-5'
        type='button'
        style={{ marginRight: '10px' }}
        onClick={() => {
          onChangeStatus('IssueFixed');
        }}
      >
        Request Change
      </button>
      <button
        className='float-start btn btn-danger mb-5 mt-5'
        type='button'
        style={{ marginRight: '10px' }}
        onClick={() => {
          setDeleteModel(true);
          setDeleteID(enrollmentID);
        }}
      >
        Decline
      </button>
      <button
        className='float-end btn btn-secondary mb-5 mt-5'
        type='button'
        onClick={() => navigate(`/enrollments`)}
      >
        Cancel
      </button>
    </div>
  );
};

export default EnrollmentDetailsFooter;
