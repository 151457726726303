import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import LoaderNew from '../LoaderNew';

const ImageViewModel = (props: any) => {
  const { showDocument, handleCloseDocument, documentUrl } = props;
  const [loader, setLoader] = useState(true);

  return (
    <>
      <Modal
        show={showDocument}
        onHide={handleCloseDocument}
        centered
        scrollable
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Documents</Modal.Title>
        </Modal.Header>

        <Modal.Body className='vh-100'>
          <>
            <LoaderNew loading={loader} isFull={false} />
            <div className='d-flex justify-content-center align-items-center flex-column h-100'>
              {documentUrl && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={documentUrl}
                  className='img-fluid'
                  onLoad={() => setLoader(false)}
                />
              )}
            </div>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDocument}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageViewModel;
