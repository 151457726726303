import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';

import StudentsPage from './StudentsPage';

const StudentsPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Students</PageTitle>
      <StudentsPage />
    </>
  );
};

export default StudentsPageWrapper;
