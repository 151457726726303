import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';

import { KTSVG } from '../../../theme/helpers';
import { PageTitle } from '../../../theme/layout/core';
import { FORMAT_ISSUES_TYPE } from '../../../shared/constants';
import { axiosApi } from '../../../helpers/api-helper';
import Loader from '../Loader';
import Table from '../Table';
import { TableGlobalSearch } from '../Table/TableGlobalSearch';
import TableInner from '../Table/TableInner';
import { TablePagination } from '../Table/TablePagination';
import useTableState from '../Table/useTableState';

import EmbeddedVideoLogDeleteModal from './EmbeddedVideoLogDeleteModal';

const EmbeddedVideoLog: React.FC = () => {
  const [description, setDescription] = React.useState(null);

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start'>
            Question
          </span>
        ),
        accessor: 'lesson',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          const path = ['/lesson', 'edit', result?.lesson || ''].join('/');
          const hash = result?.urlHash ? `#${result.urlHash}` : '';
          const pathname = path + hash;

          return (
            <div>
              <Link
                className='btn btn-icon btn-primary btn-sm me-1'
                to={pathname}
                title='Edit Question'
              >
                {' '}
                <i className='fa fa-question'></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Video Url',
        accessor: 'Video_Url',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span className='pe-4 d-block'>{result.videourl}</span>;
        },
      },
      {
        Header: 'Error',
        accessor: 'Error',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return <span className='pe-4 d-block'>{result.error}</span>;
        },
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <span className='pe-4 d-block'>
              {moment(result.createdAt).format('DD-MM-YYYY HH:MM A')}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;

          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                onClick={() => {
                  setDeleteId(result._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({});

  const { sort, page, limit, search } = tableState;

  const { isLoading, data, refetch } = useQuery(
    ['fetchEmbeddedVideoLog', sort, page, limit, search],
    () =>
      axiosApi.get('/embeddedVideoLog/getAll', {
        params: {
          ...sort,
          page,
          limit,
          search,
        },
      })
  );

  const loading = isLoading;
  const list = data?.data?.ResultData ?? [];
  const [deleteId, setDeleteId] = React.useState(null);

  const f = React.useMemo(() => {
    return list.map((item: any) => {
      return {
        _original: item,
        ...item,
        // createdAt: new Date(item.createdAt).toISOString(),
      };
    });
  }, [list]);
  const deleteMutation = useMutation({
    mutationFn: (_id) =>
      axiosApi.post('/embeddedVideoLog/delete', {
        data: {
          _id,
        },
      }),
    onSuccess: () => {
      refetch();
      if (data?.statusText === 'OK') {
        Swal.fire('Video Log deleted successfully', '', 'success');
      }
    },
    onSettled: () => {
      setDeleteId(null);
    },
  });

  const handleDeleteVideoLog = () => {
    if (deleteId) {
      deleteMutation.mutate(deleteId);
    }
  };

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <PageTitle breadcrumbs={[]}>Embedded Video Logs</PageTitle>
      <div className='card mb-10'>
        <Table
          data={f}
          columns={columns}
          initialState={tableInitialState}
          pageCount={data?.data.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header justify-content-between border-0 pt-5'>
              <h3 className='card-title'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  Embedded Video Log Data
                </span>
              </h3>
              <span>
                <TableGlobalSearch />
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={loading}
                    entityLabel='embedded video log'
                  />
                </div>
                <div className='col-12 border-top pt-5'>
                  <TablePagination />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
      <EmbeddedVideoLogDeleteModal
        visible={deleteId !== null}
        okText='Yes'
        cancelText='No'
        message='Are you sure want to Delete?'
        onCancel={() => setDeleteId(null)}
        onOk={handleDeleteVideoLog}
        title='Delete video log'
      />
    </React.Fragment>
  );
};

export default EmbeddedVideoLog;
