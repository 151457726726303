import { combineReducers } from 'redux';

import login from './auth/reducer';
import teacher from './teacher/reducer';
import student from './student/reducer';
import lesson from './lesson/reducer';
import Language from './language/reducer';
import LiveClass from './liveClass/reducer';
import LiveClassSchedule from './liveClassSchedule/reducer';
import AIActivityImage from './aiactivityimage/reducer';


import loading from './loading/reducer'

const rootReducer = combineReducers({
  login,
  teacher,
  student,
  lesson,
  Language,
  LiveClass,
  LiveClassSchedule,
  loading,
  AIActivityImage,
});

export type AuthState = ReturnType<typeof rootReducer>;

export default rootReducer;
