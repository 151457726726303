import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';
import ScheduleGoogleMeet from './ScheduleGoogleMeet';

const ScheduleGoogleMeetWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Schedule Google Meet</PageTitle>
      <ScheduleGoogleMeet />
    </>
  );
};

export default ScheduleGoogleMeetWrapper;
