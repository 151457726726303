import React, { memo, useEffect, useRef } from 'react';
import { Button, Form as BForm, Modal, Spinner } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useGetQuery, usePostQuery, usePutQuery } from 'hooks/reactQueryHelper';
import { MdDeleteForever } from 'react-icons/md';
import LoaderNew from 'app/components/LoaderNew';
import MaskedInput from 'react-text-mask';

const ScheduleGoogleMeetUpsertModal: React.FC<any> = ({
  show,
  openModal,
  closeModal,
  toggleModal,
  upsertModalData,
  callback,
  addQuery,
  updateQuery,
}): any => {
  const formRef = useRef<HTMLFormElement>(null);
  const getByIdQuery: any = useGetQuery(
    'getScheduleGoogleMeet',
    '/scheduleGoogleMeet/getById'
  );
  const getAllLanguageDataQuery: any = useGetQuery(
    'getAllLanguageQuery',
    `/language/getAllLanguages`
  );
  const getAllLevelDataQuery: any = useGetQuery(
    'getAllLevelQuery',
    `/level/getAll`
  );

  const urlPattern =
    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter class name.'),
    ageGroup: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    languageLevel: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    nativeLanguage: Yup.array()
      .min(1, 'Please select at least one option.')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    targetLanguage: Yup.object().nullable().required('Please select.'),
    scheduleDate: Yup.string()
      .nullable()
      .required('Please select schedule date.'),
    link: Yup.string()
      .required('Please enter google meet link.')
      .matches(urlPattern, 'Enter a valid google meet URL.'),
    endTime: Yup.string().required('Please enter google meet end time.'),
    vocabularyWords: Yup.array()
      .of(Yup.string().required('vocabulary word is required'))
      .min(1, 'At least one vocabulary word is required'),
  });

  const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];
  function validate(evt: any) {
    var theEvent = evt || window.event;
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  let initialValues = {
    name: '',
    ageGroup: [],
    targetLanguage: [],
    nativeLanguage: [],
    languageLevel: [],
    scheduleDate: '',
    link: '',
    endTime: '',
    vocabularyWords: [],
  };

  const ageGroupOptions: any = [
    { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
    { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
    { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
  ];

  useEffect(() => {
    if (show && !getAllLevelDataQuery?.data && !getAllLanguageDataQuery?.data) {
      getAllLevelDataQuery.mutateAsync({});
      getAllLanguageDataQuery.mutateAsync({});
    }
  }, [show]);

  useEffect(() => {
    if (upsertModalData?.id) {
      if (formRef.current) {
        formRef.current.reset();
      }
      getByIdQuery.mutate({ params: { id: upsertModalData?.id } });
    }
  }, [upsertModalData]);

  const _handleSubmit = async (values: any, actions: any) => {
    let formData: any = {
      name: values?.name,
      ageGroup: values?.ageGroup,
      languageLevel: values?.languageLevel?.map((item: any) => item.value),
      nativeLanguage: values?.nativeLanguage?.map((item: any) => item.value),
      targetLanguage: values?.targetLanguage?.value,
      scheduleDate: values?.scheduleDate,
      link: values?.link,
      endTime: values?.endTime,
      vocabularyWords: values?.vocabularyWords,
    };
    if (upsertModalData?.id) {
      formData = { ...formData, id: upsertModalData?.id };
      updateQuery.mutateAsync(formData);
    } else {
      addQuery.mutateAsync(formData);
    }
  };

  return (
    <>
      <Modal show={show} onHide={closeModal} centered backdrop={true} size='xl'>
        <LoaderNew
          loading={
            getByIdQuery?.isLoading ||
            addQuery?.isLoading ||
            updateQuery?.isLoading
          }
          isFull={false}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }: any) => {
            console.log('values', values);

            useEffect(() => {
              if (upsertModalData?.id && getByIdQuery?.isSuccess) {
                setFieldValue('name', getByIdQuery?.data?.ResultObject?.name);

                let ageGroupValue =
                  getByIdQuery?.data?.ResultObject?.ageGroup.map(
                    (item: any) => {
                      return {
                        ...item,
                        value:
                          item.minAge +
                          (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                        label:
                          item.minAge +
                          (item.maxAge < 200 ? '-' + item.maxAge : '+'),
                        minAge: item.minAge,
                        maxAge: item.maxAge,
                      };
                    }
                  );

                setFieldValue('ageGroup', ageGroupValue);

                let languageLevel =
                  getByIdQuery?.data?.ResultObject?.languageLevel?.map(
                    (item: any) => {
                      return {
                        ...item,
                        label: item?.Level_Name,
                        value: item?._id,
                      };
                    }
                  );
                setFieldValue('languageLevel', languageLevel);

                let nativeLanguageValue =
                  getByIdQuery?.data?.ResultObject?.nativeLanguage?.map(
                    (item: any) => {
                      return {
                        ...item,
                        label: item?.Language_Name,
                        value: item?._id,
                      };
                    }
                  );

                setFieldValue('nativeLanguage', nativeLanguageValue);

                let targetLanguageValue = {
                  ...getByIdQuery?.data?.ResultObject?.targetLanguage,
                  label:
                    getByIdQuery?.data?.ResultObject?.targetLanguage
                      ?.Language_Name,
                  value: getByIdQuery?.data?.ResultObject?.targetLanguage?._id,
                };

                setFieldValue('targetLanguage', targetLanguageValue);

                // let scheduleDateValue =
                //   getByIdQuery?.data?.ResultObject?.scheduleDate?.map(
                //     (item: any) => item?.date
                //   );
                setFieldValue(
                  'scheduleDate',
                  getByIdQuery?.data?.ResultObject?.scheduleDate?.date
                );

                setFieldValue('link', getByIdQuery?.data?.ResultObject?.link);
                setFieldValue(
                  'endTime',
                  getByIdQuery?.data?.ResultObject?.endTime
                );
                setFieldValue(
                  'vocabularyWords',
                  getByIdQuery?.data?.ResultObject?.vocabularyWords
                );
              }
            }, [getByIdQuery?.isSuccess, upsertModalData?.id]);

            return (
              <Container>
                <fieldset>
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <Modal.Header closeButton>
                      <Modal.Title id='example-custom-modal-styling-title'>
                        <i className='fa fa-duotone fa-pen-to-square me-2 fs-2'></i>
                        {!upsertModalData?.id ? 'Add ' : 'Edit '}
                        Schedule Google Meet
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='name'>
                            <BForm.Label>
                              Class Name :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <BForm.Control
                              type='text'
                              name='name'
                              placeholder='Enter class name'
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              value={values.name}
                              className={
                                errors.name && touched.name
                                  ? 'border border-danger'
                                  : ''
                              }
                            />
                            {errors.name && touched.name ? (
                              <div className='text-danger'>{errors.name}</div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='ageGroup'>
                            <BForm.Label>
                              Age Group :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='ageGroup'
                              isMulti
                              component={Select}
                              options={ageGroupOptions}
                              placeholder='Select an option'
                              isClearable={true}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                setFieldValue('ageGroup', option);
                              }}
                              onBlur={handleBlur}
                              value={values.ageGroup}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.ageGroup && touched.ageGroup
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.ageGroup && touched.ageGroup
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.ageGroup && touched.ageGroup
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.ageGroup && touched.ageGroup
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors && errors.ageGroup && touched.ageGroup ? (
                              <div className='text-danger'>
                                {errors.ageGroup}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='formCode'>
                            <BForm.Label>
                              Language Level :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='languageLevel'
                              isMulti={true}
                              component={Select}
                              options={getAllLevelDataQuery?.data?.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Level_Name,
                                  };
                                }
                              )}
                              placeholder='Select'
                              isClearable={true}
                              isDisabled={getAllLevelDataQuery?.isLoading}
                              isLoading={getAllLevelDataQuery?.isLoading}
                              classNamePrefix='select'
                              value={values?.languageLevel}
                              onChange={(option: any) => {
                                setFieldValue('languageLevel', option);
                              }}
                              // onBlur={handleBlur}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.languageLevel &&
                                        touched.languageLevel
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.languageLevel &&
                                        touched.languageLevel
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.languageLevel &&
                                        touched.languageLevel
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.languageLevel &&
                                        touched.languageLevel
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.languageLevel && touched.languageLevel ? (
                              <div className='text-danger'>
                                {errors.languageLevel}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='targetLanguage'
                          >
                            <BForm.Label>
                              Target Language :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='targetLanguage'
                              isMulti={false}
                              component={Select}
                              options={getAllLanguageDataQuery?.data?.ResultObject.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Language_Name,
                                  };
                                }
                              )}
                              placeholder='Select'
                              isClearable={true}
                              isDisabled={getAllLanguageDataQuery?.isLoading}
                              isLoading={getAllLanguageDataQuery?.isLoading}
                              classNamePrefix='select'
                              value={values?.targetLanguage}
                              onChange={(option: any) => {
                                setFieldValue('targetLanguage', option);
                              }}
                              // onBlur={handleBlur}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.targetLanguage &&
                                        touched.targetLanguage
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.targetLanguage &&
                                        touched.targetLanguage
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.targetLanguage &&
                                        touched.targetLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.targetLanguage &&
                                        touched.targetLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />

                            {errors.targetLanguage && touched.targetLanguage ? (
                              <div className='text-danger'>
                                {errors.targetLanguage}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group
                            className='mb-3'
                            controlId='nativeLanguage'
                          >
                            <BForm.Label>
                              Native Language :{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='nativeLanguage'
                              isMulti={true}
                              component={Select}
                              options={getAllLanguageDataQuery?.data?.ResultObject.map(
                                (option: any, index: any) => {
                                  return {
                                    ...option,
                                    value: option._id,
                                    label: option.Language_Name,
                                  };
                                }
                              )}
                              placeholder='Select'
                              isClearable={true}
                              isDisabled={getAllLanguageDataQuery?.isLoading}
                              isLoading={getAllLanguageDataQuery?.isLoading}
                              classNamePrefix='select'
                              onChange={(option: any) => {
                                // const optionValues = option.find((selected: any) => selected.value === 'All');
                                setFieldValue('nativeLanguage', option);
                                // const allSelected = optionValues;
                                // if (allSelected) {
                                //   handleSelectAll(true);
                                // } else {
                                //   handleSelectAll(false);
                                // }
                              }}
                              onBlur={handleBlur}
                              value={values.nativeLanguage}
                              // isLoading={getAllLoading}
                              // isDisabled={getAllLoading}
                              styles={{
                                control: (baseStyles: any, state: any) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? errors.nativeLanguage &&
                                        touched.nativeLanguage
                                        ? '#f1416c'
                                        : 'grey'
                                      : errors.nativeLanguage &&
                                        touched.nativeLanguage
                                      ? '#f1416c'
                                      : '#b5b5c3',
                                    boxShadow: '#b5b5c3',
                                    '&:hover': {
                                      borderColor:
                                        errors.nativeLanguage &&
                                        touched.nativeLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                    '&:focus': {
                                      borderColor:
                                        errors.nativeLanguage &&
                                        touched.nativeLanguage
                                          ? '#f1416c'
                                          : 'none',
                                    },
                                  };
                                },
                              }}
                            />
                            {errors.nativeLanguage && touched.nativeLanguage ? (
                              <div className='text-danger'>
                                {errors.nativeLanguage}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='ageGroup'>
                            <BForm.Label>
                              Date (UTC+00:00):{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <Field
                              name='scheduleDate'
                              component={DatePicker}
                              format='MM/DD/YYYY HH:mm:ss'
                              placeholder='Select Date'
                              minDate={new Date()}
                              multiple={false}
                              value={values.scheduleDate}
                              onChange={(option: any) => {
                                setFieldValue('scheduleDate', option);
                              }}
                              plugins={[
                                <DatePanel />,
                                <TimePicker position='bottom' />,
                              ]}
                              onBlur={(option: any) => {
                                setFieldValue('scheduleDate', option);
                              }}
                              style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                height: '40px',
                                padding: '10px',
                                borderColor:
                                  errors.scheduleDate && touched.scheduleDate
                                    ? '#f1416c'
                                    : '',
                              }}
                              // className="w-full p-3 rounded text-sm  outline-none  focus:ring-0 "
                              containerStyle={{
                                width: '100%',
                              }}
                              calendarPosition='bottom-center'
                            />
                            {errors.scheduleDate && touched.scheduleDate ? (
                              <div className='text-danger'>
                                {errors.scheduleDate}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='name'>
                            <BForm.Label>
                              Google Meet Link :
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <BForm.Control
                              type='text'
                              name='link'
                              placeholder='Enter google meet link'
                              onChange={handleChange}
                              value={values.link}
                              className={
                                errors.link && touched.link
                                  ? 'border border-danger'
                                  : ''
                              }
                            />
                            {errors.link && touched.link ? (
                              <div className='text-danger'>{errors.link}</div>
                            ) : null}
                          </BForm.Group>
                        </Col>
                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='name'>
                            <BForm.Label>
                              Google Meet End Time:
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>

                            <Field
                              name='endTime'
                              render={({ field }: any) => (
                                <MaskedInput
                                  {...field}
                                  onKeyPress={validate}
                                  mask={timeMask}
                                  value={values.endTime}
                                  placeholder='Enter google meet end time'
                                  type='text'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control ${
                                    errors.endTime && touched.endTime
                                      ? 'border border-danger'
                                      : ''
                                  }`}
                                />
                              )}
                            />

                            {errors.endTime && touched.endTime ? (
                              <div className='text-danger'>
                                {errors.endTime}
                              </div>
                            ) : null}
                          </BForm.Group>
                        </Col>

                        <Col md={6}>
                          <BForm.Group className='mb-3' controlId='name'>
                            <BForm.Label>
                              Vocabulary Word:
                              <span style={{ color: 'red' }}>*</span>
                            </BForm.Label>
                            <FieldArray name='vocabularyWords'>
                              {({ insert, remove, push }) => (
                                <div className='d-flex flex-column gap-2'>
                                  {values.vocabularyWords.length > 0 &&
                                    values.vocabularyWords.map(
                                      (item: any, index: number) => (
                                        <div>
                                          <div
                                            key={index}
                                            className='d-flex align-items-center'
                                          >
                                            <div className='w-100'>
                                              <Field
                                                name={`vocabularyWords.${index}`}
                                                value={item}
                                                placeholder='Enter vocabulary word'
                                                type='text'
                                                className={`form-control ${
                                                  touched.vocabularyWords &&
                                                  errors.vocabularyWords &&
                                                  errors.vocabularyWords[
                                                    index
                                                  ] &&
                                                  touched.vocabularyWords[index]
                                                    ? 'border border-danger'
                                                    : ''
                                                } `}
                                              />
                                            </div>

                                            {/* {index > 0 && ( */}
                                            <div className=''>
                                              <button
                                                type='button'
                                                className='secondary border-0 bg-transparent fs-1 text-danger'
                                                onClick={() => remove(index)}
                                              >
                                                <MdDeleteForever />
                                              </button>
                                            </div>
                                            {/* )} */}
                                          </div>
                                          <ErrorMessage
                                            name={`vocabularyWords.${index}`}
                                            component='div'
                                            className='text-danger'
                                          />
                                        </div>
                                      )
                                    )}
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-success btn-sm'
                                      onClick={() => push('')}
                                    >
                                      Add Vocabulary Word
                                    </button>
                                  </div>
                                  {errors.vocabularyWords &&
                                    !Array.isArray(errors.vocabularyWords) && (
                                      <ErrorMessage
                                        name={`vocabularyWords`}
                                        component='div'
                                        className='text-danger'
                                      />
                                    )}
                                </div>
                              )}
                            </FieldArray>
                          </BForm.Group>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant='primary' type='submit'>
                        {addQuery?.isLoading || updateQuery?.isLoading ? (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='reset'
                        variant='secondary'
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Form>
                </fieldset>
              </Container>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default memo(ScheduleGoogleMeetUpsertModal);
