import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'


const LessonDeleteModel: React.FC<any> = (props: any) => {

  const { deleteModel, handleDeleteLesson, handleCloseDeleteModel } = props

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleDeleteLesson()
  }

  const f = <Modal show={Boolean(deleteModel)} onHide={handleCloseDeleteModel} centered>
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className='delete_text'>Are you sure you want to delete?</h4>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='primary' type='submit'>
          Yes
        </Button>
        <Button variant='secondary' type='button' onClick={handleCloseDeleteModel}>
          No
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>

  return f;
}

export default LessonDeleteModel
