import React, { useEffect, useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import MultiRangeSlider from 'multi-range-slider-react';
import { useFormikContext, Formik, Form, Field } from 'formik';

const PercentageRangeSelectorPopover = (props: any) => {
  const { values, errors, touched, submitForm, setFieldValue, setErrors }: any =
    useFormikContext();
  const { field, ChildField, fieldName, minMaxValue } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(100);

  useEffect(() => {
    if (minMaxValue?.min > 0 && minMaxValue?.max > 0) {
      set_minValue(minMaxValue?.min);
      set_maxValue(minMaxValue?.max);
    }
  }, [minMaxValue]);

  const isRangesOverlap = (
    range1Min: number,
    range1Max: number,
    range2Min: number,
    range2Max: number
  ) => {
    if (
      range1Min == 0 ||
      range1Max == 0 ||
      range2Min == 0 ||
      range2Max === 0 ||
      range1Max < range2Min ||
      range2Max < range1Min
    ) {
      // Ranges do not overlap.
      return false;
    } else {
      //'Ranges overlap or intersect.
      return true;
    }
  };

  const IsSelectedRange = () => {
    let minA = Number(values.gradingRubric.A.min);
    let maxA = Number(values.gradingRubric.A.max);

    let minB = Number(values.gradingRubric.B.min);
    let maxB = Number(values.gradingRubric.B.max);

    let minC = Number(values.gradingRubric.C.min);
    let maxC = Number(values.gradingRubric.C.max);

    let minD = Number(values.gradingRubric.D.min);
    let maxD = Number(values.gradingRubric.D.max);

    let minF = Number(values.gradingRubric.F.min);
    let maxF = Number(values.gradingRubric.F.max);

    return (
      isRangesOverlap(minA, maxA, minB, maxB) ||
      isRangesOverlap(minA, maxA, minC, maxC) ||
      isRangesOverlap(minA, maxA, minD, maxD) ||
      isRangesOverlap(minA, maxA, minF, maxF) ||
      isRangesOverlap(minB, maxB, minA, maxA) ||
      isRangesOverlap(minB, maxB, minC, maxC) ||
      isRangesOverlap(minB, maxB, minD, maxD) ||
      isRangesOverlap(minB, maxB, minF, maxF) ||
      isRangesOverlap(minC, maxC, minA, maxA) ||
      isRangesOverlap(minC, maxC, minB, maxB) ||
      isRangesOverlap(minC, maxC, minD, maxD) ||
      isRangesOverlap(minC, maxC, minF, maxF) ||
      isRangesOverlap(minD, maxD, minA, maxA) ||
      isRangesOverlap(minD, maxD, minB, maxB) ||
      isRangesOverlap(minD, maxD, minC, maxC) ||
      isRangesOverlap(minD, maxD, minF, maxF) ||
      isRangesOverlap(minF, maxF, minA, maxA) ||
      isRangesOverlap(minF, maxF, minB, maxB) ||
      isRangesOverlap(minF, maxF, minC, maxC) ||
      isRangesOverlap(minF, maxF, minD, maxD)
    );
  };

  const handleInput = (e: any) => {
    IsSelectedRange() === true
      ? setFieldValue('gradingRubricOverLapRange', true)
      : setFieldValue('gradingRubricOverLapRange', false);

    setFieldValue(fieldName, {
      name: ChildField,
      min: e.minValue,
      max: e.maxValue,
    });

    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <div ref={ref}>
      <Button onClick={handleClick} size='sm' variant='light'>
        {minValue} - {maxValue}
      </Button>
      <Overlay
        show={show}
        target={target}
        placement='right'
        container={ref}
        containerPadding={20}
        rootClose={true}
      >
        <Popover style={{ width: '500px' }}>
          <MultiRangeSlider
            className='w-100 px-7'
            min={0}
            max={100}
            step={5}
            ruler={true}
            label={true}
            preventWheel={false}
            minValue={minValue}
            maxValue={maxValue}
            onInput={(e: any) => {
              handleInput(e);
            }}
          />
        </Popover>
      </Overlay>
    </div>
  );
};

export default PercentageRangeSelectorPopover;
