import Loader from 'app/components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { useGetQuery, usePostQuery, usePutQuery } from 'hooks/reactQueryHelper';
import { Link, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import EnrollmentHeadPageTab from '../EnrollmentHeadPageTab';
import useToaster from 'hooks/useToaster';
import PDFViewModel from 'app/components/PDFViewModel';
import useModal from 'hooks/useModal';
import { AiFillEye } from 'react-icons/ai';

const AddDiplomaCertificate = () => {
  const certificateType = [
    {
      label: 'Diploma',
      value: 'diploma',
    },
    {
      label: 'Certificate',
      value: 'certificate',
    },
  ];
  const [preview, setPreview] = useState('');
  const { show, openModal, closeModal, modalData } = useModal();
  const { successToaster } = useToaster();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { certificateId }: any = useParams();

  const getSchoolYearQuery: any = useGetQuery(
    'getSchoolYear',
    '/enrollment/calendartype/get'
  );

  const getStudentBySchoolYearQuery: any = useGetQuery(
    'getStudentBySchoolYearQuery',
    '/certificate/getStudentBySchoolYear'
  );

  const getByIdQuery: any = useGetQuery('getByIdQuery', '/certificate/getById');

  const addCertificateQuery: any = usePostQuery(
    'addCertificateQuery',
    '/certificate',
    {}
  );

  const updateCertificateQuery: any = usePutQuery(
    'updateCertificateQuery',
    '/certificate'
  );

  useEffect(() => {
    getSchoolYearQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (certificateId) {
      getByIdQuery.mutate({ params: { id: certificateId } });
    }
  }, [certificateId]);

  const initialValues: any = {
    type: '',
    schoolYear: '',
    student: '',
    certificate: '',
  };

  const validationSchema: any = Yup.object().shape({
    type: Yup.object().nullable().required('Please select certificate type'),
    schoolYear: Yup.object().nullable().required('Please select school year'),
    student: Yup.object().nullable().required('Please select student'),
    certificate: Yup.mixed()
      .required('Please upload a file')
      .test('fileFormat', 'Please upload a PDF file', (value) => {
        console.log('value', value);
        if (value && value.type === 'application/pdf') {
          return true;
        } else if (value && value.includes('.pdf')) {
          return true;
        }
        return false;
      }),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: async (values) => {
      const formData = new FormData();

      formData.append('type', values.type.value);
      formData.append('calendarYearId', values.schoolYear.value);
      formData.append('userId', values.student.value);
      formData.append('certificate', values.certificate);

      if (certificateId) {
        formData.append('id', certificateId);
        updateCertificateQuery.mutateAsync(formData);
      } else {
        addCertificateQuery.mutateAsync(formData);
      }
    },
  });

  useEffect(() => {
    if (getByIdQuery.isSuccess) {
      getStudentBySchoolYearQuery.mutateAsync({
        params: {
          certificateId: certificateId,
          schoolYear: getByIdQuery?.data?.ResultObject?.calendarYearId?._id,
        },
      });

      formik.setFieldValue('schoolYear', {
        label: getByIdQuery?.data?.ResultObject?.calendarYearId?.label,
        value: getByIdQuery?.data?.ResultObject?.calendarYearId?._id,
      });
      formik.setFieldValue('student', {
        userId: getByIdQuery?.data?.ResultObject?.userId?.UserId?._id,
        label: `${getByIdQuery?.data?.ResultObject?.userId?.UserId?.FirstName} ${getByIdQuery?.data?.ResultObject?.userId?.UserId?.LastName}`,
        value: getByIdQuery?.data?.ResultObject?.userId?.UserId?._id,
      });

      formik.setFieldValue(
        'certificate',
        getByIdQuery?.data?.ResultObject?.url
      );

      formik.setFieldValue(
        'type',
        getByIdQuery?.data?.ResultObject?.type
          ? certificateType.find(
              (item) => item.value === getByIdQuery?.data?.ResultObject?.type
            )
          : ''
      );
    }
  }, [getByIdQuery.isSuccess]);

  useEffect(() => {
    if (addCertificateQuery.isSuccess || updateCertificateQuery.isSuccess) {
      const msg = addCertificateQuery.isSuccess
        ? addCertificateQuery.data?.ResultMessage
        : updateCertificateQuery.data?.ResultMessage;
      successToaster(msg);
      navigate('/diploma/certificate');
    }
  }, [addCertificateQuery.isSuccess, updateCertificateQuery.isSuccess]);

  const handleSchoolYearChange = (event: any) => {
    if (event?.value) {
      formik.setFieldValue('schoolYear', {
        label: event?.label,
        value: event?.value,
      });
      getStudentBySchoolYearQuery.mutateAsync({
        params: {
          certificateId: certificateId,
          schoolYear: event?.value,
        },
      });
    } else {
      formik.setFieldValue('schoolYear', '');
      formik.setFieldValue('student', '');
      formik.setFieldValue('certificate', '');

      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset file input value
      }
    }
  };

  const handleSelectStudent = (event: any) => {
    formik.setFieldTouched(
      'student',
      !!formik?.touched?.student?._id,
      !!formik?.touched?.student?._id
    );
    if (event) {
      formik.setFieldValue('student', event, false);
    } else {
      formik.setFieldValue('student', '');
      formik.setFieldValue('certificate', '');
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset file input value
      }
    }
  };

  return (
    <>
      <Loader
        loading={
          getByIdQuery.isLoading ||
          updateCertificateQuery.isLoading ||
          addCertificateQuery.isLoading
        }
      />
      <div>
        <div className='card text-end mt-5 p-6'>
          <div className='pt-5'>
            <EnrollmentHeadPageTab activeTab='diplomaCertificate' />
          </div>
          <div className='card-body p-6'>
            <h1>Add certificate</h1>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='card mt-3 mb-3'>
            <div className='card-body p-6'>
              <div className='row'>
                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Type <span className='text-danger'> * </span>
                  </label>
                  <Select
                    name='type'
                    isMulti={false}
                    isClearable={true}
                    placeholder='Select certificate type'
                    value={formik.values.type}
                    options={certificateType}
                    onChange={(event) => {
                      formik.setFieldValue('type', event);
                    }}
                    styles={{
                      control: (baseStyles: any, state: any) => {
                        return {
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? formik.errors.type && formik.touched.type
                              ? '#f1416c'
                              : 'grey'
                            : formik.errors.type && formik.touched.type
                            ? '#f1416c'
                            : '#b5b5c3',
                          boxShadow: '#b5b5c3',
                          '&:hover': {
                            borderColor:
                              formik.errors.type && formik.touched.type
                                ? '#f1416c'
                                : 'none',
                          },
                          '&:focus': {
                            borderColor:
                              formik.errors.type && formik.touched.type
                                ? '#f1416c'
                                : 'none',
                          },
                        };
                      },
                    }}
                  />
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.type}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    School Year: <span className='text-danger'> * </span>
                  </label>

                  <Select
                    name='schoolYear'
                    isMulti={false}
                    isClearable={true}
                    classNamePrefix='select'
                    placeholder='Select school year'
                    value={formik.values.schoolYear}
                    isLoading={getSchoolYearQuery?.isLoading}
                    isDisabled={getSchoolYearQuery?.isLoading}
                    options={getSchoolYearQuery?.data?.ResultObject.map(
                      (data: any) => {
                        return {
                          label: data.description,
                          value: data._id,
                        };
                      }
                    )}
                    onChange={(event) => {
                      handleSchoolYearChange(event);
                    }}
                    styles={{
                      control: (baseStyles: any, state: any) => {
                        return {
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? formik.errors.schoolYear &&
                              formik.touched.schoolYear
                              ? '#f1416c'
                              : 'grey'
                            : formik.errors.schoolYear &&
                              formik.touched.schoolYear
                            ? '#f1416c'
                            : '#b5b5c3',
                          boxShadow: '#b5b5c3',
                          '&:hover': {
                            borderColor:
                              formik.errors.schoolYear &&
                              formik.touched.schoolYear
                                ? '#f1416c'
                                : 'none',
                          },
                          '&:focus': {
                            borderColor:
                              formik.errors.schoolYear &&
                              formik.touched.schoolYear
                                ? '#f1416c'
                                : 'none',
                          },
                        };
                      },
                    }}
                  />

                  {formik.touched.schoolYear && formik.errors.schoolYear && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.schoolYear}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Student <span className='text-danger'> * </span>
                  </label>
                  <Select
                    name='student'
                    isMulti={false}
                    isClearable={true}
                    placeholder='Select student'
                    value={formik.values.student}
                    options={
                      getStudentBySchoolYearQuery?.data?.ResultObject
                        ? getStudentBySchoolYearQuery?.data?.ResultObject
                        : []
                    }
                    isLoading={getStudentBySchoolYearQuery?.isLoading}
                    onChange={handleSelectStudent}
                    isDisabled={
                      getStudentBySchoolYearQuery.isLoading ||
                      !!!formik.values.schoolYear
                    }
                    styles={{
                      control: (baseStyles: any, state: any) => {
                        return {
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? !!formik?.values?.schoolYear &&
                              formik.errors.student &&
                              formik.touched.student
                              ? '#f1416c'
                              : 'grey'
                            : !!formik?.values?.schoolYear &&
                              formik.errors.student &&
                              formik.touched.student
                            ? '#f1416c'
                            : '#b5b5c3',
                          boxShadow: '#b5b5c3',
                          '&:hover': {
                            borderColor:
                              !!formik?.values?.schoolYear &&
                              formik.errors.student &&
                              formik.touched.student
                                ? '#f1416c'
                                : 'none',
                          },
                          '&:focus': {
                            borderColor:
                              !!formik?.values?.schoolYear &&
                              formik.errors.student &&
                              formik.touched.student
                                ? '#f1416c'
                                : 'none',
                          },
                        };
                      },
                    }}
                  />
                  {!!formik?.values?.schoolYear &&
                    formik.touched.student &&
                    formik.errors.student && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.student}</span>
                        </div>
                      </div>
                    )}
                </div>

                <div className='p-3 col-md-6'>
                  <label className='form-label'>
                    Certificate <span className='text-danger'> * </span>
                    {formik?.values?.certificate && (
                      <a
                        className='cursor-pointer'
                        title='Preview'
                        onClick={() => {
                          openModal({
                            url: preview
                              ? preview
                              : formik?.values?.certificate,
                          });
                        }}
                      >
                        <AiFillEye size={20} />
                      </a>
                    )}
                  </label>
                  <input
                    name='certificate'
                    type='file'
                    ref={fileInputRef}
                    onChange={(event) => {
                      formik.setFieldValue(
                        'certificate',
                        event.currentTarget.files
                          ? event.currentTarget.files[0]
                          : null
                      );

                      if (
                        event?.currentTarget?.files &&
                        event?.currentTarget?.files[0].type ===
                          'application/pdf'
                      ) {
                        setPreview('');
                        setPreview(
                          URL.createObjectURL(event?.currentTarget?.files[0])
                        );
                      } else {
                        setPreview('');
                      }
                    }}
                    className={clsx('form-control', {
                      'border border-danger':
                        formik.values.student &&
                        formik.touched.certificate &&
                        formik.errors.certificate,
                    })}
                    disabled={!!!formik?.values?.student}
                    accept='application/pdf'
                  />
                  {formik.values.student &&
                    formik.touched.certificate &&
                    formik.errors.certificate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.certificate}</span>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className='float-end'>
                <Button className='btn btn-primary me-3' type='submit'>
                  Save
                </Button>
                <Link to='/diploma/certificate' className='btn btn-secondary'>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <PDFViewModel
        showDocument={show}
        handleCloseDocument={closeModal}
        documentUrl={modalData.url}
      />
    </>
  );
};

export default AddDiplomaCertificate;
