import React, { FC } from 'react';

import { PageTitle } from '../../../theme/layout/core';

import TeacherPage from './TeacherPage';

const TeacherPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Teachers</PageTitle>
      <TeacherPage />
    </>
  );
};

export default TeacherPageWrapper;
