import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import { CellProps, Column } from 'react-table';
import Table, { useTableInstance } from '../../components/Table';
import TableInner from 'app/components/Table/TableInner';
import useTableState from 'app/components/Table/useTableState';
import { KTSVG, toAbsoluteUrl } from 'theme/helpers';
import { TableGlobalSearch } from 'app/components/Table/TableGlobalSearch';
import { TablePagination } from 'app/components/Table/TablePagination';
import Select from 'react-select';
import DeleteConfirmationModel from 'app/components/Modal/DeleteConfirmationModel';
import { useDeleteQuery, useGetQuery } from 'hooks/reactQueryHelper';
import { CourseAddEditModal } from './CourseAddEditModal';
import DueDatesDetailModal from './DueDatesDetailModal';
import moment from 'moment';
import EnrollStudentsDetailsModal from 'app/components/EnrollStudentsDetailsModal/EnrollStudentsDetailsModal';
import useModal from 'hooks/useModal';

const options = [
  { value: '1', label: 'Admin' },
  { value: '4', label: 'Student' },
];
const CourseSelect: React.FC<any> = ({ role, onChange }) => {
  const tableInstance = useTableInstance();

  const handleRoleChange = React.useCallback((e: any) => {
    tableInstance.gotoPage(0);
    onChange(e);
  }, []);

  return (
    <Select
      name='role'
      isMulti={false}
      isClearable={true}
      classNamePrefix='select'
      placeholder='Select Role'
      value={role}
      options={options}
      onChange={handleRoleChange}
    />
  );
};

const Course: React.FC = (props: any) => {
  const {
    show: enrollModalShow,
    openModal: openEnrollModal,
    closeModal: closeEnrollModal,
    modalData: enrollModalData,
  } = useModal();
  const defaultImageUrl = 'https://via.placeholder.com/150?text=N/A';

  const getAllCourseQuery: any = useGetQuery('getAllCourse', '/course');
  const deleteCourseQuery: any = useDeleteQuery('deleteCourse', '/course');

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageIndex: 0,
    });
  const { sort, page, limit, search } = tableState;

  const [deleteID, setDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);

  const [dueDatesModal, setDueDatesModal] = React.useState<any>({
    show: false,
    dueDates: [],
  });

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectRole, setSelectRole] = useState();

  const [addEditModel, setAddEditModel] = useState({
    id: null,
    show: false,
  });

  useEffect(() => {
    getAllCourseQuery.mutateAsync({
      params: {
        page,
        limit,
        sort,
        search,
        selectRole,
      },
    });
  }, [page, limit, sort, search, selectRole]);

  const handleDeleteRecord = () => {
    setDeleteModel(false);
    deleteCourseQuery.mutateAsync({
      params: {
        id: deleteID,
      },
    });
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseAddEditModel = (props: any) => {
    const { refresh } = props;

    setAddEditModel((prevState) => {
      const data = {
        ...prevState,
        show: false,
      };
      return data;
    });
    if (refresh) {
      getAllCourseQuery.mutateAsync();
    }
  };

  useEffect(() => {
    if (deleteCourseQuery.isSuccess) {
      getAllCourseQuery.mutateAsync();
    }
  }, [deleteCourseQuery.isSuccess]);

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: '',
        accessor: 'schoolLogo',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              <div className='symbol symbol-45px me-5'>
                {props?.row?.original?.schoolLogo ? (
                  <img src={props?.row?.original?.schoolLogo} alt='Logo' />
                ) : (
                  <img src={defaultImageUrl} />
                )}
              </div>
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className='ps-4 min-w-325px rounded-start text-start text-nowrap'>
            Name
          </span>
        ),
        accessor: 'name',
        Cell: (props: CellProps<any>) => {
          return (
            <span className='ps-4 text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.name}
            </span>
          );
        },
      },
      {
        Header: 'Target Language',
        accessor: 'targetLanguage',
        // disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const targetLanguage = props?.row?.original?.targetLanguage.map(
            (e: any) => e.Language_Name
          );
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              <span
                className='badge badge-pill badge-secondary'
                style={{ marginRight: '3px' }}
              >
                {targetLanguage}
              </span>
            </span>
          );
        },
      },
      // {
      //   Header: 'Grade',
      //   accessor: 'grade',
      //   // disableSortBy: true,
      //   Cell: (props: CellProps<any>) => {
      //     return (
      //       <span className='text-dark fw-bold d-block fs-6'>
      //         {props?.row?.original?.grade}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: 'Language Level',
        accessor: 'languageLevel',
        // disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const languageLevel = props?.row?.original?.languageLevel.map(
            (e: any) => e.Level_Name
          );
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {languageLevel}
            </span>
          );
        },
      },
      {
        Header: 'Age Group',
        accessor: 'ageOfGroup',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.ageOfGroup?.map((item: any) => (
                <span
                  className='badge badge-pill badge-secondary'
                  style={{ marginRight: '3px' }}
                >
                  {item?.minAge}{' '}
                  {item?.maxAge < 200 ? '- ' + item?.maxAge : '+'}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: 'Needed Points',
        accessor: 'pointsNeededToCompleteCourse',
        // disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {props?.row?.original?.pointsNeededToCompleteCourse}
            </span>
          );
        },
      },
      // {
      //   Header: 'Due Date',
      //   accessor: 'Due Date',
      //   disableSortBy: true,
      //   Cell: (props: CellProps<any>) => {
      //     return props?.row?.original?.isDueDate ? (
      //       <>
      //         <span className='badge badge-primary'>Yes</span>
      //         <span
      //           onClick={() => {
      //             setDueDatesModal({
      //               ...dueDatesModal,
      //               show: true,
      //               dueDates: props?.row?.original?.dueDates,
      //             });
      //           }}
      //         >
      //           <i className={'bi bi-info-circle cursor-pointer ms-2'}></i>
      //         </span>
      //       </>
      //     ) : (
      //       <span className='badge badge-secondary'>No</span>
      //     );
      //   },
      // },
      {
        Header: 'Enroll Students',
        accessor: 'Enroll',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex align-items-center'>
              <span className='text-dark fw-bold d-block fs-6'>
                {result?.enrolledCourse?.count}
              </span>

              {result?.enrolledCourse?.count > 0 ? (
                <span
                  onClick={() => {
                    openEnrollModal({
                      id: result?._id,
                    });
                  }}
                >
                  <i className={'fa-solid fa-eye cursor-pointer ms-2'}></i>
                </span>
              ) : (
                '-'
              )}
            </div>
          );
        },
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {(() => {
                if (
                  result?.createdBy?.FirstName &&
                  result?.createdBy?.LastName
                ) {
                  return (
                    <>
                      {result?.createdBy?.FirstName}{' '}
                      {result?.createdBy?.LastName}
                    </>
                  );
                } else if (result?.createdBy?.FirstName) {
                  return <>{result?.createdBy?.FirstName} </>;
                } else {
                  return <>-</>;
                }
              })()}
            </span>
          );
        },
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <span className='text-dark fw-bold d-block fs-6'>
              {moment(result?.createdAt).format('MM/DD/YYYY')}
            </span>
          );
        },
      },
      // {
      //   Header: 'Diploma English Course',
      //   accessor: 'isDiplomaEnglishCourse',
      //   // disableSortBy: true,
      //   Cell: (props: CellProps<any>) => {
      //     return (
      //       <span className='badge badge-primary'>
      //         {props?.row?.original?.isDiplomaEnglishCourse ? 'Yes' : 'No'}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  setAddEditModel((prevState) => {
                    const data = {
                      ...prevState,
                      id: result?._id,
                      show: true,
                    };
                    return data;
                  });
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
              <button
                className='btn btn-icon btn-danger btn-sm'
                title='delete'
                data-bs-toggle='modal'
                data-bs-target='#delete_confirmation_modal'
                onClick={() => {
                  setDeleteModel(true);
                  setDeleteID(result?._id);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen027.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  console.log(
    'getAllCourseQuery?.data?.ResultObject?.courseData',
    getAllCourseQuery?.data?.ResultObject?.courseData[4]?.enrolledCourse
  );

  return (
    <React.Fragment>
      {dueDatesModal.show && (
        <DueDatesDetailModal
          show={dueDatesModal.show}
          onHide={() =>
            setDueDatesModal({
              ...dueDatesModal,
              show: false,
            })
          }
          dueDates={dueDatesModal.dueDates}
        />
      )}
      {enrollModalShow && (
        <EnrollStudentsDetailsModal
          show={enrollModalShow}
          onHide={closeEnrollModal}
          data={enrollModalData}
        />
      )}

      <div className='card mb-10'>
        <Loader
          loading={getAllCourseQuery?.isLoading || deleteCourseQuery.isLoading}
        />
        <Table
          data={
            getAllCourseQuery?.data?.ResultObject?.courseData
              ? getAllCourseQuery?.data?.ResultObject?.courseData
              : []
          }
          //@ts-ignore
          pageIndex={3}
          columns={columns}
          initialState={tableInitialState}
          pageCount={getAllCourseQuery?.data?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <div className='card-label fw-bold fs-3 mb-1'>Course Data</div>{' '}
              </h3>
              <div className='d-flex align-items-center'>
                <label className='me-2 fw-bold form-label'>Created By</label>
                <div className='me-2'>
                  <CourseSelect
                    role={selectedFilter}
                    onChange={(e: any) => {
                      setSelectRole(e?.value);
                      setSelectedFilter(e);
                    }}
                  />
                </div>
                <div className='me-2'>
                  <TableGlobalSearch />
                </div>
                <div>
                  <button
                    title='Add language'
                    className='btn btn-primary'
                    onClick={() => {
                      setAddEditModel((prevState) => {
                        const data = {
                          ...prevState,
                          id: null,
                          show: true,
                        };
                        return data;
                      });
                    }}
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr075.svg'
                      className='svg-icon-3'
                    />
                    Add Course
                  </button>
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner loading={false} entityLabel='Courses' />
                </div>
                <div className='col-12 border-top pt-5'></div>
                <TablePagination />
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>

      <DeleteConfirmationModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
      {addEditModel.show && (
        <CourseAddEditModal
          addEditModel={addEditModel}
          handleCloseAddEditModel={handleCloseAddEditModel}
        />
      )}
    </React.Fragment>
  );
};

export default Course;
