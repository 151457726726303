import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

import QuizListenItActivity from '../activity/quiz-listen-it-activity';
import QuizTapItActivity from '../activity/quiz-tap-it-activity';
import QuizWriteItActivity from '../activity/quiz-write-it-activity';

const QuizSection: React.FC<any> = (props: any) => {
  const {
    errors,
    register,
    setValue,
    lessonSingleData,
    loading,
    nativeLang,
    targetLang,
  } = props;

  const [quizDetail, setQuizDetail] = useState('');

  useEffect(() => {
    const violation: any = document.getElementById('quizComponents');
    window.scrollTo({
      top: violation.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (lessonSingleData != '' && lessonSingleData != undefined) {
      setQuizDetail(
        lessonSingleData?.Activity_Details?.filter(
          (x: any) => x.Section == 'QuizSection'
        )[0]
      );
      const quizData = lessonSingleData?.Activity_Details?.filter(
        (x: any) => x.Section == 'QuizSection'
      )[0];
      // setValue("Quiz_Title", quizData?.Quiz_Title)
      // setValue("Quiz_Description", quizData?.Quiz_Discription)

      // window.scrollTo(0, 0)
      // const violation: any = document.getElementById('quizComponents');
      // violation.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }, [lessonSingleData]);

  return (
    <>
      <div
        className='add-lesson mt-3 activity-main card-box'
        id='quizComponents'
      >
        <Collapsible
          trigger={
            <div style={{ width: '100%', position: 'relative' }}>
              <h1 className='border-bottom pb-5'>
                <i className='fa fa-duotone fa-file-lines me-2 fs-2'></i> Quiz
                Activity{' '}
                <BsChevronDown style={{ position: 'absolute', right: '0' }} />
              </h1>
            </div>
          }
          open={true}
        >
          <div className='add-lesson-row-section d-none'>
            <div className='col-md-12 mt-3 form-group'>
              <label className='fw-bold form-label'>
                Quiz Title <span className='astrick'>*</span>
              </label>
              <input
                type='text'
                {...register('Quiz_Title')}
                className={
                  errors.Quiz_Title ? 'form-control errors' : 'form-control'
                }
                placeholder='Enter Title'
                disabled={loading}
              ></input>
              {errors.Quiz_Title && (
                <p style={{ color: 'red' }}>{errors.Quiz_Title.message}</p>
              )}
            </div>
          </div>
          <div className='add-lesson-row-section d-none'>
            <div className='mt-3 col-md-12 form-group '>
              <label className='fw-bold form-label'>
                Description <span className='astrick'>*</span>
              </label>
              <textarea
                typeof='text'
                className='form-control'
                placeholder='Enter Description'
                {...register('Quiz_Description')}
                disabled={loading}
              />
              {errors.Quiz_Description && (
                <p style={{ color: 'red' }}>
                  {errors.Quiz_Description.message}
                </p>
              )}
            </div>
          </div>
          <QuizTapItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            quizDetail={quizDetail}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
          <QuizListenItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            quizDetail={quizDetail}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
          <QuizWriteItActivity
            errors={errors}
            register={register}
            setValue={setValue}
            quizDetail={quizDetail}
            loading={loading}
            nativeLang={nativeLang}
            targetLang={targetLang}
          />
        </Collapsible>
      </div>
    </>
  );
};
export default QuizSection;
